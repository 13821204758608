import AdminComponent from "@/components/dashboard/AdminComponent";
import FleetSearch from "@/views/search/FleetSearch";
import FleetCreate from "@/views/create/FleetCreate";
import FleetUpdate from "@/views/update/FleetUpdate";
import FleetUpdatePhoto from "@/views/update/FleetUpdatePhoto";
import FleetShow from "@/views/show/FleetShow";

export default {
    path: '/fleet',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'fleet-search', component: FleetSearch },
        { path: 'create', name: 'fleet-create', component: FleetCreate },
        { path: 'edit/:id', name: 'fleet-edit', component: FleetUpdate, props: true },
        { path: 'show/:id', name: 'fleet-show', component: FleetShow, props: true },
        { path: 'photo/:id', name: 'fleet-update-photo', component: FleetUpdatePhoto, props: true },
    ],
}