<template>
  <div class="preloderSend">
    <h1>{{textTitle}}</h1>
  </div>
</template>

<script>
export default {
  name: "LoderSendComponent",


  props:{
    textTitle: {
      'type': String,
      "required": true,
    },

  }

}
</script>

<style scoped>



.preloderSend h1 {
  font-family: 'Comfortaa', cursive;
  font-size: 20px;
  padding: 10px 0;
}

.preloderSend h1:after{
  content: "";
  position: absolute;
  animation: load 3s infinite;
}
@keyframes load{
  0% {
    content: "";
  }
  33% {
    content: ".";
  }
  66% {
    content: "..";
  }
  99% {
    content: "...";
  }
}



</style>