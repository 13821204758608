<template>
    <div class="modalProvider" v-if="modal_email || modal_phone || modal_template">

        <div v-if="modal_email">
            <div class="btn-close" @click="openEmail"></div>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Email:</label>
                <Multiselect v-model="formDate.email" label="name" :options="selectClientEmailList"
                             :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>

            <button-one @click="openEmail" :name-button="'Confirmar'"/>
        </div>

        <div v-if="modal_phone">
            <div class="btn-close" @click="openPhone"></div>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Telefone:</label>
                <Multiselect v-model="formDate.phone" label="name" :options="selectClientPhoneList"
                             :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>

            <button-one @click="openPhone" :name-button="'Confirmar'"/>
        </div>

        <div v-if="modal_template">
            <div class="btn-close" @click="modal_template = !modal_template"></div>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                               :class="{ 'formError': errors.contract_models_id }">
                <label>Modelo de Contrato:</label>
                <Multiselect v-model="formDate.contract_models_id" :searchable="true" :options="selectContractModel"
                             @select="getContractModel(formDate.contract_models_id)"
                             :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
            </row-col-component>

            <button-one @click="modal_template = !modal_template" :name-button="'Confirmar'"/>
        </div>
    </div>

    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div class="tabs effect-3" v-else>
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="file icon"></i>
                <p>Contrato</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <div class="clearfix mb-3">
                        <button-one @click="modal_template = !modal_template" class="float-end" icon="search plus"
                                    tooltip="Buscar modelo de contrato"/>
                    </div>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.name }">
                            <label>Nome do Contrato:</label>
                            <input-component :type-input="'text'" v-model="formDate.name"/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.client_id }">
                            <label>Cliente:</label>
                            <Multiselect v-model="client" label="name" :options="selectClientList"
                                         @select="getClient(client)"
                                         :searchable="true" :noResultsText="noResultsText"
                                         :noOptionsText="noResultsText">
                                <template v-slot:singlelabel="{ value }">
                                    <div class="multiselect-single-label">
                                        <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                    </div>
                                </template>

                                <template v-slot:option="{ option }">
                                    <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                                </template>
                            </Multiselect>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.email }">
                            <label>Email:</label>
                            <div class="input-group">
                                <input class="form-control" type="email" v-model="formDate.email">
                                <span @click="openEmail" class="input-group-text my_btn" id="basic-addon1">
                                    <i class="search icon"></i>
                                </span>
                            </div>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                            <label>Telefone:</label>
                            <div class="input-group">
                                <input class="form-control" type="text" v-model="formDate.phone"
                                       v-mask="['(##) ####-####', '(##) #####-####']">
                                <span @click="openPhone" class="input-group-text my_btn" id="basic-addon1">
                                    <i class="search icon"></i>
                                </span>
                            </div>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.business }">
                            <label>Serviços:</label>
                            <Multiselect v-model="formDate.services" mode="tags" :close-on-select="false"
                                         :searchable="true" :options="selectService"
                                         :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <label>Observações:</label>
                            <input-text-area :rows="4" v-model="formDate.obs"/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <button-one class="w-100" @submit="submit" :icon="'plus circle'"
                                        :name-button="'Adicionar'" :class="{ 'preloderSend': sendForm }"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <button-float class="w-100" :itens="itensTwo"/>
                        </row-col-component>
                    </row-component>
                </section>

                <section id="tab-item-2">
                    <button-one @click="addItems" icon="plus"/>


                    <div class="alert alert-secondary alert-dismissible fade show mt-3" role="alert"
                         v-if="formDate.itens.length === 0">
                        <p>Não há cláusulas cadastradas</p>
                    </div>

                    <draggable v-else v-model="formDate.itens" @start="drag=true" @end="drag=false" item-key="id"
                               :disabled="disabled">
                        <template #item="{element, index}">
                            <div class="accordion mt-3" :id="'accordionExample' + index">
                                <div class="accordion-item position-relative">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                :data-bs-target="'#collapseOne' + index" aria-expanded="true"
                                                aria-controls="collapseOne">
                                            Cláusula {{ index + 1 }}
                                        </button>

                                        <div class="btn-group" role="group">
                                            <button class="btn btn-main" @click="deleteItem(index)">
                                                <i class="trash icon"></i>
                                            </button>
                                        </div>
                                    </h2>

                                    <div :id="'collapseOne' + index" class="accordion-collapse collapse show"
                                         :data-bs-parent="'#accordionExample' + index">
                                        <div class="accordion-body">
                                            <row-component>
                                                <row-col-component
                                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                                    <label>Importante:</label>
                                                    <checkbox-component class="my-2"
                                                                        :input-checked="parseInt(element.important) === 2"
                                                                        @checkboxSwitcher="importantChange(index)"/>
                                                </row-col-component>
                                            </row-component>

                                            <QuillEditor v-if="!drag" :contentType="'html'" :toolbar="toolbar"
                                                         @focus="disabled=true" theme="snow" @blur="disabled=false"
                                                         v-model:content="element.item"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </draggable>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputTextArea from "@/components/form/InputTextArea";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import {mask} from 'vue-the-mask'
import {MONEY} from "@/configs/constants"
import {VMoney} from 'v-money'
import {useToast} from "vue-toastification";
import {ACTION_MESSAGES} from "@/configs/constants";
import CheckboxComponent from "@/components/form/CheckboxComponent";
import draggable from "vuedraggable";
import {QuillEditor, Quill} from "@vueup/vue-quill";

const toast = useToast();

let Font = Quill.import('formats/font');
Font.whitelist = ['serif', 'monospace', 'Calibri'];

Quill.register(Font, true);

export default {
    name: "ContractCreate",

    created() {
        this.$store.dispatch('updateNamePage', 'Contrato')
        this.$store.dispatch('loadContractModelList')
        this.$store.dispatch('loadClientListSelect')
        this.$store.dispatch('loadServiceList')
        this.checkACL()
        this.cloneContract()
    },

    components: {
        QuillEditor,
        draggable,
        CheckboxComponent,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        InputTextArea,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
    },

    directives: {
        mask,
        money: VMoney
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/contract/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                proposal_id: '',
                contract_models_id: '',
                client_id: '',
                email: '',
                phone: '',
                business: '',
                obs: '',
                services: [],
                itens: [],
            },

            client: '',
            client_name: '',
            modal_phone: false,
            modal_email: false,
            modal_template: false,

            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,

            disabled: false,
            drag: false,

            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{'header': 1}, {'header': 2}],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{'direction': 'rtl'}],
                [{'size': ['small', false, 'large', 'huge']}],
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'color': []}, {'background': []}],
                [{'align': []}],
                ['clean']
            ],
        }
    },

    methods: {
        scrollTop() {
            window.scrollTo(0, 0)
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeContract', this.formDate)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                        this.$router.push({name: 'contract-edit', params: {id: r.data}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 28)
                .then((r) => {
                    if (r.data.indexOf('contract-create') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        cloneContract() {
            if (this.storeState.length > 0) {
                this.storeState.forEach((element) => {
                    let section = element.sections
                    section.forEach((el) => {
                        delete Object.assign(el, {['section']: el['item']})['item'];
                    })
                })
                if (this.storeState.length > 1)
                    this.storeState.splice(0, 1)
                this.formDate = this.storeState[0]
                this.client = this.storeState[0].client_id
            }
        },

        // Modal Functions
        openEmail() {
            this.modal_email = !this.modal_email
        },

        openPhone() {
            this.modal_phone = !this.modal_phone
        },

        getContractModel(id) {
            this.$store.dispatch('loadContractModelGet', id)
                .then((r) => {
                    this.formDate.name = r.name
                    this.formDate.obs = r.obs

                    r.itens.forEach((el)=>{
                        this.formDate.itens.push({
                            item: el.item,
                            important: el.important,
                            order: el.order,
                        })
                    })

                })
        },

        getClient(id) {
            this.$store.dispatch('loadClient', id).then((r) => {
                this.formDate.client_id = r.id
                this.$store.dispatch('loadEmailClient', r.id)
                this.$store.dispatch('loadPhoneClient', r.id)
            })
        },

        addItems() {
            this.formDate.itens.push({
                item: "",
                important: 2,
                order: "",
            })
        },

        deleteItem(index) {
            this.formDate.itens.splice(index, 1)
        },

        importantChange(index) {
            let important = this.formDate.itens[index].important
            if (parseInt(important) === 1) {
                this.formDate.itens[index].important = 2
            } else {
                this.formDate.itens[index].important = 1
            }
        },
    },

    beforeMount() {
        this.storeState.splice(0, 1)
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectContractModel() {
            return this.$store.state.var.selectContractModel
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectClientEmailList() {
            return this.$store.state.var.selectClientEmailList
        },

        selectClientPhoneList() {
            return this.$store.state.var.selectClientPhoneList
        },

        selectBusiness() {
            return this.$store.state.var.selectBusiness
        },

        selectService() {
            return this.$store.state.var.selectService
        },

        storeState() {
            return this.$store.state.var.storeState
        },
    },

}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.btn-close {
    top: 0;
    right: 0;
    margin: 1%;
    cursor: pointer;
    position: absolute;
}

.modalProvider {
    top: 50%;
    left: 50%;
    width: 40%;
    z-index: 9999;
    position: fixed;
    min-height: auto;
    background: white;
    padding: 40px 20px;
    overflow-y: scroll;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.my_btn {
    color: white;
    cursor: pointer;
    position: relative;
    background: #d67c1c;
    padding-bottom: 15px;
    border: 1px solid #d67c1c;
}

@media (max-width: 428px) {
    .modalProvider {
        width: 96%;
    }
}

.btn-group {
    top: 0;
    right: 40px;
    margin: 6px;
    z-index: 20;
    position: absolute;
}

.btn-main {
    color: #d67c1c;
    --bs-btn-border-width: 0;
}

.btn-main:active,
.btn-main:focus,
.btn-main:hover,
.btn-main:focus-visible {
    border: 0;
    color: #d67c1c;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: #000000;
    background-color: #f0f0f0;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:focus {
    box-shadow: none;
}

@font-face {
    font-family: "Calibri";
    src: url("@/assets/fonts/calibri-regular.ttf");
}

.ql-font-Calibri {
    font-family: Calibri;
}
</style>

<style>
.ql-container {
    font-size: 16px;
    font-family: 'Calibri';
}
</style>