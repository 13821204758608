export default {

    icon: 'box icon',
    name: 'Estoque',
    subLinks: [
        {
            name: 'Produtos',
            route: '/product/search',
            pagePermissions: [
                'product-edit',
                'product-search',
                'product-create'
            ],
        },
        {
            name: 'Entrada / Saída',
            route: '/inventorie/search',
            pagePermissions: [
                'inventorie-create',
                'inventorie-search'
            ],
        },
    ],

    pageLinksNavCheck: [
        'product-edit',
        'product-search',
        'product-create',
        'inventorie-create',
        'inventorie-search'
    ],
}