import menuCadastro from "@/configs/linksMenu/modules/menuCadastro";
import menuProposta from "@/configs/linksMenu/modules/menuProposta";
import menuContrato from "@/configs/linksMenu/modules/menuContrato";
import menuFinanceiro from "@/configs/linksMenu/modules/menuFinanceiro";
import menuPlanejamento from "@/configs/linksMenu/modules/menuPlanejamento";
import menuGrafico from "@/configs/linksMenu/modules/menuGrafico";
import menuReport from "@/configs/linksMenu/modules/menuReport";
import menuEstoque from "@/configs/linksMenu/modules/menuEstoque";
import menuSite from "@/configs/linksMenu/modules/menuSite";
import menuManutencao from "@/configs/linksMenu/modules/menuManutencao";

export default {
    menuCadastro,
    menuContrato,
    menuFinanceiro,
    menuPlanejamento,
    menuGrafico,
    menuProposta,
    menuReport,
    menuEstoque,
    menuSite,
    menuManutencao,
}