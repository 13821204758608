import AdminComponent from "@/components/dashboard/AdminComponent";
import ProviderSearch from "@/views/search/ProviderSearch";
import ProviderCreate from "@/views/create/ProviderCreate";
import ProviderUpdate from "@/views/update/ProviderUpdate";
import ProviderUpdatePhoto from "@/views/update/ProviderUpdatePhoto";
import ProviderShow from "@/views/show/ProviderShow"

export default {
    path: '/provider',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'provider-search', component: ProviderSearch },
        { path: 'create', name: 'provider-create', component: ProviderCreate },
        { path: 'edit/:id', name: 'provider-edit', component: ProviderUpdate, props: true },
        { path: 'photo/:id', name: 'provider-update-photo', component: ProviderUpdatePhoto, props: true },
        { path: 'show/:id', name: 'provider-show', component: ProviderShow, props: true },
    ],
}