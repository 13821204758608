import AdminComponent from "@/components/dashboard/AdminComponent";
import BudgetTemplateSearch from "@/views/search/BudgetTemplateSearch";
import BudgetTemplateCreate from "@/views/create/BudgetTemplateCreate";
import BudgetTemplateUpdate from "@/views/update/BudgetTemplateUpdate";
import BudgetTemplateShow from "@/views/show/BudgetTemplateShow";

export default {
    path: '/budget-template',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'create', name: 'budget-template-create', component: BudgetTemplateCreate },
        { path: 'search', name: 'budget-template-search', component: BudgetTemplateSearch },
        { path: 'show/:id', name: 'budget-template-show', component: BudgetTemplateShow, props: true },
        { path: 'edit/:id', name: 'budget-template-edit', component: BudgetTemplateUpdate, props: true },
    ],
}
