import AdminComponent from "@/components/dashboard/AdminComponent";
import SpotsSearch from "@/views/search/SpotsSearch";
import SpotsCreate from "@/views/create/SpotsCreate";
import SpotsUpdate from "@/views/update/SpotsUpdate";
import SpotsShow from "@/views/show/SpotsShow";

export default {
    path: '/spots',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'spots-search', component: SpotsSearch },
        { path: 'create', name: 'spots-create', component: SpotsCreate },
        { path: 'edit/:id', name: 'spots-edit', component: SpotsUpdate, props: true },
        { path: 'show/:id', name: 'spots-show', component: SpotsShow, props: true },
    ],
}