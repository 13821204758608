<template>
    <painel-component :itens="itensOne">

        <error-component :errors="errors" v-if="showError"></error-component>

        <loder-send-component :text-title="'Carregando'" v-if="showLoder"></loder-send-component>

        <!-- <div class="tabs effect-3" v-else>

        <input type="radio" id="tab-1" name="tab-effect-3" checked="checked">
        <span>
            <i class="info circle icon"></i>
            <p>Informações</p>
        </span>

        <input type="radio" id="tab-2" name="tab-effect-3">
        <span>
            <i class="cogs icon"></i>
            <p>Equipamentos</p>
        </span>

        <input type="radio" id="tab-3" name="tab-effect-3">
        <span>
            <i class="clock outline icon"></i>
            <p>Horário de Funcionamento</p>
        </span>

        <input type="radio" id="tab-4" name="tab-effect-3">
        <span>
            <i class="wifi icon"></i>
            <p>Redes Wifi</p>
        </span>

        <input type="radio" id="tab-5" name="tab-effect-3">
        <span>
            <i class="user circle icon"></i>
            <p>Responsáveis</p>
        </span>

        <div class="line ease"></div>

        <div class="tab-content">
            <section id="tab-item-1">
                <form autocomplete="off">
                    <row-component>
                        <row-col-component
                            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                            :class="{ 'formError': errors.name }">
                            <label>Ponto nome:</label>
                            <input-component :type-input="'text'" v-model="formDate.name" disabled
                                :model-value="formDate.name">
                            </input-component>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component
                            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                            :class="{ 'formError': errors.name }">
                            <label>Título:</label>
                            <input-component :type-input="'text'" v-model="formDate.title" disabled
                                :model-value="formDate.title">
                            </input-component>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                            :class="{ 'formError': errors.type }">
                            <label>Tipo de Mídia:</label>
                            <multiselect v-model="formDate.type" :searchable="true" :options="selectTypeMedia"
                                disabled :noResultsText="this.$store.state.var.selectNotSearch" />
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                            :class="{ 'formError': errors.status }">
                            <label>Status:</label>
                            <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                disabled :noResultsText="this.$store.state.var.selectNotSearch" />
                        </row-col-component>

                        <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                            <label>Público (QT/Mês):</label>
                            <input-number-component :type-input="'number'" min="1" v-model="formDate.public"
                                disabled placeholder="1" :model-value="formDate.public">
                            </input-number-component>
                        </row-col-component>

                        <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                            <label>Moradores (QT):</label>
                            <input-number-component :type-input="'number'" min="1" v-model="formDate.residents"
                                disabled placeholder="1" :model-value="formDate.residents">
                            </input-number-component>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                            :class="{ 'formError': errors.monitors_tvs }">
                            <label>Tvs/Monitores (QT):</label>
                            <input-number-component :type-input="'number'" min="1" v-model="formDate.monitors_tvs"
                                disabled placeholder="1" :model-value="formDate.monitors_tvs">
                            </input-number-component>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component
                            :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>CEP:</label>
                            <input-component @change="searchApiCEP" v-mask="'#####-###'" :type-input="'text'"
                                disabled v-model="formDate.cep" :model-value="formDate.cep">
                            </input-component>
                        </row-col-component>

                        <row-col-component
                            :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Cidade:</label>
                            <input-component :type-input="'text'" v-model="formDate.city" disabled
                                :model-value="formDate.city">
                            </input-component>
                        </row-col-component>

                        <row-col-component
                            :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Estado:</label>
                            <input-component :type-input="'text'" v-model="formDate.state" disabled
                                :model-value="formDate.state">
                            </input-component>
                        </row-col-component>

                        <row-col-component
                            :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Bairro:</label>
                            <input-component :type-input="'text'" v-model="formDate.district" disabled
                                :model-value="formDate.district">
                            </input-component>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component
                            :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                            <label>Endereço:</label>
                            <input-component :type-input="'text'" v-model="formDate.address" disabled
                                :model-value="formDate.address">
                            </input-component>
                        </row-col-component>

                        <row-col-component
                            :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                            <label>País:</label>
                            <input-component :type-input="'text'" v-model="formDate.country" disabled
                                :model-value="formDate.country">
                            </input-component>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component
                            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <label>Referência:</label>
                            <input-text-area :rows="4" v-model="formDate.complement" disabled>
                            </input-text-area>
                        </row-col-component>
                    </row-component>

                </form>

                <div class="line-division mt-5 mb-5">
                    <h1 class="h1-text"><i class="comment icon"></i> Observações</h1>
                </div>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <label>Observações:</label>

                        <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                            v-if="formDate.obsPrivate.length == 0">
                            <p>Não há nada cadastrado</p>
                        </div>

                        <div class="merito" v-else>
                            <section class="container-desc">
                                <ul class="task-desc">
                                    <li class="item" v-for="(obs, index) in formDate.obsPrivate" :key="index">

                                        <div class="aux-row">
                                            <div class="user">
                                                <img :src="obs.user_photo" alt="Image 001" class="owner-img" />
                                                {{ obs.user_name }}
                                            </div>
                                            <div class="desc-date">
                                                <div class="text"><i class="calendar icon"></i>{{ obs.date_create }}
                                                </div>
                                                <div class="text"><i class="clock icon"></i>{{ obs.hour_create }}
                                                </div>
                                            </div>
                                        </div>
                                        <p>{{ obs.obs }}</p>
                                    </li>
                                </ul>
                            </section>
                        </div>
                    </row-col-component>
                </row-component>

                <form autocomplete="off">
                    <row-component>
                        <row-col-component
                            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <label>Observações:</label>
                            <input-text-area :rows="4" v-model="formDateObs.obs">
                            </input-text-area>
                        </row-col-component>
                    </row-component>
                </form>

                <button-one @submit="submitObs" :icon="'plus circle'" :name-button="'Adicionar Observação'">
                </button-one>
            </section>

            <section id="tab-item-2">

                <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                    v-if="formDate.equipments.length == 0">
                    <p>Não há nada cadastrado</p>
                </div>

                <div class="border-content" v-for="(equipament, index) in equipmentsAdd" :key="index">
                    <form>
                        <row-component>
                            <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Equipamento Nome:</label>
                                <input-component :type-input="'text'" v-model="equipament.name" disabled>
                                </input-component>
                            </row-col-component>
                        </row-component>
                    </form>
                </div>
            </section>

            <section id="tab-item-3">

                <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                    v-if="formDate.opening_hours.length == 0">
                    <p>Não há nada cadastrado</p>
                </div>

                <div class="border-content" v-for="(time, index) in openingHours" :key="index">
                    <form>
                        <row-component>
                            <row-col-component
                                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Dia da Semana:</label>
                                <multiselect v-model="time.week" :searchable="true" :options="selectDayWeek"
                                    :noResultsText="this.$store.state.var.selectNotSearch" disabled />
                            </row-col-component>

                            <row-col-component
                                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Horário:</label>
                                <input-component :type-input="'text'" v-model="time.hour" disabled>
                                </input-component>
                            </row-col-component>
                        </row-component>
                    </form>
                </div>
            </section>

            <section id="tab-item-4">
                <div class="container-1">
                    <button class="addButton" type="button" @click="addPassword"><i class="plus icon"></i></button>
                </div>

                <div class="border-content" v-for="(data, index) in passwordAdd" :key="index">
                    <form>
                        <button class="deleteButton" type="button" @click="removePassword(index)"
                            v-if="index > 0"><i class="trash icon"></i></button>

                        <row-component>
                            <row-col-component
                                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Nome da Rede:</label>
                                <input-component :type-input="'text'" v-model="data.name">
                                </input-component>
                            </row-col-component>

                            <row-col-component
                                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Senha:</label>
                                <input-component :type-input="'text'" v-model="data.password">
                                </input-component>
                            </row-col-component>
                        </row-component>
                    </form>
                </div>
            </section>

            <section id="tab-item-5">
                <div class="container-1">
                    <button class="addButton" type="button" @click="addResponsibles"><i
                            class="plus icon"></i></button>
                </div>

                <div class="border-content" v-for="(data, index) in responsiblesAdd" :key="index">
                    <form>
                        <button class="deleteButton" type="button" @click="removeResponsibles(index)"
                            v-if="index > 0"><i class="trash icon"></i></button>

                        <row-component>
                            <row-col-component
                                :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <input-component :type-input="'text'" v-model="data.name">
                                </input-component>
                            </row-col-component>

                            <row-col-component
                                :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Função</label>
                                <multiselect v-model="data.occupation" :searchable="true"
                                    :options="selectOccupation"
                                    :noResultsText="this.$store.state.var.selectNotSearch" />
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                                <label>Email:</label>
                                <input-component :type-input="'email'" v-model="data.email">
                                </input-component>
                            </row-col-component>

                            <row-col-component
                                :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Telefone:</label>
                                <input-component v-mask="['(##) ####-####', '(##) #####-####']" :type-input="'text'"
                                    v-model="data.phone">
                                </input-component>
                            </row-col-component>
                        </row-component>
                    </form>
                </div>
            </section>

        </div>
    </div> -->

        <form autocomplete="off" v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Ponto nome:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" disabled :model-value="formDate.name">
                    </input-component>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Título:</label>
                    <input-component :type-input="'text'" v-model="formDate.title" disabled
                                     :model-value="formDate.title">
                    </input-component>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.type }">
                    <label>Tipo de Mídia:</label>
                    <multiselect v-model="formDate.type" :searchable="true" :options="selectTypeMedia" disabled
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectInput" disabled
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Segmento:</label>
                    <multiselect v-model="formDate.segment" :searchable="true" :options="selectSpotSegment"
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Público (QT/Mês):</label>
                    <input-number-component :type-input="'number'" min="1" v-model="formDate.public" disabled
                                            placeholder="1" :model-value="formDate.public">
                    </input-number-component>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.monitors_tvs }">
                    <label>Tvs/Monitores (QT):</label>
                    <input-number-component :type-input="'number'" min="1" v-model="formDate.monitors_tvs" disabled
                                            placeholder="1" :model-value="formDate.monitors_tvs">
                    </input-number-component>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>CEP:</label>
                    <input-component @change="searchApiCEP" v-mask="'#####-###'" :type-input="'text'" disabled
                                     v-model="formDate.cep" :model-value="formDate.cep">
                    </input-component>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Cidade:</label>
                    <input-component :type-input="'text'" v-model="formDate.city" disabled :model-value="formDate.city">
                    </input-component>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Estado:</label>
                    <input-component :type-input="'text'" v-model="formDate.state" disabled
                                     :model-value="formDate.state">
                    </input-component>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Bairro:</label>
                    <input-component :type-input="'text'" v-model="formDate.district" disabled
                                     :model-value="formDate.district">
                    </input-component>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <label>Endereço:</label>
                    <input-component :type-input="'text'" v-model="formDate.address" disabled
                                     :model-value="formDate.address">
                    </input-component>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>País:</label>
                    <input-component :type-input="'text'" v-model="formDate.country" disabled
                                     :model-value="formDate.country">
                    </input-component>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Referência:</label>
                    <input-text-area :rows="4" v-model="formDate.complement" disabled>
                    </input-text-area>
                </row-col-component>
            </row-component>

        </form>

        <div class="line-division mt-5 mb-5">
            <h1 class="h1-text"><i class="comment icon"></i> Observações</h1>
        </div>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Observações:</label>

                <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                     v-if="formDate.obsPrivate.length == 0">
                    <p>Não há nada cadastrado</p>
                </div>

                <div class="merito" v-else>
                    <section class="container-desc">
                        <ul class="task-desc">
                            <li class="item" v-for="(obs, index) in formDate.obsPrivate" :key="index">

                                <div class="aux-row">
                                    <div class="user">
                                        <img :src="obs.user_photo" alt="Image 001" class="owner-img"/>
                                        {{ obs.user_name }}
                                    </div>
                                    <div class="desc-date">
                                        <div class="text"><i class="calendar icon"></i>{{ obs.date_create }}
                                        </div>
                                        <div class="text"><i class="clock icon"></i>{{ obs.hour_create }}
                                        </div>
                                    </div>
                                </div>
                                <p>{{ obs.obs }}</p>
                            </li>
                        </ul>
                    </section>
                </div>
            </row-col-component>
        </row-component>

        <form autocomplete="off">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>
                    <input-text-area :rows="4" v-model="formDateObs.obs">
                    </input-text-area>
                </row-col-component>
            </row-component>
        </form>

        <button-one @submit="submitObs" :icon="'plus circle'" :name-button="'Adicionar Observação'">
        </button-one>


    </painel-component>

    <button-float :itens="itensTwo"></button-float>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputNumberComponent from "@/components/form/InputNumberComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";
import {ESTADOS} from "@/configs/constants";

const toast = useToast();

export default {
    name: "SpotsShow",

    created() {
        this.$store.dispatch('updateNamePage', 'Pontos')
        this.loadPageItens()
        this.checkACL()
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "eye"
            },

            itensTwo: {
                route: "/spots/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                title: '',
                type: '',
                public: '',
                segment: '',
                monitors_tvs: '',
                status: '',
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: '',
                equipments: [],
                opening_hours: [],
                passwords: [],
                responsibles: [],
                obsPrivate: ''
            },

            formDateObs: {
                id: this.id,
                obs: ''
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoder: true,
        }
    },

    methods: {

        submitObs() {
            this.$store.dispatch('storeSpotObs', this.formDateObs)
                .then(() => {
                    this.showError = false
                    this.sendForm = false
                    this.errors = []
                    this.loadPageItens()
                    this.formDateObs.obs = ''
                    toast.success('Observção adicionada com sucesso!', {timeout: 2000});
                }).catch((r) => {
                this.showError = true;
                this.sendForm = false
                this.errors = r.response.data.errors;
                this.scrollTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 29)
                .then((r) => {
                    if (r.data.indexOf('spot-edit') !== -1) {
                        this.showLoder = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        loadPageItens() {
            this.$store.dispatch('loadSpot', this.id).then((r) => {
                this.formDate = r;
                this.$store.dispatch('loadSpotSegmentList')
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        searchApiCEP() {
            let cep = this.formDate.cep.replace(/\D/g, '');
            const dateCEP = {
                'cep': cep
            }
            this.$store.dispatch('searchApiCEP', dateCEP)
                .then((r) => {
                    this.formDate.city = r.data.localidade
                    this.formDate.state = r.data.uf
                    this.formDate.state = ESTADOS[r.data.uf]
                    this.formDate.district = r.data.bairro
                    this.formDate.address = r.data.logradouro
                    this.formDate.complement = r.data.complemento
                }).catch(() => {
            })
        },
    },

    directives: {
        mask,
    },

    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        InputNumberComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea
    },

    computed: {
        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        selectTypeMedia() {
            return this.$store.state.var.selectTypeMedia
        },

        selectDayWeek() {
            return this.$store.state.var.selectDayWeek
        },

        selectOccupation() {
            return this.$store.state.var.selectOccupation
        },

        selectSpotSegment() {
            return this.$store.state.var.selectSpotSegment
        },

        equipmentsAdd() {
            return this.formDate.equipments
        },

        openingHours() {
            return this.formDate.opening_hours
        },

        passwordAdd() {
            return this.formDate.passwords
        },

        responsiblesAdd() {
            return this.formDate.responsibles
        }
    }

}
</script>

<style>
.merito {
    height: 200px;
    width: 100%;
    padding: 0px 20px;
    background: #ebebeb;
    overflow-x: scroll;
}

ul.task-desc li.item {
    position: relative;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 20px 0;
    padding: 0px 20px;
    background: transparent;
    box-shadow: none;
}
</style>
  
  
  
