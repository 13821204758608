<template>
    <painel-component :itens="itensOne">
        <div class="filter-position" v-if="btnFilter">
            <button-one class="btn-filter" icon="filter" @click="filterWindow"/>
        </div>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Cliente:</label>
                        <Multiselect v-model="formDate.client_id" label="name" :options="selectClientList"
                                     :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                            </template>
                        </Multiselect>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Numero / Desc:</label>
                        <input-component :type-input="'text'" v-model="formDate.number"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDate.status" :searchable="true" :options="selectStatusContractExpired"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'">
                        <button-one class="mx-1" @submit="submit" :icon="'search'"/>
                        <button-float class="mx-1" :itens="itensTwo" v-if="buttonCheck.create"/>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end'">
                    <div class="toggle_preferences">
                        <button :class="{'bg-active' : parseInt(cardTable) === 1}" @click="toggleCard" type="button"
                                tooltip="Card">
                            <i class="window maximize outline icon"></i>
                        </button>
                        <button :class="{'bg-active' : parseInt(cardTable) === 2}" @click="toggleTable" type="button"
                                tooltip="Tabela">
                            <i class="align justify icon"></i>
                        </button>
                    </div>
                </row-col-component>
            </row-component>

            <row-component v-if="parseInt(cardTable) === 1">
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-4'"
                                   v-for="(data, index) in objectReturn.itens.data" :key="index">
                    <div class="card-container">

                        <div class="flag-status" :class="[
                            { 'cadastrado': parseInt(data.status) === 1 },
                            { 'andamento': parseInt(data.status) === 2 },
                            { 'pausado': parseInt(data.status) === 3 },
                            { 'cancelado': parseInt(data.status) === 4 },
                            { 'finalizado': parseInt(data.status) === 5 }]">
                            {{ stringStatus(data.status) }}
                        </div>

                        <img v-if="data.photo_client" :src="data.photo_client" alt="">
                        <img v-else src="@/assets/img/avatar.png" alt="">
                        <p>{{ data.client_name }}</p>

                        <h3 class="title-container">Nº / Desc: {{ data.number }}</h3>
                        <p> {{ typeString(data.type) }} </p>

                        <div class="aux-row-3" v-if="data.dates">
                            <div class="text"><i class="calendar icon"></i>{{ convData(data.dates[0].date_start) }}
                            </div>
                            <div class="text"><i class="calendar icon"></i>{{ convData(data.dates[0].date_end) }}</div>
                        </div>

                        <p class="ok-tag" v-if="data.dates && !data.dates.expires">{{ data.dates.diff }}</p>
                        <p class="expired-tag" v-if="data.dates && data.dates.expires">{{ data.dates.diff }}</p>

                        <div class="buttons">
                            <button tooltip="Editar" class="primary" v-if="buttonCheck.edit"
                                    @click="routeEdit(data.id)">
                                <i class="edit icon"></i>
                            </button>

                            <button tooltip="Ver" class="primary" v-if="buttonCheck.show" @click="routeShow(data.id)">
                                <i class="eye icon"></i>
                            </button>

                            <button tooltip="Deletar" class="primary" v-if="buttonCheck.delete"
                                    @click="deleteSubmit(data)">
                                <i class="trash icon"></i>
                            </button>

                            <button v-if="data.dates && parseInt(data.status) === 2" tooltip="Finalizar" class="primary"
                                    @click="finalizeContractExpiration(data.id, data.dates[0].id)">
                                <i class="check icon"></i>
                            </button>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <table class="table table-striped" v-if="parseInt(cardTable) === 2">
                <thead>
                <tr>
                    <th scope="col" style="width: 15%">Cliente</th>
                    <th scope="col" style="width: 5%">Tipo</th>
                    <th scope="col" style="width: 10%">Nº / Desc</th>
                    <th scope="col" style="width: 10%">Status</th>
                    <th scope="col" style="width: 15%">Vencimento</th>
                    <th scope="col" style="width: 20%"
                        v-if="buttonCheck.edit || buttonCheck.show || buttonCheck.delete">Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in objectReturn.itens.data" :key="index">
                    <td data-label="Cliente">
                        <img v-if="data.photo_client" :src="data.photo_client" class="avatar__image_2" alt="">
                        <img v-else src="@/assets/img/avatar.png" class="avatar__image_2" alt="">
                        {{ data.client_name }}
                    </td>
                    <td data-label="Tipo">{{ typeString(data.type) }}</td>
                    <td data-label="Nº / Desc">{{ data.number }}</td>
                    <td data-label="Status">{{ stringStatus(data.status) }}</td>
                    <td data-label="Vencimento">
                        <div v-if="data.dates">
                            {{ convData(data.dates[0].date_start) }} |
                            {{ convData(data.dates[0].date_end) }}
                        </div>
                        <p class="ok-tag" v-if="data.dates && !data.dates.expires">{{ data.dates.diff }}</p>
                        <p class="expired-tag" v-if="data.dates && data.dates.expires">{{ data.dates.diff }}</p>
                    </td>
                    <td data-label="Ações" v-if="buttonCheck.edit || buttonCheck.show || buttonCheck.delete">
                        <button tooltip="Editar" class="btn btn-success button-show mx-1" v-if="buttonCheck.edit"
                                @click="routeEdit(data.id)">
                            <i class="edit icon"></i>
                        </button>
                        <button tooltip="Ver" class="btn btn-secondary button-show mx-1" v-if="buttonCheck.show"
                                @click="routeShow(data.id)">
                            <i class="eye icon"></i>
                        </button>
                        <button tooltip="Deletar" class="btn btn-danger button-show mx-1" v-if="buttonCheck.delete"
                                @click="deleteSubmit(data)">
                            <i class="trash icon"></i>
                        </button>
                        <button tooltip="Finalizar" class="btn btn-primary button-show mx-1"
                                v-if="data.dates && parseInt(data.status) === 2"
                                @click="finalizeContractExpiration(data.id, data.dates[0].id)">
                            <i class="check icon"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <button-pagination :pagination="objectReturn.itens" :offset="3" @paginate="loadContractsExpired"/>
        </div>
    </painel-component>
</template>

<script>
import ButtonPagination from "@/components/buttons/ButtonPagination";
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import InputComponent from "@/components/form/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";
import swal from 'sweetalert';
import {ACTION_MESSAGES} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "ContractExpirationSearch",
    components: {
        ButtonPagination,
        PainelComponent,
        ButtonOne,
        ButtonFloat,
        InputComponent,
        RowComponent,
        RowColComponent,
        LoderSendComponent,
        Multiselect,
    },

    created() {
        this.checkACL()
        this.loadContractsExpired(1)
        this.$store.dispatch('loadClientListSelect')
        this.$store.dispatch('updateNamePage', 'Vencimento')
        this.resizeWindow()
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            itensTwo: {
                route: "/contract-expiration/create",
                icon: "plus",
                name: ''
            },

            buttonCheck: {
                edit: false,
                create: false,
                show: false,
                delete: false
            },

            formDate: {
                client_id: '',
                number: '',
                status: ''
            },

            actionsFormDate: {
                id: '',
                id_date: ''
            },

            cardTable: '',
            preferences: {
                type: 4,
                preference: ''
            },

            filter: true,
            btnFilter: false,

            showLoader: true,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadContractsExpired(1)
            this.searchSubmit = true;
        },

        routeEdit(id) {
            this.$router.push({name: 'contract-expiration-edit', params: {id: id}})
        },

        routeShow(id) {
            this.$router.push({name: 'contract-expiration-show', params: {id: id}})
        },

        convData(data) {
            let stg = data.split('-')
            return `${stg[2]}/${stg[1]}/${stg[0]}`
        },

        toggleTable() {
            this.userPreferences(2)
            this.cardTable = 2
        },

        toggleCard() {
            this.userPreferences(1)
            this.cardTable = 1
        },

        userPreferences(preference) {
            this.preferences.preference = preference
            this.$store.dispatch('userPreferences', this.preferences)
        },

        stringStatus(value) {
            if (parseInt(value) === 1)
                return "Cadastrado"
            if (parseInt(value) === 2)
                return "Em andamento"
            if (parseInt(value) === 3)
                return "Pausado"
            if (parseInt(value) === 4)
                return "Cancelado"
            if (parseInt(value) === 5)
                return "Finalizado"
        },

        typeString(value) {
            if (parseInt(value) === 1)
                return "Manual"
            if (parseInt(value) === 2)
                return "Sistema"
            if (parseInt(value) === 3)
                return "PI"
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },

        finalizeContractExpiration(id, id_dates) {
            this.actionsFormDate.id = id
            this.actionsFormDate.id_date = id_dates
            this.$store.dispatch('finalizeContractExpiration', this.actionsFormDate)
                .then(() => {
                    toast.success('Finalizado', {timeout: 2000})
                    this.loadContractsExpired(1)
                })
                .catch(() => {
                    toast.error('Erro ao finalizar', {timeout: 2000})
                })
        },

        loadContractsExpired(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadContractsExpired', {...this.params, page})
                    .then((r) => {
                        this.cardTable = r.tableCard
                        this.showLoader = false
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        deleteSubmit(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar  ${item.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyContractExpiration', item.id).then(() => {
                        this.loadContractsExpired(1)
                        toast.success(ACTION_MESSAGES.delete, {timeout: 10000});
                    }).catch(() => {
                        toast.error(`Não foi possivel deletar  ${item.name} `, {timeout: 10000});
                    })
                }
            });
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 36)
                .then((r) => {
                    if (r.data.indexOf('contract-expiration-search') !== -1) {
                        this.showLoader = false

                        if (r.data.indexOf('contract-expiration-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('contract-expiration-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('contract-expiration-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('contract-expiration-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectStatusContractExpired() {
            return this.$store.state.var.selectStatusContractExpired
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        objectReturn() {
            return this.$store.state.contract_expiration.items
        },

        params() {
            return {
                page: this.objectReturn.current_page,
                client_id: this.formDate.client_id,
                number: this.formDate.number,
                status: this.formDate.status
            }
        },
    },
}
</script>

<style scoped src="@/assets/css/table-resp.css"></style>
<style scoped>
.toggle_preferences > button {
    border: 0;
    width: 45px;
    color: #fff;
    height: 45px;
    margin: 0 5px;
    padding-right: 4px;
    border-radius: 50%;
    background-color: #323232;
}

.button-show {
    width: 35px;
    height: 35px;
    font-size: 15px;
    border-radius: 50px;
    padding-left: 0.5rem;
}

.bg-active {
    background-color: #d67c1c !important;
}

.card-container {
    width: 350px;
    display: flex;
    color: #2f3032;
    margin: 20px 0;
    max-width: 100%;
    overflow: hidden;
    min-height: 390px;
    padding-top: 20px;
    position: relative;
    text-align: center;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff;
    border: solid 1px #ededed;
    box-shadow: 10px 10px 15px rgb(0 0 0 / 35%);
}

.card-container > img {
    width: 90px;
    padding: 7px;
    height: 90px;
    margin-top: 25px;
    border-radius: 50%;
    border: 1px solid #d67c1c;
}

.buttons {
    bottom: 0;
    width: 100%;
    display: flex;
    min-height: 60px;
    position: absolute;
    border-top: 1px solid #ededed;
}

button.primary {
    width: 100%;
    height: 60px;
    color: #7e7e7e;
    background-color: #f5f5f5;
    border: 1px solid #fdfdfd;
}

.flag-status {
    top: 0;
    width: 100%;
    color: #ededed;
    font-size: 14px;
    text-align: left;
    padding: 10px 20px;
    position: absolute;
    font-family: 'Comfortaa', sans-serif;
}

.cadastrado {
    background-color: #5a5a5a;
}

.andamento {
    background-color: #16ab39;
}

.pausado {
    background-color: #2185d0;
}

.cancelado {
    background-color: #db2828;
}

.finalizado {
    background-color: #b5cc18;
}

.expired-tag {
    color: #fff;
    padding: 0 15px;
    font-size: 11px;
    font-weight: bold;
    width: fit-content;
    margin-bottom: 10px;
    border-radius: 20px;
    text-transform: uppercase;
    background-color: #db2828;
}

.ok-tag {
    color: #fff;
    font-size: 11px;
    padding: 0 15px;
    font-weight: bold;
    width: fit-content;
    margin-bottom: 10px;
    border-radius: 20px;
    text-transform: uppercase;
    background-color: #16ab39;
}

.title-container {
    width: 100%;
    height: 35px;
    color: #424242;
    font-size: 14px;
    padding: 3px 15px;
    word-break: break-all;
    text-transform: uppercase;
}
</style>