import state from "@/store/modules/email-notification/state";
import actions from "@/store/modules/email-notification/actions";
import mutations from "@/store/modules/email-notification/mutations";
import getters from "@/store/modules/email-notification/getters";

export default {
  state,
  actions,
  mutations,
  getters
}