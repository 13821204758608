<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off" v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component :maxlength=100 :type-input="'text'" v-model="formDate.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.sector }">
                    <label>Setor:</label>
                    <Multiselect v-model="formDate.sector" :searchable="true" :options="selectSector"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.responsibles }">
                    <label>Responsáveis:</label>
                    <Multiselect v-model="formDate.responsibles" mode="tags" track-by="name" label="name"
                                 :options="selectUserList" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText" :close-on-select="false"
                                 :search="true">
                        <template v-slot:tag="{ option, handleTagRemove, disabled }">
                            <div class="multiselect-tag is-user" :class="{ 'is-disabled': disabled }">
                                <img :src="option.icon" alt="">
                                {{ option.name }}
                                <span v-if="!disabled" class="multiselect-tag-remove"
                                      @mousedown.prevent="handleTagRemove(option, $event)">
                                    <span class="multiselect-tag-remove-icon"></span>
                                </span>
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.frequency }">
                    <label>Periodicidade:</label>
                    <Multiselect v-model="formDate.frequency" :searchable="true" :options="selectFrequency"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <Multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.start_date }">
                    <label>Data Início:</label>
                    <input-component v-mask="'##/##/####'" :type-input="'text'" v-model="formDate.start_date"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.closing_date }">
                    <label>Data Encerramento:</label>
                    <input-component v-mask="'##/##/####'" :type-input="'text'" v-model="formDate.closing_date"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.desc }">
                    <label>Descrição:</label>
                    <QuillEditor theme="snow" :toolbar="toolbarOptions" :contentType="'html'"
                                 v-model:content="formDate.desc" style="min-height: 150px; margin-bottom: 2%"/>
                </row-col-component>
            </row-component>
        </form>

        <row-component class="position-relative mt-5" style="top: 30px">
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-one class="w-100" @submit="submit" :icon="'plus circle'" :name-button="'Adicionar'"
                            :class="{ 'preloderSend': sendForm }"/>
            </row-col-component>

            <row-col-component
                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {ACTION_MESSAGES} from "@/configs/constants";

import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "ScheduledNoteCreate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        QuillEditor
    },

    directives: {
        mask
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Anotações Agendadas')
        this.$store.dispatch('loadUserListSelect')
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/scheduled-note/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                sector: '',
                status: '',
                frequency: '',
                start_date: this.startDate(),
                closing_date: this.closingDate(),
                desc: '',
                responsibles: []
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,

            toolbarOptions: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],
                [{'header': 1}, {'header': 2}],               // custom button values
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
                [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
                [{'direction': 'rtl'}],                         // text direction
                [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                [{'font': []}],
                [{'align': []}],
                ['clean']                                         // remove formatting button
            ],
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeScheduledNote', this.formDate)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                        this.$router.push({name: 'scheduled-note-edit', params: {id: r.data}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 34)
                .then((r) => {
                    if (r.data.indexOf('note-schedule-create') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        closingDate() {
            let currentTime = new Date();
            if (currentTime.getDay() === 5) {
                currentTime.setDate(currentTime.getDate() + 3)
            }

            if (currentTime.getDay() === 4) {
                currentTime.setDate(currentTime.getDate() + 4)
            } else {
                currentTime.setDate(currentTime.getDate() + 2)
            }

            let day = currentTime.getDate();
            let month = currentTime.getMonth() + 6;
            let year = currentTime.getFullYear();

            day = (day < 10 ? '0' : '') + day;
            month = (month < 10 ? '0' : '') + month;
            return `${day}/${month}/${year}`
        },

        startDate() {
            let currentTime = new Date();
            if (currentTime.getDay() === 5) {
                currentTime.setDate(currentTime.getDate() + 3)
            }

            if (currentTime.getDay() === 4) {
                currentTime.setDate(currentTime.getDate() + 4)
            } else {
                currentTime.setDate(currentTime.getDate())
            }

            let day = currentTime.getDate();
            let month = currentTime.getMonth() + 1;
            let year = currentTime.getFullYear();

            day = (day < 10 ? '0' : '') + day;
            month = (month < 10 ? '0' : '') + month;
            return `${day}/${month}/${year}`
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectSector() {
            return this.$store.state.var.selectSector
        },

        selectUserList() {
            return this.$store.state.var.selectUserList;
        },

        selectFrequency() {
            return this.$store.state.var.selectFrequency;
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        }
    }
}
</script>

<style scoped>
#quillEditor {
    min-height: 700px;
}

.character-label-icon,
.character-option-icon {
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}
</style>