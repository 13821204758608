import axios from "axios";
import {
    URL_BASE
} from "@/configs/constants";

const RESOURCE = 'budget';
export default {

    loadBudgets(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {
                    params
                })
                .then(response => {
                    context.commit('LOAD_BUDGET', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    storeBudget(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {})
        })
    },

    storeBudgetObservation(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}budget-add-obs`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {})
        })
    },

    loadBudget(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
        })
    },
    
    updateBudget(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateBudgetSendClient(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}budget-send-client/${id}`)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateBudgetCancel(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}budget-cancel/${id}`)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateBudgetApproved(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}budget-approved/${id}`)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    }

}