<template>
  <div class="boxFull" v-show="preloader">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "PreloderComponent",

  computed: {
    preloader() {
      return this.$store.state.preloader.loading
    }
  }


}
</script>

<style scoped>
.boxFull {
  position: fixed;
  z-index: 105;
  background-color: rgba(255, 255, 255, 0.6);
  width: 100vw;
  height: 100vh;
}


.loader {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  border: 10px solid #f38f0c;
  border-top-color: #000000;
  box-sizing: border-box;
  background: transparent;
  animation: loading 1s linear infinite;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  margin: auto;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  0% {
    transform: rotate(360deg);
  }
}
</style>