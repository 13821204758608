import axios from "axios";
import {
    URL_BASE
} from "@/configs/constants";

const RESOURCE = 'my-schedule';
export default {

    loadMySchedules(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {
                    params
                })
                .then(response => {
                    context.commit('LOAD_MY_SCHEDULES', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadMySchedule(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
        })
    },

    loadMyScheduleEvent(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}my-schedule-show-event/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeMyScheduleEvent(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}my-schedule-add-event`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {})
        })
    },

    destroyMyScheduleEvent(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}my-schedule-delete-event/${id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadMyScheduleEventMonth(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}my-show-events-month/${formData.id}/${formData.month}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

}