import state from "@/store/modules/budget/state";
import actions from "@/store/modules/budget/actions";
import mutations from "@/store/modules/budget/mutations";
import getters from "@/store/modules/budget/getters";


export default {
  state,
  actions,
  mutations,
  getters
}