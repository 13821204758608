import AdminComponent from "@/components/dashboard/AdminComponent";
import ScheduleCreate from "@/views/create/ScheduleCreate";
import ScheduleSearch from "@/views/search/ScheduleSearch";
import ScheduleShow from "@/views/show/ScheduleShow";
import ScheduleUpdate from "@/views/update/ScheduleUpdate";

export default {
    path: '/schedule',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'create', name: 'schedule-create', component: ScheduleCreate },
        { path: 'search', name: 'schedule-search', component: ScheduleSearch },
        { path: 'show/:id', name: 'schedule-show', component: ScheduleShow, props: true },
        { path: 'edit/:id', name: 'schedule-edit', component: ScheduleUpdate, props: true },
    ],
}