export default {

    icon: 'handshake alternate',
    name: 'Negociações',
    subLinks: [{
            name: 'Leads',
            route: '/lead/search',
            pagePermissions: [
                'lead-edit',
                'lead-search',
                'lead-create'
            ],
        },

        {
            name: 'Modelo de Orçamento',
            route: '/budget-template/search',
            pagePermissions: [
                'budget-template-create',
                'budget-template-edit',
                'budget-template-search'
            ],
        },

        {
            name: 'Modelo de Proposta',
            route: '/proposal-template/search',
            pagePermissions: [
                'proposal-template-create',
                'proposal-template-edit',
                'proposal-template-search'
            ],
        },

        {
            name: 'Orçamento',
            route: '/budget/search',
            pagePermissions: [
                'budget-approved',
                'budget-search',
                'budget-edit',
                'budget-create',
                'budget-cancel',
                'budget-approved'
            ],
        },


        {
            name: 'Proposta',
            route: '/proposal/search',
            pagePermissions: [
                'proposal-approved',
                'proposal-creat',
                'proposal-edit',
                'proposal-search',
                'proposal-version'
            ],
        },
        {
            name: 'Serviços',
            route: '/service/search',
            pagePermissions: [
                'service-edit',
                'service-search',
                'service-create',
            ],
        },
    ],

    pageLinksNavCheck: [
        'lead-edit',
        'lead-search',
        'lead-create',
        'budget-template-create',
        'budget-template-edit',
        'budget-template-search',
        'proposal-template-create',
        'proposal-template-edit',
        'proposal-template-search',
        'budget-approved',
        'budget-search',
        'budget-edit',
        'budget-create',
        'budget-cancel',
        'budget-approved',
        'proposal-approved',
        'proposal-creat',
        'proposal-edit',
        'proposal-search',
        'proposal-version',
        'service-edit',
        'service-search',
        'service-create',
    ],

}