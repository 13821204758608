import axios from "axios";
import {
    URL_BASE
} from "@/configs/constants";

export default {

    updateNamePage(context, namePage) {
        context.commit('UPDATE_NAME', namePage)
    },

    loadSelectSearch(context, page) {
        const RESOURCE = page;
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_SEARCH', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadTypeTasksSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}task-type-list`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_TASK_TYPE_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadUserListSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}user-list`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_USER_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadClientListSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}client-list`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_CLIENT_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadAccountTypeListSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}account-type-list`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_ACCOUNT_TYPE_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadAccountTypeSubListSelect(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}account-type-sub-list/${id}`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_ACCOUNT_TYPE_SUB_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadAccountBankSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}account-bank-list`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_ACCOUNT_BANK_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadProductSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}product-list`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_PRODUCT_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadProviderSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}provider-list`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_PROVIDER_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadBudgetTemplateSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}budget-template-list`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_BUDGET_TEMPLATE', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadProposalTemplateSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}proposal-template-list`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_PROPOSAL_TEMPLATE', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadEmailClient(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}client-list-email/${id}`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_EMAIL_CLIENT', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadPhoneClient(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}client-list-phone/${id}`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_PHONE_CLIENT', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadContractModelList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}contract-model-list`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_CONTRACT_MODEL', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadServiceList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}service-list`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_SERVICE', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadGuestScheduleList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}guest-schedule-list`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_GUEST_SCHEDULE_SUB_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadBrandModelList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}fleet-list`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_BRAND_MODEL_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadSpotSegmentList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}spot-list-segments`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_SPOT_SEGMENT_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadEmployeeList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}employee-list`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_EMPLOYEE_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadEmployeeBankList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}employee-bank/${id}`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_EMPLOYEE_BANK_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    loadEmailsNotificationsList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}email-notification-list`)
                .then(response => {
                    //console.log(response)
                    context.commit('UPDATE_SELECT_EMAIL_NOTIFICATION_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

}