<template>
  <input :name="nameInput"
         :type="typeInput" class="form-control"
         :placeholder="placeholder"
         :required="requiredInput"
         :maxlength="maxlength"
         :minlength="minlength"
         :value="modelValue"
         @input="$emit('update:modelValue', $event.target.value)">



</template>

<script>
export default {
  name: "InputComponent",


  props: {

    modelValue: {
      type: String,
      default: '',
      required: false
    },


    nameInput: {
      "required": false,
      "type": String,
      "default": ''
    },


    placeholder: {
      "required": false,
      "type": String,
      "default": ''
    },

    typeInput: {
      "required": true,
      "type": String,
      "default": ''
    },



    requiredInput: {
      "required": false,
      "type": Boolean,
      "default": false
    },

    maxlength: {
      "required": false,
      "type": Number,
    },

    minlength:{
      "required": false,
      "type": Number,
    }



  },




}
</script>

<style scoped>

</style>