<template>
  <router-link :to="{name:route, params:{id: idParam}}" class="btn btn-secondary buttonRound" tooltip="Ver"
               flow="down">
    <i class="eye icon"></i>
  </router-link>
</template>

<script>
export default {
  name: "ButtonShowRound",

  props: {

    route: {
      'type': String,
      "required": true,
    },


    idParam: {
      'type': Number,
      "required": true,
    }


  }
}
</script>

<style scoped>
.buttonRound  i{
  padding-top: 5px;


}
</style>