import state from "@/store/modules/proposal/state";
import actions from "@/store/modules/proposal/actions";
import mutations from "@/store/modules/proposal/mutations";
import getters from "@/store/modules/proposal/getters";

export default {
  state,
  actions,
  mutations,
  getters
}