<template>
    <button :type="typeButton" :class="['btn btn-secondary buttonOne']" @click="submit">
        <i v-if="icon" :class="'icon ' + icon"></i>
        {{ nameButton }}
    </button>
</template>

<script>
export default {
    name: "ButtonSearch",

    props: {
        icon: {
            "required": false,
            "type": String,
            "default": ''
        },

        nameButton: {
            "type": String,
            "default": ''
        },

        typeButton: {
            "required": false,
            "type": String,
            "default": 'button'
        },


    },

    data() {
        return {
            buttonLoader: false,
        }
    },

    methods: {
        submit() {
            if (this.loderButton) {
                this.buttonLoader = true;
            }
            this.$emit('submit')
        }
    }
}
</script>

<style scoped>

.buttonOne {
    font-family: 'Comfortaa', cursive;
    color: white;
    min-width: 42px;
    height: 42px;
    //display: block;
    padding: 0.4rem;
    font-size: 18px;
    border-radius: 50px;
    text-align: center;
    text-decoration: none;
    border: 1px solid #e0e0e0;
    background-color: #d67c1c;
}

.buttonOne i.icon {
    margin: 0;
}

.buttonOne:active,
.buttonOne:focus,
.buttonOne:hover,
.buttonOne:focus-visible {
    font-family: 'Comfortaa', cursive;
    background-color: #d67c1c;
    color: white;
    border-color: #d67c1c;
    box-shadow: none !important;
}

.preloderSend {
    padding-right: 40px;
}

.preloderSend:after {
    content: "";
    position: absolute;
    animation: load 3s infinite;
    margin-left: 4px;
}

@keyframes load {
    0% {
        content: "";
    }

    33% {
        content: ".";
    }

    66% {
        content: "..";
    }

    99% {
        content: "...";
    }
}
</style>