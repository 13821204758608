<template>
    <div class="bg-mask" v-if="modal">
        <div class="janela">
            <error-component :errors="errors" v-if="showError"/>
            <div class="close" @click="(this.modal = !this.modal)">X</div>
            <form autocomplete="off">
                <row-component>
                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Frota:</label>
                        <Multiselect v-model="formDateModal.id_frota" label="name" :options="selectBrandModel"
                                     :searchable="true" disabled :noResultsText="noResultsText"
                                     :noOptionsText="noResultsText">
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                </div>
                            </template>
                            <template v-slot:option="{ option }">
                                <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                            </template>
                        </Multiselect>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                        <label>Motivo Saída:</label>
                        <input-component :type-input="'text'" v-model="formDateModal.motive" disabled/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Data Inicial:</label>
                        <input-component v-mask="'##/##/####'" :type-input="'text'" v-model="formDate.date_start"
                                         disabled/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Hora Inicial:</label>
                        <input-component :type-input="'text'" v-mask="'##:##'" v-model="formDateModal.hour_start"
                                         disabled/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>KM Inicial:</label>
                        <input-number-component :type-input="'number'" min="1" v-model="formDateModal.km_start"
                                                disabled/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.km_end }">
                        <label>KM Final:</label>
                        <input-number-component :type-input="'number'" min="1" v-model="formDateClose.km_end"/>
                    </row-col-component>
                </row-component>

                <button-one @submit="submitClose" v-if="this.buttonCheck.close" :icon="'x'" :name-button="'Fechar'"/>
            </form>
        </div>
    </div>

    <painel-component :itens="itensOne">
        <div class="filter-position" v-if="btnFilter">
            <button-one icon="filter" class="btn-filter" @click="filterWindow"/>
        </div>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Frota:</label>
                        <Multiselect v-model="formDate.name_frota" label="name" :options="selectBrandModel"
                                     :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                            </template>
                        </Multiselect>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                       v-if="buttonCheck.access_to_all">
                        <label>Usuário:</label>
                        <Multiselect v-model="formDate.name_frota" label="name" :options="selectUserList"
                                     :searchable="true"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText">
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                            </template>
                        </Multiselect>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Código:</label>
                        <input-component :type-input="'text'" v-model="formDate.code"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'">
                        <button-one class="mx-1" @submit="submit" :icon="'search'"/>
                        <button-float class="mx-1" :itens="itensTwo" v-if="buttonCheck.create"/>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end'">
                    <div class="toggle_preferences">
                        <button :class="{ 'bg-active': parseInt(cardTable) === 1 }" @click="toggleCard" type="button"
                                tooltip="Card">
                            <i class="window maximize outline icon"></i>
                        </button>
                        <button :class="{ 'bg-active': parseInt(cardTable) === 2 }" @click="toggleTable" type="button"
                                tooltip="Tabela">
                            <i class="align justify icon"></i>
                        </button>
                    </div>
                </row-col-component>
            </row-component>

            <row-component v-if="parseInt(cardTable) === 1">
                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4'"
                                   v-for="(data, index) in objectReturn.itens.data " :key="index">
                    <div class="card-container">
                        <div class="flag-status"></div>
                        <p>Código: {{ data.code }}</p>
                        <div class="skills">
                            <p class="title-container">Motivo: {{ data.motive }}</p>
                            <div class="aux-row-3">
                                <div class="text"><i class="calendar icon"></i>{{ settingsTime(data.date_start) }}</div>
                                <div class="text"><i class="clock icon"></i>{{ data.hour_start }}</div>
                            </div>
                        </div>

                        <div class="aux-row-3">
                            <div class="text"><i class="car icon"></i>{{ data.name_frota }}</div>
                            <div class="text"><i class="tachometer alternate icon"></i>{{ data.km_start }}</div>
                        </div>

                        <div class="responsible-task">
                            <h6> Criador:</h6>
                            <img v-if="data.photo_creator" :src="data.photo_creator" class="avatar__image_2" alt="">
                            <img v-else src="@/assets/img/avatar.png" alt="" class="avatar__image_2">
                            {{ data.user_name }}
                        </div>

                        <div class="buttons">
                            <button tooltip="Editar" class="primary" v-if="buttonCheck.edit"
                                    @click="routeEdit(data.id)">
                                <i class="edit icon"></i>
                            </button>

                            <button tooltip="Ver" class="primary" v-if="buttonCheck.show" @click="routeShow(data.id)">
                                <i class="eye icon"></i>
                            </button>

                            <button tooltip="Fechar" class="eye primary"
                                    v-if="buttonCheck.close && parseInt(data.status) !== 2"
                                    @click="openModal(data.id)">
                                <i class="close icon"></i>
                            </button>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <table class="table table-striped" v-if="parseInt(cardTable) === 2">
                <thead>
                <tr>
                    <th scope="col" style="width: 5%">Código</th>
                    <th scope="col" style="width: 15%">Usuário <br> Frota</th>
                    <th scope="col" style="width: 30%">Motivo</th>
                    <th scope="col" style="width: 15%">Data - Hora Início <br>  Data - Hora Final </th>
                    <th scope="col" style="width: 10%">KM Incial <br> KM Final</th>
                    <th scope="col" style="width: 10%">Total km<br> Total Horas</th>
                    <th scope="col" style="width: 15%" v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.close">
                        Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in objectReturn.itens.data" :key="index">
                    <td data-label="Código">{{ data.code }}</td>
                    <td data-label="Usuário / Frota">
                      <img :src="data.photo_creator" class="avatar__image_2"  >{{data.user_name}}
                      <br>
                      <img :src="data.fleet_photo" class="avatar__image_2" >
                      {{data.fleet_name}}
                    </td>
                    <td data-label="Motivo">{{ data.motive }}</td>
                    <td data-label="Data - Hora Início / Data - Hora Início">
                        {{ settingsTime(data.date_start) }} / {{ data.hour_start }}
                      <br>
                      <spam v-if="data.date_end">
                      {{ settingsTime(data.date_end) }} / {{ data.hour_end }}
                      </spam>
                    </td>
                    <td data-label="KM Incial / KM Final">
                      {{ data.km_start }}
                      <br>
                      {{ data.km_end }}
                    </td>
                  <td data-label="Total KM / Total Horas">
                    {{ data.totalKM }}
                    <br>
                    {{ data.totalHours }}
                  </td>
                    <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.close">
                        <button tooltip="Editar" class="btn btn-success mx-1 button-show" v-if="buttonCheck.edit"
                                @click="routeEdit(data.id)">
                            <i class="edit icon"></i>
                        </button>

                        <button tooltip="Ver" class="btn btn-secondary mx-1 button-show" v-if="buttonCheck.show"
                                @click="routeShow(data.id)">
                            <i class="eye icon"></i>
                        </button>

                        <button tooltip="Fechar" class="btn btn-danger mx-1 button-show"
                                v-if="buttonCheck.close && parseInt(data.status) !== 2" @click="openModal(data.id)">
                            <i class="close icon"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <button-pagination :pagination="objectReturn.itens" :offset="3" @paginate="loadFleetDepartures"/>
        </div>
    </painel-component>
</template>

<script>
import ButtonPagination from "@/components/buttons/ButtonPagination";
import PainelComponent from "@/components/dashboard/PainelComponent";
import InputComponent from "@/components/form/InputComponent";
import InputNumberComponent from "@/components/form/InputNumberComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";
import {useToast} from "vue-toastification";
import {ACTION_MESSAGES} from "@/configs/constants";
import {mask} from 'vue-the-mask'

const toast = useToast();

export default {
    name: "FleetDeparturesSearch",
    components: {
        ButtonPagination,
        PainelComponent,
        InputComponent,
        InputNumberComponent,
        ButtonOne,
        ButtonFloat,
        RowComponent,
        RowColComponent,
        ErrorComponent,
        LoderSendComponent,
        Multiselect,
    },

    directives: {
        mask
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Saída Frota')
        this.$store.dispatch('loadBrandModelList')
        this.$store.dispatch('loadUserListSelect')
        this.loadFleetDepartures(1)
        this.resizeWindow()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            itensTwo: {
                route: "/fleet-departures/create",
                icon: "plus",
                name: ''
            },

            buttonCheck: {
                edit: false,
                create: false,
                close: false,
                access_to_all: false
            },

            formDate: {
                user_name: '',
                name_frota: '',
                code: '',
                status: ''
            },

            formDateModal: {
                id_frota: '',
                motive: '',
                date_start: '',
                hour_start: '',
                km_start: '',
            },

            formDateClose: {
                id: '',
                km_end: ''
            },

            modal: false,

            cardTable: '',
            preferences: {
                type: 11,
                preference: ''
            },

            filter: true,
            btnFilter: false,

            errors: [],
            showError: false,
            showLoader: true,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadFleetDepartures(1)
            this.searchSubmit = true;
        },

        loadFleetDepartures(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadFleetDepartures', {...this.params, page})
                    .then((r) => {
                        this.showLoader = false
                        this.cardTable = r.tableCard
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 31)
                .then((r) => {
                    if (r.data.indexOf('fleet-departures-search') !== -1) {
                        this.showLoader = false

                        if (r.data.indexOf('fleet-departures-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('fleet-departures-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('fleet-departures-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('fleet-departures-close') !== -1) {
                            this.buttonCheck.close = true
                        }

                        if (r.data.indexOf('fleet-departures-access-to-all') !== -1) {
                            this.buttonCheck.access_to_all = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        routeEdit(id) {
            this.$router.push({name: 'fleet-departures-edit', params: {id: id}})
        },

        routeShow(id) {
            this.$router.push({name: 'fleet-departures-show', params: {id: id}})
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },

        toggleTable() {
            this.userPreferences(2)
            this.cardTable = 2
        },

        toggleCard() {
            this.userPreferences(1)
            this.cardTable = 1
        },

        userPreferences(preference) {
            this.preferences.preference = preference
            this.$store.dispatch('userPreferences', this.preferences)
        },

        openModal(id) {
            this.$store.dispatch('loadFleetDeparture', id).then((r) => {
                this.formDateClose.id = r.id
                this.formDateModal.id_frota = r.id_frota
                this.formDateModal.motive = r.motive
                this.formDateModal.date_start = r.date_start
                this.formDateModal.hour_start = r.hour_start
                this.formDateModal.km_start = r.km_start
                this.modal = !this.modal
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        submitClose() {
            this.$store.dispatch('closeFleetDeparture', this.formDateClose)
                .then(() => {
                    this.showError = false
                    this.errors = []
                    toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                    this.loadFleetDepartures(1)
                    this.modal = !this.modal
                }).catch((r) => {
                this.showError = true;
                this.errors = r.response.data.errors;
                this.scrollTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        settingsTime(label) {
            let data = label.split("-")
            let day = data[2]
            let year = data[0]
            let month = data[1]
            return `${day}/${month}/${year}`
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.SelectFleetDeparture
        },

        selectBrandModel() {
            return this.$store.state.var.selectBrandModel
        },

        selectUserList() {
            return this.$store.state.var.selectUserList
        },

        objectReturn() {
            return this.$store.state.fleet_departures.items
        },

        params() {
            return {
                page: this.objectReturn.current_page,
                user_name: this.formDate.user_name,
                name_frota: this.formDate.name_frota,
                code: this.formDate.code,
                status: this.formDate.status
            }
        },
    },
}
</script>

<style scoped src="@/assets/css/table-resp.css"></style>
<style scoped>
.bg-active {
    background-color: #d67c1c !important;
}

.buttons {
    display: flex;
}

button.primary {
    width: 100%;
}

.card-container {
    padding: 20px 0 0;
}

.toggle_preferences > button {
    border: 0;
    width: 45px;
    color: #fff;
    height: 45px;
    margin: 0 5px;
    padding-right: 4px;
    border-radius: 50%;
    background-color: #323232;
}

.button-show {
    width: 35px;
    height: 35px;
    font-size: 15px;
    border-radius: 50px;
    padding-left: 0.5rem;
}

.skills {
    padding: 0.5rem;
    background-color: #f5f5f5;
}

.title-container {
    height: 60px;
    padding: 3px 0;
    font-size: 14px;
    text-transform: uppercase;
}

.bg-mask {
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgb(0, 0, 0, 0.5);
}

.janela {
    top: 50%;
    left: 50%;
    width: 65%;
    height: auto;
    z-index: 103;
    padding: 40px;
    position: absolute;
    margin-right: -50%;
    background-color: #FFFFFF;
    box-shadow: none !important;
    transform: translate(-50%, -50%);
}

.janela .close {
    top: 0;
    right: 0;
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    margin: 0.5rem 1rem;
}

@media (max-width: 500px) {
    .janela {
        width: 98%;
    }
}
</style>