import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = 'contract';
export default {

    loadContracts(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then(response => {
                    context.commit('LOAD_CONTRACT', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    storeContract(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {
                })
        })
    },

    loadContract(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
        })
    },

    updateContract(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeContractObservation(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}contract-add-obs`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {
                })
        })
    },

    updateContractSendClient(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}contract-send-client/${id}`)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateContractApproved(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}contract-approved/${id}`)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateContractNew(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}proposal-new/${id}`)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeContractItem(context, formData) {
        context.commit("PRELOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}contract-add-item`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("PRELOADER", false)
            })
        })
    },

    updateContractItem(context, formData) {
        context.commit("PRELOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}contract-add-item-edit/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("PRELOADER", false)
            })
        })
    },

    updateOrderContractItem(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}contract-add-item-order/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    destroyContractItem(context, id) {
        context.commit("PRELOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}contract-add-item-delete/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("PRELOADER", false)
            })
        })
    }

}