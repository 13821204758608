import state from "@/store/modules/lead/state";
import actions from "@/store/modules/lead/actions";
import mutations from "@/store/modules/lead/mutations";
import getters from "@/store/modules/lead/getters";

export default {
  state,
  actions,
  mutations,
  getters
}