<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <input type="radio" id="cliente" v-model="formDate.client_employee_provider_type" value="1">
                    <label for="cliente">Cliente</label>

                    <input type="radio" id="fornecedor" v-model="formDate.client_employee_provider_type" value="3">
                    <label for="fornecedor">Fornecedor</label>

                    <input type="radio" id="funcionario" v-model="formDate.client_employee_provider_type" value="2">
                    <label for="funcionario">Funcionário</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.type }">
                    <label>Tipo:</label>
                    <multiselect v-model="formDate.type" @select="suggestionTitle(formDate.type)" :searchable="true"
                                 :options="selectTypeReceipt" :noResultsText="noResultsText"
                                 :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component v-if="parseInt(formDate.client_employee_provider_type) === 1"
                                   :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <label>Cliente:</label>
                    <Multiselect v-model="formDate.client_employee_provider_id" label="name" :searchable="true"
                                 :options="selectClientList" :noResultsText="noResultsText"
                                 :noOptionsText="noResultsText">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>

                <row-col-component v-if="parseInt(formDate.client_employee_provider_type) === 2"
                                   :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <label>Funcionário:</label>
                    <Multiselect @select="getEmployeeBank(formDate.client_employee_provider_id)" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"
                                 v-model="formDate.client_employee_provider_id" label="name" :options="selectEmployee">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>

                <row-col-component v-if="parseInt(formDate.client_employee_provider_type) === 3"
                                   :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <label>Fornecedor:</label>
                    <Multiselect v-model="formDate.client_employee_provider_id" label="name" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"
                                 :options="selectProvider">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.title }">
                    <label>Título:</label>
                    <input-component :maxlength=100 v-model="formDate.title" :type-input="'text'"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.date }">
                    <label>Data:</label>
                    <input-component v-model="formDate.date" :type-input="'text'" v-mask="'##/##/####'"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.price }">
                    <label>Valor:</label>
                    <input-component v-money="money" v-model="formDate.price" :type-input="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.paid }">
                    <label>Pago:</label>
                    <div class="input-group">
                        <input class="form-control" type="email" v-model="formDate.paid">
                        <span v-if="parseInt(formDate.client_employee_provider_type) === 2" @click="modal"
                              class="input-group-text my_btn" id="basic-addon1">
                            <i class="search icon"></i>
                        </span>
                    </div>
                </row-col-component>

                <div class="modalProvider" v-if="modal_pago">
                    <div class="close-provider" @click="modal">X</div>

                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <label>Pago:</label>
                        <multiselect v-model="formDate.paid" :searchable="true" :options="selectEmployeeBank"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <button-one :name-button="'Confirmar'" @click="modal"/>
                </div>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.referent }">
                    <label>Referente:</label>
                    <input-text-area :rows="2" v-model="formDate.referent" :type-input="'text'"/>
                </row-col-component>
            </row-component>
        </form>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-one class="w-100" @submit="submit" :icon="'plus circle'" :name-button="'Adicionar'"
                            :class="{ 'preloderSend': sendForm }"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputTextArea from "@/components/form/InputTextArea";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import {ACTION_MESSAGES} from "@/configs/constants";
import {useToast} from "vue-toastification";
import {mask} from 'vue-the-mask'
import {VMoney} from 'v-money'
import {MONEY} from "@/configs/constants"

const toast = useToast();

export default {
    name: "ReceiptCreate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        InputTextArea,
        ButtonFloat,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        ButtonOne,
    },

    directives: {
        mask,
        money: VMoney
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Recibo')
        this.$store.dispatch('loadClientListSelect')
        this.$store.dispatch('loadProviderSelect')
        this.$store.dispatch('loadEmployeeList')
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/receipt/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                type: '',
                title: '',
                client_employee_provider_type: 2,
                client_employee_provider_id: '',
                date: '',
                price: '',
                paid: '',
                referent: ''
            },

            modal_pago: false,

            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeReceipt', this.formDate)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                        this.$router.push({name: 'receipt-edit', params: {id: r.data}})
                        this.errors = [];
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop();
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 33)
                .then((r) => {
                    if (r.data.indexOf('receipt-create') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        modal() {
            this.modal_pago = !this.modal_pago
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        suggestionTitle(type) {
            if (parseInt(type) === 1)
                this.formDate.title = "Recibo de pagamento"

            if (parseInt(type) === 2)
                this.formDate.title = "Recibo de recebimento"

            if (parseInt(type) === 3)
                this.formDate.title = "Recibo de pagamento salarial"

            if (parseInt(type) === 4)
                this.formDate.title = "Recibo adiantamento salarial"

            if (parseInt(type) === 5)
                this.formDate.title = "Recibo de pagamento salarial (Prestador de serviço)"

            if (parseInt(type) === 6)
                this.formDate.title = "Recibo adiantamento salarial (Prestador de serviço)"
        },

        getEmployeeBank(id) {
            this.$store.dispatch('loadEmployeeBankList', id)
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectTypeReceipt() {
            return this.$store.state.var.selectTypeReceipt
        },

        selectProvider() {
            return this.$store.state.var.selectProvider
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectEmployee() {
            return this.$store.state.var.selectEmployee
        },

        selectEmployeeBank() {
            return this.$store.state.var.selectEmployeeBank
        },
    },
}
</script>

<style scoped>
.my_btn {
    color: white;
    cursor: pointer;
    position: relative;
    background: #d67c1c;
    padding-bottom: 15px;
    border: 1px solid #d67c1c;
}

.modalProvider {
    top: 50%;
    left: 50%;
    width: 40%;
    z-index: 9999;
    position: fixed;
    min-height: auto;
    background: white;
    margin-right: -50%;
    padding: 40px 20px;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.close-provider {
    top: 0;
    right: 0;
    cursor: pointer;
    margin: 10px 20px;
    font-size: 1.5rem;
    position: absolute;
}

@media (max-width: 768px) {
    .modalProvider {
        width: 95%;
    }
}
</style>