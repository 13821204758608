<template>
    <div class="janela" v-if="windowSection">
        <div class="btn-close" @click="windowSectionClose"></div>

        <div class="clearfix">
            <button-one class="rounded-0" icon="plus" @click="addSection"/>
        </div>
        <div class="border-content" v-for="(section, index) in sectionAdd" :key="index">
            <form autocomplete="off" class="mt-2">
                <row-component>
                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <label>Adicionar Sessão:</label>
                        <input-component :type-input="'text'" v-model="section.section" :model-value="section.section"/>
                    </row-col-component>
                </row-component>
            </form>
            <div class="clearfix">
                <button-one class="float-end rounded-0 bg-danger" icon="trash" @click="sectionRemove(index)"
                            v-if="index > 0"/>
            </div>
        </div>
        <button-one @submit="submitSection" :icon="'plus'" :name-button="'adicionar'"/>
    </div>
    <div class="janela" v-if="windowItem">
        <div class="btn-close" @click="windowItemClose"></div>

        <div class="clearfix">
            <button-one class="rounded-0" icon="plus" @click="addItem"/>
        </div>
        <div class="border-content" v-for="(item, index) in itemAdd" :key="index">
            <form autocomplete="off" class="mt-2">
                <row-component>
                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <label>Adicionar Items:</label>
                        <input-component :type-input="'text'" v-model="item.item" :model-value="item.item"/>
                    </row-col-component>
                </row-component>
            </form>
            <div class="clearfix">
                <button-one class="float-end rounded-0 bg-danger" icon="trash" @click="itemRemove(index)"
                            v-if="index > 0"/>
            </div>
        </div>
        <button-one @submit="submitItem" :icon="'plus'" :name-button="'Adicionar'"/>
    </div>

    <div class="modalEditSections" v-if="modal_section">
        <div class="btn-close" @click="openSection"></div>
        <draggable
                v-model="formDate.sections"
                group="people"
                @start="drag=true"
                @end="drag=false"
                ghost-class="ghost"
                item-key="id">
            <template #item="{element, index}">
                <row-component class="section-style" style="cursor: pointer">
                    <div class="indexCount">{{ index + 1 }}</div>
                    <row-col-component
                            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <label>Nome da sessão:</label>
                        <input-component :type-input="'text'" v-model="element.item"/>
                    </row-col-component>
                </row-component>
            </template>
        </draggable>

        <button-one @submit="submitSectionEdit" icon="save" name-button="Salvar"/>
    </div>
    <div class="modalEditSections" v-if="modal_item">
        <div class="btn-close" @click="openItem"></div>
        <!--:delay="500"-->
        <draggable
                v-model="formDateItem.itens"
                group="people"
                @start="drag=true"
                @end="drag=false"
                ghost-class="ghost"
                item-key="id">
            <template #item="{element, index}">
                <row-component class="section-style" style="cursor: pointer">
                    <div class="indexCount">{{ index + 1 }}</div>
                    <row-col-component
                            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <label>Item:</label>
                        <input-component :type-input="'text'" v-model="element.item"/>
                    </row-col-component>
                </row-component>
            </template>
        </draggable>

        <button-one @submit="submitItemEdit" icon="save" name-button="Salvar"/>
    </div>

    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoder"/>

        <div class="tabs effect-3" v-else>
            <input type="radio" id="tab-1" name="tab-effect-3" checked="checked">
            <span>
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="tasks icon"></i>
                <p>Sessão</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <row-component>
                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 clearfix'">
                            <button-add class="m-0" :itens="itensThree" v-if="buttonCheck.create"/>
                            <button-one @click="cloneFunction" icon="copy" v-if="buttonCheck.create"
                                        class="me-5 color border-0 bg-transparent fs-4 float-end"
                                        tooltip="Clonar modelo de contrato"/>
                        </row-col-component>
                    </row-component>

                    <form>
                        <row-component>
                            <row-col-component :class-row-col="'col-xxl-8 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.name }">
                                <label>Nome:</label>
                                <input-component :maxlength=100 :type-input="'text'" v-model="formDate.name"
                                                 :model-value="formDate.name"/>
                            </row-col-component>

                            <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.status }">
                                <label>Status:</label>
                                <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                             :noResultsText="this.$store.state.var.selectNotSearch"/>
                            </row-col-component>

                            <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.price }">
                                <label>Valor Total:</label>
                                <input-component :type-input="'text'" v-model="formDate.price"
                                                 :model-value="formDate.price" v-money="money"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Observações:</label>
                                <input-text-area :rows="4" v-model="formDate.obs"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-11 col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12'">
                            <button-one @submit="windowSectionModal" :icon="'plus'" tooltip="Adicionar Sessão"/>
                            <button-one class="ms-2" @submit="openSection" :icon="'edit'" tooltip="Editar Sessão"/>
                        </row-col-component>
                    </row-component>

                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formDate.sections.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <form v-for="(section, index) in formDate.sections" :key="index" class="section-style">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'">
                                <label>Nome da sessão:</label>
                                <input-component :type-input="'text'" v-model="section.item" disabled/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 pt-lg-0 pt-4'">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button-one @click="windowItemModal(section.id)" :icon="'plus icon'"/>
                                    <button-one v-if="section.itens.length > 0"
                                                @click="openItem(section.itens, section.id)" :icon="'edit icon'"/>
                                    <button-one @click="deleteSection(section)" :icon="'trash icon'"/>
                                </div>
                            </row-col-component>
                        </row-component>

                        <row-component v-for="(itens, index) in section.itens" :key="index" class="item-style">
                            <row-col-component
                                    :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                                <label>Item:</label>
                                <input-component :type-input="'text'" v-model="itens.item" disabled/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 pt-4'">
                                <button-one @click="deleteItens(itens)" :icon="'trash icon'"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <button-one style="width: 100%" @submit="submit" :icon="'save'" :name-button="'Salvar'"
                                    :class="{ 'preloderSend': sendForm }"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <button-float style="width: 100%" :itens="itensTwo"/>
                    </row-col-component>
                </row-component>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonAdd from "@/components/buttons/ButtonAdd"
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import swal from 'sweetalert';
import {ACTION_MESSAGES} from "@/configs/constants";
import {MONEY} from "@/configs/constants"
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";
import draggable from "vuedraggable";

const toast = useToast();

export default {
    name: "ProposalTemplateUpdate",

    created() {
        this.$store.dispatch('updateNamePage', 'Modelo de Proposta')
        this.loadPageItens()
        this.checkACL()
    },

    props: {
        id: {
            required: true
        }
    },

    components: {
        draggable,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        ButtonAdd,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea
    },

    directives: {
        mask,
        money: VMoney
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/proposal-template/search",
                icon: "reply all",
                name: 'Voltar'
            },

            itensThree: {
                route: "/proposal-template/create",
                icon: "plus",
                name: 'Adicionar'
            },

            buttonCheck: {
                create: false,
            },

            formDate: {
                name: '',
                price: '',
                obs: '',
                status: '',
                sections: []
            },

            formDateSection: {
                id: this.id,
                sections: [],
            },

            formDateItem: {
                id: '',
                itens: []
            },

            windowSection: false,
            windowItem: false,
            modal_section: false,
            modal_item: false,

            drag: false,
            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoder: true,
        }
    },

    methods: {
        scrollTop() {
            window.scrollTo(0, 0)
        },

        loadPageItens() {
            this.$store.dispatch('loadProposalTemplate', this.id).then((r) => {
                this.formDate = r;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            }).finally(() => {
                this.nullInputArray()
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 20)
                .then((r) => {
                    if (r.data.indexOf('proposal-template-edit') !== -1) {
                        this.showLoder = false
                    }
                    if (r.data.indexOf('proposal-template-create') !== -1) {
                        this.buttonCheck.create = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        // Clone Functions
        cloneFunction() {
            this.storeState.push({
                name: this.formDate.name,
                price: this.formDate.price,
                obs: this.formDate.obs,
                status: this.formDate.status,
                sections: this.formDate.sections
            })
            this.$router.push({name: 'proposal-template-create'})
        },

        // Submit Functions
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateProposalTemplate', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        submitSection() {
            this.$store.dispatch('storeProposalTemplateSection', this.formDateSection)
                .then(() => {
                    this.showError = false
                    this.sendForm = false
                    this.errors = []
                    this.loadPageItens()
                    this.windowSectionClose()
                    toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                }).catch((r) => {
                this.showError = true;
                this.sendForm = false
                this.errors = r.response.data.errors;
                this.scrollTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        submitItem() {
            this.$store.dispatch('storeProposalTemplateItem', this.formDateItem)
                .then(() => {
                    this.showError = false
                    this.sendForm = false
                    this.errors = []
                    this.loadPageItens()
                    this.windowItemClose()
                    toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                }).catch((r) => {
                this.showError = true;
                this.sendForm = false
                this.errors = r.response.data.errors;
                this.scrollTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        submitSectionEdit() {
            let params = {id: this.id, sections: this.formDate.sections}
            params.sections.forEach((el) => {
                delete el.itens
                delete Object.assign(el, {['section']: el['item']})['item'];
            })
            this.$store.dispatch('updateProposalTemplateSection', params)
                .then(() => {
                    this.openSection()
                    this.loadPageItens()
                    toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                }).catch(() => {
                this.scrollTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        submitItemEdit() {
            let params = {id: this.formDateItem.id, itens: []}
            this.formDateItem.itens.forEach((el) => {
                params.itens.push(el.item)
            })
            this.$store.dispatch('updateProposalTemplateItem', params)
                .then(() => {
                    this.openItem()
                    this.loadPageItens()
                    toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                }).catch(() => {
                this.scrollTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        // Delete Functions
        deleteSection(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.item}, ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyProposalTemplateSection', item.id).then(() => {
                        this.loadPageItens()
                        toast.success(ACTION_MESSAGES.delete, {timeout: 10000});
                    })
                        .catch(() => {
                            toast.error(`Não foi possivel deletar  ${item.name} `, {timeout: 10000});
                        })
                }
            });
        },

        deleteItens(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.item}, ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyProposalTemplateItem', item.id).then(() => {
                        this.loadPageItens()
                        toast.success(ACTION_MESSAGES.delete, {timeout: 10000});
                    })
                        .catch(() => {
                            toast.error(`Não foi possivel deletar  ${item.name} `, {timeout: 10000});
                        })
                }
            });
        },

        // Modal Functions
        openSection() {
            this.modal_section = !this.modal_section
        },

        openItem(itens, id) {
            this.modal_item = !this.modal_item
            this.formDateItem.id = id
            this.formDateItem.itens = itens

            if (this.modal_item === false)
                this.formDateItem = {
                    id: this.id,
                    itens: []
                }
            this.nullInputArray()
        },

        windowSectionModal() {
            this.windowSection = true
        },

        windowItemModal(id) {
            this.windowItem = true
            this.formDateItem.id = id
        },

        windowSectionClose() {
            this.windowSection = false
            this.formDateSection = {
                id: this.id,
                sections: []
            }
            this.nullInputArray()
        },

        windowItemClose() {
            this.windowItem = false
            this.formDateItem = {
                id: this.id,
                itens: []
            }
            this.nullInputArray()
        },

        addSection() {
            this.formDateSection.sections.push({
                section: '',
            })
        },

        addItem() {
            this.formDateItem.itens.push({
                item: '',
            })
        },

        sectionRemove(index) {
            this.formDateSection.sections.splice(index, 1)
        },

        itemRemove(index) {
            this.formDateItem.itens.splice(index, 1)
        },

        nullInputArray() {
            if (this.formDateSection.sections.length === 0)
                this.formDateSection.sections.push({section: ''})
            if (this.formDateItem.itens.length === 0)
                this.formDateItem.itens.push({item: ''})
        }
    },

    computed: {
        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        storeState() {
            return this.$store.state.var.storeState
        },

        sectionAdd() {
            return this.formDateSection.sections;
        },

        itemAdd() {
            return this.formDateItem.itens;
        }
    },
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.color {
    color: #d67c1c !important;
}

.ghost {
    opacity: 0.5;
    background: #d67c1c !important;
}

.indexCount {
    top: 0;
    left: 0;
    width: 20px;
    height: 25px;
    display: flex;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    border-radius: 50%;
    background: #d67c1c;
    align-items: center;
    justify-content: center;
}

.janela,
.modalEditSections {
    top: 50%;
    left: 50%;
    z-index: 9999;
    position: fixed;
    background: white;
    overflow-x: scroll;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.janela {
    width: 850px;
    height: auto;
    max-height: 550px;
    padding: 20px 20px;
}

.modalEditSections {
    width: 60%;
    height: auto;
    max-height: 700px;
    padding: 45px 20px;
}

.btn-close {
    top: 0;
    right: 0;
    margin: 1%;
    cursor: pointer;
    position: absolute;
}

.border-content {
    margin-top: 50px;
    position: relative;
    margin-bottom: 10px;
    background: #fbfbfb;
    padding: 0 20px 15px 20px;
}

.section-style {
    max-height: 450px;
    position: relative;
    overflow-y: scroll;
    padding: 15px 25px;
    margin-bottom: 50px;
    background-color: #f0f0f0;
}

.item-style {
    padding-left: 25px;
}

@media (max-width: 991px) {
    .janela,
    .modalEditSections{
        width: 95%;
    }

    .item-style {
        padding-left: 0px;
    }
}
</style>