<template>
    <div class="modal-add-briefings" v-if="add_briefing">
        <div class="div-add-briefings">
            <error-component :errors="errors" v-if="showErrorBriefing"/>
            <h2 v-if="add_briefing_btn">Ver</h2>

            <div @click="closeModalBriefing" class="btn-close"></div>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component disabled :type-input="'text'" v-model="formDateBriefings.name"/>
                </row-col-component>

                <row-col-component
                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4'">
                    <label>Visível para time?:</label>

                    <input disabled type="radio" id="nao" name="teste" v-model="formDateBriefings.visible" value="1">
                    <label for="nao">Não</label>

                    <input disabled type="radio" id="sim" name="teste" v-model="formDateBriefings.visible" value="2">
                    <label for="sim">Sim</label>
                </row-col-component>
            </row-component>

            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                               :class="{ 'formError': errors.desc }">
                <label>Descrição:</label>
                <QuillEditor disabled :theme="'bubble'" :toolbar="toolbarOptions" :contentType="'html'" ref="myEditor"
                             v-model:content="formDateBriefings.desc"
                             style="max-height:280px; overflow: scroll;margin-bottom: 2%"/>
            </row-col-component>
        </div>
    </div>

    <div class="modal-files-preview" v-if="preview_files">
        <span @click="preview_files = false"><div class="btn-close"></div></span>
        <video v-if="extension(url_file) === 'mp4' || extension(url_file) === 'mov' || extension(url_file) === 'avi'"
               :src="url_file" controls autoplay></video>
        <img
                v-else-if="extension(url_file) === 'png' || extension(url_file) === 'jpg' || extension(url_file) === 'jpeg'"
                :src="url_file" alt=""/>
        <audio :src="url_file" v-else controls autoplay></audio>
    </div>

    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoder"/>

        <div class="tabs effect-3" v-else>
            <input checked type="radio" id="tab-1" name="tab-effect-3">
            <span>
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="file alternate icon"></i>
                <p>Briefing</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3">
            <span>
                <i class="file word icon"></i>
                <p>Docs/Refs</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">

                <section id="tab-item-1">
                    <form autocomplete="off">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <Multiselect disabled :searchable="true" v-model="formDate.client_id" label="name"
                                             :options="selectClientList">
                                    <template v-slot:singlelabel="{ value }">
                                        <div class="multiselect-single-label">
                                            <img alt="" class="character-label-icon" :src="value.icon"> {{ value.name }}
                                        </div>
                                    </template>

                                    <template v-slot:option="{ option }">
                                        <img alt="" class="character-option-icon" :src="option.icon"> {{ option.name }}
                                    </template>
                                </Multiselect>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Status:</label>
                                <multiselect disabled v-model="formDate.status" :searchable="true"
                                             :options="selectInput"
                                             :noResultsText="this.$store.state.var.selectNotSearch"/>
                            </row-col-component>
                        </row-component>
                    </form>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <button-float @click="this.$router.back()" style="width: 100%;" :itens="itensTwo"/>
                        </row-col-component>
                    </row-component>
                </section>

                <section id="tab-item-2">
                    <div class="alert alert-secondary alert-dismissible fade show mt-5" role="alert"
                         v-if="formDate.brifings.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <row-component v-else class="mt-5">
                        <row-col-component
                                :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                v-for="(briefings, index) in formDate.brifings" :key="index">

                            <div class="file-content">
                                <label>
                                    <img :src="briefings.photo" alt="">
                                    <p>{{ briefings.name }}</p>
                                </label>
                                <div class="action-buttons">
                                    <button @click="updateCustomerProfileBriefing(index, briefings.id)">
                                        <i class="eye icon"></i>
                                    </button>
                                </div>
                            </div>
                        </row-col-component>
                    </row-component>
                </section>

                <section id="tab-item-3">
                    <div class="alert alert-secondary alert-dismissible fade show mt-5" role="alert"
                         v-if="formDate.files.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <row-component v-else class="mt-5">
                        <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                           v-for="(file, index) in formDate.files" :key="index">
                            <div class="card">
                                <div class="card-image">
                                    <a :href="file.name_encoded" target="_blank"
                                       v-if="extension(file.name_encoded) === 'png' || extension(file.name_encoded) === 'jpeg' || extension(file.name_encoded) === 'jpg'">
                                        <img :src="file.name_encoded" alt="">
                                    </a>

                                    <i v-else-if="extension(file.name_encoded) === 'pdf'" class="file pdf icon"></i>

                                    <i v-else-if="extension(file.name_encoded) === 'docx' || extension(file.name_encoded) === 'doc'"
                                       class="file word icon"></i>

                                    <i v-else-if="extension(file.name_encoded) === 'mp4' || extension(file.name_encoded) === 'mov' || extension(file.name_encoded) === 'avi'"
                                       class="file video icon"></i>

                                    <i v-else-if="extension(file.name_encoded) === 'xlsx' || extension(file.name_encoded) === 'xlsm' || extension(file.name_encoded) === 'xlsb'
                                       || extension(file.name_encoded) === 'xltx'|| extension(file.name_encoded) === 'xltm'|| extension(file.name_encoded) === 'xls'
                                       || extension(file.name_encoded) === 'xlt' || extension(file.name_encoded) === 'xml' || extension(file.name_encoded) === 'xlam'
                                       || extension(file.name_encoded) === 'xla' || extension(file.name_encoded) === 'xlw' || extension(file.name_encoded) === 'xlr'"
                                       class="file excel icon"></i>

                                    <i v-else-if="extension(file.name_encoded) === 'txt' || extension(file.name_encoded) === 'csv'"
                                       class="file alternate icon"></i>

                                    <i v-else class="file audio icon"></i>
                                </div>

                                <p>{{ file.name_original }}</p>

                                <div class="aux-row-3">
                                    <div class="text"><i class="calendar icon"></i>{{ file.date_create }}</div>
                                    <div class="text"><i class="clock icon"></i>{{ file.hour_create }}</div>
                                </div>
                                <div class="ow-img">
                                    <img :src="file.user_photo" alt=""/>
                                    Enviado por: {{ file.user_name }}
                                </div>
                                <div class="dates-card">
                                    <button style="width: 100%" @click="previewFiles(file.name_encoded)"
                                            class="btn btn-secondary buttonRound" type="button">
                                        <i class="eye icon"></i>
                                    </button>
                                </div>
                            </div>
                        </row-col-component>
                    </row-component>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css'
import {useToast} from "vue-toastification";
import InputComponent from "@/components/form/InputComponent.vue";

const toast = useToast();

export default {
    name: "CustomerProfileShow",

    components: {
        InputComponent,
        PainelComponent,
        RowComponent,
        RowColComponent,
        ButtonFloat,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        QuillEditor
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Perfil do Cliente')
        this.$store.dispatch('loadClientListSelect')
        this.loadMyTaskProfileClient()
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "eye"
            },

            itensTwo: {
                route: "",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                client_id: '',
                status: '',
                brifings: [],
                files: []
            },

            url_file: '',
            contUpload: 0,
            add_files: false,
            preview_files: false,

            formDateBriefings: {
                profile_id: this.id,
                name: '',
                visible: 1,
                desc: ''
            },

            add_briefing: false,
            add_briefing_btn: false,
            showErrorBriefing: false,

            toolbarOptions: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{'header': 1}, {'header': 2}],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{'direction': 'rtl'}],
                [{'size': ['small', false, 'large', 'huge']}],
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'color': []}, {'background': []}],
                [{'font': []}],
                [{'align': []}],
                ['clean']
            ],

            errors: [],
            showError: false,
            sendForm: false,
            showLoder: false,
        }
    },

    methods: {
        scrollTop() {
            window.scrollTo(0, 0)
        },

        loadMyTaskProfileClient() {
            this.$store.dispatch('loadMyTaskProfileClient', this.id)
                .then((r) => {
                    this.formDate = r
                })
                .catch(() => {
                    toast.error("Erro desconhecido", {timeout: 1000});
                })
        },

        extension(name) {
            return name.split('.').pop().toString()
        },

        previewFiles(file) {
            let ext = file.split('.').pop()
            let types = ['wav', 'aiff', 'pcm', 'flac', 'alac', 'wma', 'mp3', 'ogg', 'aac', 'mp4', 'mov', 'avi', 'png', 'jpeg', 'jpg']

            if (types.find(element => element === ext)) {
                this.url_file = file
                this.preview_files = true
            } else {
                window.open(file, '_blank')
            }
        },

        closeModalBriefing() {
            this.formDateBriefings = {}
            this.add_briefing = false
            this.add_briefing_btn = false
        },

        updateCustomerProfileBriefing(index, id) {
            this.formDateBriefings = this.formDate.brifings[index]
            this.add_briefing = true
            this.add_briefing_btn = true
            this.formDateBriefings.id_briefing = id
            delete Object.assign(this.formDateBriefings, {['profile_id']: this.formDateBriefings['id']})['id'];
        },
    },

    computed: {
        selectClientList() {
            return this.$store.state.var.selectClientList;
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        files_computed() {
            return this.formDateFile
        },
    }

}
</script>


<style scoped>

</style>

<style scoped>
#tab-2, #tab-2 + span,
#tab-2:checked ~ .line {
    left: 33.3%;
}

#tab-3, #tab-3 + span,
#tab-3:checked ~ .line {
    left: 66.6%;
}

.tabs > input,
.tabs > span,
.effect-3 .line {
    width: calc(100% / 3);
}

.modal-files-preview,
.modal-add-briefings {
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 100;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
    background: rgb(0, 0, 0, 0.6);
}

.modal-add-briefings .div-add-briefings {
    width: 60%;
    padding: 2%;
    height: auto;
    box-shadow: none;
    position: relative;
    background: #FFFFFF;
    margin: 5% 2% 5% 2%;
}

.modal-add-briefings .div-add-briefings h2 {
    width: fit-content;
    font-family: 'Comfortaa', cursive;
    font-size: 20px;
    position: relative;
    left: 0;
    top: -19px;
    color: #000;
    font-weight: 50;
}

.modal-files-preview .btn-close,
.modal-add-briefings .btn-close {
    top: 0;
    right: 0;
    margin: 2%;
    cursor: pointer;
    position: absolute;
}

.modal-files-preview span {
    top: 0;
    right: 0;
    margin: 2%;
    width: 50px;
    height: 50px;
    display: block;
    background: white;
    position: absolute;
    border-radius: 50%;
    padding: 0.4rem 0.5rem;
}

.modal-files-preview .btn-close {
    font-size: 1.5rem;
    position: relative;
}

.modal-files-preview > img,
.modal-files-preview > video {
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    display: block;
    max-height: 80vh;
    max-width: 80vw;
    position: relative;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
}

.card {
    width: 90%;
    margin: 1rem;
    font-size: 1rem;
    max-width: 20rem;
    min-height: 24rem;
    position: relative;
    display: inline-block;
    text-decoration: none;
    box-shadow: 0 0 3rem -1rem rgba(0, 0, 0, 0.5);
    transition: transform 0.1s ease-in-out, box-shadow 0.1s;
}

.card-image {
    display: block;
    object-fit: contain;
    text-align: center;
}

.card-image i.icon {
    display: initial;
    font-size: 8rem;
}

.card-image a > img {
    width: 100%;
    display: block;
}

.card:hover {
    transform: translateY(-0.5rem) scale(1.0125);
    box-shadow: 0 0.5em 3rem -1rem rgba(0, 0, 0, 0.5);
}

.card p {
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
}

.ow-img {
    width: 100%;
    display: flex;
    padding: 10px 10px;
    margin-bottom: 5px;
    align-items: center;
}

.ow-img > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.aux-row-3 {
    display: flex;
    justify-content: space-evenly
}

.dates-card {
    bottom: 0;
    opacity: 1;
    width: 100%;
    height: 15%;
    display: flex;
    position: absolute;
    place-items: center;
    place-content: space-evenly;
}

.dates-card button {
    margin: 0;
    width: 50%;
    height: 100%;
    border-radius: 0;
}

/*#############################*/

.file-content {
    width: 100%;
    height: 60px;
    display: flex;
    color: black;
    padding: 15px 0;
    position: relative;
    align-items: center;
    background: #ebebeb;
    justify-content: space-between;
}

.file-content label {
    width: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
}

.file-content label img {
    height: auto;
    width: 50px;
    border-radius: 50%;
    margin: 0 10px 0 5px;
}

.file-content label p {
    margin: 0;
    width: 85%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.action-buttons {
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    position: absolute;
}

.action-buttons > button {
    border: 0;
    width: 55px;
    padding: 5px;
    height: 100%;
    background: #ebebeb;
}

.action-buttons > div {
    border: 0;
    width: 30px;
    padding: 5px;
    display: grid;
    place-items: center;
    background: #ebebeb;
}

@media (max-width: 428px) {
    .modal-add-briefings .div-add-briefings {
        width: 95%;
    }
}
</style>