import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = 'profile';
export default {

    loadCustomersProfiles(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then(response => {
                    context.commit('LOAD_CUSTOMER_PROFILE', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    storeCustomerProfile(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {
                })
        })
    },

    loadCustomerProfile(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
        })
    },

    updateCustomerProfile(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                .then((r) => {resolve(r)})
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeCustomerProfilePassword(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}profile-password-save`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {
                })
        })
    },

    updateCustomerProfilePassword(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}profile-password-edit`, formData)
                .then((r) => {resolve(r)})
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyCustomerProfilePassword(context, formDate) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}profile-password-delete/${formDate.id}/${formDate.profile_id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeCustomerProfileFiles(context, formData) {
        let config = {headers: {'Content-Type': 'multipart/form-data'}}
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}profile-add-file`, formData, config)
                .then((response) => {resolve(response)})
                .catch(errors => reject(errors))
        })
    },

    destroyCustomerProfileFiles(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}profile-delete-file/${id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeCustomerProfileBriefings(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}profile-briefing-save`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {
                })
        })
    },

    destroyCustomerProfileBriefings(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}profile-briefing-delete/${formData.id}/${formData.profile_id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateCustomerProfileBriefings(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}profile-briefing-edit`, formData)
                .then((r) => {resolve(r)})
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

}