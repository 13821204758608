<template>
    <div class="janela" v-if="windowEvent">
        <div class="flag-status-two" :class="[{'entrance-color': parseInt(formDateLow.type) === 1,
                    'exit-color': parseInt(formDateLow.type) === 2}]">

            <i :class="[{'arrow alternate circle up outline': parseInt(formDateLow.type) === 1,
                        'arrow alternate circle down outline': parseInt(formDateLow.type) === 2}, 'icon']"></i>

            {{ typeString(formDateLow.type) }}
            <div class="close-event" @click="modalAccount()">X</div>
        </div>

        <form autocomplete="off" class="mt-2">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <div class="aux-row-2">
                        <p>Criado por:</p>
                        <img v-if="formDateLow.photo_user" class="character-label-icon-2"
                             :src="formDateLow.photo_user" alt="">
                        <img v-else class="character-label-icon-2" src="@/assets/img/avatar.png" alt="">
                        {{ formDateLow.name_user }}
                    </div>

                    <div class="aux-row-2">
                        <p>Código:</p>
                        {{ formDateLow.code }}
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <div class="aux-row-3">
                        <div class="text">
                            Data de criação <br> <i class="calendar icon"></i>
                            {{ formDateLow.date_create }}
                        </div>
                        <div class="text">
                            Horário de criação <br> <i class="clock icon"></i>
                            {{ formDateLow.hour_create }}
                        </div>
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <div class="aux-row-3">
                        <div class="text">Valor <br>
                            <i class="money icon"></i>
                            {{ formDateLow.price }}
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                    <div class="responsible-task">
                        <h6>Conta Bancária:</h6>
                        <Multiselect v-model="formDateLow.bank_accounts_id" label="name"
                                     :options="selectBankAccountList" :searchable="true"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText">
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                            </template>
                        </Multiselect>
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <div class="responsible-task">
                        <h6>Negócio:</h6>
                        <multiselect v-model="formDateLow.business" :searchable="true" :options="selectBusiness"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <div class="responsible-task">
                        <h6>Vencimento</h6>
                        <input-component :name-input="'name'" :type-input="'text'" v-mask="'##/##/####'"
                                         v-model="formDateLow.due_date"></input-component>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Tipo de Conta:</label>
                    <multiselect @select="selectSubTypeAccountTwo(formDateLow.account_types_id)"
                                 v-model="formDateLow.account_types_id" :searchable="true"
                                 :options="selectSearchAccountType"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Tipo de Sub Conta:</label>
                    <multiselect v-model="formDateLow.account_type_sub_id" :searchable="true"
                                 :options="selectAccountTypeSubList"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Valor Pago:</label>
                    <input-component :name-input="'name'" v-money="money" :type-input="'text'"
                                     v-model="formDateLow.price_paid"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Data de Efetivação:</label>
                    <input-component :name-input="'name'" :type-input="'text'" v-mask="'##/##/####'"
                                     v-model="formDateLow.effective_date"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Antecipado:</label>
                    <multiselect v-model="formDateLow.attended" :searchable="true" :options="selectAttended"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <input-text-area :rows="4" v-model="formDateLow.obs"/>
                </row-col-component>
            </row-component>

            <button-one v-if="parseInt(formDateLow.type) === 1" name-button="Baixar" icon="arrow up"
                        @click="storeLowEntranceExitPreview"/>
            <button-one v-else name-button="Baixar" icon="arrow down" @click="storeLowEntranceExitPreview"/>
        </form>
    </div>

    <div class="janela" v-if="windowEventEdit">

        <div class="flag-status-two" :class="[{'entrance-color': parseInt(formDateEdit.type) === 1,
                    'exit-color': parseInt(formDateEdit.type) === 2}]">

            <i :class="[{'arrow alternate circle up outline': parseInt(formDateEdit.type) === 1,
                        'arrow alternate circle down outline': parseInt(formDateEdit.type) === 2}, 'icon']"></i>

            {{ typeString(formDateEdit.type) }}
            <div class="close-event" @click="modalAccountEdit()">X</div>
        </div>

        <form autocomplete="off" class="mt-2">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <div class="aux-row-2">
                        <p>Criado por:</p>
                        <img v-if="formDateEdit.photo_user" class="character-label-icon-2"
                             :src="formDateEdit.photo_user" alt="">
                        <img v-else class="character-label-icon-2" src="@/assets/img/avatar.png" alt="">
                        {{ formDateEdit.name_user }}
                    </div>

                    <div class="aux-row-2">
                        <p>Código:</p>
                        {{ formDateEdit.code }}
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <div class="aux-row-3">
                        <div class="text">Data de criação <br> <i class="calendar icon"></i>
                            {{ formDateEdit.date_create }}
                        </div>
                        <div class="text">
                            Horário de criação <br> <i class="clock icon"></i>
                            {{ formDateEdit.hour_create }}
                        </div>
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <div class="aux-row-3">
                        <div class="text">Valor <br>
                            <i class="money icon"></i>
                            {{ formDateEdit.price }}
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                    <div class="responsible-task">
                        <h6>Conta Bancária:</h6>
                        <Multiselect v-model="formDateEdit.bank_accounts_id" label="name"
                                     :options="selectBankAccountList" :searchable="true"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText">
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                            </template>
                        </Multiselect>
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <div class="responsible-task">
                        <h6>Negócio:</h6>
                        <multiselect v-model="formDateEdit.business" :searchable="true" :options="selectBusiness"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <div class="responsible-task">
                        <h6>Vencimento</h6>
                        <input-component :name-input="'name'" :type-input="'text'" v-mask="'##/##/####'"
                                         v-model="formDateEdit.due_date"></input-component>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Tipo de Conta:</label>
                    <multiselect @select="selectSubTypeAccountTwo(formDateEdit.account_types_id)"
                                 v-model="formDateEdit.account_types_id" :searchable="true"
                                 :options="selectSearchAccountType"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Tipo de Sub Conta:</label>
                    <multiselect v-model="formDateEdit.account_type_sub_id" :searchable="true"
                                 :options="selectAccountTypeSubList"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Valor Pago:</label>
                    <input-component :name-input="'name'" v-money="money" :type-input="'text'"
                                     v-model="formDateEdit.price_paid"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Data de Efetivação:</label>
                    <input-component :name-input="'name'" :type-input="'text'" v-mask="'##/##/####'"
                                     v-model="formDateEdit.effective_date"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Antecipado:</label>
                    <multiselect v-model="formDateEdit.attended" :searchable="true" :options="selectAttended"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <input-text-area :rows="4" v-model="formDateEdit.obs"/>
                </row-col-component>
            </row-component>

            <button-one icon="save" name-button="Editar" @click="updateEntranceExitPreview"/>
        </form>
    </div>

    <painel-component :itens="itensOne">
        <div class="filter-position" v-if="btnFilter">
            <button-one icon="filter" class="btn-filter" @click="filterWindow"/>
        </div>

        <form class="mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                        <label>Código:</label>
                        <input-component :type-input="'text'" v-model="formDate.code"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                        <label>Mês:</label>
                        <multiselect v-model="formDate.month" :searchable="true" :options="selectMonth"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                        <label>Ano:</label>
                        <multiselect v-model="formDate.year" :searchable="true" :options="getYearSelect()"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Negócio:</label>
                        <multiselect v-model="formDate.business" :searchable="true" :options="selectBusiness"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Conta Bancária:</label>
                        <Multiselect v-model="formDate.bank_accounts_id" label="name" :options="selectBankAccountList"
                                     :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                            </template>
                        </Multiselect>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Tipo Entrada / Saída:</label>
                        <multiselect v-model="formDate.type" :searchable="true" :options="selectEntranceExit"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Tipo de Conta:</label>
                        <multiselect v-model="formDate.account_types_id" :searchable="true"
                                     :options="selectSearchAccountType"
                                     @select="selectSubTypeAccount(formDate.account_types_id)"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Sub Tipo de Conta:</label>
                        <multiselect v-model="formDate.account_type_sub_id" :searchable="true"
                                     :options="selectAccountTypeSubList"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Antecipado:</label>
                        <multiselect v-model="formDate.attended" :searchable="true" :options="selectAttended"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'">
                        <button-one class="mx-1" @submit="submit" :icon="'search'"/>
                        <button-float class="mx-1" :itens="itensTwo" v-if="buttonCheck.create"/>
                    </row-col-component>
                </row-component>
            </transition>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <div class="bg-card-alert alert alert-secondary alert-dismissible fade show" role="alert">
                        <p>Total antecipado: {{ objectReturn.totalQuantAnticipated }}</p>
                        <p>Valor: <span>R${{ objectReturn.totalSumAnticipated }}</span></p>
                        <i class="icon-card-exit  arrow alternate circle down outline icon"></i>
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <div class="bg-card-alert alert alert-secondary alert-dismissible fade show" role="alert">
                        <p>Total de entrada: {{ objectReturn.totalQuantEntrance }}</p>
                        <p>Valor: <span>R${{ objectReturn.totalValueEntrance }}</span></p>
                        <i class="icon-card-entrance  arrow alternate circle up outline icon"></i>
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <div class="bg-card-alert alert alert-secondary alert-dismissible fade show" role="alert">
                        <p>Total de saída: {{ objectReturn.totalQuantExit }}</p>
                        <p>Valor: <span>R${{ objectReturn.totalValueExit }}</span></p>
                        <i class="icon-card-exit arrow alternate circle down outline icon"></i>
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <div class="bg-card-alert alert alert-secondary alert-dismissible fade show" role="alert">
                        <p>Subtotal</p>
                        <p>Valor: <span>R${{ objectReturn.subtotal }}</span></p>
                        <i class="icon-card-entrance money outline icon"></i>
                    </div>
                </row-col-component>
            </row-component>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end'">
                    <div class="toggle_preferences">
                        <button :class="{ 'bg-active': parseInt(cardTable) === 1 }" @click="toggleCard" type="button"
                                tooltip="Card">
                            <i class="window maximize outline icon"></i>
                        </button>
                        <button :class="{ 'bg-active': parseInt(cardTable) === 2 }" @click="toggleTable" type="button"
                                tooltip="Tabela">
                            <i class="align justify icon"></i>
                        </button>
                    </div>
                </row-col-component>
            </row-component>

            <row-component v-if="parseInt(cardTable) === 1">
                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   v-for="(data, index) in  object" :key="index">
                    <div class="card-container">
                        <div class="flag-status" :class="[{ 'entrance-color': parseInt(data.type) === 1,
                            'exit-color': parseInt(data.type) === 2}]">

                            <div>
                                <i :class="[{'arrow alternate circle up outline': parseInt(data.type) === 1,
                                    'arrow alternate circle down outline': parseInt(data.type) === 2}, 'icon']"></i>
                                {{ typeString(data.type) }}
                            </div>

                            <p v-if="parseInt(data.attended) === 2">Antecipado!</p>
                        </div>

                        <p>Código: {{ data.code }}</p>
                        <h3>Negócio: {{ businessString(data.business) }}</h3>

                        <div class="skills">
                            <p>{{ data.account_types_name }}</p>
                            <div class="text text-center" style="font-weight: bold; font-size: 13px;">
                                {{ data.account_types_sub_name }}
                            </div>
                            <div class="aux-row-3">
                                <div class="text"><i class="calendar icon"></i>{{ data.due_date }}</div>
                                <div class="text"><i class="money icon"></i>R$ {{ data.price }}</div>
                            </div>
                        </div>

                        <div class="responsible-task">
                            <div class="tag-vanquished">
                                <h6>Conta Bancária:</h6>
                                <span v-if="parseInt(data.vanquished) !== 3">
                                    {{ vanquishedString(data.vanquished) }}
                                </span>
                            </div>

                            <img v-if="data.bank_accounts_photo" :src="data.bank_accounts_photo"
                                 class="avatar__image_2" alt="">
                            <img v-else src="@/assets/img/avatar.png" alt="" class="avatar__image_2">
                            {{ data.bank_accounts_name }}
                        </div>

                        <div class="buttons">
                            <button tooltip="Excluir" class="primary" v-if="buttonCheck.delete"
                                    @click=deleteSubmit(data)>
                                <i class="trash icon"></i>
                            </button>

                            <button tooltip="Editar" class="primary" v-if="buttonCheck.delete"
                                    @click="showPreviewEdit(data.id)">
                                <i class="edit icon"></i>
                            </button>

                            <button tooltip="Baixar" class="primary" v-if="buttonCheck.low"
                                    @click="showPreview(data.id)">
                                <i class="arrow down icon" :class="[{'arrow up icon': parseInt(data.type) === 1,
                                    'arrow down icon': parseInt(data.type) === 2}]"></i>
                            </button>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <table class="table table-striped" v-if="parseInt(cardTable) === 2">
                <thead>
                <tr>
                    <th scope="col" style="width: 5%">Código</th>
                    <th scope="col" style="width: 10%">Conta Bancária</th>
                    <th scope="col" style="width: 8%">Tipo de Conta <br> Tipo de Sub Conta</th>
                    <th scope="col" style="width: 5%">Preço</th>
                    <th scope="col" style="width: 5%">Negócio</th>
                    <th scope="col" style="width: 8%">Entrada / Saída</th>
                    <th scope="col" style="width: 8%">Data de Vencimento</th>
                    <th scope="col" style="width: 8%">Atrasado <br> Antecipado</th>
                    <th scope="col" style="width: 8%" v-if="buttonCheck.show || buttonCheck.delete || buttonCheck.low">
                        Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in object" :key="index">
                    <td data-label="Código">{{ data.code }}</td>
                    <td data-label="Conta Bancária">
                        <img v-if="data.bank_accounts_photo" :src="data.bank_accounts_photo" class="avatar__image_2"
                             alt="">
                        <img v-else src="@/assets/img/avatar.png" alt="" class="avatar__image_2">
                        {{ data.bank_accounts_name }}
                    </td>
                    <td data-label="Tipo de Conta / Tipo de Sub Conta">
                        {{ data.account_types_name }} <br> {{ data.account_types_sub_name }}
                    </td>
                    <td data-label="Preço">{{ data.price }}</td>
                    <td data-label="Negócio">{{ businessString(data.business) }}</td>
                    <td data-label="Entrada / Saída">
                        <i :class="[{'icon-card-entrance-table arrow alternate circle up outline': parseInt(data.type) === 1,
                                'icon-card-exit-table arrow alternate circle down outline': parseInt(data.type) === 2}, 'icon']"></i>
                    </td>
                    <td data-label="Data de Vencimento">{{ data.due_date }}</td>
                    <td data-label="Atrasado Antecipado">
                        <div class="tag-vanquished">
                            <span v-if="parseInt(data.vanquished) !== 3">
                                {{ vanquishedString(data.vanquished) }}
                            </span>
                        </div>
                        <p v-if="parseInt(data.attended) === 2">Antecipado!</p>
                    </td>
                    <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.delete || buttonCheck.low">
                        <button tooltip="Ver" class="btn btn-success mx-1 button-show" v-if="buttonCheck.show"
                                @click="showPreviewEdit(data.id)">
                            <i class="edit icon"></i>
                        </button>

                        <button tooltip="Deletar" class="btn btn-danger mx-1 button-show" v-if="buttonCheck.delete"
                                @click=deleteSubmit(data)>
                            <i class="trash icon"></i>
                        </button>

                        <button tooltip="Baixar" class="btn btn-secondary mx-1 button-show" v-if="buttonCheck.low"
                                @click="showPreview(data.id)">
                            <i class="arrow down icon" :class="[{'arrow up icon': parseInt(data.type) === 1,
                                    'arrow down icon': parseInt(data.type) === 2}]"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <button-pagination :pagination="objectReturn.itens" :offset="3" @paginate="loadEntranceExitPreviews"/>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import InputComponent from "@/components/form/InputComponent";
import InputTextArea from "@/components/form/InputTextArea"
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";
import swal from 'sweetalert';
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import {ACTION_MESSAGES, MONEY} from "@/configs/constants"
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "EntExitPreviewSearch",
    components: {
        PainelComponent,
        ButtonOne,
        ButtonFloat,
        ButtonPagination,
        InputComponent,
        RowComponent,
        RowColComponent,
        LoderSendComponent,
        Multiselect,
        InputTextArea
    },

    directives: {
        money: VMoney,
        mask
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Entrada / Saída (Previsão)')
        this.$store.dispatch('loadAccountTypeListSelect')
        this.$store.dispatch('loadAccountBankSelect')
        this.loadEntranceExitPreviews(1)
        this.resizeWindow()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            itensTwo: {
                route: "/entrance-exit-preview/create",
                icon: "plus",
                name: ''
            },

            buttonCheck: {
                edit: false,
                create: false,
                show: false,
                delete: false,
                low: false
            },

            formDate: {
                code: '',
                bank_accounts_id: '',
                account_types_id: '',
                account_type_sub_id: '',
                business: '',
                type: '',
                attended: '',
                month: this.getMonth(),
                year: this.getYear()
            },

            formDateLow: {
                code: '',
                type: '',
                type_preview: '',
                due_date: '',
                price: '',
                price_paid: '',
                obs: '',
                business: '',
                user_name: '',
                created_at: '',
                updated_at: '',
                photo_user: '',
                name_user: '',
                date_create: '',
                hour_create: '',
                account_types_name: '',
                bank_accounts_name: '',
                bank_accounts_id: '',
                bank_accounts_photo: '',
                effective_date: '',
                attended: '',
                account_types_id: '',
                account_type_sub_id: '',
            },

            formDateLowSubmit: {
                id: '',
                due_date: '',
                price_paid: '',
                price: '',
                business: '',
                bank_accounts_id: '',
                effective_date: '',
                attended: '',
                account_types_id: '',
                account_type_sub_id: '',
                obs: '',
            },

            formDateEdit: {
                id: '',
                due_date: '',
                price_paid: '',
                price: '',
                business: '',
                bank_accounts_id: '',
                effective_date: '',
                attended: '',
                account_types_id: '',
                account_type_sub_id: '',
                obs: '',
            },

            object: {},

            windowEvent: false,
            windowEventEdit: false,

            cardTable: '',
            preferences: {
                type: 8,
                preference: ''
            },

            money: MONEY,

            filter: true,
            btnFilter: false,
            showLoader: true,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadEntranceExitPreviews(1)
            this.searchSubmit = true;
        },

        loadEntranceExitPreviews(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadEntranceExitPreviews', {...this.params, page})
                    .then((r) => {
                        this.showLoader = false
                        this.cardTable = r.tableCard
                        this.object = r.itens.data
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        deleteSubmit(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar  ${item.account_types_name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyEntranceExitPreview', item.id).then(() => {
                        this.loadEntranceExitPreviews(1)
                        toast.success(ACTION_MESSAGES.delete, {timeout: 10000});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar`, {timeout: 10000});
                    })
                }
            });
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 16)
                .then((r) => {

                    if (r.data.indexOf('entrance-exit-preview-search') !== -1) {
                        this.showLoader = false

                        if (r.data.indexOf('entrance-exit-preview-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('entrance-exit-preview-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('entrance-exit-preview-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                        if (r.data.indexOf('entrance-exit-preview-low') !== -1) {
                            this.buttonCheck.low = true
                        }

                        if (r.data.indexOf('entrance-and-exit-preview-low') !== -1) {
                            this.buttonCheck.low = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },

        toggleTable() {
            this.userPreferences(2)
            this.cardTable = 2
        },

        toggleCard() {
            this.userPreferences(1)
            this.cardTable = 1
        },

        userPreferences(preference) {
            this.preferences.preference = preference
            this.$store.dispatch('userPreferences', this.preferences)
                .then(() => {
                })
        },

        modalAccount() {
            this.windowEvent = !this.windowEvent
        },

        modalAccountEdit() {
            this.windowEventEdit = !this.windowEventEdit
        },

        showPreview(params) {
            this.$store.dispatch('loadEntranceExitPreview', params).then((r) => {
                this.formDateLow = r;

                if (r.price_paid == "0,00") {
                    this.formDateLow.price_paid = r.price
                }
                this.$store.dispatch('loadAccountTypeSubListSelect', r.account_types_id)
                this.windowEvent = true;
            }).catch(() => {
                this.showLoader = false;
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        showPreviewEdit(params) {
            this.$store.dispatch('loadEntranceExitPreview', params).then((r) => {
                this.formDateEdit = r;
                if (r.price_paid == "0,00") {
                    this.formDateEdit.price_paid = r.price
                }
                this.$store.dispatch('loadAccountTypeSubListSelect', r.account_types_id)
                this.windowEventEdit = true;
            }).catch(() => {
                this.showLoader = false;
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        updateEntranceExitPreview() {
            this.$store.dispatch('updateEntranceExitPreview', this.formDateEdit).then(() => {
                toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                this.loadEntranceExitPreviews()
                this.windowEventEdit = false
            }).catch(() => {
                toast.error("Não foi possível realizar a baixa!", {timeout: 2000});
            })
        },

        storeLowEntranceExitPreview() {
            this.formDateLowSubmit.id = this.formDateLow.id;
            this.formDateLowSubmit.price_paid = this.formDateLow.price_paid;
            this.formDateLowSubmit.bank_accounts_id = this.formDateLow.bank_accounts_id;
            this.formDateLowSubmit.attended = this.formDateLow.attended;
            this.formDateLowSubmit.effective_date = this.formDateLow.effective_date
            this.formDateLowSubmit.due_date = this.formDateLow.due_date
            this.formDateLowSubmit.business = this.formDateLow.business
            this.formDateLowSubmit.price = this.formDateLow.price
            this.formDateLowSubmit.obs = this.formDateLow.obs
            this.formDateLowSubmit.account_types_id = this.formDateLow.account_types_id
            this.formDateLowSubmit.account_type_sub_id = this.formDateLow.account_type_sub_id

            this.$store.dispatch('storeLowEntranceExitPreview', this.formDateLowSubmit).then(() => {
                toast.success("Baixa realizada com sucesso", {timeout: 2000});
                this.loadEntranceExitPreviews()
                this.windowEvent = false
            }).catch(() => {
                toast.error("Não foi possível realizar a baixa!", {timeout: 2000});
            })
        },

        businessString(value) {
            if (parseInt(value) === 1)
                return 'Cidade Vertical'

            if (parseInt(value) === 2)
                return 'D7M Marketing'

            if (parseInt(value) === 3)
                return 'Methoduz'
        },

        typeString(type) {
            if (parseInt(type) === 1)
                return 'Entrada'
            return 'Saida'
        },

        vanquishedString(type) {
            if (parseInt(type) === 1)
                return 'Vence Hoje!'
            if (parseInt(type) === 2)
                return 'Atrasado!'
            else
                return ''
        },

        getYearSelect() {
            let dataActual = new Date();
            let year = dataActual.getFullYear() + 2;
            let years = [];
            for (let i = 2021; i <= year; i++) {
                years.push(i);
            }
            return years
        },

        getYear() {
            let date = new Date()
            return date.getFullYear()
        },

        getMonth() {
            let date = new Date()
            return date.getMonth() + 1
        },

        selectSubTypeAccount(id) {
            this.$store.dispatch('loadAccountTypeSubListSelect', id)
        },

        selectSubTypeAccountTwo(id) {
            this.formDateLow.account_type_sub_id = ''
            this.$store.dispatch('loadAccountTypeSubListSelect', id)
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectBusiness() {
            return this.$store.state.var.selectBusiness;
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        selectMonth() {
            return this.$store.state.var.selectMonth;
        },

        selectSearchAccountType() {
            return this.$store.state.var.selectSearchAccountType;
        },

        selectEntranceExit() {
            return this.$store.state.var.selectEntranceExit;
        },

        selectBankAccountList() {
            return this.$store.state.var.selectBankAccountList
        },

        selectAccountTypeSubList() {
            return this.$store.state.var.selectAccountTypeSubList
        },

        selectAttended() {
            return this.$store.state.var.selectAttended
        },

        objectReturn() {
            return this.$store.state.entrance_exit_preview.items
        },

        params() {
            return {
                page: this.objectReturn.current_page,
                code: this.formDate.code,
                bank_accounts_id: this.formDate.bank_accounts_id,
                account_types_id: this.formDate.account_types_id,
                account_type_sub_id: this.formDate.account_type_sub_id,
                business: this.formDate.business,
                type: this.formDate.type,
                attended: this.formDate.attended,
                month: this.formDate.month,
                year: this.formDate.year
            }
        },
    },
}
</script>

<style scoped src="@/assets/css/table-resp.css"></style>
<style scoped>
.responsible-task h6 {
    font-size: 14px;
}

.bg-card-alert p {
    margin: 0;
}

.bg-card-alert span {
    font-weight: bold;
}

button.primary i,
.aux-row-3 .text > i {
    color: #7e7e7e;
}

.icon-card-entrance,
.icon-card-entrance-table {
    font-size: 24px;
    color: #16ab39;
}

.icon-card-exit,
.icon-card-exit-table {
    font-size: 24px;
    color: #db2828;
}

.bg-card-alert {
    background-color: #fdfdfd;
}

.bg-active {
    background-color: #d67c1c !important;
}

.entrance-color {
    background-color: #16ab39;
}

.exit-color {
    background-color: #db2828;
}

.toggle_preferences > button {
    border: 0;
    width: 45px;
    color: #fff;
    height: 45px;
    margin: 0 5px;
    padding-right: 4px;
    border-radius: 50%;
    background-color: #323232;
}

.button-show {
    width: 35px;
    height: 35px;
    font-size: 15px;
    border-radius: 50px;
    padding-left: 0.5rem;
}

.character-label-icon-2 {
    height: 45px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 50%;
}

.aux-row-2 {
    width: 98%;
    display: flex;
    align-items: center;
}

.aux-row-2 p {
    margin: 0 10px 0 0;
}

.tag-vanquished {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tag-vanquished span {
    color: #fff;
    padding: 0 1rem;
    font-size: 13px;
    font-weight: bold;
    width: fit-content;
    border-radius: 25px;
    background-color: #d67c1c;
}

.icon-card-entrance,
.icon-card-exit {
    top: 0;
    right: 0;
    font-size: 2rem;
    margin: 15px 15px;
    position: absolute;
}

.responsible-task .avatar__image_2 {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #d67c1c;
}

.card-container {
    width: 350px;
    margin: 20px 0;
    color: #2f3032;
    max-width: 100%;
    overflow: hidden;
    padding: 50px 0 0;
    position: relative;
    text-align: center;
    border: solid 1px #ededed;
    background-color: #ffffff;
    box-shadow: 10px 10px 15px rgb(0 0 0 / 35%);
}

.card-container h3 {
    color: #424242;
    font-size: 14px;
    font-family: inherit;
    text-transform: uppercase;
}

.buttons {
    min-height: 60px;
    border-top: 1px solid #ededed;
}

button.primary {
    width: 50%;
    height: 60px;
    color: #7e7e7e;
    padding: 5px 25px;
    background-color: #f5f5f5;
    border: 1px solid #fdfdfd;
}

button.primary a {
    color: #7e7e7e;
    padding: 15px 25px;
    text-decoration: none;
}

.skills {
    padding: 15px;
    min-height: 80px;
    text-align: left;
    margin-top: 30px;
    background-color: #f5f5f5;
    border-top: 1px solid #ededed;
}

.responsible-task {
    padding: 15px;
    min-height: 80px;
    text-align: left;
    border-top: 1px solid #ededed;
}

.skills p {
    font-size: 1rem;
    text-align: center;
}

.flag-status {
    top: 0;
    width: 100%;
    display: flex;
    color: #ededed;
    font-size: 14px;
    text-align: left;
    padding: 10px 20px;
    position: absolute;
    align-items: center;
    justify-content: space-between;
    font-family: 'Comfortaa', sans-serif;
}

.flag-status-two {
    top: 0;
    width: 100%;
    color: #ededed;
    font-size: 14px;
    text-align: left;
    padding: 10px 20px;
    position: absolute;
    font-family: 'Comfortaa', sans-serif;
}

.aux-row-3 {
    width: 100%;
    display: flex;
    margin-top: 10px;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-evenly;
}

.janela {
    top: 50%;
    left: 50%;
    z-index: 20;
    width: 850px;
    height: auto;
    position: fixed;
    background: white;
    padding: 40px 20px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.janela > .flag-status-two {
    left: 0;
    position: absolute;
}

.close-event {
    top: 0;
    right: 0;
    font-size: 1rem;
    cursor: pointer;
    margin: 10px 20px;
    position: absolute;
}

@media (max-width: 992px) {
    .janela {
        width: 95%;
        height: 815px;
        overflow: scroll;
    }

    .card-container {
        height: 385px;
        width: 90%;
    }

    .skills {
        flex-direction: column;
        align-items: flex-start;
    }

    .close-event {
        margin: 0 10px 0 0;
    }

    .card-container {
        height: 414px;
    }
}

@media (max-width:  500px) {
    .janela {
        width: 95%;
        height: 700px;
        overflow: scroll;
    }
}
</style>