<template>
    <painel-component :itens="itensOne">

        <error-component :errors="errors" v-if="showError"></error-component>

        <loder-send-component :text-title="'Carregando'" v-if="showLoder"></loder-send-component>

        <form autocomplete="off">

            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                    :class="{'formError':errors.name}">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" :model-value="formDate.name" disabled>
                    </input-component>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                    :class="{'formError':errors.sector}">
                    <label>Setor:</label>
                    <Multiselect v-model="formDate.sector" :searchable="true" :options="selectSector" disabled
                        :noResultsText="this.$store.state.var.selectNotSearch" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                    :class="{'formError':errors.responsibles}">
                    <label>Responsaveis:</label>
                    <Multiselect v-model="formDate.responsibles" mode="tags" track-by="name" label="name" disabled
                        :options="selectUserList" :searchable="true"
                        :noResultsText="this.$store.state.var.selectNotSearch" :close-on-select="false" :search="true">
                        <template v-slot:tag="{ option, handleTagRemove, disabled }">
                            <div class="multiselect-tag is-user" :class="{'is-disabled': disabled}">
                                <img :src="option.icon">
                                {{ option.name }}
                                <span v-if="!disabled" class="multiselect-tag-remove"
                                    @mousedown.prevent="handleTagRemove(option, $event)">
                                    <span class="multiselect-tag-remove-icon"></span>
                                </span>
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon"> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                    :class="{'formError':errors.desc}">
                    <label>Descrição:</label>
                    <QuillEditor :theme="'bubble'" :contentType="'html'" ref="myEditor" v-model:content="formDate.desc"
                        style="min-height: 150px; margin-bottom: 2%; border:1px solid #1b1c1d" :readOnly="true">
                    </QuillEditor>
                </row-col-component>
            </row-component>

        </form>

    </painel-component>

    <button-float :itens="itensTwo"></button-float>
</template>
  
<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { useToast } from "vue-toastification";

const toast = useToast();

export default {
    name: "NoteShow",

    created() {
        this.checkACL()
        this.loaditensPage()
        this.$store.dispatch('loadUserListSelect')
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "eye"
            },

            itensTwo: {
                route: "/note/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                sector: '',
                desc: '',
                responsibles: []
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoder: true,

            toolbarOptions: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],
                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction
                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],
                ['clean']                                         // remove formatting button
            ],

        }
    },

    methods: {
        loaditensPage() {
            this.$store.dispatch('updateNamePage', 'Anotações')
            this.$store.dispatch('loadNote', this.id).then((r) => {
                this.formDate = r;
                this.$refs.myEditor.setHTML(r.desc)
            }).catch(() => {
                this.showLoder = true;
                toast.error("Erro desconhecido !", { timeout: 2000 });
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 12)
                .then((r) => {
                    if (r.data.indexOf('note-search') !== -1) {
                        this.showLoder = false
                    } else {
                        this.$router.push({ name: 'home' })
                    }
                }).catch(() => {
                    this.$router.push({ name: 'home' })
                })
        },
    },

    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        QuillEditor
    },

    computed: {
        selectSector() {
            return this.$store.state.var.selectSector
        },

        selectUserList() {
            return this.$store.state.var.selectUserList;
        },
    }

}
</script>
  
<style scoped>
#quillEditor {
    min-height: 700px;
}

.character-label-icon,
.character-option-icon {
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}
</style>
  
<style src="../../../src/assets/css/multSelect.css">

</style>
  


  
    