<template>
    <painel-component :itens="itensOne">
        <div class="clearfix" v-if="btnFilter">
            <button-one icon="filter" class="btn-filter" @click="filterWindow"/>
        </div>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDate.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'">
                        <button-one class="mx-1" @submit="submit" :icon="'search'"/>
                        <button-float class="mx-1" :itens="itensTwo" v-if="buttonCheck.create"/>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <table class="table table-striped">
                <thead>
                <tr>
                    <th scope="col" style="width: 30%">Nome</th>
                    <th scope="col" style="width: 25%">Status</th>
                    <th scope="col" style="width: 25%">Valor</th>
                    <th scope="col" style="width: 15%" v-if="buttonCheck.show || buttonCheck.edit">Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in  objectReturn.data" :key="index">
                    <td data-label="Nome">{{ data.name }}</td>
                    <td data-label="Status">{{ statusString(data.status) }}</td>
                    <td data-label="Valor">{{ data.price }}</td>
                    <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.edit">
                        <button-show-round :route="'service-show'" :id-param="data.id" v-if="buttonCheck.show"/>
                        <button-edit-round :route="'service-edit'" :id-param="data.id" v-if="buttonCheck.edit"/>
                    </td>
                </tr>
                </tbody>
            </table>

            <button-pagination :pagination="objectReturn" :offset="3" @paginate="loadServices"/>
        </div>
    </painel-component>
</template>

<script>
import ButtonPagination from "@/components/buttons/ButtonPagination";
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonShowRound from "@/components/buttons/ButtonShowRound";
import ButtonEditRound from "@/components/buttons/ButtonEditRound";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import InputComponent from "@/components/form/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";

import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "ServiceSearch",
    components: {
        ButtonShowRound,
        ButtonEditRound,
        ButtonPagination,
        PainelComponent,
        ButtonOne,
        ButtonFloat,
        InputComponent,
        RowComponent,
        RowColComponent,
        LoderSendComponent,
        Multiselect,
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Serviços')
        this.loadServices(1)
        this.resizeWindow()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            itensTwo: {
                route: "/service/create",
                icon: "plus",
                name: ''
            },

            buttonCheck: {
                edit: false,
                create: false,
                show: false
            },

            formDate: {
                code: '',
                name: '',
                status: 1,
                sector: '',
                responsibles: []
            },

            filter: true,
            showLoader: true,
            btnFilter: false,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadServices(1)
            this.searchSubmit = true;
        },

        loadServices(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadServices', {...this.params, page})
                    .then((r) => {
                        this.showLoader = false
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 18)
                .then((r) => {
                    if (r.data.indexOf('service-search') !== -1) {
                        this.showLoader = false

                        if (r.data.indexOf('service-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('service-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('service-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return 'Ativo'
            return 'Inativo'
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        objectReturn() {
            return this.$store.state.service.items
        },

        params() {
            return {
                page: this.objectReturn.current_page,
                name: this.formDate.name,
                status: this.formDate.status,
            }
        },
    },
}
</script>

<style scoped src="@/assets/css/table-resp.css"></style>