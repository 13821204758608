import LoginView from "@/views/LoginView";
import HomeView from "@/views/HomeView";
import WrapperComponent from "@/components/dashboard/AdminComponent";
import GroupUser from "@/router/modules/group-user";
import User from "@/router/modules/user";
import Client from "@/router/modules/client";
import Employee from "@/router/modules/employee";
import TaskType from "@/router/modules/task-type";
import GuestSchedule from "@/router/modules/guest-schedule";
import Task from "@/router/modules/task";
import MyTask from "@/router/modules/my-task";
import Schedule from "@/router/modules/schedule";
import MySchedule from "@/router/modules/my-schedule";
import Note from "@/router/modules/note";
import AccountType from "@/router/modules/account-type";
import AccountBank from "@/router/modules/account-bank";
import Provider from "@/router/modules/provider";
import EntranceExitPreview from "@/router/modules/entrance-exit-preview";
import EntranceExit from "@/router/modules/entrance-exit";
import Lead from "@/router/modules/lead";
import ProposalTemplate from "@/router/modules/proposal-template";
import Service from "@/router/modules/service";
import Product from "@/router/modules/product";
import Inventorie from "@/router/modules/inventorie";
import ContractModel from "@/router/modules/contract-model";
import BudgetTemplate from "@/router/modules/budget-template";
import Budget from "@/router/modules/budget";
import Proposal from "@/router/modules/proposal";
import Contract from "@/router/modules/contract";
import Banner from "@/router/modules/banner";
import Spots from "@/router/modules/spots"
import Fleet from "@/router/modules/fleet";
import FleetDepartures from "@/router/modules/fleet-departures";
import OrderService from "@/router/modules/order-service";
import Receipt from "@/router/modules/receipt";
import ScheduledNote from "@/router/modules/scheduled-note";
import EmailNotification from "@/router/modules/email-notification";
import ReportAnalyticalCashFlow from "@/router/modules/report-analytical-cash-flow";
import ContractExpiration from "@/router/modules/contract-expiration";
import CustomerProfile from "@/router/modules/customer-profile";
import ProfileClient from "@/router/modules/profile-client";

const routes = [
    {
        path: '/',
        component: WrapperComponent,
        children: [
            { path: 'login', name: 'login', component: LoginView },
            { path: '/', meta: { auth: true }, component: HomeView, name: 'home' },
        ]

    },
    GroupUser,
    User,
    Client,
    Employee,
    TaskType,
    GuestSchedule,
    Task,
    MyTask,
    Schedule,
    MySchedule,
    Note,
    AccountType,
    AccountBank,
    Provider,
    EntranceExitPreview,
    EntranceExit,
    Lead,
    ProposalTemplate,
    Service,
    Product,
    Inventorie,
    ContractModel,
    BudgetTemplate,
    Budget,
    Proposal,
    Contract,
    Banner,
    Spots,
    Fleet,
    FleetDepartures,
    OrderService,
    Receipt,
    ScheduledNote,
    EmailNotification,
    ReportAnalyticalCashFlow,
    ContractExpiration,
    CustomerProfile,
    ProfileClient
]

export default routes