<template>
    <painel-component :itens="itensOne">
        <button-add :itens="itensThree" v-if="buttonCheck.create"/>
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoder"/>

        <form autocomplete="off" v-else>
            <div class="aux mb-4 mt-3">
                <div class="avatar-form">
                    <img v-if="formDate.photo" :src="formDate.photo" class="avatar__image" alt="">
                    <img v-else src="@/assets/img/avatar.png" class="avatar__image" alt="">
                </div>
                <p>{{ formDate.name }}</p>
            </div>

            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2 mb-2'">
                    <label>Sexo:</label>

                    <input type="radio" id="nao-phone" name="teste" v-model="formDate.sexo" value="1" checked>
                    <label for="nao-phone">Feminino</label>

                    <input type="radio" id="sim-phone" name="teste" v-model="formDate.sexo" value="2">
                    <label for="sim-phone">Masculino</label>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component :name-input="'name'" :type-input="'text'" v-model="formDate.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Email (Login):</label>
                    <input-component :name-input="'email'" :type-input="'email'" v-model="formDate.email"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Status:</label>
                    <Multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText" :name="'status'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Grupo:</label>
                    <Multiselect v-model="formDate.group_id" :searchable="true" :options="selectPage"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText" :name="'group_id'"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 pt-2 mt-4'">
                    <checkbox-component :text-check-box="'Alterar Senha'" @checkboxSwitcher="passwordUp"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   v-if="updatePassword">
                    <label>Senha:</label>
                    <input-component :type-input="'password'" v-model="formDate.password" autocomplete="new-password"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   v-if="updatePassword">
                    <label>Confirmação de Senha:</label>
                    <input-component :type-input="'password'" v-model="formDate.passwordconfirm"
                                     autocomplete="new-password"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one class="w-100" @submit="submit" :icon="'save'" :name-button="'Salvar'"
                                :class="{'preloderSend': sendForm }"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-float class="w-100" :itens="itensTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonAdd from "@/components/buttons/ButtonAdd";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import CheckboxComponent from "@/components/form/CheckboxComponent";
import {ACTION_MESSAGES} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "UserUpdate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonAdd,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        CheckboxComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/user/search",
                icon: "reply all",
                name: 'Voltar'
            },

            itensThree: {
                route: "/user/create",
                icon: "plus",
                name: 'Adicionar'
            },

            formDate: {
                name: '',
                status: '',
                email: '',
                group_id: '',
                password: '',
                passwordconfirm: '',
                updatepassword: false,
                photo: '',
                sexo: ''
            },

            buttonCheck: {
                create: false
            },

            errors: [],
            showError: false,
            loaddingPageGroup: false,
            sendForm: false,
            showLoder: true,
            updatePassword: false
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateUser', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = [];
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                        if (this.id == this.$store.state.auth.me.id)
                            setTimeout(() => window.location.reload(), 2100);
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        passwordUp() {
            this.updatePassword = !this.updatePassword
            this.formDate.updatepassword = this.updatePassword
        },

        loadPageItens() {
            this.$store.dispatch('updateNamePage', 'Usuário')
            this.$store.dispatch('loadSelectSearch', 'group-list')
            this.$store.dispatch('loadUser', this.id).then((r) => {
                this.formDate = r;
                this.formDate.updatepassword = false;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 2)
                .then((r) => {

                    if (r.data.indexOf('user-edit') !== -1) {
                        this.showLoder = false
                    }

                    if (r.data.indexOf('user-create') !== -1) {
                        this.buttonCheck.create = true
                    } else {
                        this.$router.push({name: 'home'})
                    }

                }).catch(() => {
                this.$router.push({name: 'home'})
            });
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        selectPage() {
            return this.$store.state.var.selectSearch;
        }
    }
}
</script>