<template>
    <painel-component :itens="itensOne">
        <div class="filter-position" v-if="btnFilter">
            <button-one class="btn-filter" @click="filterWindow"/>
        </div>

        <form class="my-4">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Código:</label>
                        <input-component :type-input="'text'" v-model="formDate.code"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDate.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Setor:</label>
                        <multiselect v-model="formDate.sector" :searchable="true" :options="selectSector"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDate.status" :searchable="true" :options="SelectNoteStatus"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <button-one class="mx-1" @submit="submit" :icon="'search'"/>
                        <button-float class="mx-1" :itens="itensTwo" v-if="buttonCheck.create"/>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end'">
                    <div class="toggle_preferences">
                        <button :class="{ 'bg-active': parseInt(cardTable) === 1 }" @click="toggleCard" type="button"
                                tooltip="Card">
                            <i class="window maximize outline icon"></i>
                        </button>
                        <button :class="{ 'bg-active': parseInt(cardTable) === 2 }" @click="toggleTable" type="button"
                                tooltip="Tabela">
                            <i class="align justify icon"></i>
                        </button>
                    </div>
                </row-col-component>
            </row-component>

            <row-component v-if="parseInt(cardTable) === 1">
                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4'"
                                   v-for="(note, index) in notes.itens.data" :key="index">
                    <div class="card-container">
                        <div class="flag-status"
                             :class="[{ 'approval-color': parseInt(note.status) === 1, 'send-color': parseInt(note.status) === 2 }]">
                            {{ this.statusString(note.status) }}
                        </div>

                        <p>Código: {{ note.code }}</p>
                        <p>{{ sectorString(note.sector) }}</p>
                        <p class="title-container"><span>{{ note.name }}</span></p>

                        <div class="aux-row-3">
                            <div class="text"><i class="calendar icon"></i>{{ note.date_create }}</div>
                            <div class="text"><i class="clock icon"></i>{{ note.hour_create }}</div>
                        </div>

                        <div class="responsible-task">
                            <article v-if="note.responsibles.length > 0">
                                <p>Responsáveis:</p>
                                <div class="c-profile__list">
                                    <a v-for="(image, index) in note.responsibles" :key="index" class="c-profile"
                                       :data-user="image.user_name"
                                       :style="{ backgroundImage: 'url(' + image.user_photo + ')' }">
                                    </a>
                                </div>
                            </article>
                        </div>

                        <div class="buttons">
                            <button tooltip="Editar" class="primary"
                                    v-if="buttonCheck.edit && parseInt(note.status) === 1"
                                    @click="routeEdit(note.id)">
                                <i class="edit icon"></i>
                            </button>

                            <button tooltip="Ver" class="primary" v-if="buttonCheck.show && parseInt(note.status) === 2"
                                    @click="routeShow(note.id)">
                                <i class="eye icon"></i>
                            </button>

                            <button tooltip="Finalizar" class="primary"
                                    v-if="buttonCheck.edit && parseInt(note.status) === 1"
                                    @click=submitUpdateStatus(note)>
                                <i class="check icon"></i>
                            </button>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <table class="table table-striped" v-if="parseInt(cardTable) === 2">
                <thead>
                <tr>
                    <th scope="col" style="width: 5%">Código</th>
                    <th scope="col" style="width: 15%">Responsáveis</th>
                    <th scope="col" style="width: 5%">Setor</th>
                    <th scope="col" style="width: 10%">Data / Hora de Criação</th>
                    <th scope="col" style="width: 20%">Nome</th>
                    <th scope="col" style="width: 10%" v-if="buttonCheck.show || buttonCheck.edit">
                        Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(note, index) in notes.itens.data" :key="index">
                    <td data-label="Código">{{ note.code }}</td>
                    <td data-label="Responsáveis">
                        <article v-if="note.responsibles.length > 0">
                            <div class="c-profile__list">
                                <a v-for="(image, index) in note.responsibles" :key="index" class="c-profile"
                                   :data-user="image.user_name"
                                   :style="{ backgroundImage: 'url(' + image.user_photo + ')' }">
                                </a>
                            </div>
                        </article>
                    </td>
                    <td data-label="Setor">{{ sectorString(note.sector) }}</td>
                    <td data-label="Data / Hora de Criação">{{ note.date_create }} / {{ note.hour_create }}</td>
                    <td data-label="Nome">{{ note.name }}</td>
                    <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.show">
                        <button class="btn btn-success mx-1 button-show" tooltip="Editar"
                                v-if="buttonCheck.edit && parseInt(note.status) === 1" @click="routeEdit(note.id)">
                            <i class="edit icon"></i>
                        </button>

                        <button class="btn btn-secundary mx-1 button-show" tooltip="Ver"
                                v-if="buttonCheck.show && parseInt(note.status) === 2" @click="routeShow(note.id)">
                            <i class="eye icon"></i>
                        </button>

                        <button class="btn mx-1 button-show" tooltip="Finalizar"
                                style="background: #b5cc18; color: #FFFFFF;"
                                v-if="buttonCheck.edit && parseInt(note.status) === 1" @click=submitUpdateStatus(note)>
                            <i class="check icon"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <button-pagination :pagination="notes.itens" :offset="3" @paginate="loadNotes"/>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import InputComponent from "@/components/form/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";

import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "NoteSearch",
    components: {
        PainelComponent,
        ButtonOne,
        ButtonFloat,
        ButtonPagination,
        InputComponent,
        RowComponent,
        RowColComponent,
        LoderSendComponent,
        Multiselect,
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Anotações')
        this.loadNotes(1)
        this.resizeWindow()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            itensTwo: {
                route: "/note/create",
                icon: "plus",
                name: ''
            },

            buttonCheck: {
                edit: false,
                create: false,
                show: false
            },

            formDate: {
                code: '',
                name: '',
                status: 1,
                sector: '',
                responsibles: []
            },

            formDateUpdateStatus: {
                id: this.id,
                status: ''
            },

            cardTable: '',
            preferences: {
                type: 13,
                preference: ''
            },

            filter: true,
            showLoader: true,
            btnFilter: false,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadNotes(1)
            this.searchSubmit = true;
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },

        routeEdit(id) {
            this.$router.push({name: 'note-edit', params: {id: id}})
        },

        routeShow(id) {
            this.$router.push({name: 'note-show', params: {id: id}})
        },

        toggleTable() {
            this.userPreferences(2)
            this.cardTable = 2
        },

        toggleCard() {
            this.userPreferences(1)
            this.cardTable = 1
        },

        userPreferences(preference) {
            this.preferences.preference = preference
            this.$store.dispatch('userPreferences', this.preferences)
        },

        submitUpdateStatus(note) {
            this.formDateUpdateStatus.status = 2;
            this.formDateUpdateStatus.id = note.id
            this.$store.dispatch('storeNoteStatus', this.formDateUpdateStatus).then(() => {
                if (parseInt(this.formDateUpdateStatus.status) === 2) {
                    this.loadNotes()
                    toast.success("Anotação finalizada com sucesso", {timeout: 2000});
                }
            }).catch(() => {
                toast.error("Não foi possível alterar o status!", {timeout: 2000});
            })
        },

        loadNotes(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadNotes', {...this.params, page})
                    .then((r) => {
                        this.showLoader = false
                        this.cardTable = r.tableCard
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return 'Aberto'
            return 'Finalizado'
        },

        sectorString(value) {
            if (parseInt(value) === 1)
                return 'Administrativo'
            if (parseInt(value) === 2)
                return 'Criação'
            if (parseInt(value) === 3)
                return 'Comercial'
            if (parseInt(value) === 4)
                return 'Financeiro'
            if (parseInt(value) === 5)
                return 'RH'
            if (parseInt(value) === 6)
                return 'Operacional'
            if (parseInt(value) === 7)
                return 'T.I'
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 12)
                .then((r) => {
                    if (r.data.indexOf('note-search') !== -1) {
                        this.showLoader = false

                        if (r.data.indexOf('note-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('note-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('note-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        SelectNoteStatus() {
            return this.$store.state.var.SelectNoteStatus;
        },

        selectSector() {
            return this.$store.state.var.selectSector
        },

        notes() {
            return this.$store.state.note.items
        },

        params() {
            return {
                page: this.notes.current_page,
                code: this.formDate.code,
                name: this.formDate.name,
                status: this.formDate.status,
                sector: this.formDate.sector
            }
        },
    },
}
</script>

<style scoped src="@/assets/css/table-resp.css"></style>
<style scoped>
.toggle_preferences > button {
    border: 0;
    width: 45px;
    color: #fff;
    height: 45px;
    margin: 0 5px;
    padding-right: 4px;
    border-radius: 50%;
    background-color: #323232;
}

.button-show {
    width: 35px;
    height: 35px;
    font-size: 15px;
    border-radius: 50px;
    padding-left: 0.5rem;
}

.bg-active {
    background-color: #d67c1c !important;
}

.c-profile {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
    margin-bottom: 5px;
    display: inline-block;
    background: #FFFFFF center center;
    background-size: cover;
    border: 4px solid white;
    transition: all 200ms ease;
    box-shadow: 0 3px 8px rgba(44, 44, 84, 0.2);
}

.c-profile:nth-of-type(n+2) {
    margin-left: -42px;
}

.c-profile:hover {
    transform: scale(1.2);
    box-shadow: 0 8px 20px rgba(44, 44, 84, 0.2);
}

.c-profile:hover:after {
    top: 35px;
    opacity: 0;
    padding: 5px;
    width: 100px;
    color: #2C3E50;
    font-size: 10px;
    position: absolute;
    border-radius: 0.15rem;
    content: attr(data-user);
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 5px 12px rgb(0 0 0 / 12%);
    animation: fade 100ms ease 100ms forwards;
    -webkit-animation: fade 50ms ease 50ms forwards;
}

.c-profile__list {
    z-index: 1;
    width: auto;
    position: relative;
    display: inline-flex;
}

.c-profile__list:hover .c-profile:nth-of-type(n+2) {
    margin-left: 7px;
}

article {
    width: 100%;
    margin-top: 10px;
    max-width: 600px;
}

.card-container {
    width: 350px;
    display: flex;
    margin: 0 auto;
    color: #2f3032;
    max-width: 100%;
    overflow: hidden;
    min-height: 317px;
    padding-top: 50px;
    text-align: center;
    position: relative;
    flex-direction: column;
    background-color: #ffffff;
    border: solid 1px #ededed;
    justify-content: space-evenly;
    box-shadow: 10px 10px 15px rgb(0 0 0 / 35%);
}

.title-container {
    height: 60px;
    padding: 3px 0;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}

.card-container h3 {
    color: #424242;
    font-size: 14px;
    font-family: inherit;
    text-transform: uppercase;
}

.card-container > img {
    width: 90px;
    padding: 7px;
    height: 90px;
    margin-top: 25px;
    border-radius: 50%;
    margin-bottom: 10px;
    border: 1px solid #d67c1c;
}

.buttons {
    width: 100%;
    display: flex;
    min-height: 60px;
    border-top: 1px solid #ededed;
}

button.primary {
    width: 100%;
    height: 60px;
    color: #7e7e7e;
    border: 1px solid #fdfdfd;
    background-color: #f5f5f5;
}

.responsible-task {
    padding: 0 15px;
    min-height: 80px;
    text-align: left;
    border-top: 1px solid #ededed;
}

.flag-status {
    top: 0;
    width: 100%;
    color: #ededed;
    font-size: 14px;
    text-align: left;
    padding: 10px 20px;
    position: absolute;
    font-family: 'Comfortaa', sans-serif;
}

.approval-color {
    background-color: #2185d0;
}

.send-color {
    background-color: #16ab39;
}

.aux-row-3 {
    width: 100%;
    display: flex;
    margin-top: 10px;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-evenly;
}

.aux-row-3 .text > i {
    color: #7e7e7e;
}

@-webkit-keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>