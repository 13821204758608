<template>
    <Transition name="show">
        <div class="toasts-wrapper" v-if="notify.length">
            <notifys-component v-for="(notify, index) of notify" :key="index" :itens="notify"/>
        </div>
    </Transition>
</template>

<script>
import NotifysComponent from "@/components/alerts/NotifysComponent";

export default {
    name: 'NotifyComponent',
    components: {NotifysComponent},
    computed: {
        notify() {
            return this.$store.state.notify.notify;
        }
    }
}
</script>

<style scoped>
.toasts-wrapper {
    width: 400px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0;
    top: 1rem;
    z-index: 9999;
    transition: 1s;
}

.show-enter-active,
.show-leave-enter {
    transform: translateX(-100%);
    transition: all 0.2s linear;
}

.show-enter,
.show-leave-to {
    transform: translateX(-100%);
    transition: all 1s linear;
}

@media (max-width: 428px) {
    .toasts-wrapper {
        bottom: 0;
    }
}
</style>