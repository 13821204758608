import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = 'contract-model';
export default {

    loadContractsModels(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then(response => {
                    context.commit('LOAD_CONTRACT_MODEL', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    storeContractModel(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((response) => {
                    resolve(response.data)
                }).catch(errors => reject(errors))
        })
    },

    loadContractModel(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then((response) => {
                    resolve(response.data)
                }).catch((e) => reject(e))
        })
    },

    updateContractModel(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                .then((response) => {
                    resolve(response.data)
                }).catch(errors => {
                reject(errors)
            })
        })
    },

    loadContractModelGet(context, id){
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}contract-model-get/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    storeContractModelItem(context, formData) {
        context.commit("PRELOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}contract-model-add-item`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(()=>{
                context.commit("PRELOADER", false)
            })
        })
    },

    updateContractModelItem(context, formData) {
        context.commit("PRELOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}contract-model-add-item-edit/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(()=>{
                context.commit("PRELOADER", false)
            })
        })
    },

    updateOrderContractModelItem(context, formData){
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}contract-model-add-item-order/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    destroyContractModelItem(context, id){
        context.commit("PRELOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}contract-model-add-item-delete/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(()=>{
                context.commit("PRELOADER", false)
            })
        })
    }
}