import axios from "axios";
import { URL_BASE } from "@/configs/constants";
const RESOURCE = 'report-analytical-cash-flow';

export default {

    loadReportAnalyticalCashFlow(context, params) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios(`${URL_BASE}${RESOURCE}`, { params })
                .then(response => {
                    context.commit('LOAD_REPORT_ANALYTICAL_CASH_FLOW', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
                .finally(() => context.commit('PRELOADER', false))
        })
    },

}