import AdminComponent from "@/components/dashboard/AdminComponent";
import InventorieSearch from "@/views/search/InventorieSearch";
import InventorieCreate from "@/views/create/InventorieCreate";
// import LeadUpdate from "@/views/update/LeadUpdate";
// import LeadShow from "@/views/show/LeadShow"

export default {
    path: '/inventorie',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'create', name: 'inventorie-create', component: InventorieCreate },
        { path: 'search', name: 'inventorie-search', component: InventorieSearch },
        // { path: 'show/:id', name: 'lead-show', component: LeadShow, props: true },
        // { path: 'edit/:id', name: 'lead-edit', component: LeadUpdate, props: true },
    ],
}
