import AdminComponent from "@/components/dashboard/AdminComponent";
import BannerSearch from "@/views/search/BannerSearch";
import BannerCreate from "@/views/create/BannerCreate";
import BannerUpdate from "@/views/update/BannerUpdate";
import BannerUpdatePhoto from "@/views/update/BannerUpdatePhoto";
import BannerShow from "@/views/show/BannerShow";

export default {
    path: '/banner',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'banner-search', component: BannerSearch },
        { path: 'create', name: 'banner-create', component: BannerCreate },
        { path: 'edit/:id', name: 'banner-edit', component: BannerUpdate, props: true },
        { path: 'show/:id', name: 'banner-show', component: BannerShow, props: true },
        { path: 'photo/:id', name: 'banner-update-photo', component: BannerUpdatePhoto, props: true }
    ],
}