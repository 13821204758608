<template>


  <section>
    <div class="images"><img src="../assets/img/login-equipe.png" alt=""></div>

    <div class="painel">
      <form @submit.prevent="login">
        <div class="logo"><img src="../assets/img/logo.png" alt=""></div>

        <div class="container-input">
          <div class="group-input ">
            <input class="inputMaterial" type="text" required v-model="formDate.email" autocomplete="on">
            <label> <i class="envelope icon"></i>E-mail</label>
          </div>

          <div class="group-input ">
            <input class="inputMaterial" type="password" required v-model="formDate.password" autocomplete="on">
            <label> <i class="lock icon"></i>Senha</label>
          </div>


          <button-one :nameButton="'ENTRAR'" :icon="'sign-out'" style="width: 80%" :loder-button="true"
            :type-button="'submit'" :class="{ 'preloderSend': sendForm }"></button-one>

        </div>
      </form>
    </div>
  </section>

</template>

<script>
import ButtonOne from "@/components/buttons/ButtonOne";
import { useToast } from "vue-toastification";

const toast = useToast();


export default {
  name: "LoginView",

  data() {
    return {
      formDate: {
        email: '',
        password: ''
      },

      sendForm: false,
    }
  },

  methods: {
    login() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store.dispatch('login', this.formDate)
          .then(() => {
            this.sendForm = false;
            toast.success("Login efetuado com sucesso!", { timeout: 2000 });
            setTimeout(() => this.$router.push({ name: 'home' }), 2100);
          })
          .catch(() => {
            // Use it!
            this.sendForm = false;
            toast.error("E-mail ou senha inválida", {
              timeout: 10000
            });
          })

      }
    }
  },

  components: {
    ButtonOne,
  }


}
</script>

<style scoped lang="scss">
/* Hexadecimal: #f38f0c */


body {
  background: whitesmoke;
}


section {
  display: grid;
  height: 100%;
  grid-template-areas: 'images' 'painel';
  grid-template-columns: 1fr 1fr;
}


form {
  display: flex;
  height: 75%;
  width: 400px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 40px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
}

.images {
  height: 100vh;
  display: flex;
  grid-area: 'images';
  justify-content: center;
  align-items: center;
  width: 60vw;

}

.images>img {
  height: 60%;
  margin-left: 15%;
}

.painel {
  display: flex;
  grid-area: 'painel';
  height: 100vh;
  align-items: center;

}

.logo>img {
  height: 70px;
  margin-top: 2px;
}

.container-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
}

@media(max-width: 1090px) {
  body {
    background: white;
  }

  section {
    display: block;
  }

  .images {
    display: none;
  }

  form {
    /* align-items: center; */
    justify-content: center;
    box-shadow: none;
  }
}

@media(max-width: 1440px) {
  form {
    height: 70%;
  }

  .images>img {
    height: 50%;
    margin-left: 10%;
  }

}

@media(max-width: 1259px) {
  form {
    height: 70%;
  }

  .images>img {
    height: 45%;
    margin-left: 7%;
  }

}

@media(max-width: 991px) {

  .group-input,
  form .inputMaterial {
    width: 100%;
  }
}

@media (max-width: 761px) {
  form {
    height: 80%;
    width: 90%;
  }

  .buttonOne {
    width: 100% !important;
  }

}

.group-input {
  position: relative;
  margin-bottom: 35px;
  font-family: 'Comfortaa', cursive;
  font-size: 14px;
}


.inputMaterial {
  padding: 10px 10px 10px 5px;
  display: block;
  width: 300px;
  border: none;
  border-bottom: 1px solid #757575;
}

.inputMaterial:focus {
  outline: none;
}

/* LABEL ======================================= */

label {
  color: #999;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
.inputMaterial:focus~label,
.inputMaterial:valid~label {
  top: -20px;
  font-size: 14px;
  color: #d67c1c;
}

/* Cor de fundo do autocomplete */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset;
}

/* Cor do texto do autocomplete */
input:-webkit-autofill {
  -webkit-text-fill-color: black !important;
}
</style>