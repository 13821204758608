import AdminComponent from "@/components/dashboard/AdminComponent";
import NoteSearch from "@/views/search/NoteSearch";
import NoteCreate from "@/views/create/NoteCreate";
import NoteUpdate from "@/views/update/NoteUpdate";
import NoteShow from "@/views/show/NoteShow"

export default {
    path: '/note',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'note-search', component: NoteSearch },
        { path: 'create', name: 'note-create', component: NoteCreate },
        { path: 'edit/:id', name: 'note-edit', component: NoteUpdate, props: true },
        { path: 'show/:id', name: 'note-show', component: NoteShow, props: true },
    ],
}