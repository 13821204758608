<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off" v-else>
            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2 mb-2'"
                        :class="{ 'formError': errors.sexo }">
                    <label>Sexo:</label>

                    <input type="radio" id="nao-phone" name="teste" v-model="formDate.sexo" value="1" checked>
                    <label for="nao-phone">Feminino</label>

                    <input type="radio" id="sim-phone" name="teste" v-model="formDate.sexo" value="2">
                    <label for="sim-phone">Masculino</label>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component :name-input="'name'" :type-input="'text'" v-model="formDate.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.email }">
                    <label>Email (Login):</label>
                    <input-component :name-input="'email'" :type-input="'email'" v-model="formDate.email"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <Multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText" :name="'status'"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.group_id }">
                    <label>Grupo:</label>
                    <Multiselect v-model="formDate.group_id" :searchable="true" :options="selectPage"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText" :name="'group_id'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one class="w-100" @submit="submit" :icon="'plus circle'" :name-button="'Adicionar'"/>
                </row-col-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-float class="w-100" :itens="itensTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import {useToast} from "vue-toastification";
import {ACTION_MESSAGES} from "@/configs/constants";

const toast = useToast();

export default {
    name: "UserCreate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent
    },

    created() {
        this.checkACL()
        this.$store.dispatch('updateNamePage', 'Usuário')
        this.$store.dispatch('loadSelectSearch', 'group-list')
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/user/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                status: '',
                email: '',
                group_id: '',
                password: '',
                passwordconfirm: '',
                sexo: 1,
            },

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: true,
        }
    },


    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeUser', this.formDate)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                        this.$router.push({name: 'user-edit', params: {id: r.data.id}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        scrollTop() {
            window.scrollTo(0, 0);
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 2)
                .then((r) => {
                    if (r.data.indexOf('user-create') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        selectPage() {
            return this.$store.state.var.selectSearch;
        }
    }
}
</script>