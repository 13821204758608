import state from "@/store/modules/provider/state";
import actions from "@/store/modules/provider/actions";
import mutations from "@/store/modules/provider/mutations";
import getters from "@/store/modules/provider/getters";


export default {
  state,
  actions,
  mutations,
  getters
}