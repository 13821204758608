<template>
    <painel-component :itens="itensOne">
        <button-add :itens="itensThree" v-if="buttonCheck.create"/>
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" :model-value="formDate.name"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Email:</label>
                    <input-component :type-input="'text'" v-model="formDate.email" :model-value="formDate.email"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <Multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.types }">
                    <div class="form-check checkBoxHeader">
                        <input class="form-check-input" v-model="select_all" type="checkbox" @click="checkAll">
                        <label class="form-check-label">Selecionar Tudo</label>
                    </div>

                    <div class="form-check " v-for="(option, index) in selectEmailNotificationList" :key="index">
                        <input @change="checkOne" type="checkbox" :value="option.value" :id="index"
                               v-model="formDate.types" class="form-check-input checkBoxAll checkOne">
                        <label class="form-check-label">
                            {{ option.name }}
                        </label>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 '">
                    <button-one style="width: 100%;" @submit="submit" :icon="'save'" :name-button="'Salvar'"
                                :class="{ 'preloderSend': sendForm }"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 '">
                    <button-float style="width: 100%;" :itens="itensTwo"></button-float>
                </row-col-component>
            </row-component>

        </form>

    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonAdd from "@/components/buttons/ButtonAdd";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";

import {useToast} from "vue-toastification";
import {ACTION_MESSAGES} from "@/configs/constants";

const toast = useToast();

export default {
    name: "EmailNotificationUpdate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        ButtonAdd,
        Multiselect,
        ErrorComponent,
        LoderSendComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.checkACL()
        this.loadPageItens()
        this.$store.dispatch('loadEmailsNotificationsList')
        this.$store.dispatch('updateNamePage', 'Email Notificação')
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/email-notification/search",
                icon: "reply all",
                name: 'Voltar'
            },

            itensThree: {
                route: "/email-notification/create",
                icon: "plus",
                name: 'Adicionar'
            },

            buttonCheck: {
                create: false
            },

            formDate: {
                name: '',
                email: '',
                status: '',
                types: []
            },

            select_all: false,

            errors: [],
            sendForm: false,
            showLoader: true,
            showError: false,
        }
    },

    methods: {
        loadPageItens() {
            this.$store.dispatch('loadEmailNotification', this.id).then((r) => {
                this.formDate = r;
                this.checkAllStart()
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateEmailNotification', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        checkAll() {
            this.formDate.types = []
            if (!this.select_all) {
                for (let i in this.selectEmailNotificationList) {
                    this.formDate.types.push(this.selectEmailNotificationList[i].value)
                }
            }
        },

        checkOne() {
            let type = this.formDate.types
            let i = type.length
            let option = this.selectEmailNotificationList
            this.select_all = i >= option.length;
        },

        checkAllStart() {
            let type = this.formDate.types
            let i = type.length
            let option = this.selectEmailNotificationList
            this.select_all = i >= option.length;
        },

        scrollTop() {
            window.scrollTo(0, 0);
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 35)
                .then((r) => {
                    if (r.data.indexOf('email-notification-edit') !== -1) {
                        this.showLoader = false
                    }
                    if (r.data.indexOf('email-notification-create') !== -1) {
                        this.buttonCheck.create = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        selectEmailNotificationList() {
            return this.$store.state.var.selectEmailNotificationList
        }
    },
}
</script>