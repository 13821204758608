<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off" v-else>
            <div class="aux mb-4 mt-3">
                <div class="banner-form">
                    <img v-if="formDate.banner_link" :src="formDate.banner_link" class="banner_image" alt="">
                    <img v-else src="@/assets/img/avatar.png" class="banner_image" alt="">
                </div>
                <p>{{ formDate.name }}</p>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.type }">
                    <label>Tipo:</label>
                    <multiselect v-model="formDate.type" :searchable="true" :options="selectTypeBanner"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText" disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectInput" disabled
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Link:</label>
                    <input-component :type-input="'text'" v-model="formDate.link" disabled/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>
                    <input-text-area :rows="4" v-model="formDate.obs" disabled/>
                </row-col-component>
            </row-component>
        </form>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputTextArea from "@/components/form/InputTextArea"
import ButtonFloat from "@/components/buttons/ButtonFloat";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";

import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "BannerShow",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        InputTextArea,
        ButtonFloat,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Banner')
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "eye"
            },

            itensTwo: {
                route: "/banner/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                link: '',
                banner: '',
                status: '',
                type: '',
                obs: '',
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        scrollTop() {
            window.scrollTo(0, 0)
        },

        loadPageItens() {
            this.$store.dispatch('loadBanner', this.id).then((r) => {
                this.formDate = r;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 26)
                .then((r) => {
                    if (r.data.indexOf('banner-search') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        selectTypeBanner() {
            return this.$store.state.var.selectTypeBanner
        }
    },

}
</script>

<style>
.banner-form {
    width: 250px;
    margin-right: 20px;
}

.banner_image {
    width: 100%;
    object-fit: cover;
}
</style>
  
  
