<template>

    <painel-component :itens="itensOne">

        <error-component :errors="errors" v-if="showError" :class="'mb-5'"></error-component>

        <div class="aux mb-4">
            <div class="avatar-form">
                <img v-if="formDate.photo" :src="imagePreview" class="avatar__image">
                <img v-else src="@/assets/img/avatar.png" alt="" class="avatar__image">
            </div>
            <p>{{ formDate.nameClient }}</p>
        </div>

        <loder-send-component :text-title="'Carregando'" v-if="showLoder"></loder-send-component>

        <form autocomplete="off" v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Foto:</label>
                    <input type="file" name="file" id="formFile" @change="onFileChange">
                    <label for="formFile" class="labelFile">
                        <span>{{ this.namePhoto }}</span>
                        <span><i class="icon photo"></i></span>
                    </label>
                </row-col-component>
            </row-component>

            <button-one v-if="sendForm" :icon="'cloud upload'" :name-button="'Enviando'"
                        :class="{ 'preloderSend': sendForm }">
            </button-one>
        </form>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <button-float :itens="itensTwo"></button-float>
            </row-col-component>
        </row-component>

    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne.vue"
import ErrorComponent from "@/components/alerts/ErrorComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import {useToast} from "vue-toastification";
import {ACTION_MESSAGES} from "@/configs/constants";

const toast = useToast();

export default {
    name: "ClientUpdatePhoto",

    created() {

        this.loadPageItens();
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            imagePreview: "",

            itensOne: {
                title: "Alterar Foto",
                icon: "edit"
            },

            itensTwo: {
                route: `/client/edit/${this.id}`,
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                photo: '',
                nameClient: '',
                id: this.id,
            },

            namePhoto: '',

            errors: [],
            showError: false,
            loaddingPageGroup: false,
            sendForm: false,
            showLoder: false,
        }
    },

    methods: {

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateClientPhoto', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        toast.success(ACTION_MESSAGES.update_photo, {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length)
                return
            this.formDate.photo = files[0]
            this.namePhoto = this.formDate.photo.name
            // console.log(this.namePhoto)
            if (files[0].type.indexOf('image') == 0)
                this.previewImage(files[0])
            this.submit()
        },

        previewImage(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.imagePreview = e.target.result
            }
            reader.readAsDataURL(file)
        },

        photoPreviewTimer() {
            this.imagePreview = this.formDate.photo
        },

        loadPageItens() {
            this.$store.dispatch('updateNamePage', 'Cliente')
            this.$store.dispatch('loadClient', this.id).then((r) => {
                this.formDate.photo = r.photo;
                this.formDate.nameClient = r.name;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },
    },

    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        ButtonFloat,
        ButtonOne,
        ErrorComponent,
        LoderSendComponent,
    },

    beforeMount() {
        setTimeout(() => this.photoPreviewTimer(), 1000);
    },


}
</script>

<style src="../../../src/assets/css/avatar.css">

</style>
  