export default {

    icon: 'chart line',
    name: 'Gráfico',
    subLinks: [
        {
            name: 'Link01',
            route: '#',
        },

        {
            name: 'Link02',
            route: '#',
        },

        {
            name: 'Link03',
            route: '#',
        },
        {
            name: 'Link01',
            route: '#',
        },

    ]
}