<template>
    <painel-component :itens="itensOne">

        <error-component :errors="errors" v-if="showError"></error-component>

        <loder-send-component :text-title="'Carregando'" v-if="showLoder"></loder-send-component>

        <form autocomplete="off" v-else>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Modelo de Prosposta:</label>
                    <Multiselect v-model="formDate.sector" :searchable="true" :options="selectInput"
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Valído (Dias Quantidade):</label>
                    <input-component :type-input="'text'" v-model="formDate.name" :model-value="formDate.name">
                    </input-component>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Empresa/Negócio:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" :model-value="formDate.name">
                    </input-component>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.sector }">
                    <label>Responsável:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" :model-value="formDate.name">
                    </input-component>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Email:</label>
                    <input-component :type-input="'email'" v-model="formDate.name" :model-value="formDate.name">
                    </input-component>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.sector }">
                    <label>Telefone:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" :model-value="formDate.name"
                                     v-mask="['(##) ####-####', '(##) #####-####']">
                    </input-component>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-float :itens="itensTwo"/>
                </row-col-component>
            </row-component>

        </form>

    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";

// import { useToast } from "vue-toastification";
import {mask} from 'vue-the-mask'

// const toast = useToast();

export default {
    name: "ProposalShow",

    created() {
        this.$store.dispatch('updateNamePage', 'Proposta')
        this.checkACL()
        this.$store.dispatch('loadUserListSelect')
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/proposal/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                sector: '',
                desc: '',
                responsibles: []
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoder: true,
        }
    },

    methods: {
        async checkACL() {
            this.$store.dispatch('ACLitens', 27)
                .then((r) => {
                    if (r.data.indexOf('proposal-search') !== -1) {
                        this.showLoder = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
    },

    directives: {
        mask
    },

    computed: {
        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },
    }

}
</script>
  

  
  
    