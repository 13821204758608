import AdminComponent from "@/components/dashboard/AdminComponent";
import ProposalSearch from "@/views/search/ProposalSearch";
import ProposalShow from "@/views/show/ProposalShow";
import ProposalCreate from "@/views/create/ProposalCreate";
import ProposalUpdate from "@/views/update/ProposalUpdate";


export default {
    path: '/proposal',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'create', name: 'proposal-create', component: ProposalCreate },
        { path: 'search', name: 'proposal-search', component: ProposalSearch },
        { path: 'edit/:id', name: 'proposal-edit', component: ProposalUpdate, props: true },
        { path: 'show/:id', name: 'proposal-show', component: ProposalShow, props: true },
    ],
}
