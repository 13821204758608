<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div class="tab-content" v-else>
            <form autocomplete="off">
                <row-component>
                    <row-col-component :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
                                       :class="{'formError':errors.name}">
                        <label>Nome:</label>
                        <input-component :name-input="'name'" :type-input="'text'" v-model="formDate.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                       :class="{'formError':errors.status}">
                        <label>Status:</label>
                        <Multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText" :name="'status'"/>
                    </row-col-component>
                </row-component>

                <div class="clearfix mb-3">
                    <button-one icon="plus" class="btn-filter float-end rounded-0" @click="addNewFormats"/>
                </div>

                <div class="border-content" v-for="(format, index) in formatsAdd" :key="index">
                    <form>
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Resolução:</label>
                                <input-component :type-input="'text'" v-model="format.resolution" :required="true"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Formato:</label>
                                <Multiselect v-model="format.format" :searchable="true" :options="selectFormat"
                                             :required="true" :noResultsText="noResultsText"
                                             :noOptionsText="noResultsText" :name="'status'"/>
                            </row-col-component>

                            <button-remove-round v-if="formatsAdd.length > 1" @click="formatsRemove(index)"
                                                 class="position-absolute end-0 bottom-0 rounded-0 my-2"/>
                        </row-component>
                    </form>
                </div>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <label>Observações:</label>
                        <input-text-area v-model="formDate.desc" :model-value="formDate.desc" :rows="4"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <button-one class="w-100" @submit="submit" :icon="'plus circle'"
                                    :name-button="'Adicionar'"
                                    :class="{'preloderSend': sendForm}"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <button-float class="w-100" :itens="itensTwo"/>
                    </row-col-component>
                </row-component>
            </form>
        </div>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea.vue";
import {ACTION_MESSAGES} from "@/configs/constants";
import {useToast} from "vue-toastification";
import ButtonRemoveRound from "@/components/buttons/ButtonRemoveRound.vue";

const toast = useToast();

export default {
    name: "TaskTypeCreate",
    components: {
        ButtonRemoveRound,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Tipo de Tarefa')
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/task-type/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                status: '',
                desc: '',
                resolutions_formats: []
            },

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: true,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeTaskType', this.formDate)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                        this.$router.push({name: 'task-type-edit', params: {id: r.data}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        addNewFormats() {
            this.formDate.resolutions_formats.push({
                resolution: '',
                format: '',
            })
        },

        formatsRemove(index) {
            this.formDate.resolutions_formats.splice(index, 1)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 6)
                .then((r) => {
                    if (r.data.indexOf('task-type-create') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        selectFormat() {
            return this.$store.state.var.selectFormat;
        },

        formatsAdd() {
            return this.formDate.resolutions_formats;
        },
    },

    mounted() {
        this.formDate.resolutions_formats.push({resolution: '', format: ''})
    }
}
</script>

<style scoped>
.tab-content {
    padding: 0;
}

.border-content {
    height: auto;
    padding: 25px 10px;
    position: relative;
    background: #ebebeb;
}
</style>