<template>

    <router-link :to="itens.route" class="float" :tooltip="itens.name" flow="left"><i :class="'icon ' + itens.icon"></i>
    </router-link>

</template>

<script>
export default {
    name: "ButtonAdd",
    props: {
        itens: {
            "required": true,
            "type": Object,
            "default": []
        },
    },
}
</script>

<style scoped>
.float {
    position: absolute;
    width: 45px;
    height: 45px;
    top: 0;
    right: 0;
    margin: 1% 2%;
    background-color: #f38f0c;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    font-size: 18px;
    text-decoration: none;
    display: block;
    padding-top: 0.6rem;
    padding-left: 0.4rem;
}

[tooltip]::after {
    font-size: 12px;
}

@media (max-width: 991px) {
    .float {
        right: 12px;
    }
}
</style>