<template>
    <error-component :errors="errors" v-if="showError"/>
    <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

    <form autocomplete="off" v-else>
        <row-component>
            <row-col-component :class-row-col="'col-xxl-8 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                               :class="{ 'formError': errors.responsible_id }">
                <label>Responsável:</label>
                <Multiselect v-model="formDate.responsible_id" label="name" :options="selectUserList" disabled
                             :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                               :class="{ 'formError': errors.date_scheduling }">
                <label>Data de Agendamento:</label>
                <input-component v-mask="'##/##/####'" :type-input="'text'" v-model="formDate.date_scheduling"
                                 disabled/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                               :class="{ 'formError': errors.time_scheduling }">
                <label>Hora de Agendamento:</label>
                <input-component v-mask="'##:##'" :type-input="'text'" v-model="formDate.time_scheduling" disabled/>
            </row-col-component>
        </row-component>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                     v-if="formDate.activitys.length === 0">
                    <p>Não há nada cadastrado</p>
                </div>
                <div class="border-content" v-for="(activity, index) in activitysAdd" :key="index" v-else>
                    <form autocomplete="off">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Atividade:</label>
                                <input-text-area :rows="4" v-model="activity.activity" disabled/>
                            </row-col-component>
                        </row-component>
                    </form>
                </div>
            </row-col-component>
        </row-component>
    </form>

    <div class="line-division mt-5 mb-5">
        <h1 class="h1-text">
            <i class="comment icon"></i>
            Observações Internas
        </h1>
    </div>

    <row-component>
        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
            <label>Observações:</label>
            <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                 v-if="formDate.obsPrivate.length === 0">
                <p>Não há nada cadastrado</p>
            </div>

            <div class="merito" v-else>
                <section class="container-desc">
                    <ul class="task-desc">
                        <li class="item" v-for="(obs, index) in formDate.obsPrivate" :key="index">
                            <div class="aux-row">
                                <div class="user">
                                    <img :src="obs.user_photo" alt="Image 001" class="owner-img"/>
                                    {{ obs.user_name }}
                                </div>
                                <div class="desc-date">
                                    <div class="text"><i class="calendar icon"></i>{{ obs.date_create }}</div>
                                    <div class="text"><i class="clock icon"></i>{{ obs.hour_create }}</div>
                                </div>
                            </div>
                            <p>{{ obs.obs }}</p>
                        </li>
                    </ul>
                </section>
            </div>
        </row-col-component>
    </row-component>

    <form autocomplete="off">
        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Observações:</label>
                <input-text-area :rows="4" v-model="formDateObs.obs"/>
            </row-col-component>
        </row-component>
    </form>

    <button-one @submit="$emit('submit-obs', formDateObs)" :icon="'plus circle'" :name-button="'Adicionar Observação'"/>

    <div class="line-division mt-5 mb-5">
        <h1 class="h1-text">
            <i class="history icon"></i>
            Histórico de interações
        </h1>
    </div>

    <section class="container-desc-two">
        <ul class="task-desc">
            <li class="item" v-for="(historic, index) in formDate.historic" :key="index">
                <div class="aux-row">
                    <div class="user">
                        <img :src="historic.user_photo" alt="Image 001" class="owner-img"/>
                        {{ historic.name_user }}
                    </div>
                    <div class="desc-date">
                        <div class="text"><i class="calendar icon"></i>{{ historic.date_create }}</div>
                        <div class="text"><i class="clock icon"></i>{{ historic.hour_create }}</div>
                    </div>
                </div>
                <p>{{ statusHistoric(historic.status) }}, Plataforma: {{ historic.platform }},
                    Navegador: {{ historic.browser }}, Endereço IP: {{ historic.ip }}</p>
            </li>
        </ul>
    </section>
</template>

<script>
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";

import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "OrderServiceShow",
    components: {
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea
    },

    directives: {
        mask,
    },

    emits: ['submit-obs'],

    props: {
        id: {
            required: true
        },

        error: {
            type: Array
        },

        showErro: {
            type: Boolean
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Ordem de Serviço')
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/order-of-service/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                responsible_id: '',
                responsible_name: '',
                date_scheduling: '',
                time_scheduling: '',
                activitys: [],
                historic: [],
                obsPrivate: [],
            },

            formDateObs: {
                id: this.id,
                obs: ''
            },

            debug: '',

            errors: this.error,
            showError: this.showErro,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        loadPageItens() {
            this.$store.dispatch('loadOrderOfService', this.id).then((r) => {
                this.formDate = r;
                this.$store.dispatch('loadUserListSelect')
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 32)
                .then((r) => {
                    if (r.data.indexOf('order-of-service-search') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        statusHistoric(value) {
            if (parseInt(value) === 1)
                return "Cadastro"
            if (parseInt(value) === 2)
                return "Editado"
            if (parseInt(value) === 3)
                return "Iniciou"
            if (parseInt(value) === 4)
                return "Finalizado"
        },
    },

    computed: {
        selectUserList() {
            return this.$store.state.var.selectUserList
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        activitysAdd() {
            return this.formDate.activitys
        }
    }
}
</script>