<template>
    <router-link :to="itens.route" class="float btn" :tooltip="itens.tooltip" flow="left">
        <i :class="'icon ' + itens.icon"></i>
        {{ itens.name }}
    </router-link>
</template>

<script>
export default {
    name: "ButtonFloat",


    props: {
        itens: {
            "required": true,
            "type": Object,
            "default": []
        },

    },


}
</script>

<style scoped>

.float i.icon {
    margin: 0;
}

[tooltip]::after {
    font-size: 12px;
}

.float {
    font-family: 'Comfortaa', cursive;
    color: white;
    min-width: 42px;
    height: 42px;
//display: block; padding: 0.4rem;
    font-size: 18px;
    border-radius: 50px;
    text-align: center;
    text-decoration: none;
    border: 1px solid #e0e0e0;
    background-color: #d67c1c;
}


.float:active,
.float:focus,
.float:hover,
.float:focus-visible {
    font-family: 'Comfortaa', cursive;
    background-color: #d67c1c;
    color: white;
    border-color: #d67c1c;
    box-shadow: none !important;
}

</style>