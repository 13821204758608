<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off" v-else>
            <div class="cont-status" v-if="formDateAmount.total !== ''">
                Quantidade total disponível: {{ formDateAmount.total }}
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.product_id }">
                    <label>Produto:</label>
                    <Multiselect v-model="formDate.product_id" label="name" :options="selectProductList"
                                 @select="loadAmountProducts(formDate.product_id)" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>

                <row-col-component v-if="parseInt(formDate.type) === 1"
                                   :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.type }">
                    <label>Entrada / Saida:</label>
                    <multiselect v-model="formDate.type" :searchable="true" :options="selectEntranceExit"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component v-else
                                   :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.type }">
                    <label>Entrada / Saida:</label>
                    <multiselect v-model="formDate.type" :searchable="true" :options="selectEntranceExit"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component v-if="parseInt(formDate.type) === 1"
                                   :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.amount }">
                    <label>Quantidade:</label>
                    <input-number-component :type-input="'number'" v-model="formDate.amount"/>
                </row-col-component>

                <row-col-component v-else
                                   :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.amount }">
                    <label>Quantidade:</label>
                    <input-number-component :type-input="'number'" v-model="formDate.amount"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   v-if="parseInt(formDate.type) === 1" :class="{ 'formError': errors.price }">
                    <label>Preço Unitário:</label>
                    <input-component :type-input="'text'" v-model="formDate.price" v-money="money"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações</label>
                    <input-text-area :rows="4" v-model="formDate.obs" :model-value="formDate.obs"/>
                </row-col-component>
            </row-component>
        </form>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-one class="w-100" @submit="submit" :icon="'plus circle'" :name-button="'Adicionar'"
                            :class="{ 'preloderSend': sendForm }"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import InputNumberComponent from "@/components/form/InputNumberComponent.vue"
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import {MONEY} from "@/configs/constants"
import {VMoney} from 'v-money'
import {useToast} from "vue-toastification";
import {ACTION_MESSAGES} from "@/configs/constants";

const toast = useToast();

export default {
    name: "InventorieCreate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea,
        InputNumberComponent
    },

    directives: {
        money: VMoney
    },

    created() {
        this.$store.dispatch('loadProductSelect')
        this.$store.dispatch('updateNamePage', 'Entrada / Saída')
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/inventorie/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                product_id: '',
                type: 1,
                price: '',
                amount: '',
                obs: ''
            },

            formDateAmount: {
                total: ''
            },

            money: MONEY,

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: true,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeInventorie', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        if (parseInt(this.formDate.type) === 1)
                            toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                        else
                            toast.success(ACTION_MESSAGES.delete, {timeout: 2000});
                        this.$router.push({name: 'inventorie-search'})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        loadAmountProducts(id) {
            this.$store.dispatch('loadAmountProducts', id).then((r) => {
                this.formDateAmount = r;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 22)
                .then((r) => {
                    if (r.data.indexOf('product-create') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectEntranceExit() {
            return this.$store.state.var.selectEntranceExit
        },

        selectProductList() {
            return this.$store.state.var.selectProductList
        }
    }
}
</script>

<style scoped>
.cont-status {
    background-color: #d67c1c;
    margin-top: 20px;
    margin-bottom: 15px;
    width: fit-content;
    padding: 0 10px 0 10px;
    border-radius: 20px;
    color: white;
    font-weight: bold;
}
</style>
  
  
  
    