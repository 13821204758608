import state from "@/store/modules/guest-schedule/state";
import actions from "@/store/modules/guest-schedule/actions";
import mutations from "@/store/modules/guest-schedule/mutations";
import getters from "@/store/modules/guest-schedule/getters";

export default {
  state,
  actions,
  mutations,
  getters
}