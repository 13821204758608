<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div class="tabs effect-3" v-else>
            <input type="radio" id="tab-1" name="tab-effect-3" checked="checked" class="mobileTab">
            <span class="mobileTab">
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3" class="mobileTab">
            <span class="mobileTab">
                <i class="envelope icon"></i>
                <p>Email</p>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3" class="mobileTab">
            <span class="mobileTab">
                <i class="phone square icon"></i>
                <p>Telefone</p>
            </span>

            <input type="radio" id="tab-5" name="tab-effect-3" class="mobileTab">
            <span class="mobileTab">
                <i class="money icon"></i>
                <p>Conta Bancária</p>
            </span>

            <div class="line line-2 ease"></div>
            <div class="tab-content">

                <section id="tab-item-1">
                    <form>
                        <div class="aux mb-5 mt-3">
                            <div class="avatar-form">
                                <img v-if="formDate.photo" :src="formDate.photo" class="avatar__image" alt="">
                                <img v-else src="@/assets/img/avatar.png" class="avatar__image" alt="">
                            </div>
                            <p>{{ formDate.name }}</p>
                        </div>

                        <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                            <input type="radio" id="funcionario" v-model="formDate.type" value="1" disabled>
                            <label for="funcionario">Funcionário</label>

                            <input type="radio" id="prestador" v-model="formDate.type" value="2" disabled>
                            <label for="prestador">Prestador de serviço</label>
                        </row-col-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <input-component :type-input="'text'" disabled v-model="formDate.name"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Sexo:</label>
                                <Multiselect v-model="formDate.sexo" :searchable="true" :options="selectSexo" disabled
                                             :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>CPF:</label>
                                <input-component :type-input="'text'" v-model="formDate.cpf" disabled
                                                 v-mask="'###.###.###-##'"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>RG:</label>
                                <input-component :type-input="'text'" v-model="formDate.rg"
                                                 disabled v-mask="['##.###.###-X', '#.###.###' ]"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Data Nasc:</label>
                                <input-component :type-input="'text'" disabled v-model="formDate.birth_date"
                                                 v-mask="'##/##/####'"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Status:</label>
                                <Multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                             disabled :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>CEP:</label>
                                <input-component :type-input="'text'" v-mask="'#####-###'" disabled
                                                 v-model="formDate.cep"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Cidade:</label>
                                <input-component :type-input="'text'" disabled v-model="formDate.city"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Estado:</label>
                                <input-component :type-input="'text'" disabled v-model="formDate.state"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Bairro:</label>
                                <input-component :type-input="'text'" disabled v-model="formDate.district"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Endereço:</label>
                                <input-component :type-input="'text'" disabled v-model="formDate.address"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-6  col-xl-6  col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>País:</label>
                                <input-component :type-input="'text'" disabled v-model="formDate.country"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Refêrencia:</label>
                                <input-text-area v-model="formDate.complement" disabled :rows="4"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-3">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="emailsAdd.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <div class="border-content" v-for="(email, index) in emailsAdd" :key="index">
                        <form>
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-11 col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12 mt-2 mb-2 '">
                                    <label>Receber notificação</label>

                                    <input type="radio" id="nao" v-model="email.notification" value="1" disabled>
                                    <label for="nao">Não</label>

                                    <input type="radio" id="sim" v-model="email.notification" value="2" disabled>
                                    <label for="sim">Sim</label>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Nome:</label>
                                    <input-component :type-input="'text'" v-model="email.name" disabled/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                    <label>Email:</label>
                                    <input-component :type-input="'email'" v-model="email.email" disabled/>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>
                </section>

                <section id="tab-item-4">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if=" phonesAdd.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <div class="border-content" v-for="(phone, index) in phonesAdd" :key="index">
                        <form>
                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-2 mb-2'">
                                <label>Receber notificação</label>

                                <input type="radio" id="nao-phone" name="teste" v-model="phone.notification" value="1"
                                       disabled>
                                <label for="nao-phone">Não</label>

                                <input type="radio" id="sim-phone" name="teste" v-model="phone.notification" value="2"
                                       disabled>
                                <label for="sim-phone">Sim</label>
                            </row-col-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Nome:</label>
                                    <input-component :type-input="'text'" v-model="phone.name" disabled/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                    <label>Telefone:</label>
                                    <input-component :type-input="'text'" v-model="phone.phone" disabled
                                                     v-mask="['(##) ####-####', '(##) #####-####']"/>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>
                </section>

                <section id="tab-item-5">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if=" bankAccountAdd.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <div class="border-content" v-for="(account, index) in bankAccountAdd" :key="index">
                        <form>
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Banco:</label>
                                    <Multiselect v-model="account.bank" :searchable="true" :options="selectBank"
                                                 disabled :noResultsText="noResultsText"
                                                 :noOptionsText="noResultsText"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Tipo de Conta:</label>
                                    <Multiselect v-model="account.account_type" :searchable="true" disabled
                                                 :options="selectAccountType"
                                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Agência:</label>
                                    <input-component :type-input="'text'" v-model="account.agency" disabled/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Conta/Dígito</label>
                                    <input-component :type-input="'text'" v-model="account.digit_account" disabled/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Tipo PIX:</label>
                                    <Multiselect v-model="account.pix_type" :searchable="true" :options="selectPIX"
                                                 disabled :noResultsText="noResultsText"
                                                 :noOptionsText="noResultsText"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-7 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Chave:</label>
                                    <input-component :type-input="'text'" v-model="account.key_pix" disabled
                                                     v-mask="['###.###.###-##', '##.###.###/####-##']"
                                                     v-if="parseInt(this.formDate.bank_accounts[index].pix_type) === 1"/>

                                    <input-component :type-input="'text'" v-model="account.key_pix" disabled
                                                     v-mask="['(##) ####-####', '(##) #####-####']"
                                                     v-else-if="parseInt(this.formDate.bank_accounts[index].pix_type) === 2"/>

                                    <input-component :type-input="'email'" v-model="account.key_pix" disabled
                                                     v-else-if="parseInt(this.formDate.bank_accounts[index].pix_type) === 3"/>

                                    <input-component :type-input="'text'" v-model="account.key_pix" disabled
                                                     v-else-if="parseInt(this.formDate.bank_accounts[index].pix_type) === 4"/>

                                    <input-component :type-input="'text'" v-model="account.key_pix" disabled v-else/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-center pt-4'">
                                    <label>Conta Principal ?</label>

                                    <input type="radio" :id="'nao-main' + index" name="teste" disabled
                                           v-model="account.main_account" value="1">
                                    <label :for="'nao-main' + index">Não</label>

                                    <input type="radio" :id="'sim-main' + index" name="teste" disabled
                                           v-model="account.main_account" value="2">
                                    <label :for="'sim-main' + index">Sim</label>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>
                </section>

                <row-component>
                    <row-col-component
                            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <button-float class="w-100" :itens="itensTwo"/>
                    </row-col-component>
                </row-component>
            </div>
        </div>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import InputTextArea from "@/components/form/InputTextArea";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import {ESTADOS} from "@/configs/constants"
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "EmployeeShow",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea
    },

    directives: {mask},

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.loadPageItens(1)
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "eye"
            },

            itensTwo: {
                route: "/employee/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                cpf: '',
                name: '',
                birth_date: '',
                situation: '',
                rg: '',
                type: '1',
                status: '',
                emails: [],
                phones: [],
                bank_accounts: [],
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: '',
                sexo: '',
                photo: '',
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        loadPageItens() {
            this.$store.dispatch('updateNamePage', 'Funcionário')
            this.$store.dispatch('loadEmployee', this.id).then((r) => {
                this.formDate.state = ESTADOS[r.state]
                this.formDate = r;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 5)
                .then((r) => {
                    if (r.data.indexOf('employee-create') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        selectSexo() {
            return this.$store.state.var.selectSexo;
        },

        emailsAdd() {
            return this.formDate.emails;
        },

        phonesAdd() {
            return this.formDate.phones;
        },

        bankAccountAdd() {
            return this.formDate.bank_accounts
        }
    },
}
</script>

<style>
.border-content {
    padding: 0 20px 40px 20px;
    margin-bottom: 10px;
    position: relative;
    background: #fbfbfb;
}
</style>
