<template>

  <router-link :to="{name:route, params:{id: idParam}}" class="btn btn-success buttonRound" tooltip="Editar" flow="down">
    <i class="edit outline icon"></i>
  </router-link>
</template>

<script>
export default {
  name: "ButtonEditRound",

  props: {

    route: {
      'type': String,
      "required": true,
    },


  idParam: {
    'type': Number,
    "required": true,
  }



},


  }
</script>

<style scoped>
.buttonRound  i{
  padding-top: 5px;


}
</style>