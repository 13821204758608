<template>
    <painel-component :itens="itensOne">
        <div class="filter-position" v-if="btnFilter">
            <button-one class="btn-filter" @click="filterWindow"/>
        </div>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Código:</label>
                        <input-component :type-input="'text'" v-model="formDate.code"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDate.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'">
                        <button-one @submit="submit" :icon="'search'"/>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end'">
                    <div class="toggle_preferences">
                        <button :class="{ 'bg-active': parseInt(cardTable) === 1 }" @click="toggleCard" type="button"
                                tooltip="Card">
                            <i class="window maximize outline icon"></i>
                        </button>
                        <button :class="{ 'bg-active': parseInt(cardTable) === 2 }" @click="toggleTable" type="button"
                                tooltip="Tabela">
                            <i class="align justify icon"></i>
                        </button>
                    </div>
                </row-col-component>
            </row-component>

            <row-component v-if="parseInt(cardTable) === 1">
                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4'"
                                   v-for="(my_schedule, index) in my_schedules.itens.data" :key="index">
                    <div class="card-container">
                        <div class="flag-status">
                            <i class="calendar icon"></i>
                            {{ my_schedule.name }}
                        </div>
                        <p>Código: {{ my_schedule.code }}</p>

                        <article v-if="my_schedule.guests.length > 0">
                            <p>Participantes:</p>
                            <div class="c-profile__list">
                                <a v-for="(image, index) in my_schedule.guests" :key="index" class="c-profile"
                                   :data-user="image.guest_name"
                                   :style="{ backgroundImage: 'url(' + image.guest_photo + ')' }">
                                </a>
                            </div>
                        </article>

                        <div class="buttons">
                            <button tooltip="Editar" class="primary" v-if="buttonCheck.edit"
                                    @click="routeEdit(my_schedule.id)">
                                <i class="edit icon"></i>
                            </button>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <table class="table table-striped" v-if="parseInt(cardTable) === 2">
                <thead>
                <tr>
                    <th scope="col" style="width: 5%">Código</th>
                    <th scope="col" style="width: 20%">Nome</th>
                    <th scope="col" style="width: 20%">Participantes</th>
                    <th scope="col" style="width: 5%" v-if="buttonCheck.edit">Ações</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(my_schedule, index) in my_schedules.itens.data" :key="index">
                    <td data-label="Código">{{ my_schedule.code }}</td>
                    <td data-label="Nome">{{ my_schedule.name }}</td>
                    <td data-label="Participantes">
                        <div class="c-profile__list">
                            <a v-for="(image, index) in my_schedule.guests" :key="index" class="c-profile"
                               :data-user="image.guest_name"
                               :style="{ backgroundImage: 'url(' + image.guest_photo + ')' }">
                            </a>
                        </div>
                    </td>
                    <td data-label="Ações" v-if="buttonCheck.edit">
                        <button tooltip="Editar" class="btn btn-success mx-1 button-show" v-if="buttonCheck.edit"
                                @click="routeEdit(my_schedule.id)">
                            <i class="edit icon"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <button-pagination :pagination="my_schedules.itens" :offset="3" @paginate="loadMySchedules"/>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import InputComponent from "@/components/form/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";

import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "MyScheduleSearch",
    components: {
        PainelComponent,
        ButtonOne,
        ButtonPagination,
        InputComponent,
        RowComponent,
        RowColComponent,
        LoderSendComponent,
        Multiselect,
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Minha Agenda')
        this.loadMySchedules(1)
        this.resizeWindow()
        this.checkACL();
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            formDate: {
                code: '',
                name: '',
                status: '',
                guests: []
            },

            buttonCheck: {
                edit: false,
                show: false
            },

            cardTable: '',
            preferences: {
                type: 15,
                preference: ''
            },

            filter: true,
            btnFilter: false,
            showLoader: true,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadMySchedules(1)
            this.searchSubmit = true;
        },

        loadMySchedules(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadMySchedules', {...this.params, page})
                    .then((r) => {
                        this.showLoader = false
                        this.cardTable = r.tableCard
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 11)
                .then((r) => {
                    if (r.data.indexOf('my-schedule-search') !== -1) {
                        this.showLoader = false

                        if (r.data.indexOf('my-schedule-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('my-schedule-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },

        routeEdit(id) {
            this.$router.push({name: 'my-schedule-edit', params: {id: id}})
        },

        toggleTable() {
            this.userPreferences(2)
            this.cardTable = 2
        },

        toggleCard() {
            this.userPreferences(1)
            this.cardTable = 1
        },

        userPreferences(preference) {
            this.preferences.preference = preference
            this.$store.dispatch('userPreferences', this.preferences)
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return 'Ativo'
            return 'Inativo'
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactiveSearch;
        },

        my_schedules() {
            return this.$store.state.my_schedule.items
        },

        params() {
            return {
                page: this.my_schedules.current_page,
                code: this.formDate.code,
                name: this.formDate.name,
                status: this.formDate.status
            }

        },
    },
}
</script>

<style scoped src="@/assets/css/table-resp.css"></style>
<style scoped>
.toggle_preferences > button {
    border: 0;
    width: 45px;
    height: 45px;
    color: #fff;
    margin: 0 5px;
    padding-right: 4px;
    border-radius: 50%;
    background-color: #323232;
}

.button-show {
    border-radius: 50px;
    font-size: 15px;
    width: 35px;
    height: 35px;
    padding-left: 0.5rem;
}

.bg-active {
    background-color: #d67c1c !important;
}

.card-container {
    width: 350px;
    color: #2f3032;
    max-width: 100%;
    overflow: hidden;
    margin: 20px auto;
    min-height: 250px;
    padding: 65px 0 0;
    position: relative;
    text-align: center;
    background-color: #ffffff;
    border: solid 1px #ededed;
    box-shadow: 50px 10px 15px rgb(0 0 0 / 35%);
}

.card-container p {
    margin: 0;
}

.buttons {
    bottom: 0;
    width: 100%;
    min-height: 60px;
    position: absolute;
    border-top: 1px solid #ededed;
}

button.primary {
    width: 100%;
    height: 60px;
    color: #7e7e7e;
    padding: 5px 25px;
    background-color: #f5f5f5;
    border: 1px solid #fdfdfd;
}

.flag-status {
    top: 0;
    width: 100%;
    color: #ededed;
    font-size: 14px;
    text-align: left;
    padding: 10px 20px;
    position: absolute;
    background-color: #d67c1c;
    font-family: 'Comfortaa', sans-serif;
}

.c-profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 5px;
    display: inline-block;
    background: #FFFFFF center center;
    background-size: cover;
    border: 4px solid white;
    transition: all 200ms ease;
    box-shadow: 0 3px 8px rgba(44, 44, 84, 0.2);
}

.c-profile:nth-of-type(n+2) {
    margin-left: -42px;
}

.c-profile:hover {
    transform: scale(1.2);
    box-shadow: 0 8px 20px rgba(44, 44, 84, 0.2);
}

.c-profile:hover:after {
    top: 35px;
    opacity: 0;
    width: 100px;
    padding: 5px;
    color: #2C3E50;
    font-size: 10px;
    position: absolute;
    content: attr(data-user);
    border-radius: 0.15rem;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 5px 12px rgb(0 0 0 / 12%);
    -webkit-animation: fade 50ms ease 50ms forwards;
    animation: fade 100ms ease 100ms forwards;
}

.c-profile__list {
    z-index: 1;
    width: auto;
    position: relative;
    display: inline-flex;
}

.c-profile__list:hover .c-profile:nth-of-type(n+2) {
    margin-left: 7px;
}

article {
    width: 100%;
    margin-top: 10px;
    max-width: 600px;
}

@-webkit-keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>