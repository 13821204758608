import state from "@/store/modules/account-bank/state";
import actions from "@/store/modules/account-bank/actions";
import mutations from "@/store/modules/account-bank/mutations";
import getters from "@/store/modules/account-bank/getters";

export default {
  state,
  actions,
  mutations,
  getters
}