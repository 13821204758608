export default {

    icon: 'file excel',
    name: 'Relatório ',
    subLinks: [
        {
            name: 'Fluxo de Caixa Analítico',
            route: '/report-analytical-cash-flow/search',
            pagePermissions: [
                'report-analytical-cash-flow'
            ],
        },
    ],

    pageLinksNavCheck: [
        'report-analytical-cash-flow'
    ],
}