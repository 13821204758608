import AdminComponent from "@/components/dashboard/AdminComponent";
import ContractExpirationSearch from "@/views/search/ContractExpirationSearch";
import ContractExpirationCreate from "@/views/create/ContractExpirationCreate";
import ContractExpirationUpdate from "@/views/update/ContractExpirationUpdate";
import ContractExpirationShow from "@/views/show/ContractExpirationShow";

export default {
    path: '/contract-expiration',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'contract-expiration-search', component: ContractExpirationSearch },
        { path: 'create', name: 'contract-expiration-create', component: ContractExpirationCreate },
        { path: 'show/:id', name: 'contract-expiration-show', component: ContractExpirationShow, props: true },
        { path: 'edit/:id', name: 'contract-expiration-edit', component:ContractExpirationUpdate, props: true },
    ],
}
