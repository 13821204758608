<template>
    <div class="modal-add-password" v-if="add_password">
        <div class="div-add-password">
            <error-component :errors="errors" v-if="showErrorPass"/>
            <h2 v-if="!add_password_btn">Adicionar</h2>
            <h2 v-if="add_password_btn">Editar</h2>

            <div @click="closeModalPassword" class="btn-close"></div>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formDatePassword.name"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Link:</label>
                    <input-component :type-input="'text'" v-model="formDatePassword.link"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.login }">
                    <label>Login:</label>
                    <input-component :type-input="'text'" v-model="formDatePassword.login"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.password }">
                    <label>Senha:</label>
                    <input-component :type-input="'text'" v-model="formDatePassword.password"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-one v-if="!add_password_btn" style="width: 100%;" @submit="storeCustomerProfilePassword"
                                :icon="'plus circle'" :name-button="'Adicionar'" :class="{ 'preloderSend': sendForm }"/>

                    <button-one v-if="add_password_btn" style="width: 100%;" :icon="'save'" :name-button="'Salvar'"
                                :class="{ 'preloderSend': sendForm }" @submit="updateCustomerProfilePasswordSubmit"/>
                </row-col-component>
            </row-component>
        </div>
    </div>

    <div class="modal-add-files" v-if="add_files">
        <div class="container">
            <div @click="add_files = !add_files" class="btn-close"></div>
            <form>
                <label class="uploadButton">
                    <input type="file" @change="selectFiles" id="upload" multiple data-max-file-size="30MB">
                    <i class="plus icon"></i>
                </label>
                <div class="files">
                    <ul>
                        <li v-for="(data, index) in files_computed" :key="index"
                            class="d-flex align-items-center justify-content-between">
                            <div>
                                {{ data.file.name }}
                                <span class="file-size">
                                    {{ convertBytes(data.file.size) }}
                                </span>
                            </div>

                            <div class="me-5 align-items-center justify-content-around"
                                 style="width: 30%; display: flex" :id="'progress-bar' + index">
                                <progress :value="data.progress" max="100"></progress>
                                {{ data.progress }}%
                            </div>

                            <button class="remove-file" type="button" @click="remove(index)">
                                <i class="trash alternate outline icon"></i>
                            </button>

                            <div class="file-success" :id="'file-success' + index">
                                Sucesso ao enviar
                                <i class="check icon"></i>
                            </div>

                            <div class="file-erro" :id="'file-erro' + index">
                                Erro ao enviar
                                <i class="close icon"></i>
                            </div>
                        </li>
                    </ul>
                </div>
            </form>

            <button-one style="width: 30%;" :icon="'save'" :name-button="'Enviar'"
                        :class="{ 'preloderSend': sendForm }" @submit="uploadFiles"/>
        </div>
    </div>

    <div class="modal-files-preview" v-if="preview_files">
        <span @click="preview_files = false"><div class="btn-close"></div></span>
        <video v-if="extension(url_file) === 'mp4' || extension(url_file) === 'mov' || extension(url_file) === 'avi'"
               :src="url_file" controls autoplay></video>
        <img
                v-else-if="extension(url_file) === 'png' || extension(url_file) === 'jpg' || extension(url_file) === 'jpeg'"
                :src="url_file" alt=""/>
        <audio :src="url_file" v-else controls autoplay></audio>
    </div>

    <div class="modal-add-briefings" v-if="add_briefing">
        <div class="div-add-briefings">
            <error-component :errors="errors" v-if="showErrorBriefing"/>
            <h2 v-if="!add_briefing_btn">Adicionar</h2>
            <h2 v-if="add_briefing_btn">Editar</h2>

            <div @click="closeModalBriefing" class="btn-close"></div>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formDateBriefings.name"/>
                </row-col-component>

                <row-col-component
                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4'">
                    <label>Visível para time?:</label>

                    <input checked type="radio" id="nao" name="teste" v-model="formDateBriefings.visible" value="1">
                    <label for="nao">Não</label>

                    <input type="radio" id="sim" name="teste" v-model="formDateBriefings.visible" value="2">
                    <label for="sim">Sim</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.desc }">
                    <label>Descrição:</label>
                    <QuillEditor :theme="'snow'" :toolbar="toolbarOptions" :contentType="'html'" ref="myEditor"
                                 v-model:content="formDateBriefings.desc"
                                 style="height:280px; overflow: scroll;"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-one v-if="!add_briefing_btn" style="width: 100%;" @submit="storeCustomerProfileBriefings"
                                :icon="'plus circle'" :name-button="'Adicionar'" :class="{ 'preloderSend': sendForm }"/>
                    <button-one v-if="add_briefing_btn" style="width: 100%;" @submit="updateCustomerProfileBriefings"
                                :icon="'save'" :name-button="'Editar'"
                                :class="{ 'preloderSend': sendForm }"/>
                </row-col-component>
            </row-component>
        </div>
    </div>

    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div class="tabs effect-3" v-else>
            <input checked type="radio" id="tab-1" name="tab-effect-3">
            <span :class="{'span-password' : !view_password}">
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input v-if="view_password" type="radio" id="tab-2" name="tab-effect-3">
            <span v-if="view_password">
                <i class="lock icon"></i>
                <p>Senha</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3">
            <span :class="{'span-password' : !view_password}">
                <i class="file alternate icon"></i>
                <p>Briefing</p>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3">
            <span :class="{'span-password' : !view_password}">
                <i class="file word icon"></i>
                <p>Docs/Refs</p>
            </span>

            <div class="line ease" :class="{'span-password' : !view_password}"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form autocomplete="off">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <Multiselect disabled :searchable="true" v-model="formDate.client_id" label="name"
                                             :options="selectClientList">
                                    <template v-slot:singlelabel="{ value }">
                                        <div class="multiselect-single-label">
                                            <img alt="" class="character-label-icon" :src="value.icon"> {{ value.name }}
                                        </div>
                                    </template>

                                    <template v-slot:option="{ option }">
                                        <img alt="" class="character-option-icon" :src="option.icon"> {{ option.name }}
                                    </template>
                                </Multiselect>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Status:</label>
                                <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                             :noResultsText="this.$store.state.var.selectNotSearch"/>
                            </row-col-component>
                        </row-component>
                    </form>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <button-one style="width: 100%;" @submit="submit" :icon="'save'"
                                        :name-button="'Salvar'"
                                        :class="{ 'preloderSend': sendForm }"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <button-float style="width: 100%;" :itens="itensTwo"/>
                        </row-col-component>
                    </row-component>
                </section>

                <section v-if="view_password" id="tab-item-2" class="position-relative">
                    <button @click="add_password = true" class="btn-add"><i class="plus icon"></i></button>
                    <div class="alert alert-secondary alert-dismissible fade show mt-5" role="alert"
                         v-if="formDate.passwords.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <row-component v-else class="mt-5 container-section" v-for="(password, index) in formDate.passwords"
                                   :key="index">
                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <label>Nome:</label>
                            <input-component disabled :type-input="'text'" v-model="password.name"/>
                        </row-col-component>

                        <row-col-component
                                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <label>Link:</label>
                            <input-component disabled :type-input="'text'" v-model="password.link"/>
                        </row-col-component>

                        <row-col-component
                                :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Login:</label>
                            <input-component disabled :type-input="'text'" v-model="password.login"/>
                        </row-col-component>

                        <row-col-component
                                :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Senha:</label>
                            <div class="password-field">
                                <input-component :id="'passwordField' + index" disabled type-input="password"
                                                 v-model="password.password"/>
                                <i :id="'eyeIcon' + index" @click="passwordEye(index)"
                                   v-if="view_password_show"
                                   class="eye icon"></i>
                                <i style="display: none" :id="'eyeSlash' + index" @click="passwordEye(index)"
                                   v-if="view_password_show"
                                   class="eye slash icon"></i>
                            </div>
                        </row-col-component>

                        <div style="width: fit-content;" class="position-absolute bottom-0 end-0">
                            <button @click="updateCustomerProfilePassword(index, password.id)"
                                    class="btn-password bg-success mx-1">
                                <i class="edit icon"></i>
                            </button>

                            <button @click="destroyCustomerProfilePassword(password.id)"
                                    class="btn-password bg-danger mx-1">
                                <i class="trash icon"></i>
                            </button>
                        </div>
                    </row-component>
                </section>

                <section id="tab-item-3">
                    <button @click="add_briefing = true" class="btn-add"><i class="plus icon"></i></button>
                    <div class="alert alert-secondary alert-dismissible fade show mt-5" role="alert"
                         v-if="formDate.brifings.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <row-component v-else class="mt-5">
                        <row-col-component
                                :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                v-for="(briefings, index) in formDate.brifings" :key="index">

                            <div class="file-content">
                                <label>
                                    <img :src="briefings.photo" alt="">
                                    <p>{{ briefings.name }}</p>
                                </label>

                                <div class="action-buttons">
                                    <button @click="updateCustomerProfileBriefing(index, briefings.id)">
                                        <i class="edit icon"></i>
                                    </button>
                                    <button @click="destroyCustomerProfileBriefings(briefings.id)">
                                        <i class="trash icon"></i>
                                    </button>
                                </div>
                            </div>
                        </row-col-component>
                    </row-component>
                </section>

                <section id="tab-item-4">
                    <button @click="add_files = true" class="btn-add"><i class="plus icon"></i></button>

                    <div class="alert alert-secondary alert-dismissible fade show mt-5" role="alert"
                         v-if="formDate.files.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <row-component v-else class="mt-5">
                        <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                           v-for="(file, index) in formDate.files" :key="index">
                            <div class="card">
                                <div class="card-image">
                                    <a :href="file.name_encoded" target="_blank"
                                       v-if="extension(file.name_encoded) === 'png' || extension(file.name_encoded) === 'jpeg' || extension(file.name_encoded) === 'jpg'">
                                        <img :src="file.name_encoded" alt="">
                                    </a>

                                    <i v-else-if="extension(file.name_encoded) === 'pdf'" class="file pdf icon"></i>

                                    <i v-else-if="extension(file.name_encoded) === 'docx' || extension(file.name_encoded) === 'doc'"
                                       class="file word icon"></i>

                                    <i v-else-if="extension(file.name_encoded) === 'mp4' || extension(file.name_encoded) === 'mov' || extension(file.name_encoded) === 'avi'"
                                       class="file video icon"></i>

                                    <i v-else-if="extension(file.name_encoded) === 'xlsx' || extension(file.name_encoded) === 'xlsm' || extension(file.name_encoded) === 'xlsb'
                                       || extension(file.name_encoded) === 'xltx'|| extension(file.name_encoded) === 'xltm'|| extension(file.name_encoded) === 'xls'
                                       || extension(file.name_encoded) === 'xlt' || extension(file.name_encoded) === 'xml' || extension(file.name_encoded) === 'xlam'
                                       || extension(file.name_encoded) === 'xla' || extension(file.name_encoded) === 'xlw' || extension(file.name_encoded) === 'xlr'"
                                       class="file excel icon"></i>

                                    <i v-else-if="extension(file.name_encoded) === 'txt' || extension(file.name_encoded) === 'csv'"
                                       class="file alternate icon"></i>

                                    <i v-else class="file audio icon"></i>
                                </div>

                                <p>{{ file.name_original }}</p>

                                <div class="aux-row-3">
                                    <div class="text"><i class="calendar icon"></i>{{ file.date_create }}</div>
                                    <div class="text"><i class="clock icon"></i>{{ file.hour_create }}</div>
                                </div>

                                <div class="ow-img">
                                    <img :src="file.user_photo" alt=""/>
                                    Enviado por: {{ file.user_name }}
                                </div>

                                <div class="dates-card">
                                    <button @click="previewFiles(file.name_encoded)"
                                            class="btn btn-secondary buttonRound" type="button">
                                        <i class="eye icon"></i>
                                    </button>
                                    <button @click="destroyCustomerProfileFiles(file.id)" type="button"
                                            class="btn btn-danger  buttonRound">
                                        <i class="trash icon"></i>
                                    </button>
                                </div>
                            </div>
                        </row-col-component>
                    </row-component>
                </section>
            </div>
        </div>

    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";

import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css'

import {useToast} from "vue-toastification";
import {ACTION_MESSAGES} from "@/configs/constants";
import InputComponent from "@/components/form/InputComponent.vue";
import swal from "sweetalert";
import axios from "axios";

const toast = useToast();

export default {
    name: "CustomerProfileUpdate",
    components: {
        InputComponent,
        PainelComponent,
        RowComponent,
        RowColComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        QuillEditor
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Perfil do Cliente')
        this.$store.dispatch('loadClientListSelect')
        this.loadCustomerProfile()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/customer-profile/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                client_id: '',
                status: '',
                passwords: [],
                brifings: [],
                files: []
            },

            formDatePassword: {
                profile_id: this.id,
                name: '',
                link: '',
                login: '',
                password: ''
            },

            // extension: '',
            files: [],

            add_password: false,
            showErrorPass: false,
            view_password: false,
            add_password_btn: false,
            view_password_show: false,

            formDateFile: [],
            formDateUploadFile: {
                id: this.id,
                file: ''
            },

            url_file: '',
            contUpload: 0,
            add_files: false,
            preview_files: false,

            formDateBriefings: {
                profile_id: this.id,
                name: '',
                visible: 1,
                desc: ''
            },

            add_briefing: false,
            add_briefing_btn: false,
            showErrorBriefing: false,

            toolbarOptions: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{'header': 1}, {'header': 2}],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{'direction': 'rtl'}],
                [{'size': ['small', false, 'large', 'huge']}],
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'color': []}, {'background': []}],
                [{'font': []}],
                [{'align': []}],
                ['clean']
            ],

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        async checkACL() {
            this.$store.dispatch('ACLitens', 39)
                .then((r) => {
                    if (r.data.indexOf('profile-edit') !== -1) {
                        this.showLoader = false

                        if (r.data.indexOf('profile-password') !== -1) {
                            this.view_password = true
                        }

                        if (r.data.indexOf('profile-password-show') !== -1) {
                            this.view_password_show = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        loadCustomerProfile() {
            this.$store.dispatch('loadCustomerProfile', this.id)
                .then((r) => {
                    this.formDate = r
                })
                .catch(() => {
                    toast.error("Erro desconhecido", {timeout: 1000});
                })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateCustomerProfile', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        closeModalPassword() {
            this.clearFormDataPassword()
            this.add_password = false
            this.add_password_btn = false
        },

        passwordEye(index) {
            let eyeIcon = document.getElementById('eyeIcon' + index)
            let eyeSlash = document.getElementById('eyeSlash' + index)
            let passwordField = document.getElementById('passwordField' + index)

            if (eyeSlash.style.display === 'none') {
                eyeIcon.style.display = 'none'
                eyeSlash.style.display = 'block'
                passwordField.type = 'text'
            } else {
                eyeIcon.style.display = 'block'
                eyeSlash.style.display = 'none'
                passwordField.type = 'password'
            }
        },

        updateCustomerProfilePassword(index, id) {
            this.formDatePassword = this.formDate.passwords[index]
            this.add_password = true
            this.add_password_btn = true
            this.formDatePassword.id_password = id
            delete Object.assign(this.formDatePassword, {['profile_id']: this.formDatePassword['id']})['id'];
        },

        updateCustomerProfilePasswordSubmit() {
            this.formDatePassword.profile_id = this.id
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateCustomerProfilePassword', this.formDatePassword)
                    .then(() => {
                        this.showErrorPass = false
                        this.sendForm = false
                        this.errors = []
                        this.add_password = false
                        this.loadCustomerProfile()
                        this.clearFormDataPassword()
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    }).catch((r) => {
                    this.showErrorPass = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        storeCustomerProfilePassword() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeCustomerProfilePassword', this.formDatePassword)
                    .then(() => {
                        this.showErrorPass = false
                        this.sendForm = false
                        this.errors = []
                        this.add_password = false
                        this.loadCustomerProfile()
                        this.clearFormDataPassword()
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                    }).catch((r) => {
                    this.showErrorPass = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        clearFormDataPassword(){
            this.formDatePassword = {
                profile_id: this.id,
                name: '',
                link: '',
                login: '',
                password: ''
            }
        },

        destroyCustomerProfilePassword(id) {
            let params = {profile_id: id, id: this.id}
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyCustomerProfilePassword', params).then(() => {
                        this.loadCustomerProfile(1)
                        toast.success(ACTION_MESSAGES.delete, {timeout: 10000});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar`, {timeout: 10000});
                    })
                }
            });
        },

        extension(name) {
            return name.split('.').pop().toString()
        },

        previewFiles(file) {
            let ext = file.split('.').pop()
            let types = ['wav', 'aiff', 'pcm', 'flac', 'alac', 'wma', 'mp3', 'ogg', 'aac', 'mp4', 'mov', 'avi', 'png', 'jpeg', 'jpg']

            if (types.find(element => element === ext)) {
                this.url_file = file
                this.preview_files = true
            } else {
                window.open(file, '_blank')
            }
        },

        remove(index) {
            this.files = Array.from(this.files)
            this.files.splice(index, 1)
        },

        selectFiles(event) {
            this.files = Array.from(event.target.files).map((file) => ({
                file,
                id: this.id,
            }));
        },

        uploadFiles() {
            this.sendForm = true
            let removeFileClass = document.getElementsByClassName("remove-file");
            removeFileClass.forEach((element) => {
                element.style.display = 'none'
            });

            this.files.forEach((fileObject, index) => {
                const params = {
                    file: fileObject.file,
                    id: this.id
                }

                axios.post("api/v1/admin/profile-add-file", params, {
                    headers: {'Content-Type': 'multipart/form-data'},
                    onUploadProgress: (progressEvent) => {
                        fileObject.progress = Math.round(
                            (progressEvent.loaded / progressEvent.total) * 100
                        );
                    },
                }).then(() => {
                    document.getElementById('file-success' + index).style.display = 'block'
                    document.getElementById('progress-bar' + index).style.display = 'none'
                    fileObject.progress = 0;
                    this.contUpload += 1
                    if (this.contUpload === this.files.length) {
                        this.sendForm = false
                        this.contUploadApproved = 0
                        this.files = []
                        this.loadCustomerProfile()
                    }
                }).catch(() => {
                    document.getElementById('file-erro' + index).style.display = 'block'
                    document.getElementById('progress-bar' + index).style.display = 'none'
                    fileObject.progress = 0;
                    this.contUpload += 1
                    if (this.contUpload === this.files.length) {
                        this.sendForm = false
                        this.contUploadApproved = 0
                        this.files = []
                        this.loadCustomerProfile()
                    }
                });
            });
        },

        convertBytes(size) {
            if (size >= 1024 && size < 1024000) {
                size = Math.round((size / 1024) * 100) / 100;
                return size
            } else if (size >= 1024000) {
                size = Math.round((size / 1024000) * 100) / 100;
                return size
            }
        },

        destroyCustomerProfileFiles(id) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyCustomerProfileFiles', id).then(() => {
                        this.loadCustomerProfile(1)
                        toast.success(ACTION_MESSAGES.delete, {timeout: 10000});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar`, {timeout: 10000});
                    })
                }
            });
        },

        closeModalBriefing() {
            this.formDateBriefings = {}
            this.add_briefing = false
            this.add_briefing_btn = false
        },

        updateCustomerProfileBriefing(index, id) {
            this.formDateBriefings = this.formDate.brifings[index]
            this.add_briefing = true
            this.add_briefing_btn = true
            this.formDateBriefings.id_briefing = id
            delete Object.assign(this.formDateBriefings, {['profile_id']: this.formDateBriefings['id']})['id'];
        },

        storeCustomerProfileBriefings() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeCustomerProfileBriefings', this.formDateBriefings)
                    .then(() => {
                        this.showErrorBriefing = false
                        this.sendForm = false
                        this.errors = []
                        this.loadCustomerProfile()
                        this.add_briefing = false
                        this.formDateBriefings = {}
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                    }).catch((r) => {
                    this.showErrorBriefing = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        updateCustomerProfileBriefings() {
            this.formDateBriefings.profile_id = this.id
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateCustomerProfileBriefings', this.formDateBriefings)
                    .then(() => {
                        this.showErrorBriefing = false
                        this.sendForm = false
                        this.errors = []
                        this.add_password = false
                        this.loadCustomerProfile()
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    }).catch((r) => {
                    this.showErrorBriefing = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        destroyCustomerProfileBriefings(id) {
            let params = {profile_id: id, id: this.id}
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyCustomerProfileBriefings', params).then(() => {
                        this.loadCustomerProfile(1)
                        toast.success(ACTION_MESSAGES.delete, {timeout: 10000});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar`, {timeout: 10000});
                    })
                }
            });
        },
    },

    computed: {
        selectClientList() {
            return this.$store.state.var.selectClientList;
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        files_computed() {
            return this.files
        },
    }

}
</script>

<style scoped>

.tabs > span.span-password,
.effect-3 .line.span-password {
    width: calc(100% / 3);
}

#tab-3, #tab-3 + span.span-password,
#tab-3:checked ~ .line.span-password {
    left: 33.3%;
}

#tab-4, #tab-4 + span.span-password,
#tab-4:checked ~ .line.span-password {
    left: 66.6%;
}

/*####################*/

#tab-2, #tab-2 + span,
#tab-2:checked ~ .line {
    left: 25%;
}

#tab-3, #tab-3 + span,
#tab-3:checked ~ .line {
    left: 50%;
}

#tab-4, #tab-4 + span,
#tab-4:checked ~ .line {
    left: 75%;
}

.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 25%;
}

.btn-add,
label.uploadButton {
    right: 0;
    outline: 0;
    top: -50px;
    width: 40px;
    height: 40px;
    color: #FFFFFF;
    position: absolute;
    padding-right: 1px;
    border-radius: 8px;
    background: #d67c1c;
    border: 1px solid #f0f0f0;
}

.btn-add:hover {
    border: 1px solid #d67c1c;
    transition: all 0.2s ease-in-out;
}

.container-section {
    padding: 35px 10px;
    position: relative;
    background: #f0f0f0;
}

.password-field {
    position: relative;
}

.password-field i.eye {
    top: 8px;
    right: 5px;
    cursor: pointer;
    position: absolute;
}

.container-section .btn-password {
    border: 0;
    width: 40px;
    height: 40px;
    color: #FFFFFF;
    margin-bottom: 10px;
    border-radius: 8px;
    padding-right: 2px;
    display: inline-block;
}

.modal-add-password,
.modal-add-files,
.modal-files-preview,
.modal-add-briefings {
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 100;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
    background: rgb(0, 0, 0, 0.6);
}

.modal-add-password .div-add-password,
.modal-add-briefings .div-add-briefings {
    width: 60%;
    padding: 2%;
    height: auto;
    box-shadow: none;
    position: relative;
    background: #FFFFFF;
    margin: 5% 2% 5% 2%;
}

.modal-add-password .div-add-password h2,
.modal-add-briefings .div-add-briefings h2 {
    width: fit-content;
    font-family: 'Comfortaa', cursive;
    font-size: 20px;
    position: relative;
    left: 0;
    top: -19px;
    color: #000;
    font-weight: 50;
}

.modal-add-password .div-add-password .btn-close,
.modal-add-files .container .btn-close,
.modal-files-preview .btn-close,
.modal-add-briefings .btn-close {
    top: 0;
    right: 0;
    margin: 2%;
    cursor: pointer;
    position: absolute;
}

.modal-files-preview span {
    top: 0;
    right: 0;
    margin: 2%;
    width: 50px;
    height: 50px;
    display: block;
    background: white;
    position: absolute;
    border-radius: 50%;
    padding: 0.4rem 0.5rem;
}

.modal-files-preview .btn-close {
    font-size: 1.5rem;
    position: relative;
}

/*#############################*/

.modal-add-files .container {
    width: 30%;
    height: 30%;
    overflow: hidden;
    padding: 15px 5px;
    border-radius: 8px;
    position: relative;
    background: #FFFFFF;
}

label.uploadButton {
    top: 0;
    left: 0;
    margin: 1%;
    cursor: pointer;
    padding: 0.5rem 0.6rem;
}

input[type=file] {
    display: none;
}

.modal-add-files .container input[type=button] {
    border: 0;
    color: #fff;
    overflow: hidden;
    position: relative;
    padding: 1rem 3rem;
    text-align: center;
    border-radius: 5px;
    background: #FF7700;
    display: inline-block;
}

div.files {
    padding: 2rem 0 0;
    margin: 1rem 0;
}

div.files ul {
    list-style: none;
    padding: 0;
    max-height: 160px;
    overflow: auto;
}

div.files ul li {
    width: 99%;
    background: #eee;
    margin: 0.5rem 0;
    padding: 0.5rem;
    position: relative;
}

div.files ul li button {
    border: 0;
    outline: none;
    position: absolute;
    right: 0;
    margin-right: 0.5rem;
}

div.container {
    max-width: 100%;
    padding: 0;
}

span.file-size {
    color: #999;
    padding-left: 0.5rem;
}

@keyframes Rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.file-success > i {
    filter: invert(50%) sepia(99%) saturate(426%) hue-rotate(74deg) brightness(96%) contrast(87%);
}

.file-erro > i {
    filter: invert(23%) sepia(80%) saturate(2715%) hue-rotate(352deg) brightness(95%) contrast(81%)
}

.file-success,
.file-erro {
    display: none;
    width: fit-content;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;
}

.spinner {
    display: none;
    margin: 0.5rem;
    width: 25px;
    height: 25px;
    border: 4px solid lightgray;
    border-top-color: gray;
    animation: Rotate 1.2s linear 0s infinite forwards;
    position: absolute;
    top: 0;
    right: 0;
}

/*#############################*/

.modal-files-preview > img,
.modal-files-preview > video {
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    display: block;
    max-height: 80vh;
    max-width: 80vw;
    position: relative;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
}

.card {
    width: 90%;
    margin: 1rem;
    font-size: 1rem;
    max-width: 20rem;
    min-height: 24rem;
    position: relative;
    display: inline-block;
    text-decoration: none;
    box-shadow: 0 0 3rem -1rem rgba(0, 0, 0, 0.5);
    transition: transform 0.1s ease-in-out, box-shadow 0.1s;
}

.card-image {
    display: block;
    object-fit: contain;
    text-align: center;
}

.card-image i.icon {
    display: initial;
    font-size: 8rem;
}

.card-image a > img {
    width: 100%;
    display: block;
}

.card:hover {
    transform: translateY(-0.5rem) scale(1.0125);
    box-shadow: 0 0.5em 3rem -1rem rgba(0, 0, 0, 0.5);
}

.card p {
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
}

.ow-img {
    width: 100%;
    display: flex;
    padding: 10px 10px;
    margin-bottom: 5px;
    align-items: center;
}

.ow-img > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.aux-row-3 {
    display: flex;
    justify-content: space-evenly
}

.dates-card {
    bottom: 0;
    opacity: 1;
    width: 100%;
    height: 15%;
    display: flex;
    position: absolute;
    place-items: center;
    place-content: space-evenly;
}

.dates-card button {
    margin: 0;
    width: 50%;
    height: 100%;
    border-radius: 0;
}

/*#############################*/

.file-content {
    width: 100%;
    height: 60px;
    display: flex;
    color: black;
    padding: 15px 0;
    position: relative;
    align-items: center;
    background: #ebebeb;
    justify-content: space-between;
}

.file-content label {
    width: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
}

.file-content label img {
    height: auto;
    width: 50px;
    border-radius: 50%;
    margin: 0 10px 0 5px;
}

.file-content label p {
    margin: 0;
    width: 85%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.action-buttons {
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    position: absolute;
}

.action-buttons > button {
    border: 0;
    width: 55px;
    padding: 5px;
    height: 100%;
    background: #ebebeb;
}

.action-buttons > button i.trash {
    color: #bb2d3b;
}

.action-buttons > button i.edit {
    color: #146c43;
}

.action-buttons > div {
    border: 0;
    width: 30px;
    padding: 5px;
    display: grid;
    place-items: center;
    background: #ebebeb;
}

@media (max-width: 428px) {

    .modal-add-password .div-add-password,
    .modal-add-briefings .div-add-briefings {
        width: 95%;
    }

    .modal-add-files .container {
        width: 95%;
    }

}

progress {
    border-radius: 8px;
    border: 1px solid #fbfbfb;
}

progress::-webkit-progress-bar {
    background-color: #fbfbfb;
}

progress::-webkit-progress-value {
    background-color: #d67c1c;
}
</style>