import state from "@/store/modules/product/state";
import actions from "@/store/modules/product/actions";
import mutations from "@/store/modules/product/mutations";
import getters from "@/store/modules/product/getters";


export default {
  state,
  actions,
  mutations,
  getters
}