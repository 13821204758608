<template>
    <painel-component :itens="itensOne">
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form class="mb-4 mt-5" v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Mês:</label>
                    <multiselect v-model="formDate.month" :searchable="true" :options="selectMonth"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Ano:</label>
                    <multiselect v-model="formDate.year" :searchable="true" :options="getYearSelect()"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Negócio:</label>
                    <multiselect v-model="formDate.business" :searchable="true" :options="selectBusiness"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Conta Bancária:</label>
                    <Multiselect v-model="formDate.bank_accounts_id" label="name" :options="selectBankAccountList"
                                 :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Tipo de Conta:</label>
                    <multiselect v-model="formDate.account_types_id" :searchable="true"
                                 @select="selectSubTypeAccount(formDate.account_types_id)"
                                 :options="selectSearchAccountType"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Sub Tipo de Conta:</label>
                    <multiselect v-model="formDate.account_type_sub_id" :searchable="true"
                                 :options="selectAccountTypeSubList"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-4 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Tipo Entrada / Saída:</label>
                    <multiselect v-model="formDate.type" :searchable="true" :options="selectEntranceExit"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-4 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Operação:</label>
                    <multiselect v-model="formDate.preview" :searchable="true" :options="selectPreviewReport"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-4 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Excel / PDF:</label>
                    <multiselect v-model="formDate.pdf_excel" :searchable="true" :options="selectFormatReport"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'">
                    <button-one @submit="submit" :icon="'arrow alternate circle down icon'" :nameButton="'Gerar'"
                                v-if="buttonCheck.generate"/>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "ReportAnalyticalCashFlow",
    components: {
        PainelComponent,
        ButtonOne,
        RowComponent,
        RowColComponent,
        LoderSendComponent,
        Multiselect,
    },

    created() {
        this.checkACL()
        this.$store.dispatch('loadAccountBankSelect')
        this.$store.dispatch('loadAccountTypeListSelect')
        this.$store.dispatch('updateNamePage', 'Fluxo de Caixa Analítico')
    },

    data() {
        return {
            itensOne: {
                title: "Relatório",
                icon: "file excel"
            },

            buttonCheck: {
                generate: false
            },

            formDate: {
                bank_accounts_id: '',
                account_types_id: '',
                account_type_sub_id: '',
                business: '',
                type: '',
                preview: 1,
                pdf_excel: 1,
                month: this.getMonth(),
                year: this.getYear()
            },
            showLoader: true,
        }
    },

    methods: {
        submit() {
            this.loadReportAnalyticalCashFlow()
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 50)
                .then((r) => {
                    if (r.data.indexOf('report-analytical-cash-flow') !== -1) {
                        this.showLoader = false
                        if (r.data.indexOf('report-analytical-cash-flow') !== -1) {
                            this.buttonCheck.generate = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        loadReportAnalyticalCashFlow() {
            this.$store.dispatch('loadReportAnalyticalCashFlow', {...this.params})
                .then((r) => {
                    window.open(r.link)
                }).catch(() => {
                toast.error('Erro ao gerar relatório', {timeout: 1200})
            });
        },

        selectSubTypeAccount(id) {
            this.$store.dispatch('loadAccountTypeSubListSelect', id)
        },

        getYearSelect() {
            let dataActual = new Date();
            let year = dataActual.getFullYear() + 2;
            let years = [];
            for (let i = 2021; i <= year; i++) {
                years.push(i);
            }
            return years
        },

        getYear() {
            let date = new Date()
            return date.getFullYear()
        },

        getMonth() {
            let date = new Date()
            return date.getMonth() + 1
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectBusiness() {
            return this.$store.state.var.selectBusiness;
        },

        selectMonth() {
            return this.$store.state.var.selectMonth;
        },

        selectSearchAccountType() {
            return this.$store.state.var.selectSearchAccountType;
        },

        selectEntranceExit() {
            return this.$store.state.var.selectEntranceExit;
        },

        selectBankAccountList() {
            return this.$store.state.var.selectBankAccountList
        },

        selectAccountTypeSubList() {
            return this.$store.state.var.selectAccountTypeSubList
        },

        selectPreviewReport() {
            return this.$store.state.var.selectPreviewReport
        },

        selectFormatReport() {
            return this.$store.state.var.selectFormatReport
        },

        objectReturn() {
            return this.$store.state.report_analytical_cash_flow.items
        },

        params() {
            return {
                bank_accounts_id: this.formDate.bank_accounts_id,
                account_types_id: this.formDate.account_types_id,
                account_type_sub_id: this.formDate.account_type_sub_id,
                business: this.formDate.business,
                type: this.formDate.type,
                preview: this.formDate.preview,
                pdf_excel: this.formDate.pdf_excel,
                month: this.formDate.month,
                year: this.formDate.year
            }
        },
    },
}
</script>

<style scoped src="@/assets/css/table-resp.css"></style>