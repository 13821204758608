<template>
    <painel-component :itens="itensOne">
        <div class="filter-position" v-if="btnFilter">
            <button-one icon="filter" class="btn-filter" @click="filterWindow"/>
        </div>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <div v-if="filter">
                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                            <input type="radio" id="cliente" v-model="typeInput" value="1">
                            <label for="cliente">Cliente</label>

                            <input type="radio" id="fornecedor" v-model="typeInput" value="3">
                            <label for="fornecedor">Fornecedor</label>

                            <input type="radio" id="funcionario" v-model="typeInput" value="2">
                            <label for="funcionario">Funcionário</label>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Código:</label>
                            <input-component :type-input="'text'" v-model="formDate.code"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Título:</label>
                            <input-component :type-input="'text'" v-model="formDate.title"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Tipo:</label>
                            <multiselect v-model="formDate.client_employee_provider_type" :searchable="true"
                                         :options="selectTypeReceipt"
                                         :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                        </row-col-component>

                        <row-col-component v-if="parseInt(typeInput) === 1"
                                           :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Cliente:</label>
                            <Multiselect v-model="formDate.client_employee_provider_id" label="name" :searchable="true"
                                         :options="selectClientList"
                                         :noResultsText="noResultsText" :noOptionsText="noResultsText">
                                <template v-slot:singlelabel="{ value }">
                                    <div class="multiselect-single-label">
                                        <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                    </div>
                                </template>

                                <template v-slot:option="{ option }">
                                    <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                                </template>
                            </Multiselect>
                        </row-col-component>

                        <row-col-component v-if="parseInt(typeInput) === 2"
                                           :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Funcionário:</label>
                            <Multiselect v-model="formDate.client_employee_provider_id" label="name" :searchable="true"
                                         :options="selectEmployee"
                                         :noResultsText="noResultsText" :noOptionsText="noResultsText">
                                <template v-slot:singlelabel="{ value }">
                                    <div class="multiselect-single-label">
                                        <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                    </div>
                                </template>

                                <template v-slot:option="{ option }">
                                    <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                                </template>
                            </Multiselect>
                        </row-col-component>

                        <row-col-component v-if="parseInt(typeInput) === 3"
                                           :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Fornecedor:</label>
                            <Multiselect v-model="formDate.client_employee_provider_id" label="name" :searchable="true"
                                         :options="selectProvider"
                                         :noResultsText="noResultsText" :noOptionsText="noResultsText">
                                <template v-slot:singlelabel="{ value }">
                                    <div class="multiselect-single-label">
                                        <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                    </div>
                                </template>

                                <template v-slot:option="{ option }">
                                    <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                                </template>
                            </Multiselect>
                        </row-col-component>

                        <row-col-component
                                :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'">
                            <button-one class="mx-1" @submit="submit" :icon="'search'"/>
                            <button-float class="mx-1" :itens="itensTwo"/>
                        </row-col-component>
                    </row-component>
                </div>
            </transition>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end'">
                    <div class="toggle_preferences">
                        <button :class="{ 'bg-active': parseInt(cardTable) === 1 }" @click="toggleCard" type="button"
                                tooltip="Card">
                            <i class="window maximize outline icon"></i>
                        </button>
                        <button :class="{ 'bg-active': parseInt(cardTable) === 2 }" @click="toggleTable" type="button"
                                tooltip="Tabela">
                            <i class="align justify icon"></i>
                        </button>
                    </div>
                </row-col-component>
            </row-component>

            <row-component v-if="parseInt(cardTable) === 1">
                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4'"
                                   v-for="(receipt, index) in objectReturn.itens.data" :key="index">
                    <div class="card-container">
                        <div class="flag-status"></div>
                        <p>Código: {{ receipt.code }}</p>
                        <p class="title-container">{{ receipt.title }}</p>

                        <div class="aux-row-3">
                            <div class="text"><i class="money icon"></i>R${{ receipt.price }}</div>
                            <div class="text"><i class="user circle icon"></i> {{ receipt.name }}</div>
                        </div>

                        <div class="responsible-task">
                            <h6> Criado por:</h6>
                            <img v-if="receipt.photo_creator" :src="receipt.photo_creator" class="avatar__image_2"
                                 alt="">
                            <img v-else src="@/assets/img/avatar.png" alt="" class="avatar__image_2">
                            {{ receipt.creator_user_name }}
                        </div>

                        <div class="buttons">
                            <button class="primary" tooltip="Editar" v-if="buttonCheck.edit"
                                    @click="routeEdit(receipt.id)">
                                <i class="edit icon"></i>
                            </button>

                            <button class="primary" tooltip="Ver" v-if="buttonCheck.show"
                                    @click="routeShow(receipt.id)">
                                <i class="eye icon"></i>
                            </button>

                            <button class="primary " tooltip="Deletar" v-if="buttonCheck.delete"
                                    @click="deleteSubmit(receipt)">
                                <i class="trash icon"></i>
                            </button>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <table class="table table-striped" v-if="parseInt(cardTable) === 2">
                <thead>
                <tr>
                    <th scope="col" style="width: 5%">Código</th>
                    <th scope="col" style="width: 15%">Criador</th>
                    <th scope="col" style="width: 15%">Titulo</th>
                    <th scope="col" style="width: 5%">Preço</th>
                    <th scope="col" style="width: 15%">Nome</th>
                    <th scope="col" style="width: 10%"
                        v-if="buttonCheck.show || buttonCheck.delete || buttonCheck.edit">
                        Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(receipt, index) in objectReturn.itens.data" :key="index">
                    <td data-label="Código">{{ receipt.code }}</td>
                    <td data-label="Criador">
                        <img v-if="receipt.photo_creator" :src="receipt.photo_creator" class="avatar__image_2" alt="">
                        <img v-else src="@/assets/img/avatar.png" alt="" class="avatar__image_2">
                        {{ receipt.creator_user_name }}
                    </td>
                    <td data-label="Titulo">{{ receipt.title }}</td>
                    <td data-label="Preço">{{ receipt.price }}</td>
                    <td data-label="Nome">{{ receipt.name }}</td>
                    <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.delete || buttonCheck.low">
                        <button tooltip="Ver" class="btn btn-secondary mx-1 button-show" v-if="buttonCheck.show"
                                @click="routeShow(receipt.id)">
                            <i class="eye icon"></i>
                        </button>

                        <button tooltip="Deletar" class="btn btn-danger mx-1 button-show" v-if="buttonCheck.delete"
                                @click=deleteSubmit(receipt)>
                            <i class="trash icon"></i>
                        </button>

                        <button tooltip="Editar" class="btn btn-success mx-1 button-show" v-if="buttonCheck.edit"
                                @click="routeEdit(receipt.id)">
                            <i class="edit icon"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <button-pagination :pagination="objectReturn.itens" :offset="3" @paginate="loadReceipts"/>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import InputComponent from "@/components/form/InputComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";
import swal from 'sweetalert';

import {ACTION_MESSAGES} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "ReceiptSearch",
    components: {
        ButtonPagination,
        PainelComponent,
        ButtonOne,
        ButtonFloat,
        RowComponent,
        RowColComponent,
        LoderSendComponent,
        Multiselect,
        InputComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Recibo')
        this.$store.dispatch('loadProviderSelect')
        this.$store.dispatch('loadClientListSelect')
        this.$store.dispatch('loadEmployeeList')
        this.loadReceipts(1)
        this.resizeWindow()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            itensTwo: {
                route: "/receipt/create",
                icon: "plus",
                name: ''
            },

            buttonCheck: {
                edit: false,
                create: false,
                show: false,
                delete: false
            },

            formDate: {
                code: '',
                title: '',
                client_employee_provider_id: '',
                client_employee_provider_type: '',
            },

            typeInput: 2,

            cardTable: '',
            preferences: {
                type: 9,
                preference: ''
            },

            filter: true,
            btnFilter: false,
            showLoader: true,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadReceipts(1)
            this.searchSubmit = true;
        },

        loadReceipts(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadReceipts', {...this.params, page})
                    .then((r) => {
                        this.showLoader = false
                        this.cardTable = r.tableCard
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        deleteSubmit(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyReceipt', item.id)
                        .then(() => {
                            this.loadReceipts(1)
                            toast.success(ACTION_MESSAGES.delete, {timeout: 10000});
                        })
                        .catch(() => {
                            toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 10000});
                        })
                }
            });
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 33)
                .then((r) => {
                    if (r.data.indexOf('receipt-search') !== -1) {
                        this.showLoader = false

                        if (r.data.indexOf('receipt-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('receipt-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('receipt-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('receipt-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        routeEdit(id) {
            this.$router.push({name: 'receipt-edit', params: {id: id}})
        },

        routeShow(id) {
            this.$router.push({name: 'receipt-show', params: {id: id}})
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },

        toggleTable() {
            this.userPreferences(2)
            this.cardTable = 2
        },

        toggleCard() {
            this.userPreferences(1)
            this.cardTable = 1
        },

        userPreferences(preference) {
            this.preferences.preference = preference
            this.$store.dispatch('userPreferences', this.preferences)
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectTypeReceipt() {
            return this.$store.state.var.selectTypeReceipt
        },

        selectProvider() {
            return this.$store.state.var.selectProvider
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectEmployee() {
            return this.$store.state.var.selectEmployee
        },

        objectReturn() {
            return this.$store.state.receipt.items
        },

        params() {
            return {
                page: this.objectReturn.current_page,
                code: this.formDate.code,
                title: this.formDate.title,
                client_employee_provider_id: this.formDate.client_employee_provider_id,
                client_employee_provider_type: this.formDate.client_employee_provider_type,
            }
        },
    },
}
</script>

<style scoped src="@/assets/css/table-resp.css"></style>
<style scoped>
.aux-row-3 {
    flex-flow: column wrap;
}

button.primary {
    width: 100%;
}

.bg-active {
    background-color: #d67c1c !important;
}

.toggle_preferences > button {
    border: 0;
    width: 45px;
    color: #fff;
    height: 45px;
    margin: 0 5px;
    padding-right: 4px;
    border-radius: 50%;
    background-color: #323232;
}

.button-show {
    width: 35px;
    height: 35px;
    font-size: 15px;
    border-radius: 50px;
    padding-left: 0.5rem;
}

.card-container > img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
}

.card-container {
    padding: 20px 0 0;
    min-height: 325px;
}

.title-container {
    height: 60px;
    padding: 3px 0;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}

.buttons {
    bottom: 0;
    width: 100%;
    display: flex;
    min-height: 60px;
    position: absolute;
    border-top: 1px solid #ededed;
}
</style>