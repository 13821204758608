<template>
    <div class="modalProvider" v-if="modal_provider">
        <div class="close-provider" @click="openProvider">X</div>

        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
            <label>Fornecedor:</label>
            <Multiselect v-model="provider" label="name" :options="selectProvider" @select="get(provider)"
                         :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                <template v-slot:singlelabel="{ value }">
                    <div class="multiselect-single-label">
                        <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                    </div>
                </template>

                <template v-slot:option="{ option }">
                    <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                </template>
            </Multiselect>
        </row-col-component>

        <button-one @click="openProvider" name-button="Confirmar"/>
    </div>

    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off" v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formDate.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Fornecedor:</label>
                    <div class="input-group" @click="openProvider()">
                        <input class="form-control" type="text" v-model="formDate.provider_name">
                        <span class="input-group-text my_btn" id="basic-addon1"><i class="search icon"></i></span>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <div class="clearfix">
                        <button-one icon="plus" class="float-start rounded-0 my-3" @click="addBudget"/>
                    </div>

                    <div class="border-content" v-for="(budget, index) in budgetAdd" :key="index">
                        <form autocomplete="off">
                            <button-remove-round v-if="budgetAdd.length > 1" @click="budgetRemove(index)"
                                                 class="rounded-0 position-absolute end-0 bottom-0 my-2"/>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <label>Produto/Serviço:</label>
                                    <input-component :type-input="'text'" v-model="budget.name"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Quantidade:</label>
                                    <input-number-component :type-input="'number'" min="1" v-model="budget.amount"
                                                            @keyup="calcBudget(index)"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Valor Unitário:</label>
                                    <input-component :type-input="'text'" v-model="budget.unit_price"
                                                     @keyup="calcBudget(index)" v-money="money" :maxlength="12"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Valor Total:</label>
                                    <input-component :type-input="'text'" v-model="budget.total_price" v-money="money"
                                                     disabled/>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>

                    <h4>Total: R$ {{ totalResult }}</h4>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>
                    <input-text-area :rows="4" v-model="formDate.obs"/>
                </row-col-component>
            </row-component>
        </form>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-one class="w-100" @submit="submit" :icon="'plus circle'"
                            :name-button="'Adicionar'" :class="{ 'preloderSend': sendForm }"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputNumberComponent from "@/components/form/InputNumberComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {MONEY} from "@/configs/constants"
import {ACTION_MESSAGES} from "@/configs/constants";
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";
import ButtonRemoveRound from "@/components/buttons/ButtonRemoveRound";

const toast = useToast();

export default {
    name: "BudgetTemplateCreate",
    components: {
        ButtonRemoveRound,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea,
        InputNumberComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Modelo de Orçamento')
        this.$store.dispatch('loadProviderSelect')
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/budget-template/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                status: '',
                provider_id: '',
                provider_name: '',
                obs: '',
                itens: []
            },

            provider: [],
            totalResult: 0,

            modal_provider: false,
            money: MONEY,

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: true,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeBudgetTemplate', this.formDate)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                        this.$router.push({name: 'budget-template-edit', params: {id: r.data}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        openProvider() {
            this.modal_provider = !this.modal_provider
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        addBudget() {
            this.formDate.itens.push({
                name: '',
                amount: '',
                unit_price: '',
                total_price: ''
            })
        },

        budgetRemove(index) {
            this.formDate.itens.splice(index, 1)
        },

        get(id) {
            this.$store.dispatch('loadProvider', id).then((r) => {
                this.formDate.provider_id = r.id
                this.formDate.provider_name = r.name
            })
        },

        calcBudget(index) {
            let budget = this.formDate.itens[index];
            budget.unit_price.toLocaleString('pt-br', {minimumFractionDigits: 2});
            let value = budget.unit_price.replace(/[.,]/g, '')
            let amount = budget.amount
            let result = (value * amount / 100)
            budget.total_price = result.toLocaleString('pt-br', {minimumFractionDigits: 2})
            this.calcTotal()
        },

        calcTotal() {
            let sum = 0;
            if (this.totalResult > 0)
                sum = this.replaceStringOne(this.totalResult)
            this.formDate.itens.forEach(item => {
                sum += this.replaceStringOne(item.total_price.replace(/[.,]/g, '')) / 100;
            });
            this.totalResult = sum.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        },

        replaceStringOne(label) {
            if (label.length < 8) {
                return parseFloat(label.replace(',', '.'))
            } else {
                return parseFloat(label.replace(',', ''))
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 24)
                .then((r) => {
                    if (r.data.indexOf('budget-template-create') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    mounted() {
        this.formDate.itens.push({
            name: '',
            amount: 1,
            unit_price: '',
            total_price: ''
        })
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        selectProvider() {
            return this.$store.state.var.selectProvider
        },

        budgetAdd() {
            return this.formDate.itens;
        },
    },
}
</script>

<style scoped>
.my_btn {
    color: white;
    cursor: pointer;
    position: relative;
    background: #d67c1c;
    padding-bottom: 15px;
    border: 1px solid #d67c1c;
}

.modalProvider {
    top: 50%;
    left: 50%;
    width: 40%;
    z-index: 9999;
    position: fixed;
    min-height: auto;
    background: white;
    padding: 40px 20px;
    overflow-y: scroll;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.close-provider {
    top: 0;
    right: 0;
    cursor: pointer;
    margin: 10px 20px;
    font-size: 1.5rem;
    position: absolute;
}

.border-content {
    margin-top: 20px;
    position: relative;
    background: #ebebeb;
    padding: 10px 20px 40px 20px;
}

h4 {
    color: #000;
    text-align: end;
    font-size: 16px;
    padding: 10px 15px;
    background-color: #c9c9c9;
}

@media (max-width: 991px) {
    .modalProvider {
        width: 95%;
    }
}
</style>
