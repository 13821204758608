export default {
    icon: 'cog icon',
    name: 'Manutenção',
    subLinks: [

        {
            name: 'Ordem de Serviço',
            route: '/order-of-service/search',
            pagePermissions: [
                'order-of-service-access-to-all',
                'order-of-service-search',
                'order-of-service-edit',
                'order-of-service-close',
                'order-of-service-start',
                'order-of-service-create'
            ],
        },

        {
            name: 'Saída Frota',
            route: '/fleet-departures/search',
            pagePermissions: [
                'fleet-departures-edit',
                'fleet-departures-search',
                'fleet-departures-create',
                'fleet-departures-close',
                'fleet-departures-access-to-all'
            ],
        },
    ],

    pageLinksNavCheck: [
        'fleet-departures-edit',
        'fleet-departures-search',
        'fleet-departures-create',
        'fleet-departures-close',
        'fleet-departures-access-to-all',
        'order-of-service-access-to-all',
        'order-of-service-search',
        'order-of-service-edit',
        'order-of-service-close',
        'order-of-service-start',
        'order-of-service-create'
    ],


}