import state from "@/store/modules/report-analytical-cash-flow/state";
import actions from "@/store/modules/report-analytical-cash-flow/actions";
import mutations from "@/store/modules/report-analytical-cash-flow/mutations";
import getters from "@/store/modules/report-analytical-cash-flow/getters";

export default {
  state,
  actions,
  mutations,
  getters
}