import AdminComponent from "@/components/dashboard/AdminComponent";
import ProfileClienteShow from "@/views/show/ProfileClienteShow";

export default {
    path: '/profile-client',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'show/:id', name: 'profile-client-show', component: ProfileClienteShow, props: true},
    ],
}