<template>
    <painel-component :itens="itensOne">
        <button-add :itens="itensThree" v-if="buttonCheck.create"/>
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off" v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.responsible_id }">
                    <label>Responsável:</label>
                    <Multiselect v-model="formDate.responsible_id" label="name" :options="selectUserList"
                                 @select="loadUser(formDate.responsible_id)" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.date_scheduling }">
                    <label>Data de Agendamento:</label>
                    <input-component v-mask="'##/##/####'" :type-input="'text'" v-model="formDate.date_scheduling"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.time_scheduling }">
                    <label>Hora de Agendamento:</label>
                    <input-component v-mask="'##:##'" :type-input="'text'" v-model="formDate.time_scheduling"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <div class="clearfix my-3">
                        <button-one class="float-start rounded-0" icon="plus"  @click="addActivities"/>
                    </div>

                    <div class="border-content" v-for="(activity, index) in activitiesAdd" :key="index">
                        <form autocomplete="off">
                            <button-remove-round class="position-absolute end-0 bottom-0 rounded-0"
                                                 v-if="activitiesAdd.length >1" @click="removeActivities(index)"/>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                    <label>Atividade:</label>
                                    <input-text-area :rows="4" v-model="activity.activity">
                                    </input-text-area>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>
                </row-col-component>
            </row-component>
        </form>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-one class="w-100" @submit="submit" :icon="'save'" :name-button="'Salvar'"
                            :class="{ 'preloderSend': sendForm }"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>

        <div class="line-division mt-5 mb-5">
            <h1 class="h1-text">
                <i class="comment icon"></i>
                Observações Internas
            </h1>
        </div>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Observações:</label>

                <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                     v-if="formDate.obsPrivate.length === 0">
                    <p>Não há nada cadastrado</p>
                </div>

                <div class="merito" v-else>
                    <section class="container-desc">
                        <ul class="task-desc">
                            <li class="item" v-for="(obs, index) in formDate.obsPrivate" :key="index">
                                <div class="aux-row">
                                    <div class="user">
                                        <img :src="obs.user_photo" alt="Image 001" class="owner-img"/>
                                        {{ obs.user_name }}
                                    </div>
                                    <div class="desc-date">
                                        <div class="text"><i class="calendar icon"></i>{{ obs.date_create }}</div>
                                        <div class="text"><i class="clock icon"></i>{{ obs.hour_create }}</div>
                                    </div>
                                </div>
                                <p>{{ obs.obs }}</p>
                            </li>
                        </ul>
                    </section>
                </div>
            </row-col-component>
        </row-component>

        <form autocomplete="off">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>
                    <input-text-area :rows="4" v-model="formDateObs.obs"/>
                </row-col-component>
            </row-component>
        </form>

        <button-one @submit="submitObs" :icon="'plus circle'" :name-button="'Adicionar Observação'"/>

        <div class="line-division mt-5 mb-5">
            <h1 class="h1-text">
                <i class="history icon"></i>
                Histórico de interações
            </h1>
        </div>

        <section class="container-desc-two">
            <ul class="task-desc">
                <li class="item" v-for="(historic, index) in formDate.historic" :key="index">
                    <div class="aux-row">
                        <div class="user">
                            <img :src="historic.user_photo" alt="Image 001" class="owner-img"/>
                            {{ historic.name_user }}
                        </div>
                        <div class="desc-date">
                            <div class="text"><i class="calendar icon"></i>{{ historic.date_create }}</div>
                            <div class="text"><i class="clock icon"></i>{{ historic.hour_create }}</div>
                        </div>
                    </div>
                    <p>
                        {{ statusHistoric(historic.status) }},
                        Plataforma: {{ historic.platform }},
                        Navegador: {{ historic.browser }},
                        Endereço IP: {{ historic.ip }}
                    </p>
                </li>
            </ul>
        </section>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonAdd from "@/components/buttons/ButtonAdd"
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";

import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";
import {ACTION_MESSAGES} from "@/configs/constants";
import ButtonRemoveRound from "@/components/buttons/ButtonRemoveRound.vue";

const toast = useToast();

export default {
    name: "OrderServiceUpdate",
    components: {
        ButtonRemoveRound,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        ButtonAdd,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea
    },

    directives: {
        mask,
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Ordem de Serviço')
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/order-of-service/search",
                icon: "reply all",
                name: 'Voltar'
            },

            itensThree: {
                route: "/order-of-service/create",
                icon: "plus",
                name: 'Adicionar'
            },

            buttonCheck: {
                create: false
            },

            formDate: {
                responsible_id: '',
                responsible_name: '',
                date_scheduling: '',
                time_scheduling: '',
                historic: [],
                activitys: [],
                obsPrivate: [],
            },

            formDateObs: {
                id: this.id,
                obs: ''
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateOrderOfService', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        submitObs() {
            this.$store.dispatch('storeOrderOfServiceObs', this.formDateObs)
                .then(() => {
                    this.showError = false
                    this.errors = []
                    this.loadPageItens()
                    this.formDateObs.obs = ''
                    toast.success('Observação criada com sucesso', {timeout: 2000});
                }).catch((r) => {
                this.showError = true;
                this.errors = r.response.data.errors;
                this.scrollTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        loadUser(id) {
            this.$store.dispatch('loadUser', id).then((r) => {
                this.formDate.responsible_id = r.id
                this.formDate.responsible_name = r.name
            })
        },

        loadPageItens() {
            this.$store.dispatch('loadOrderOfService', this.id).then((r) => {
                this.formDate = r;
                this.nullInputArray()
                this.$store.dispatch('loadUserListSelect')
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 32)
                .then((r) => {
                    if (r.data.indexOf('order-of-service-edit') !== -1) {
                        this.showLoader = false
                    }
                    if (r.data.indexOf('order-of-service-create') !== -1) {
                        this.buttonCheck.create = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        statusHistoric(value) {
            if (parseInt(value) === 1)
                return "Cadastro"
            if (parseInt(value) === 2)
                return "Editado"
            if (parseInt(value) === 3)
                return "Iniciou"
            if (parseInt(value) === 4)
                return "Finalizado"
        },

        addActivities() {
            this.formDate.activitys.push({
                activity: ''
            })
        },

        removeActivities(index) {
            this.formDate.activitys.splice(index, 1)
        },

        nullInputArray() {
            if (this.formDate.activitys.length === 0)
                this.addActivities()
        }
    },

    computed: {
        noResultsText(){
          return this.$store.state.var.selectNotSearch
        },

        selectUserList() {
            return this.$store.state.var.selectUserList
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        activitiesAdd() {
            return this.formDate.activitys
        }
    }
}
</script>

  
  
  
