import AdminComponent from "@/components/dashboard/AdminComponent";
import ContractSearch from "@/views/search/ContractSearch";
import ContractCreate from "@/views/create/ContractCreate";
import ContractUpdate from "@/views/update/ContractUpdate";
import ContractShow from "@/views/show/ContractShow";


export default {

    path: '/contract',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'contract-search', component: ContractSearch },
        { path: 'create', name: 'contract-create', component: ContractCreate },
        { path: 'edit/:id', name: 'contract-edit', component: ContractUpdate, props: true },
        { path: 'show/:id', name: 'contract-show', component: ContractShow, props: true },
    ],

}
