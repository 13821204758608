<template>
    <div class="janela" v-if="windowEvent">
        <div class="flag-status-two" :class="[{
                'entrance-color': parseInt(formDateShow.type) === 1,
                'exit-color': parseInt(formDateShow.type) === 2,
            }]">
            <i :class="[{
                    'arrow alternate circle up outline': parseInt(formDateShow.type) === 1,
                    'arrow alternate circle down outline': parseInt(formDateShow.type) === 2,
                }, 'icon']"></i>

            {{ typeString(formDateShow.type) }}
            <div class="close-event" @click="ForModalAccount">X</div>
        </div>

        <form autocomplete="off" class="mt-2">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <div class="aux-row-2">
                        <p>Criado por:</p>
                        <img v-if="formDateShow.photo_user" class="character-label-icon-2"
                             :src="formDateShow.photo_user" alt="">
                        <img v-else class="character-label-icon-2" src="@/assets/img/avatar.png" alt="">
                        {{ formDateShow.name_user }}
                    </div>

                    <div class="aux-row-2">
                        <p>Código:</p>
                        {{ formDateShow.code }}
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <div class="aux-row-3">
                        <div class="text">
                            Data de criação <br> <i class="calendar icon"></i>
                            {{ formDateShow.date_create }}
                        </div>
                        <div class="text">
                            Horário de criação <br> <i class="clock icon"></i>
                            {{ formDateShow.hour_create }}
                        </div>
                    </div>
                </row-col-component>

            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <div class="responsible-task">
                        <h6 v-if="parseInt(historicDate.historic_type) === 1">Baixa realizada por:</h6>
                        <h6 v-if="parseInt(historicDate.historic_type) === 2">Lançamento direto realizada por:</h6>
                        <h6 v-if="parseInt(historicDate.historic_type) === 3">Editado por:</h6>
                        <img v-if="historicDate.historic_photo" class="character-label-icon-2"
                             :src="historicDate.historic_photo" alt="">
                        <img v-else class="character-label-icon-2" src="@/assets/img/avatar.png" alt="">
                        {{ historicDate.historic_name }}
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <div class="responsible-task">
                        <div class="aux-row-3">
                            <div class="text">
                                Data de criação <br> <i class="calendar icon"></i>
                                {{ historicDate.historic_date_create }}
                            </div>
                            <div class="text">
                                Horário de criação <br> <i class="clock icon"></i>
                                {{ historicDate.historic_time_create }}
                            </div>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <div class="responsible-task">
                        <h6>Conta Bancária:</h6>
                        <img v-if="formDateShow.bank_accounts_photo" :src="formDateShow.bank_accounts_photo"
                             class="avatar__image_2" alt="">
                        <img v-else src="@/assets/img/avatar.png" class="avatar__image_2" alt="">
                        {{ formDateShow.bank_accounts_name }}
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <div class="responsible-task">
                        <h6>Valor:</h6>
                        R$ {{ formDateShow.price }}
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <div class="responsible-task">
                        <h6>Vencimento:</h6>
                        {{ formDateShow.due_date }}
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <div class="responsible-task">
                        <h6>Negócio:</h6>
                        {{ businessString(formDateShow.business) }}
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Conta:</label>
                    <multiselect v-model="formDateShow.account_types_id" :searchable="true" disabled
                                 :options="selectSearchAccountType"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Sub Conta:</label>
                    <multiselect v-model="formDateShow.account_type_sub_id" :searchable="true" disabled
                                 :options="selectAccountTypeSubList"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Antecipado:</label>
                    <input-component :name-input="'name'" :type-input="'text'" disabled
                                     v-model="attended"></input-component>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Valor Pago:</label>
                    <input-component :name-input="'name'" v-money="money" :type-input="'text'" disabled
                                     v-model="formDateShow.price_paid">
                    </input-component>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>
                    <input-text-area disabled :rows="4" v-model="formDateShow.obs"></input-text-area>
                </row-col-component>
            </row-component>
        </form>
    </div>

    <painel-component :itens="itensOne">
        <div class="filter-position" v-if="btnFilter">
            <button-one class="btn-filter" icon="filter" @click="filterWindow"/>
        </div>

        <form class="mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                        <label>Código:</label>
                        <input-component :type-input="'text'" v-model="formDate.code"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                        <label>Mês:</label>
                        <multiselect v-model="formDate.month" :searchable="true" :options="selectMonth"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                        <label>Ano:</label>
                        <multiselect v-model="formDate.year" :searchable="true" :options="getYearSelect()"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Negócio:</label>
                        <multiselect v-model="formDate.business" :searchable="true" :options="selectBusiness"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Conta Bancária:</label>
                        <Multiselect v-model="formDate.bank_accounts_id" label="name" :options="selectBankAccountList"
                                     :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                            </template>
                        </Multiselect>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Tipo Entrada / Saída:</label>
                        <multiselect v-model="formDate.type" :searchable="true" :options="selectEntranceExit"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Tipo de Conta:</label>
                        <multiselect v-model="formDate.account_types_id" :searchable="true"
                                     @select="selectSubTypeAccount(formDate.account_types_id)"
                                     :options="selectSearchAccountType"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Sub Tipo de Conta:</label>
                        <multiselect v-model="formDate.account_type_sub_id" :searchable="true"
                                     :options="selectAccountTypeSubList"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Antecipado:</label>
                        <multiselect v-model="formDate.attended" :searchable="true" :options="selectAttended"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'">
                        <button-one class="me-2" @submit="submit" :icon="'search'"/>
                        <button-float :itens="itensTwo" v-if="buttonCheck.create"/>
                    </row-col-component>
                </row-component>
            </transition>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <div class="bg-card-alert alert alert-secondary alert-dismissible fade show" role="alert">
                        <p>Total antecipado: {{ objectReturn.totalQuantAnticipated }}</p>
                        <p>Valor: <span>R${{ objectReturn.totalSumAnticipated }}</span></p>
                        <i class="icon-card-exit  arrow alternate circle down outline icon"></i>
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <div class="bg-card-alert alert alert-secondary alert-dismissible fade show" role="alert">
                        <p>Total de entrada: {{ objectReturn.totalQuantEntrance }}</p>
                        <p>Valor: <span>R${{ objectReturn.totalValueEntrance }}</span></p>
                        <i class="icon-card-entrance  arrow alternate circle up outline icon"></i>
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <div class="bg-card-alert alert alert-secondary alert-dismissible fade show" role="alert">
                        <p>Total de saída: {{ objectReturn.totalQuantExit }}</p>
                        <p>Valor: <span>R${{ objectReturn.totalValueExit }}</span></p>
                        <i class="icon-card-exit arrow alternate circle down outline icon"></i>
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <div class="bg-card-alert alert alert-secondary alert-dismissible fade show" role="alert">
                        <p>Subtotal</p>
                        <p>Valor: <span>R${{ objectReturn.subtotal }}</span></p>
                        <i class="icon-card-entrance money outline icon"></i>
                    </div>
                </row-col-component>
            </row-component>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end'">
                    <div class="toggle_preferences">
                        <button :class="{ 'bg-active': parseInt(cardTable) === 1 }" @click="toggleCard" type="button"
                                tooltip="Card">
                            <i class="window maximize outline icon"></i>
                        </button>
                        <button :class="{ 'bg-active': parseInt(cardTable) === 2 }" @click="toggleTable" type="button"
                                tooltip="Tabela">
                            <i class="align justify icon"></i>
                        </button>
                    </div>
                </row-col-component>
            </row-component>

            <row-component v-if="parseInt(cardTable) === 1">
                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   v-for="(data, index) in object" :key="index">
                    <div class="card-container">
                        <div class="flag-status" :class="[{ 'entrance-color': parseInt(data.type) === 1,
                        'exit-color': parseInt(data.type) === 2}]">

                            <div>
                                <i :class="[{'arrow alternate circle up outline': parseInt(data.type) === 1,
                                'arrow alternate circle down outline': parseInt(data.type) === 2}, 'icon']"></i>
                                {{ typeString(data.type) }}
                            </div>
                            <p v-if="parseInt(data.attended) === 2">Antecipado!</p>
                        </div>

                        <p>Código: {{ data.code }}</p>
                        <h3>Negócio: {{ businessString(data.business) }}</h3>

                        <div class="skills">
                            <p>{{ data.account_types_name }}</p>
                            <div class="text text-center" style="font-weight: bold; font-size: 13px;">
                                {{ data.account_types_sub_name }}
                            </div>
                            <div class="aux-row-3">
                                <div class="text"><i class="calendar icon"></i>{{ data.due_date }}</div>
                                <div class="text"><i class="money icon"></i>R$ {{ data.price_paid }}</div>
                            </div>
                            <div class="aux-row-3">
                                <div class="text">Efetivado em: <i class="calendar icon"></i>{{ data.effective_date }}
                                </div>
                            </div>
                        </div>

                        <div class="responsible-task">
                            <h6>Conta Bancária:</h6>
                            <img v-if="data.bank_accounts_photo" :src="data.bank_accounts_photo"
                                 class="avatar__image_2" alt="">
                            <img v-else src="@/assets/img/avatar.png" alt="" class="avatar__image_2">
                            {{ data.bank_accounts_name }}
                        </div>

                        <div class="buttons">
                            <button tooltip="Ver" class="primary" v-if="buttonCheck.show" @click="showPreview(data.id)">
                                <i class="eye icon"></i>
                            </button>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <table class="table table-striped" v-if="parseInt(cardTable) === 2">
                <thead>
                <tr>
                    <th scope="col" style="width: 5%">Código</th>
                    <th scope="col" style="width: 15%">Conta Bancária</th>
                    <th scope="col" style="width: 15%">Tipo de Conta <br> Tipo de Sub Conta</th>
                    <th scope="col" style="width: 10%">Preço</th>
                    <th scope="col" style="width: 10%">Negócio</th>
                    <th scope="col" style="width: 10%">Entrada / Saída</th>
                    <th scope="col" style="width: 10%">Data de Vencimento <br> Data de Efetivação</th>
                    <th scope="col" style="width: 10%" v-if="buttonCheck.show">Ações</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in object" :key="index">
                    <td data-label="Código">{{ data.code }}</td>
                    <td data-label="Conta Bancária">
                        <img v-if="data.bank_accounts_photo" :src="data.bank_accounts_photo" class="avatar__image_2"
                             alt="">
                        <img v-else src="@/assets/img/avatar.png" class="avatar__image_2" alt="">
                        {{ data.bank_accounts_name }}
                    </td>
                    <td data-label="Tipo de Conta / Tipo de Sub Conta">
                        {{ data.account_types_name }} <br> {{ data.account_types_sub_name }}
                    </td>
                    <td data-label="Preço">{{ data.price_paid }}</td>
                    <td data-label="Negócio">{{ businessString(data.business) }}</td>
                    <td data-label="Entrada / Saída">
                        <i :class="[{
                                'icon-card-entrance-table arrow alternate circle up outline': parseInt(data.type) === 1,
                                'icon-card-exit-table arrow alternate circle down outline': parseInt(data.type) === 2,
                            }, 'icon']"></i>
                    </td>
                    <td data-label="Data de Vencimento / Data de Efetivação">
                        {{ data.due_date }} <br> {{ data.effective_date }}
                    </td>
                    <td data-label="Ações" v-if="buttonCheck.show">
                        <button tooltip="Ver" class="btn btn-secondary button-show" v-if="buttonCheck.show"
                                @click="showPreview(data.id)">
                            <i class="eye icon"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <button-pagination :pagination="objectReturn.itens" :offset="3" @paginate="loadEntrancesExits"/>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import InputComponent from "@/components/form/InputComponent";
import InputTextArea from "@/components/form/InputTextArea"
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";
import {VMoney} from 'v-money'
import {MONEY} from "@/configs/constants"
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "EntranceExitSearch",
    components: {
        PainelComponent,
        ButtonOne,
        ButtonFloat,
        ButtonPagination,
        InputComponent,
        RowComponent,
        RowColComponent,
        LoderSendComponent,
        Multiselect,
        InputTextArea
    },

    directives: {
        money: VMoney
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Entrada / Saída')
        this.$store.dispatch('loadAccountTypeListSelect')
        this.$store.dispatch('loadAccountBankSelect')
        this.loadEntrancesExits(1)
        this.resizeWindow()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            itensTwo: {
                route: "/entrance-exit/create",
                icon: "plus",
                name: ''
            },

            buttonCheck: {
                create: false,
                show: false
            },

            formDate: {
                code: '',
                bank_accounts_id: '',
                account_types_id: '',
                account_type_sub_id: '',
                business: '',
                type: '',
                attended: '',
                month: this.getMonth(),
                year: this.getYear()
            },

            formDateShow: {
                code: '',
                type: '',
                type_preview: '',
                bank_accounts_id: '',
                account_types_id: '',
                account_type_sub_id: '',
                due_date: '',
                price: '',
                price_paid: '',
                obs: '',
                attended: '',
                business: '',
                user_id: '',
                user_name: '',
                photo_user: '',
                name_user: '',
                date_create: '',
                hour_create: '',
                account_types_name: '',
                account_type_sub: '',
                bank_accounts_name: '',
                bank_accounts_photo: '',
                historic: []
            },

            cardTable: '',
            preferences: {
                type: 7,
                preference: ''
            },

            object: {},

            attended: '',
            historicDate: [],
            windowEvent: false,

            money: MONEY,

            filter: true,
            btnFilter: false,
            showLoader: true,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadEntrancesExits(1)
            this.searchSubmit = true;
        },

        loadEntrancesExits(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadEntrancesExits', {...this.params, page})
                    .then((r) => {
                        this.cardTable = r.tableCard
                        this.object = r.itens.data
                        this.showLoader = false
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 17)
                .then((r) => {
                    if (r.data.indexOf('entrance-exit-search') !== -1) {
                        this.showLoader = false

                        if (r.data.indexOf('entrance-exit-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('entrance-exit-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },

        toggleTable() {
            this.userPreferences(2)
            this.cardTable = 2
        },

        toggleCard() {
            this.userPreferences(1)
            this.cardTable = 1
        },

        userPreferences(preference) {
            this.preferences.preference = preference
            this.$store.dispatch('userPreferences', this.preferences)
        },

        ForModalAccount() {
            this.windowEvent = !this.windowEvent
        },

        selectSubTypeAccount(id) {
            this.$store.dispatch('loadAccountTypeSubListSelect', id)
        },

        showPreview(params) {
            this.$store.dispatch('loadEntranceExit', params).then((r) => {
                this.formDateShow = r;
                this.historicDate = this.formDateShow.historic
                this.$store.dispatch('loadAccountTypeSubListSelect', r.account_types_id)
                this.antecipado()
                this.windowEvent = true;
            }).catch(() => {
                this.showLoader = false;
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        businessString(value) {
            if (parseInt(value) === 1)
                return 'Cidade Vertical'

            if (parseInt(value) === 2)
                return 'D7M Marketing'

            if (parseInt(value) === 3)
                return 'Methoduz'
        },

        antecipado() {
            let value = this.formDateShow.attended
            if (parseInt(value) === 1) {
                return this.attended = 'Não'
            }
            return this.attended = 'Sim'
        },

        typeString(type) {
            if (parseInt(type) === 1)
                return 'Entrada'
            return 'Saida'
        },

        getYearSelect() {
            let dataActual = new Date();
            let year = dataActual.getFullYear() + 2;
            let years = [];

            for (let i = 2021; i <= year; i++) {
                years.push(i);
            }
            return years
        },

        getYear() {
            let date = new Date()
            return date.getFullYear()
        },

        getMonth() {
            let date = new Date()
            return date.getMonth() + 1
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectBusiness() {
            return this.$store.state.var.selectBusiness;
        },

        selectMonth() {
            return this.$store.state.var.selectMonth;
        },

        selectSearchAccountType() {
            return this.$store.state.var.selectSearchAccountType;
        },

        selectEntranceExit() {
            return this.$store.state.var.selectEntranceExit;
        },

        selectBankAccountList() {
            return this.$store.state.var.selectBankAccountList
        },

        selectAccountTypeSubList() {
            return this.$store.state.var.selectAccountTypeSubList
        },

        selectAttended() {
            return this.$store.state.var.selectAttended
        },

        objectReturn() {
            return this.$store.state.entrance_exit.items
        },

        params() {
            return {
                page: this.objectReturn.current_page,
                code: this.formDate.code,
                bank_accounts_id: this.formDate.bank_accounts_id,
                account_types_id: this.formDate.account_types_id,
                account_type_sub_id: this.formDate.account_type_sub_id,
                business: this.formDate.business,
                type: this.formDate.type,
                attended: this.formDate.attended,
                month: this.formDate.month,
                year: this.formDate.year
            }
        },
    },
}
</script>

<style scoped src="@/assets/css/table-resp.css"></style>
<style scoped>
.button-show i {
    padding-left: 1px;
}

.bg-card-alert p {
    margin: 0;
}

.bg-card-alert span {
    font-weight: bold;
}

.bg-active {
    background-color: #d67c1c !important;
}

.bg-card-alert {
    background-color: #fdfdfd;
}

.entrance-color {
    background-color: #16ab39;
}

.exit-color {
    background-color: #db2828;
}

.icon-card-entrance-table,
.icon-card-entrance {
    color: #16ab39;
    font-size: 24px;
}

.aux-row-3 .text > i,
button.primary i {
    color: #7e7e7e;
}

.icon-card-exit-table,
.icon-card-exit {
    color: #db2828;
    font-size: 24px;
}

.toggle_preferences > button {
    border: 0;
    width: 45px;
    color: #fff;
    height: 45px;
    margin: 0 5px;
    padding-top: 3px;
    padding-left: 8px;
    border-radius: 50%;
    background-color: #323232;
}

.button-show {
    width: 35px;
    height: 35px;
    font-size: 15px;
    padding: 0.4rem;
    border-radius: 50px;
}

.icon-card-entrance,
.icon-card-exit {
    top: 0;
    right: 0;
    font-size: 2rem;
    margin: 15px 15px;
    position: absolute;
}

.responsible-task .avatar__image_2 {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #d67c1c;
}

.card-container {
    width: 350px;
    color: #2f3032;
    margin: 20px 0;
    max-width: 100%;
    overflow: hidden;
    padding: 50px 0 0;
    position: relative;
    text-align: center;
    border: solid 1px #ededed;
    background-color: #ffffff;
    box-shadow: 10px 10px 15px rgb(0 0 0 / 35%);
}

.card-container h3 {
    color: #424242;
    font-size: 14px;
    font-family: inherit;
    text-transform: uppercase;
}

.buttons {
    min-height: 60px;
    border-top: 1px solid #ededed;
}

button.primary {
    width: 100%;
    height: 60px;
    color: #7e7e7e;
    padding: 5px 25px;
    border: 1px solid #fdfdfd;
    background-color: #f5f5f5;
}

button.primary a {
    color: #7e7e7e;
    padding: 15px 25px;
    text-decoration: none;
}

.skills {
    padding: 15px;
    min-height: 80px;
    text-align: left;
    margin-top: 30px;
    background-color: #f5f5f5;
    border-top: 1px solid #ededed;
}

.responsible-task h6 {
    font-size: 14px;
}

.responsible-task {
    padding: 15px;
    min-height: 80px;
    text-align: left;
    border-top: 1px solid #ededed;
}

.skills p {
    font-size: 1rem;
    text-align: center;
    font-family: 'Comfortaa', sans-serif;
}

.flag-status {
    top: 0;
    width: 100%;
    display: flex;
    color: #ededed;
    text-align: left;
    font-size: 14px;
    position: absolute;
    padding: 10px 20px;
    align-items: center;
    justify-content: space-between;
    font-family: 'Comfortaa', sans-serif;
}

.flag-status-two {
    top: 0;
    width: 100%;
    color: #ededed;
    font-size: 14px;
    text-align: left;
    position: absolute;
    padding: 10px 20px;
    font-family: 'Comfortaa', sans-serif;
}

.aux-row-3 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 15px;
}

.character-label-icon-2 {
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.aux-row-2 {
    display: flex;
    width: 98%;
    align-items: center;
}

.aux-row-2 p {
    margin: 0 10px 0 0;
}

.janela {
    top: 50%;
    left: 50%;
    z-index: 15;
    width: 850px;
    height: auto;
    position: fixed;
    background: white;
    padding: 40px 20px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

@media (max-width: 991px) {
    .janela {
        width: 95%;
        height: 815px;
        overflow: scroll;
    }

    .card-container {
        height: 385px;
        width: 90%;
    }

    .skills {
        flex-direction: column;
        align-items: flex-start;
    }

    .close-event {
        margin: 0 10px 0 0;
    }

    .card-container {
        height: 414px;
    }
}

.janela > .flag-status-two {
    left: 0;
    position: absolute;
}

.close-event {
    top: 0;
    right: 0;
    font-size: 1rem;
    cursor: pointer;
    margin: 10px 20px;
    position: absolute;
}

.multiselect-tag.is-user {
    padding: 5px 8px;
    border-radius: 22px;
    background: #d67c1c;
    margin: 3px 3px 8px;
}

.multiselect-tag.is-user img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    border-radius: 50%;
    border: 2px solid #ffffffbf;
}

.multiselect-tag.is-user i:before {
    color: #ffffff;
    border-radius: 50%;;
}

</style>