import {createStore} from 'vuex'

import Auth from '@/store/modules/auth'
import Preloder from '@/store/modules/preloader'
import Group from '@/store/modules/group'
import Var from '@/store/modules/var'
import User from '@/store/modules/user'
import Client from '@/store/modules/client'
import Employee from '@/store/modules/employee'
import ExternalApi from '@/store/modules/externalApi'
import TaskType from '@/store/modules/task-type'
import GuestSchedule from '@/store/modules/guest-schedule'
import Task from '@/store/modules/task'
import MyTask from '@/store/modules/my-task'
import Schedule from '@/store/modules/schedule'
import MySchedule from '@/store/modules/my-schedule'
import Note from '@/store/modules/note'
import AccountType from '@/store/modules/account-type'
import AccountBank from '@/store/modules/account-bank'
import Provider from '@/store/modules/provider'
import EntranceExitPreview from '@/store/modules/entrance-exit-preview'
import EntranceExit from '@/store/modules/entrance-exit'
import Service from '@/store/modules/service'
import Lead from '@/store/modules/lead'
import ProposalTemplate from '@/store/modules/proposal-template'
import Proposal from '@/store/modules/proposal'
import Product from '@/store/modules/product'
import Inventorie from '@/store/modules/inventorie'
import ContractModel from '@/store/modules/contract-model'
import Contract from '@/store/modules/contract'
import BudgetTemplate from '@/store/modules/budget-template'
import Budget from '@/store/modules/budget'
import Banner from '@/store/modules/banner'
import Spots from '@/store/modules/spots'
import Fleet from '@/store/modules/fleet'
import FleetDepartures from '@/store/modules/fleet-departures'
import OrderService from '@/store/modules/order-service'
import Receipt from '@/store/modules/receipt'
import EmailNotification from '@/store/modules/email-notification'
import ScheduledNote from '@/store/modules/scheduled-note'
import ReportAnalyticalCashFlow from '@/store/modules/report-analytical-cash-flow'
import Home from '@/store/modules/home'
import ContractExpiration from '@/store/modules/contract-expiration'
import CustomerProfile from "@/store/modules/customer-profile";
import Notify from "@/store/modules/notify";

export default createStore({
    modules: {
        auth: Auth,
        preloader: Preloder,
        group: Group,
        var: Var,
        user: User,
        client: Client,
        employee: Employee,
        externalAPi: ExternalApi,
        task_type: TaskType,
        guest_schedule: GuestSchedule,
        task: Task,
        my_task: MyTask,
        schedule: Schedule,
        my_schedule: MySchedule,
        note: Note,
        account_type: AccountType,
        account_bank: AccountBank,
        provider: Provider,
        entrance_exit_preview: EntranceExitPreview,
        entrance_exit: EntranceExit,
        service: Service,
        lead: Lead,
        proposal_template: ProposalTemplate,
        product: Product,
        inventorie: Inventorie,
        contract_model: ContractModel,
        contract: Contract,
        budget_template: BudgetTemplate,
        budget: Budget,
        banner: Banner,
        proposal: Proposal,
        spots: Spots,
        fleet: Fleet,
        fleet_departures: FleetDepartures,
        order_service: OrderService,
        receipt: Receipt,
        email_notification: EmailNotification,
        scheduled_note: ScheduledNote,
        report_analytical_cash_flow: ReportAnalyticalCashFlow,
        home: Home,
        contract_expiration: ContractExpiration,
        customer_profile: CustomerProfile,
        notify: Notify
    },
})