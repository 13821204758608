<template>
    <painel-component :itens="itensOne">
        <div class="filter-position" v-if="btnFilter">
            <button-one icon="filter" @click="filterWindow"/>
        </div>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Código:</label>
                        <input-component :type-input="'text'" v-model="formDate.code"></input-component>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Cliente:</label>
                        <Multiselect v-model="formDate.client_id" label="name" :options="selectClientList"
                                     :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                            </template>
                        </Multiselect>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Tipo da Tarefa:</label>
                        <Multiselect v-model="formDate.type_task_id" :searchable="true" :options="selectTypeTaskList"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <Multiselect v-model="formDate.status" :searchable="true" :options="selectStatusMyTask"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'">
                        <button-one @submit="submit" :icon="'search'"/>
                        <button-float :itens="itensTwo" v-if="buttonCheck.create"/>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end'">
                    <div class="toggle_preferences">
                        <button :class="{ 'bg-active': parseInt(cardTable) === 1 }" @click="toggleCard" type="button"
                                tooltip="Card">
                            <i class="window maximize outline icon"></i>
                        </button>
                        <button :class="{ 'bg-active': parseInt(cardTable) === 2 }" @click="toggleTable" type="button"
                                tooltip="Tabela">
                            <i class="align justify icon"></i>
                        </button>
                    </div>
                </row-col-component>
            </row-component>

            <row-component v-if="parseInt(cardTable) === 1">
                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4'"
                                   v-for="(task, index) in my_task.itens.data" :key="index">
                    <div class="card-container">
                        <div class="flag-status"
                             :class="[{ 'send-color': parseInt(task.status) === 2,
                             'approval-color': parseInt(task.status) === 3,
                             'disapproved-color': parseInt(task.status) === 4,
                             'canceled-color': parseInt(task.status) === 5,
                             'finished-color': parseInt(task.status) === 6}]">

                            <i :class="[{
                                'pen square': parseInt(task.status) === 2,
                                'coffee': parseInt(task.status) === 3,
                                'window close': parseInt(task.status) === 4,
                                'times circle': parseInt(task.status) === 5,
                                'check circle': parseInt(task.status) === 6
                            }, 'icon']"></i>
                            {{ loadStatusTask(task.status) }}
                        </div>

                        <img v-if="task.photo_client" :src="task.photo_client" alt="">
                        <img v-else src="@/assets/img/avatar.png" alt="">

                        <h3>CLIENTE: {{ task.name_client }}</h3>
                        <p>Código: {{ task.code }}</p>
                        <p>{{ sectorString(task.sector) }}</p>

                        <div class="skills">
                            <h6><span>Tarefa:</span> {{ task.name }}</h6>
                            <br>
                            <span class="tag-priority" :class="[{
                                'send-color': parseInt(task.priority) === 1,
                                'bg-warning': parseInt(task.priority) === 2,
                                'disapproved-color': parseInt(task.priority) === 3
                            }]">
                                Prioridade: {{ priorityString(task.priority) }}
                            </span>
                        </div>

                        <div class="buttons">
                            <button tooltip="Ver" class="primary eye" v-if="buttonCheck.show"
                                    @click="showMyTask(task.id)">
                                <i class="eye icon"></i>
                            </button>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <table class="table table-striped" v-if="parseInt(cardTable) === 2">
                <thead>
                <tr>
                    <th scope="col" style="width: 5%">Código</th>
                    <th scope="col" style="width: 5%">Prioridade</th>
                    <th scope="col" style="width: 10%">Cliente</th>
                    <th scope="col" style="width: 5%">Setor</th>
                    <th scope="col" style="width: 10%">Tarefa</th>
                    <th scope="col" style="width: 10%">Status</th>
                    <th scope="col" style="width: 10%" v-if="buttonCheck.edit || buttonCheck.show">
                        Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(task, index) in my_task.itens.data" :key="index">
                    <td data-label="Código">{{ task.code }}</td>
                    <td data-label="Prioridade">
                            <span class="tag-priority" :class="[{
                                'send-color': parseInt(task.priority) === 1,
                                'bg-warning': parseInt(task.priority) === 2,
                                'disapproved-color': parseInt(task.priority) === 3
                            }]">
                                {{ priorityString(task.priority) }}
                            </span>
                    </td>
                    <td data-label="Cliente">
                        <img v-if="task.photo_client" :src="task.photo_client" class="avatar__image_2" alt="">
                        <img v-else src="@/assets/img/avatar.png" class="avatar__image_2" alt="">
                        {{ task.name_client }}
                    </td>
                    <td data-label="Setor">{{ sectorString(task.sector) }}</td>
                    <td data-label="Tarefa">{{ task.name }}</td>
                    <td data-label="Status">{{ loadStatusTask(task.status) }}</td>
                    <td data-label="Ações" v-if="buttonCheck.edit || buttonCheck.show">
                        <button-show-round v-if="buttonCheck.show" :id-param="task.id" :route="'my-task-edit'"/>
                    </td>
                </tr>
                </tbody>
            </table>

            <button-pagination :pagination="my_task.itens" :offset="3" @paginate="loadMyTasks"/>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import InputComponent from "@/components/form/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";

import {useToast} from "vue-toastification";
import ButtonShowRound from "@/components/buttons/ButtonShowRound.vue";

const toast = useToast();

export default {
    name: "MyTaskSearch",
    components: {
        ButtonShowRound,
        PainelComponent,
        ButtonOne,
        ButtonFloat,
        ButtonPagination,
        InputComponent,
        RowComponent,
        RowColComponent,
        LoderSendComponent,
        Multiselect,
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Minhas Tarefas')
        this.$store.dispatch('loadTypeTasksSelect')
        this.$store.dispatch('loadClientListSelect')
        this.loadMyTasks(1)
        this.resizeWindow()
        this.checkACL();
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            itensTwo: {
                route: "/task/create",
                icon: "plus",
                name: ''
            },

            buttonCheck: {
                edit: false,
                delete: false,
                create: false,
                show: false
            },

            formDate: {
                code: '',
                client_id: '',
                type_task_id: '',
                status: ''
            },

            showLoader: true,
            searchSubmit: false,

            idModal: '',
            windowModal: false,
            scroll: true,

            cardTable: '',
            preferences: {
                type: 16,
                preference: ''
            },

            errors: [],
            filter: true,
            btnFilter: false,
            showError: false,
        }
    },

    methods: {
        submit() {
            this.loadMyTasks(1)
            this.searchSubmit = true;
        },

        loadMyTasks(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadMyTasks', {...this.params, page})
                    .then((r) => {
                        this.showLoader = false
                        this.cardTable = r.tableCard
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 9)
                .then((r) => {
                    if (r.data.indexOf('my-task-search') !== -1) {
                        this.showLoader = false
                        if (r.data.indexOf('my-task-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('my-task-search') !== -1) {
                            this.buttonCheck.show = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        showMyTask(id) {
            this.$router.push({name: 'my-task-edit', params: {id: id}})
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },

        toggleTable() {
            this.userPreferences(2)
            this.cardTable = 2
        },

        toggleCard() {
            this.userPreferences(1)
            this.cardTable = 1
        },

        userPreferences(preference) {
            this.preferences.preference = preference
            this.$store.dispatch('userPreferences', this.preferences)
        },

        loadStatusTask(item) {
            if (parseInt(item) === 2)
                return "A fazer"
            if (parseInt(item) === 3)
                return "Enviado para aprovação"
            if (parseInt(item) === 4)
                return "Reprovado (Alteração)"
            if (parseInt(item) === 5)
                return "Cancelado"
            if (parseInt(item) === 6)
                return "Finalizado"
        },

        sectorString(value) {
            if (parseInt(value) === 1)
                return 'Administrativo'
            if (parseInt(value) === 2)
                return 'Criação'
            if (parseInt(value) === 3)
                return 'Comercial'
            if (parseInt(value) === 4)
                return 'Financeiro'
            if (parseInt(value) === 5)
                return 'RH'
            if (parseInt(value) === 6)
                return 'Operacional'
            if (parseInt(value) === 7)
                return 'T.I'
        },

        priorityString(value) {
            if (parseInt(value) === 1)
                return "Normal"
            if (parseInt(value) === 2)
                return "Média"
            if (parseInt(value) === 3)
                return "Alta"
        }
    },


    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectTypeTaskList() {
            return this.$store.state.var.selectTypeTaskList;
        },

        selectClientList() {
            return this.$store.state.var.selectClientList;
        },

        selectSector() {
            return this.$store.state.var.selectSector;
        },

        selectStatusMyTask() {
            return this.$store.state.var.selectStatusMyTask;
        },

        my_task() {
            return this.$store.state.my_task.items
        },

        params() {
            return {
                page: this.my_task.current_page,
                code: this.formDate.code,
                type_task_id: this.formDate.type_task_id,
                client_id: this.formDate.client_id,
                status: this.formDate.status
            }
        },
    },
}
</script>

<style scoped src="@/assets/css/table-resp.css"></style>
<style scoped>
.bg-active {
    background-color: #d67c1c !important;
}

.toggle_preferences > button {
    border: 0;
    width: 45px;
    height: 45px;
    color: #fff;
    margin: 0 5px;
    padding-right: 4px;
    border-radius: 50%;
    background-color: #323232;
}

.tag-priority {
    color: #fff;
    font-size: 11px;
    padding: 0 15px;
    text-align: right;
    font-weight: bold;
    border-radius: 10px;
}

.avatar__image_2 {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #d67c1c;
}

.card-container {
    width: 350px;
    color: #2f3032;
    max-width: 100%;
    overflow: hidden;
    padding: 30px 0 0;
    /*margin: 20px auto;*/
    position: relative;
    text-align: center;
    background-color: #ffffff;
    border: solid 1px #ededed;
    box-shadow: 10px 10px 15px rgb(0 0 0 / 35%);
}

.card-container h3 {
    color: #424242;
    font-size: 14px;
    font-family: inherit;
    text-transform: uppercase;
}

.card-container > img {
    width: 90px;
    padding: 7px;
    height: 90px;
    border-radius: 50%;
    margin: 25px 0 10px;
    border: 1px solid #d67c1c;
}

.buttons {
    display: flex;
    min-height: 60px;
    border-top: 1px solid #ededed;
}

.buttons > a {
    width: 50%;
    height: 60px;
    color: #7e7e7e;
    padding: 5px 25px;
    text-decoration: none;
    background-color: #f5f5f5;
    border: 1px solid #fdfdfd;
}

button.primary {
    width: 100%;
    height: 60px;
    color: #7e7e7e;
    padding: 5px 25px;
    background-color: #f5f5f5;
    border: 1px solid #fdfdfd;
}

button.primary.eye {
    width: 100%;
}

button.primary i {
    color: #7e7e7e;
}

button.primary.eye a {
    color: #7e7e7e;
    text-decoration: none;
    padding: 15px 120px 15px 120px;
}

.skills {
    padding: 15px;
    min-height: 80px;
    text-align: left;
    margin-top: 30px;
    flex-direction: column;
    align-items: flex-start;
    background-color: #f5f5f5;
    border-top: 1px solid #ededed;
}

.skills h6 {
    font-size: 1rem;
    font-family: 'Comfortaa', sans-serif;
}

.skills h6 > span {
    font-weight: bold;
}

.flag-status {
    top: 0;
    width: 100%;
    color: #ededed;
    font-size: 14px;
    text-align: left;
    padding: 10px 20px;
    position: absolute;
    font-family: 'Comfortaa', sans-serif;
}

.create-color {
    background-color: #5a5a5a;
}

.send-color {
    background-color: #16ab39;
}

.approval-color {
    background-color: #2185d0;
}

.disapproved-color {
    background-color: #db2828;
}

.canceled-color {
    background-color: #db2828;
}

.finished-color {
    background-color: #b5cc18;
}
</style>