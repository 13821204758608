<template>
    <painel-component :itens="itensOne">
        <div class="filter-position" v-if="btnFilter">
            <button-one class="btn-filter" @click="filterWindow" icon="filter"/>
        </div>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDate.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'">
                        <button-one class="me-2" @submit="submit" :icon="'search'"/>
                        <button-float :itens="itensTwo" v-if="buttonCheck.create"/>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <table class="table table-striped">
                <thead>
                <tr>
                    <th scope="col" style="width: 40%">Nome</th>
                    <th scope="col" style="width: 40%">Status</th>
                    <th scope="col" style="width: 20%"
                        v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.delete">Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(group, index) in groups.data" :key="index">
                    <td data-label="Nome">{{ group.name }}</td>
                    <td data-label="Status">{{ statusString(group.status) }}</td>
                    <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.delete">
                        <button-show-round :route="'group-user-show'" :id-param="group.id" v-if="buttonCheck.show"/>
                        <button-edit-round :route="'group-user-edit'" :id-param="group.id" v-if="buttonCheck.edit"/>
                        <button-remove-round @deleteSubmit="deleteSubmit(group)" v-if="buttonCheck.delete"/>
                    </td>
                </tr>
                </tbody>
            </table>

            <button-pagination :pagination="groups" :offset="3" @paginate="loadItems"/>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import InputComponent from "@/components/form/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonShowRound from "@/components/buttons/ButtonShowRound";
import ButtonEditRound from "@/components/buttons/ButtonEditRound";
import ButtonRemoveRound from "@/components/buttons/ButtonRemoveRound";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";
import {ACTION_MESSAGES} from "@/configs/constants";
import swal from 'sweetalert';
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "GroupUserSearch",
    components: {
        PainelComponent,
        ButtonOne,
        ButtonFloat,
        ButtonPagination,
        InputComponent,
        RowComponent,
        RowColComponent,
        ButtonShowRound,
        ButtonEditRound,
        ButtonRemoveRound,
        LoderSendComponent,
        Multiselect,
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Grupo de usuário')
        this.loadItems(1)
        this.resizeWindow()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            itensTwo: {
                route: "/group-user/create",
                icon: "plus",
                name: ''
            },

            buttonCheck: {
                edit: false,
                delete: false,
                create: false,
                show: false
            },

            formDate: {
                name: '',
                status: ''
            },

            filter: true,
            btnFilter: false,

            showLoader: true,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadGroups', {...this.params, page})
                    .then((r) => {
                        this.showLoader = false
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 1)
                .then((r) => {

                    if (r.data.indexOf('group-user-search') !== -1) {
                        this.showLoader = false

                        if (r.data.indexOf('group-user-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('group-user-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('group-user-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                        if (r.data.indexOf('group-user-create') !== -1) {
                            this.buttonCheck.create = true
                        }


                    } else {
                        this.$router.push({name: 'home'})
                    }


                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        deleteSubmit(item) {
            if (parseInt(item.id) === parseInt(this.me.group_id)) {
                toast.info("Você não pode excluir o próprio grupo!", {timeout: 2000});
                return false
            }
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar  ${item.name}, ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyGroupUser', item.id).then(() => {
                        this.loadItems(1)
                        toast.success(ACTION_MESSAGES.delete, {timeout: 10000});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 10000});
                    })
                }
            });
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return 'Ativo'
            return 'Inativo'
        },
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        noResultsText() {
            return this.$store.state.var.selectNotSearch;
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactiveSearch;
        },

        groups() {
            return this.$store.state.group.items
        },

        params() {
            return {
                page: this.groups.current_page,
                name: this.formDate.name,
                status: this.formDate.status
            }
        },
    },
}
</script>

<style scoped src="@/assets/css/table-resp.css"></style>