<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.type_task_id }">
                    <label>Tipo de Tarefa:</label>
                    <Multiselect v-model="formDate.type_task_id" :searchable="true" :options="selectTypeTaskList"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.responsible_task_user }">
                    <label>Responsável pela Tarefa:</label>
                    <Multiselect v-model="formDate.responsible_task_user" label="name" :options="selectUserList"
                                 :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.client_id }">
                    <label>Cliente:</label>
                    <Multiselect v-model="formDate.client_id" label="name" :options="selectClientList"
                                 :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.priority }">
                    <label>Prioridade:</label>
                    <Multiselect v-model="formDate.priority" :searchable="true" :options="selectPriority"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.quantity }">
                    <label>Quantidade:</label>
                    <input-number-component v-model="formDate.quantity" :model-value="formDate.quantity">
                    </input-number-component>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Data de Entrega:</label>
                    <input-component :type-input="'text'" v-model="formDate.date_delivery"
                                     :model-value="formDate.date_delivery"
                                     v-mask="'##/##/####'">
                    </input-component>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Hora entrega:</label>
                    <input-component :type-input="'text'" v-model="formDate.time_delivery"
                                     :model-value="formDate.time_delivery"
                                     v-mask="'##:##:##'">
                    </input-component>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Precisa de aprovação ?</label>
                    <input type="radio" id="aprovadoN" name="aprovacao" value="1" v-model="formDate.approve">
                    <label for="aprovadoN">Não</label>

                    <input type="radio" id="aprovadoS" name="aprovacao" value="2" checked v-model="formDate.approve">
                    <label for="aprovadoS">Sim</label>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Informar local do arquivo ?</label>
                    <input type="radio" id="arquivoN" value="1" name="arquivo" v-model="formDate.inform_file_location">
                    <label for="arquivoN">Não</label>

                    <input type="radio" id="arquivoS" value="2" name="arquivo" checked
                           v-model="formDate.inform_file_location">
                    <label for="arquivoS">Sim</label>
                </row-col-component>
            </row-component>

            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                               :class="{ 'formError': errors.desc }">
                <label>Descrição:</label>
                <QuillEditor :modules="modules" theme="snow" :toolbar="toolbarOptions" :contentType="'html'"
                             v-model:content="formDate.desc"/>
            </row-col-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one class="w-100" @submit="submit" :icon="'plus circle'" :name-button="'Adicionar'"
                                :class="{ 'preloderSend': sendForm }"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-float class="w-100" :itens="itensTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputNumberComponent from "@/components/form/InputNumberComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import {mask} from 'vue-the-mask'
import {ACTION_MESSAGES} from "@/configs/constants";
import {useToast} from "vue-toastification";
import ImageUploader from "quill-image-uploader";
import axios from "axios";

const toast = useToast();

export default {
    name: "TaskTypeCreate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputNumberComponent,
        QuillEditor
    },

    directives: {mask},

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Tarefa')
        this.$store.dispatch('loadTypeTasksSelect')
        this.$store.dispatch('loadUserListSelect')
        this.$store.dispatch('loadClientListSelect')
        this.checkACL()
    },

    setup(props) {
        const modules = {
            name: "imageUploader",
            module: ImageUploader,
            options: {
                upload: file => {
                    return new Promise((resolve, reject) => {
                        const formData = new FormData()
                        formData.append("file", file)
                        formData.append("id", props.id)
                        axios.post(`/api/v1/admin/task-file`, formData, {
                            headers: {'Content-Type': 'multipart/form-data'},
                            responseType: 'json'
                        }).then(res => {
                            resolve(res.data[0].name_encoded);
                        }).catch(() => {
                            reject("Upload failed");
                        })
                    })
                },
            }
        }

        return {
            modules
        }
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/task/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                id: this.id,
                type_task_id: "",
                platform: "",
                responsible_task_user: "",
                client_id: "",
                priority: "",
                quantity: "",
                inform_file_location: "2",
                approve: "2",
                date_delivery: this.dateCurrent(),
                time_delivery: this.timeCurrent(),
                desc: "",
                sector: ''
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,

            toolbarOptions: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],
                [{'header': 1}, {'header': 2}],               // custom button values
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
                [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
                [{'direction': 'rtl'}],                         // text direction
                [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                [{'font': []}],
                [{'align': []}],
                ['clean']                                         // remove formatting button
            ],
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateTask', this.formDate)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                        this.$router.push({name: 'task-edit', params: {id: r.data}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        dateCurrent() {
            let currentTime = new Date();
            if (currentTime.getDay() === 5) {
                currentTime.setDate(currentTime.getDate() + 3)
            }

            if (currentTime.getDay() === 4) {
                currentTime.setDate(currentTime.getDate() + 4)
            } else {
                currentTime.setDate(currentTime.getDate() + 2)
            }

            let day = currentTime.getDate();
            let month = currentTime.getMonth() + 1;
            let year = currentTime.getFullYear();

            // pad numbers with zero if necessary
            day = (day < 10 ? '0' : '') + day;
            month = (month < 10 ? '0' : '') + month;

            return `${day}/${month}/${year}`
        },

        timeCurrent() {
            let currentTime = new Date();
            let currentHours = currentTime.getHours();
            let currentMinutes = currentTime.getMinutes();
            let currentSeconds = currentTime.getSeconds();

            // pad numbers with zero if necessary
            currentHours = (currentHours < 10 ? '0' : '') + currentHours;
            currentMinutes = (currentMinutes < 10 ? '0' : '') + currentMinutes;
            currentSeconds = (currentSeconds < 10 ? '0' : '') + currentSeconds;
            return `${currentHours}:${currentMinutes}:${currentSeconds}`
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 8)
                .then((r) => {
                    if (r.data.indexOf('task-create') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectPlatform() {
            return this.$store.state.var.selectPlatform;
        },

        selectPriority() {
            return this.$store.state.var.selectPriority;
        },

        selectTypeTaskList() {
            return this.$store.state.var.selectTypeTaskList;
        },

        selectUserList() {
            return this.$store.state.var.selectUserList;
        },

        selectClientList() {
            return this.$store.state.var.selectClientList;
        },

        selectSector() {
            return this.$store.state.var.selectSector;
        },
    }
}
</script>

<style scoped>
.character-label-icon,
.character-option-icon {
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}
</style>