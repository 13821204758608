<template>
    <painel-component :itens="itensOne">
        <button-add :itens="itensThree" v-if="buttonCheck.create"/>
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component :name-input="'name'" :type-input="'text'" v-model="formDate.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <Multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText" :name="'status'"/>
                </row-col-component>
            </row-component>

            <div class="clearfix my-3">
                <button-one icon="plus" class="rounded-0 float-end" @click="addNewFormats"/>
            </div>

            <div class="border-content" v-for="(format, index) in formatsAdd" :key="index">
                <form>
                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <label>Resolução:</label>
                            <input-component :type-input="'text'" v-model="format.resolution"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <label>Formato:</label>
                            <Multiselect v-model="format.format" :searchable="true" :options="selectFormat"
                                         :noResultsText="noResultsText" :noOptionsText="noResultsText" :name="'status'"/>
                        </row-col-component>

                        <button-remove-round v-if="formatsAdd.length > 1" @click="formatsRemove(index)"
                                             class="rounded-0 position-absolute end-0 bottom-0 my-2"/>
                    </row-component>
                </form>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>
                    <input-text-area v-model="formDate.desc" :model-value="formDate.desc" :rows="4"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one class="w-100" @submit="submit" :icon="'save'" :name-button="'Salvar'"
                                :class="{ 'preloderSend': sendForm }"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-float class="w-100" :itens="itensTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonAdd from "@/components/buttons/ButtonAdd"
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea.vue";
import {useToast} from "vue-toastification";
import {ACTION_MESSAGES} from "@/configs/constants";
import ButtonRemoveRound from "@/components/buttons/ButtonRemoveRound.vue";

const toast = useToast();

export default {
    name: "TaskTypeUpdate",
    components: {
        ButtonRemoveRound,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        ButtonAdd,
        Multiselect,
        ErrorComponent,
        InputTextArea,
        LoderSendComponent,
    },

    created() {
        this.loadPageItens()
        this.checkACL()
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/task-type/search",
                icon: "reply all",
                name: 'Voltar'
            },

            itensThree: {
                route: "/task-type/create",
                icon: "plus",
                name: 'Adicionar'
            },

            buttonCheck: {
                create: false,
            },

            formDate: {
                name: '',
                status: '',
                desc: '',
                resolutions_formats: []
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateTaskType', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        scrollTop() {
            window.scrollTo(0, 0);
        },

        nullInputArray() {
            if (this.formDate.resolutions_formats.length === 0)
                this.addNewFormats()
        },

        addNewFormats() {
            this.formDate.resolutions_formats.push({
                resolution: '',
                format: '',
            })
        },

        formatsRemove(index) {
            this.formDate.resolutions_formats.splice(index, 1)
        },

        loadPageItens() {
            this.$store.dispatch('updateNamePage', 'Tipos de Tarefa')
            this.$store.dispatch('loadTaskType', this.id).then((r) => {
                this.formDate = r;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            }).finally(() => this.nullInputArray())
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 6)
                .then((r) => {
                    if (r.data.indexOf('task-type-edit') !== -1) {
                        this.showLoader = false
                    }
                    if (r.data.indexOf('task-type-create') !== -1) {
                        this.buttonCheck.create = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            });
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        selectFormat() {
            return this.$store.state.var.selectFormat;
        },

        formatsAdd() {
            return this.formDate.resolutions_formats;
        },

    },
}
</script>

<style scoped>
.border-content {
    height: auto;
    padding: 25px 10px;
    position: relative;
    background: #ebebeb;
}
</style>