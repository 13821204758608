import state from "@/store/modules/contract-model/state";
import actions from "@/store/modules/contract-model/actions";
import mutations from "@/store/modules/contract-model/mutations";
import getters from "@/store/modules/contract-model/getters";

export default {
  state,
  actions,
  mutations,
  getters
}