<template>
    <painel-component :itens="itensOne">
        <button-add :itens="itensThree" v-if="buttonCheck.create"/>
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off" v-else>
            <div class="aux mb-5 mt-3">
                <div class="avatar-form">
                    <img v-if="formDate.fleet_photo" :src="formDate.fleet_photo" class="avatar__image" alt="">
                    <img v-else src="@/assets/img/avatar.png" class="avatar__image" alt="">
                </div>
                <p>{{ formDate.name }}</p>
                <router-link :to="{ name: 'fleet-update-photo', id: this.id }" class="figure">
                    <i class="camera icon"></i>
                </router-link>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.brand_model }">
                    <label>Marca/Modelo:</label>
                    <input-component :type-input="'text'" v-model="formDate.brand_model"
                                     :model-value="formDate.brand_model"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Renavam:</label>
                    <input-component :type-input="'text'" v-model="formDate.reindeer" :model-value="formDate.reindeer"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Chassi:</label>
                    <input-component :type-input="'text'" v-model="formDate.chassis" :model-value="formDate.chassis"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Nº Documento:</label>
                    <input-component :type-input="'text'" v-model="formDate.document" :model-value="formDate.document"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Cor:</label>
                    <input-component :type-input="'text'" v-model="formDate.color" :model-value="formDate.color"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Placa:</label>
                    <input-component :type-input="'text'" v-model="formDate.board" :model-value="formDate.board"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Ano:</label>
                    <input-component :type-input="'text'" v-model="formDate.year" :model-value="formDate.year"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Tipo de combustível:</label>
                    <multiselect v-model="formDate.fuel" :searchable="true" :options="selectTypeFuel"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.km }">
                    <label>KM:</label>
                    <input-number-component :type-input="'number'" min="1" v-model="formDate.km"
                                            :model-value="formDate.km"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one class="w-100" @submit="submit" :icon="'save'" :name-button="'Salvar'"
                                :class="{ 'preloderSend': sendForm }"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-float class="w-100" :itens="itensTwo"/>
                </row-col-component>
            </row-component>
        </form>

        <div class="line-division mt-5 mb-5">
            <h1 class="h1-text"><i class="comment icon"></i>Observações</h1>
        </div>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Observações:</label>

                <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                     v-if="formDate.obsPrivate.length === 0">
                    <p>Não há nada cadastrado</p>
                </div>

                <div class="merito" v-else>
                    <section class="container-desc">
                        <ul class="task-desc">
                            <li class="item" v-for="(obs, index) in formDate.obsPrivate" :key="index">
                                <div class="aux-row">
                                    <div class="user">
                                        <img :src="obs.user_photo" alt="Image 001" class="owner-img"/>
                                        {{ obs.user_name }}
                                    </div>
                                    <div class="desc-date">
                                        <div class="text"><i class="calendar icon"></i>{{ obs.date_create }}</div>
                                        <div class="text"><i class="clock icon"></i>{{ obs.hour_create }}</div>
                                    </div>
                                </div>
                                <p>{{ obs.obs }}</p>
                            </li>
                        </ul>
                    </section>
                </div>
            </row-col-component>
        </row-component>

        <form autocomplete="off">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>
                    <input-text-area :rows="4" v-model="formDateObs.obs"/>
                </row-col-component>
            </row-component>
        </form>
        <button-one @submit="submitObs" :icon="'plus circle'" :name-button="'Adicionar Observações'"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputNumberComponent from "@/components/form/InputNumberComponent";
import InputComponent from "@/components/form/InputComponent";
import InputTextArea from "@/components/form/InputTextArea";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonAdd from "@/components/buttons/ButtonAdd"
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";

import {useToast} from "vue-toastification";
import {ACTION_MESSAGES} from "@/configs/constants";

const toast = useToast();

export default {
    name: "FleetUpdate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputNumberComponent,
        InputComponent,
        InputTextArea,
        ButtonFloat,
        ButtonOne,
        ButtonAdd,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Frota')
        this.loadPageItens()
        this.checkACL()
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/fleet/search",
                icon: "reply all",
                name: 'Voltar'
            },

            itensThree: {
                route: "/fleet/create",
                icon: "plus",
                name: 'Adicionar'
            },

            formDate: {
                brand_model: '',
                year: '',
                reindeer: '',
                color: '',
                chassis: '',
                document: '',
                board: '',
                fuel: '',
                km: '',
                status: '',
                obsPrivate: ''
            },

            formDateObs: {
                id: this.id,
                obs: '',
            },

            buttonCheck: {
                create: false
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateFleet', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        submitObs() {
            this.$store.dispatch('storeFleetObs', this.formDateObs)
                .then(() => {
                    this.showError = false
                    this.errors = []
                    this.loadPageItens()
                    this.formDateObs.obs = ''
                    toast.success('Observção adicionada com sucesso!', {timeout: 2000});
                }).catch((r) => {
                this.showError = true;
                this.errors = r.response.data.errors;
                this.scrollTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        loadPageItens() {
            this.$store.dispatch('loadFleet', this.id).then((r) => {
                this.formDate = r;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 30)
                .then((r) => {
                    if (r.data.indexOf('fleet-edit') !== -1) {
                        this.showLoader = false
                    }
                    if (r.data.indexOf('fleet-create') !== -1) {
                        this.buttonCheck.create = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        selectTypeFuel() {
            return this.$store.state.var.selectTypeFuel
        }
    }
}
</script>

<style scoped>
.avatar-form::after,
.avatar-form::before,
.avatar-form {
    border-radius: 0;
    width: 200px;
}

.avatar__image {
    object-fit: cover;
    border-radius: 0;
}

.figure {
    left: 170px;
}
</style>