export default {

    icon: 'globe icon',
    name: 'Site ',
    subLinks: [

        {
            name: 'Banner',
            route: '/banner/search',
            pagePermissions: [
                'banner-search',
                'banner-create',
                'banner-edit',
                'banner-delete'
            ],
        },

        {
            name: 'Pontos',
            route: '/spots/search',
            pagePermissions: [
                'spot-search',
                'spot-create',
                'spot-edit'
            ],
        },
    ],

    pageLinksNavCheck: [
        'banner-search',
        'banner-create',
        'banner-edit',
        'banner-delete',
        'spot-search',
        'spot-create',
        'spot-edit'
    ],

}