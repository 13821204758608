import state from "@/store/modules/spots/state";
import actions from "@/store/modules/spots/actions";
import mutations from "@/store/modules/spots/mutations";
import getters from "@/store/modules/spots/getters";

export default {
  state,
  actions,
  mutations,
  getters
}