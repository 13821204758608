<template>
  <painel-component :itens="itensOne">
    <error-component :errors="errors" v-if="showError"/>
    <loder-send-component :text-title="'Carregando'" v-if="showLoder"/>

    <div class="tabs effect-3" v-else>
      <input type="radio" id="tab-1" name="tab-effect-3" checked="checked"
             :class="{ 'mobileTab-client': tabMobile }">
      <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

      <input type="radio" id="tab-2" name="tab-effect-3" v-if="picked == '2'">
      <span v-if="picked == '2'">
                 <i class="user circle icon"></i>
                 <p>Sócio</p>
            </span>

      <input type="radio" id="tab-3" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
      <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="envelope icon"></i>
                <p>Email</p>
            </span>

      <input type="radio" id="tab-4" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
      <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="phone square icon"></i>
                <p>Telefone</p>
            </span>

      <input type="radio" id="tab-5" name="tab-effect-3" v-if="picked == '2'">
      <span v-if="picked == '2'">
                <i class="tasks icon"></i>
                <p>Atividades econômicas secundárias</p>
            </span>

      <div class="line ease" :class="{ 'line-3': tabMobile }"></div>
      <div class="tab-content">
        <section id="tab-item-1">
          <form>
            <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                               :class="{ 'formError': errors.type }">
              <input type="radio" id="cpf" v-model="formDate.type" value="1" @change="CheckPfPj">
              <label for="cpf">Pessoa Física</label>

              <input type="radio" id="cnpj" v-model="formDate.type" value="2" checked @change="CheckPfPj">
              <label for="cnpj">Pessoa Jurídica</label>
            </row-col-component>

            <div v-if="picked == '2'">
              <row-component>
                <row-col-component :class="{ 'formError': errors.cnpj }"
                                   :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                  <label>CNPJ:</label>
                  <input-component :type-input="'text'" v-model="formDate.cnpj" @change="searchCNPJ"
                                   :modelValue="formDate.cnpj" v-mask="'##.###.###/####-##'"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                  <label>Porte:</label>
                  <input-component :type-input="'text'" :modelValue="formDate.size"
                                   v-model="formDate.size"/>
                </row-col-component>

                <row-col-component :class="{ 'formError': errors.service }"
                                   :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                  <label>Serviço:</label>
                  <Multiselect v-model="formDate.service" :searchable="true"
                               :options="selectServiceClient"
                               :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.status }">
                  <label>Status:</label>
                  <Multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                               :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component :class="{ 'formError': errors.name }"
                                   :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-10 col-sm-12 col-12'">
                  <label>Nome:</label>
                  <input-component :type-input="'text'" :modelValue="formDate.name"
                                   v-model="formDate.name"/>
                </row-col-component>

                <row-col-component :class="{ 'formError': errors.name }"
                                   :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                  <label>Parceiro:</label>
                  <Multiselect v-model="formDate.partner" :searchable="true" :options="selectAttended"
                               :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component
                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                  <label>Nome Fantasia:</label>
                  <input-component :type-input="'text'" :modelValue="formDate.name_f"
                                   v-model="formDate.name_f"/>
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component
                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.surname }">
                  <label>Apelido:</label>
                  <input-component :type-input="'text'" :modelValue="formDate.surname"
                                   v-model="formDate.surname"/>
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component
                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                  <label>Natureza Jurídica:</label>
                  <input-component :type-input="'text'" :modelValue="formDate.legal_nature"
                                   v-model="formDate.legal_nature"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                  <label>Status da Empresa:</label>
                  <input-component :type-input="'text'" :modelValue="formDate.company_status"
                                   v-model="formDate.company_status"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                  <label>Capital Social:</label>
                  <input-component :type-input="'text'" :modelValue="formDate.share_capital"
                                   v-money="money" v-model="formDate.share_capital"/>
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component
                    :class-row-col="'col-xxl-8 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                  <label>Atividade Econômica Principal:</label>
                  <input-component :type-input="'text'" :modelValue="formDate.main_activity"
                                   v-model="formDate.main_activity"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                  <label>Data de Abertura:</label>
                  <input-component :type-input="'text'" :modelValue="formDate.date_open"
                                   v-model="formDate.date_open" v-mask="'##/##/####'"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                  <label>Matriz:</label>
                  <input-component :type-input="'text'" :modelValue="formDate.headquarters"
                                   v-model="formDate.headquarters"/>
                </row-col-component>
              </row-component>
            </div>

            <div v-else>
              <row-component>
                <row-col-component
                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.name }">
                  <label>Nome:</label>
                  <input-component :type-input="'text'" :modelValue="formDate.name"
                                   v-model="formDate.name"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.surname }">
                  <label>Apelido:</label>
                  <input-component :type-input="'text'" :modelValue="formDate.surname"
                                   v-model="formDate.surname"/>
                </row-col-component>

                <row-col-component :class="{ 'formError': errors.service }"
                                   :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                  <label>Serviço:</label>
                  <Multiselect v-model="formDate.service" :searchable="true"
                               :options="selectServiceClient"
                               :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>




              </row-component>

              <row-component>
                <row-col-component
                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.cpf }">
                  <label>CPF:</label>
                  <input-component :type-input="'text'" v-model="formDate.cpf"
                                   v-mask="'###.###.###-##'"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                  <label>RG:</label>
                  <input-component :type-input="'text'" v-model="formDate.rg"
                                   v-mask="['##.###.###-X', '#.###.###']"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                  <label>Data Nasc:</label>
                  <input-component :type-input="'text'" v-model="formDate.birth_date"
                                   v-mask="'##/##/####'"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.status }">
                  <label>Status:</label>
                  <Multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                               :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>
              </row-component>
            </div>

            <row-component>
              <row-col-component
                  :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>CEP:</label>
                <input-component :type-input="'text'" :modelValue="formDate.cep" v-model="formDate.cep"
                                 @change="searchApiCEP" v-mask="'#####-###'"/>
              </row-col-component>

              <row-col-component
                  :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Cidade:</label>
                <input-component :type-input="'text'" :modelValue="formDate.city"
                                 v-model="formDate.city"/>
              </row-col-component>

              <row-col-component
                  :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Estado:</label>
                <input-component :type-input="'text'" :modelValue="formDate.stateView"
                                 v-model="formDate.stateView"/>
              </row-col-component>

              <row-col-component
                  :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Bairro:</label>
                <input-component :type-input="'text'" :modelValue="formDate.district"
                                 v-model="formDate.district"/>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                  :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                <label>Endereço:</label>
                <input-component :type-input="'text'" :modelValue="formDate.address"
                                 v-model="formDate.address"/>
              </row-col-component>

              <row-col-component
                  :class-row-col="'col-xxl-3  col-xl-3  col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>País:</label>
                <input-component :type-input="'text'" :modelValue="formDate.country"
                                 v-model="formDate.country"/>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                  :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Refêrencia:</label>
                <input-text-area :modelValue="formDate.complement" v-model="formDate.complement"
                                 :rows="4"/>
              </row-col-component>
            </row-component>
          </form>
        </section>

        <section id="tab-item-2">
          <div class="container-1">
            <button class="addButton" type="button" @click="addNewMembers">
              <i class="plus icon"></i>
            </button>
          </div>

          <div class="border-content" v-for="(member, index) in membersAdd" :key="index">
            <form>
              <button class="deleteButton" type="button" @click="membersRemove(index)" v-if="index > 0">
                <i class="trash icon"></i>
              </button>

              <row-component>
                <row-col-component
                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                  <label>Nome:</label>
                  <input-component :type-input="'text'" v-model="member.name"/>
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component
                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                  <label>CPF/CNPJ:</label>
                  <input-component :type-input="'text'" v-model="member.cpf_cnpj"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                  <label>Idade:</label>
                  <input-component :type-input="'text'" v-model="member.age"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                  <label>Qualificação:</label>
                  <input-component :type-input="'text'" v-model="member.qualification"/>
                </row-col-component>
              </row-component>
            </form>
          </div>
        </section>

        <section id="tab-item-3">
          <div class="container-1">
            <button class="addButton" type="button" @click="addNewEmail">
              <i class="plus icon"></i>
            </button>
          </div>

          <div class="border-content" v-for="(email, index) in emailsAdd" :key="index">
            <form>
              <button class="deleteButton" type="button" @click="emailsRemove(index)" v-if="index > 0">
                <i class="trash icon"></i>
              </button>

              <row-component>
                <row-col-component
                    :class-row-col="'col-xxl-11 col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12 mt-2 mb-2 '">
                  <label>Receber notificação</label>
                  <input type="radio" :id="'nao-email' + index" v-model="email.notification" value="1"
                         checked>
                  <label :for="'nao-email' + index">Não</label>
                  <input type="radio" :id="'sim-email' + index" v-model="email.notification"
                         value="2">
                  <label :for="'sim-email' + index">Sim</label>
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component
                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                  <label>Nome:</label>
                  <input-component :type-input="'text'" v-model="email.name"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                  <label>Email:</label>
                  <input-component :type-input="'email'" v-model="email.email"/>
                </row-col-component>
              </row-component>
            </form>
          </div>
        </section>

        <section id="tab-item-4">
          <div class="container-1">
            <button class="addButton" type="button" @click="addNewPhones">
              <i class="plus icon"></i>
            </button>
          </div>

          <div class="border-content" v-for="(phone, index) in phonesAdd" :key="index">
            <form>
              <button class="deleteButton" type="button" @click="phonesRemove(index)" v-if="index > 0">
                <i class="trash icon"></i>
              </button>

              <row-col-component
                  :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-2 mb-2'">
                <label>Receber notificação</label>
                <input type="radio" :id="'nao-phone' + index" name="teste" v-model="phone.notification"
                       value="1" checked>
                <label :for="'nao-phone' + index">Não</label>
                <input type="radio" :id="'sim-phone' + index" name="teste" v-model="phone.notification"
                       value="2">
                <label :for="'sim-phone' + index">Sim</label>
              </row-col-component>

              <row-component>
                <row-col-component
                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                  <label>Nome:</label>
                  <input-component :type-input="'text'" v-model="phone.name"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                  <label>Telefone:</label>
                  <input-component :type-input="'text'" v-model="phone.phone"
                                   v-mask="['(##) ####-####', '(##) #####-####']"/>
                </row-col-component>
              </row-component>
            </form>
          </div>
        </section>

        <section id="tab-item-5">
          <div class="container-1">
            <button class="addButton" type="button" @click="addNewActivitys">
              <i class="plus icon"></i>
            </button>
          </div>

          <div class="border-content" v-for="(secondary_activity, index) in activityAdd" :key="index">
            <form>
              <button class="deleteButton" type="button" @click="activitysRemove(index)" v-if="index > 0">
                <i class="trash icon"></i>
              </button>

              <row-component>
                <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                  <label>Código:</label>
                  <input-component :type-input="'text'" v-model="secondary_activity.code"
                                   :modelValue="secondary_activity.code"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'">
                  <label>Descrição:</label>
                  <input-component :type-input="'text'" v-model="secondary_activity.desc"
                                   :modelValue="secondary_activity.desc"/>
                </row-col-component>
              </row-component>
            </form>
          </div>
        </section>

        <row-component>
          <row-col-component
              :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
            <button-one class="w-100" @submit="submit" :icon="'plus circle'" :name-button="'Adicionar'"
                        :class="{ 'preloderSend': sendForm }"/>
          </row-col-component>
          <row-col-component
              :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
            <button-float class="w-100" :itens="itensTwo"/>
          </row-col-component>
        </row-component>
      </div>
    </div>
  </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import {ESTADOS} from "@/configs/constants"
import {MONEY} from "@/configs/constants"
import {ACTION_MESSAGES} from "@/configs/constants";
import {mask} from 'vue-the-mask'
import {VMoney} from 'v-money'
import {useToast} from "vue-toastification";
import InputTextArea from "@/components/form/InputTextArea";

const toast = useToast();

export default {
  name: "ClientCreate",
  components: {
    PainelComponent,
    RowComponent,
    RowColComponent,
    InputComponent,
    ButtonFloat,
    ButtonOne,
    Multiselect,
    ErrorComponent,
    LoderSendComponent,
    InputTextArea
  },

  directives: {
    mask,
    money: VMoney
  },

  created() {
    this.$store.dispatch('updateNamePage', 'Cliente')
    this.checkACL()
  },

  data() {
    return {
      itensOne: {
        title: "Adicionar",
        icon: "pen square"
      },

      itensTwo: {
        route: "/client/search",
        icon: "reply all",
        name: 'Voltar'
      },

      formDate: {
        cpf: '',
        cnpj: '',
        size: '',
        name: '',
        name_f: '',
        surname: '',
        legal_nature: '',
        share_capital: '',
        main_activity: '',
        date_open: '',
        birth_date: '',
        headquarters: '',
        rg: '',
        type: '2',
        company_status: '',
        emails: [],
        phones: [],
        members: [],
        secondary_activitys: [],
        cep: '',
        city: '',
        state: '',
        stateView: '',
        district: '',
        address: '',
        complement: '',
        country: '',
        status: 1,
        service: '',
        partner: 1
      },

      money: MONEY,

      errors: [],
      showError: false,
      sendForm: false,
      showLoder: true,

      picked: '2',
    }
  },

  methods: {
    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store.dispatch('storeClient', this.formDate)
            .then((r) => {
              this.showError = false
              this.sendForm = false
              toast.success(ACTION_MESSAGES.create, {timeout: 1200});
              this.$router.push({name: 'client-edit', params: {id: r.data}})
            }).catch((r) => {
          this.showError = true;
          this.sendForm = false
          this.errors = r.response.data.errors;
          this.scrollToTop();
          toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
        })
      }
    },

    CheckPfPj() {
      this.ResetForm();
      this.nullInputArray();
      this.errors = []
      this.showError = false
      if (this.formDate.type == 1)
        this.picked = 1
      if (this.formDate.type == 2)
        this.picked = 2
    },

    searchCNPJ() {
      const dateCNPJ = {
        'type': this.formDate.type,
        'cnpj': this.formDate.cnpj
      }
      this.$store.state.preloader.loading = true;
      this.$store.dispatch('searchCNPJ', dateCNPJ)
          .then((r) => {
            if (r.data.check) {
              toast.info(`Cliente já está cadastrado, ${this.me.name}!`, {timeout: 2000});
              this.$store.state.preloader.loading = false
              this.ResetForm()
            } else {
              this.searchAPiCNPJ()
            }
          }).catch(() => {
        this.$store.state.preloader.loading = false
      })
    },

    searchCPF() {
      const dateCPF = {
        'type': this.formDate.type,
        'cpf': this.formDate.cpf
      }
      this.$store.dispatch('searchCPF', dateCPF)
          .then((r) => {
            if (r.data.check) {
              toast.info(`Cliente já está cadastrado, ${this.me.name}!`, {timeout: 2000});
              this.ResetForm()
            }
          }).catch(() => {
      })
    },

    searchAPiCNPJ() {
      let cnpj = this.formDate.cnpj.replace(/\D/g, '');
      const dateCNPJ = {
        'cnpj': cnpj
      }
      this.$store.dispatch('searchApiCNPJ', dateCNPJ)
          .then((r) => {
            this.completeInput(r.data)
          }).catch(() => {
      }).finally(() => {
        {
          this.$store.state.preloader.loading = false
        }
      })
    },

    searchApiCEP() {
      this.resetFormAddresse()
      let cep = this.formDate.cep.replace(/\D/g, '');
      const dateCEP = {
        'cep': cep
      }
      this.$store.dispatch('searchApiCEP', dateCEP)
          .then((r) => {
            this.formDate.city = r.data.localidade,
                this.formDate.state = r.data.uf,
                this.formDate.stateView = ESTADOS[r.data.uf],
                this.formDate.district = r.data.bairro,
                this.formDate.address = r.data.logradouro,
                this.formDate.complement = r.data.complemento
          }).catch(() => {
      })
    },

    ResetForm() {
      this.formDate = {
        cpf: '',
        cnpj: '',
        size: '',
        name: '',
        name_f: '',
        surname: '',
        legal_nature: '',
        share_capital: '',
        main_activity: '',
        date_open: '',
        birth_date: '',
        headquarters: '',
        rg: '',
        type: this.formDate.type,
        company_status: '',
        emails: [],
        phones: [],
        members: [],
        secondary_activitys: [],
        cep: '',
        city: '',
        state: '',
        stateView: '',
        district: '',
        address: '',
        complement: '',
        country: '',
      }
    },

    resetFormAddresse() {
      this.formDate.city = '',
          this.formDate.state = '',
          this.formDate.stateView = '',
          this.formDate.district = '',
          this.formDate.address = '',
          this.formDate.complement = ''
      this.formDate.country = 'Brasil'
    },

    completeInput(data) {
      this.formDate.name = data.company.name
      if (data.alias != null) {
        this.formDate.name_f = data.alias
      } else {
        this.formDate.name_f = data.company.name
      }

      this.formDate.surname = this.formDate.name_f
      this.formDate.legal_nature = `${data.company.nature.id} - ${data.company.nature.text}`

      if (data.company.equity != null) {
        this.formDate.share_capital = data.company.equity.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      } else {
        this.formDate.share_capital = 0
      }

      this.formDate.main_activity = `${data.mainActivity.id} - ${data.mainActivity.text}`
      this.formDate.date_open = this.dateFormactBR(data.founded)
      this.formDate.headquarters = data.head ? 'Sim' : 'Não'
      this.formDate.company_status = `${data.status.id} - ${data.status.text}`
      this.formDate.cep = data.address.zip
      this.formDate.city = data.address.city
      this.formDate.stateView = ESTADOS[data.address.state]
      this.formDate.state = data.address.state
      this.formDate.district = data.address.district
      this.formDate.address = `${data.address.street}, ${data.address.number}`
      this.formDate.country = data.address.country.name
      this.formDate.complement = data.address.details
      this.formDate.size = `${data.company.size.id} -  ${data.company.size.acronym} - ${data.company.size.text}`

      if (data.company.members.length > 0) {
        this.formDate.members = []
        data.company.members.forEach(element => {
          this.formDate.members.push({
            name: element.person.name,
            cpf_cnpj: element.person.taxId,
            age: element.person.age,
            qualification: `${element.role.id} - ${element.role.text}`
          })
        });
      }

      if (data.emails.length > 0) {
        this.formDate.emails = []
        data.emails.forEach(element => {
          this.formDate.emails.push({
            name: element.domain,
            email: element.address,
            notification: '1'
          })
        });
      }

      if (data.phones.length > 0) {
        this.formDate.phones = []
        data.phones.forEach(element => {
          this.formDate.phones.push({
            name: '',
            phone: `${element.area}${element.number}`,
            notification: '1'
          })
        });
      }

      if (data.sideActivities.length > 0) {
        this.formDate.secondary_activitys = []
        data.sideActivities.forEach(element => {
          this.formDate.secondary_activitys.push({
            code: `${element.id}`,
            desc: `${element.text}`
          })
        });
      }

    },

    nullInputArray() {
      if (this.formDate.emails.length == 0)
        this.formDate.emails.push({name: '', email: '', notification: '1'})
      if (this.formDate.members.length == 0)
        this.formDate.members.push({name: '', cpf_cnpj: '', age: '', qualification: ''})
      if (this.formDate.phones.length == 0)
        this.formDate.phones.push({name: '', phone: '', notification: '1'})
      if (this.formDate.secondary_activitys.length == 0)
        this.formDate.secondary_activitys.push({code: '', desc: ''})
    },

    addNewEmail() {
      this.formDate.emails.push({
        name: '',
        email: '',
        notification: ''
      })
    },

    addNewMembers() {
      this.formDate.members.push({
        name: '',
        date_entry: '',
        cpf_cnpj: '',
        age: '',
        country: '',
        qualification: ''
      })
    },

    addNewPhones() {
      this.formDate.phones.push({
        name: '',
        phone: '',
        notification: ''
      })
    },

    addNewActivitys() {
      this.formDate.secondary_activitys.push({
        code: '',
        desc: '',
      })
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    emailsRemove(index) {
      this.formDate.emails.splice(index, 1)
    },

    membersRemove(index) {
      this.formDate.members.splice(index, 1)
    },

    phonesRemove(index) {
      this.formDate.phones.splice(index, 1)
    },

    activitysRemove(index) {
      this.formDate.secondary_activitys.splice(index, 1)
    },

    dateFormactBR(inputDate) {
      let dateArray = inputDate.split("-");
      return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
    },

    async checkACL() {
      this.$store.dispatch('ACLitens', 4)
          .then((r) => {
            if (r.data.indexOf('client-create') !== -1) {
              this.showLoder = false
            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

  },


  computed: {
    selectInput() {
      return this.$store.state.var.selectActiveInactive;
    },

    selectServiceClient() {
      return this.$store.state.var.selectServiceClient;
    },

    selectAttended() {
      return this.$store.state.var.selectAttended;
    },

    tabMobile() {
      if (this.picked == '2')
        return false
      return true
    },

    emailsAdd() {
      return this.formDate.emails;
    },

    membersAdd() {
      return this.formDate.members;
    },

    phonesAdd() {
      return this.formDate.phones;
    },

    activityAdd() {
      return this.formDate.secondary_activitys;
    },

    me() {
      return this.$store.state.auth.me
    },
  },

  mounted() {
    this.formDate.emails.push({name: '', email: '', notification: '1'})
    this.formDate.members.push({name: '', cpf_cnpj: '', age: '', qualification: ''})
    this.formDate.phones.push({name: '', phone: '', notification: '1'})
    this.formDate.secondary_activitys.push({code: '', desc: ''})
  }

}
</script>
<style src="../../../src/assets/css/formTab.css"></style>

<style>
.border-content {
  position: relative;
  margin-bottom: 10px;
  background: #fbfbfb;
  padding: 0 20px 40px 20px;
}

.container-1 {
  padding: 0;
  height: 75px;
  position: relative;
}

.addButton {
  right: 0;
  border: 0;
  color: #FFF;
  width: 50px;
  height: 50px;
  position: absolute;
  background-color: #f38f0c;
}

.addButton i.icon,
.deleteButton i.icon {
  margin: 0;
  height: 0;
}

.deleteButton {
  right: 0;
  bottom: 0;
  border: 0;
  width: 50px;
  color: #FFF;
  height: 50px;
  position: absolute;
  background-color: #dc3545;
}

.addButton:hover {
  background-color: #cf7807;
}
</style>
