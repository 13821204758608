import AdminComponent from "@/components/dashboard/AdminComponent";
import ReportAnalyticalCashFlow from "@/views/search/ReportAnalyticalCashFlow"

export default {
    path: '/report-analytical-cash-flow',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'report-analytical-cash-flow', component: ReportAnalyticalCashFlow },
    ],
}