<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                    <label>Nome:</label>
                    <input-component :name-input="'name'" :type-input="'text'" v-model="formDate.name" disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Status:</label>
                    <Multiselect v-model="formDate.status" :searchable="true" :options="selectInput" disabled
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText" :name="'status'"/>
                </row-col-component>
            </row-component>

            <div class="border-content" v-for="(format, index) in formatsAdd" :key="index">
                <form>
                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <label>Resolução:</label>
                            <input-component :type-input="'text'" v-model="format.resolution" disabled/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <label>Formato:</label>
                            <Multiselect v-model="format.format" :searchable="true" :options="selectFormat" disabled
                                         :noResultsText="noResultsText" :noOptionsText="noResultsText"
                                         :name="'status'"/>
                        </row-col-component>
                    </row-component>
                </form>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>
                    <input-text-area v-model="formDate.desc" :model-value="formDate.desc" :rows="4" disabled/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-float class="w-100" :itens="itensTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputTextArea from "@/components/form/InputTextArea";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "TaskTypeShow",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.loadItensPage()
        this.checkACL();
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "eye"
            },

            itensTwo: {
                route: "/task-type/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                status: '',
                desc: '',
                resolutions_formats: []
            },

            errors: [],
            showError: false,
            showLoader: true,
        }
    },

    methods: {
        async checkACL() {
            this.$store.dispatch('ACLitens', 6)
                .then((r) => {
                    if (r.data.indexOf('task-type-search') !== -1) {
                        this.showLoader = false;
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        loadItensPage() {
            this.$store.dispatch('updateNamePage', 'Tipo de Tarefa')
            this.$store.dispatch('loadTaskType', this.id).then((r) => {
                this.formDate = r;
            }).catch(() => {
                this.showLoader = true;
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        }
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        selectFormat() {
            return this.$store.state.var.selectFormat;
        },

        formatsAdd() {
            return this.formDate.resolutions_formats;
        },
    }
}
</script>

<style scoped>
.border-content {
    padding: 0;
}
</style>
