import AdminComponent from "@/components/dashboard/AdminComponent";
import GroupUserCreate from "@/views/create/GroupUserCreate";
import GroupUserSearch from "@/views/search/GroupUserSearch";
import GroupUserUpdate from "@/views/update/GroupUserUpdate";
import GroupUserShow from "@/views/show/GroupUserShow";

export default {

    path: '/group-user',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'create', name: 'group-user-create', component: GroupUserCreate},
        {path: 'search', name: 'group-user-search', component: GroupUserSearch},
        {path: 'edit/:id', name: 'group-user-edit', component: GroupUserUpdate, props:true},
        {path: 'show/:id', name: 'group-user-show', component: GroupUserShow, props:true}


    ],

}

