<template>
  <button type="button" class="btn btn-danger  buttonRound" tooltip="Excluir" flow="down"><i
      class="trash icon" @click="deleteSubmit"></i></button>
</template>

<script>
export default {
name: "ButtonRemoveRound",

  methods: {
    deleteSubmit() {
      this.$emit('deleteSubmit')
    }
  }

}
</script>

<style scoped>

</style>