import AdminComponent from "@/components/dashboard/AdminComponent";
import OrderServiceSearch from "@/views/search/OrderServiceSearch";
import OrderServiceCreate from "@/views/create/OrderServiceCreate";
import OrderServiceUpdate from "@/views/update/OrderServiceUpdate";
import OrderServiceShow from "@/views/show/OrderServiceShow"

export default {
    path: '/order-of-service',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'order-of-service-search', component: OrderServiceSearch },
        { path: 'create', name: 'order-of-service-create', component: OrderServiceCreate },
        { path: 'edit/:id', name: 'order-of-service-edit', component: OrderServiceUpdate, props: true },
        { path: 'show/:id', name: 'order-of-service-show', component: OrderServiceShow, props: true },
    ],
}