import AdminComponent from "@/components/dashboard/AdminComponent";
import EmailNotificationSearch from "@/views/search/EmailNotificationSearch";
import EmailNotificationCreate from "@/views/create/EmailNotificationCreate";
import EmailNotificationUpdate from "@/views/update/EmailNotificationUpdate";
import EmailNotificationShow from "@/views/show/EmailNotificationShow";

export default {

    path: '/email-notification',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'email-notification-search', component: EmailNotificationSearch },
        { path: 'create', name: 'email-notification-create', component: EmailNotificationCreate },
        { path: 'edit/:id', name: 'email-notification-edit', component: EmailNotificationUpdate, props: true },
        { path: 'show/:id', name: 'email-notification-show', component: EmailNotificationShow, props: true },
    ],

}
