import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = 'proposal-template';
export default {

    loadProposalsTemplates(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then(response => {
                    context.commit('LOAD_PROPOSAL_TEMPLATE', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    storeProposalTemplate(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {})
        })
    },

    loadProposalTemplate(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
        })
    },

    loadProposalTemplateInfos(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}proposal-template-get/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
        })
    },

    updateProposalTemplate(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeProposalTemplateItem(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}proposal-template-add-item`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {})
        })
    },

    updateProposalTemplateItem(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}proposal-template-add-item-edit/${formData.id}`, formData)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyProposalTemplateItem(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}proposal-template-add-item-delete/${id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProposalTemplateSection(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}proposal-template-section-edit/${formData.id}`, formData)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeProposalTemplateSection(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}proposal-template-section-add`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {})
        })
    },

    destroyProposalTemplateSection(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}proposal-template-section-delete/${id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

}