<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoder"/>

        <div class="tabs effect-3" v-else>
            <input type="radio" id="tab-1" name="tab-effect-3" checked="checked">
            <span>
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="tasks icon"></i>
                <p>Sessão</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component :class-row-col="'col-xxl-8 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.name }">
                                <label>Nome:</label>
                                <input-component :maxlength=100 :type-input="'text'" v-model="formDate.name"
                                                 :model-value="formDate.name"/>
                            </row-col-component>

                            <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.status }">
                                <label>Status:</label>
                                <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                             :noResultsText="this.$store.state.var.selectNotSearch"/>
                            </row-col-component>

                            <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.price }">
                                <label>Valor Total:</label>
                                <input-component :type-input="'text'" v-model="formDate.price"
                                                 :model-value="formDate.price"
                                                 v-money="money"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Observações:</label>
                                <input-text-area :rows="4" v-model="formDate.obs"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-11 col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12'">
                            <button-one @click="sectionsAdd" class="me-2" :icon="'plus'" tooltip="Adicionar sessão"/>
                        </row-col-component>
                    </row-component>

                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formDate.sections.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <form v-for="(section, index) in addSection" :key="index" class="section-style">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'">
                                <label>Nome da sessão:</label>
                                <input-component :type-input="'text'" v-model="section.section"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 pt-4'">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button-one @click="itemsAdd(index)" tooltip="Adicionar item" :icon="'plus icon'"/>
                                    <button-one v-if="addSection.length > 1" @click="sectionsRemove(index)"
                                                tooltip="Deletar sessão" :icon="'trash icon'"/>
                                </div>
                            </row-col-component>
                        </row-component>

                        <row-component class="ps-4" v-for="(itens, indexTwo) in section.itens" :key="indexTwo">
                            <row-col-component
                                    :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'">
                                <label>Item:</label>
                                <input-component :type-input="'text'" v-model="itens.item"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 pt-4'">
                                <button-one tooltip="Deletar item" @click="itemsRemove(index, indexTwo)"
                                            v-if="section.itens.length > 1" :icon="'trash icon'"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>
            </div>
        </div>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-one class="w-100" @submit="submit" :icon="'plus circle'" :name-button="'Adicionar'"
                            :class="{ 'preloderSend': sendForm }"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import {MONEY} from "@/configs/constants"
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";
import {ACTION_MESSAGES} from "@/configs/constants";

const toast = useToast();

export default {
    name: "ProposalTemplateCreate",

    created() {
        this.$store.dispatch('updateNamePage', 'Modelo de Proposta')
        this.checkACL()
        this.cloneContract()
    },

    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea
    },

    directives: {
        mask,
        money: VMoney
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/proposal-template/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                price: '',
                obs: '',
                status: '',
                sections: []
            },

            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoder: true,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeProposalTemplate', this.formDate)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                        this.$router.push({name: 'proposal-template-edit', params: {id: r.data}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 20)
                .then((r) => {
                    if (r.data.indexOf('proposal-template-create') !== -1) {
                        this.showLoder = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        // Clone Function
        cloneContract() {
            if (this.storeState.length > 0) {
                this.storeState.forEach((element) => {
                    let section = element.sections
                    section.forEach((el) => {
                        delete Object.assign(el, {['section']: el['item']})['item'];
                    })
                })
                if (this.storeState.length > 1)
                    this.storeState.splice(0, 1)
                this.formDate = this.storeState[0]
            } else {
                this.sectionsAdd()
            }
        },

        //Sections Functions
        sectionsAdd() {
            this.formDate.sections.push({
                item: '',
                itens: [{item: ''}]
            })
        },

        itemsAdd(index) {
            this.formDate.sections[index].itens.push({
                item: ''
            })
        },

        sectionsRemove(index) {
            this.formDate.sections.splice(index, 1)
        },

        itemsRemove(index, indexTwo) {
            this.formDate.sections[index].itens.splice(indexTwo, 1)
        },

    },

    beforeMount() {
        this.storeState.splice(0, 1)
    },

    computed: {
        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        storeState() {
            return this.$store.state.var.storeState
        },

        addSection() {
            return this.formDate.sections
        }
    },
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.section-style {
    max-height: 450px;
    position: relative;
    overflow-y: scroll;
    padding: 15px 25px;
    margin-bottom: 50px;
    background-color: #f0f0f0;
}
</style>