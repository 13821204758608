import AdminComponent from "@/components/dashboard/AdminComponent";
import MyScheduleSearch from "@/views/search/MyScheduleSearch";
import MyScheduleUpdate from "@/views/update/MyScheduleUpdate"

export default {
    path: '/my-schedule',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'my-schedule-search', component: MyScheduleSearch },
        { path: 'edit/:id', name: 'my-schedule-edit', component: MyScheduleUpdate, props: true },
    ],
}