export default {

    icon: 'list alternate',
    name: 'Cadastro',
    subLinks: [

        {
            name: 'Cliente',
            route: '/client/search',
            pagePermissions: [
                'client-edit',
                'client-search',
                'client-create',
                'client-delete',
            ],
        },

        {
            name: 'Email Notificação',
            route: '/email-notification/search',
            pagePermissions: [
                'email-notification-search',
                'email-notification-edit',
                'email-notification-create',
                'email-notification-delete'
            ],
        },


        {
            name: 'Fornecedor',
            route: '/provider/search',
            pagePermissions: [
                'provider-search',
                'provider-create',
                'provider-edit'
            ],
        },

        {
            name: 'Frota',
            route: '/fleet/search',
            pagePermissions: [
                'fleet-search',
                'fleet-create',
                'fleet-edit'
            ],
        },

        {
            name: 'Funcionário',
            route: '/employee/search',
            pagePermissions: [
                'employee-edit',
                'employee-search',
                'employee-create',
            ],
        },

        {
            name: 'Grupo de Usuário',
            route: '/group-user/search',
            pagePermissions: [
                'group-user-edit',
                'group-user-search',
                'group-user-create',
                'group-user-delete',
            ],
        },

        {
            name: 'Usuário',
            route: '/user/search',
            pagePermissions: [
                'user-edit',
                'user-search',
                'user-create',
                'user-delete',
            ],
        },


    ],

    pageLinksNavCheck: [
        'client-edit',
        'client-search',
        'client-create',
        'client-delete',
        'group-user-edit',
        'group-user-search',
        'group-user-create',
        'group-user-delete',
        'user-delete',
        'user-edit',
        'user-create',
        'user-search',
        'employee-edit',
        'employee-search',
        'employee-create',
        'provider-search',
        'provider-create',
        'provider-edit',
        'fleet-search',
        'fleet-create',
        'fleet-edit',
        'email-notification-search',
        'email-notification-edit',
        'email-notification-create',
        'email-notification-delete'
    ],


}