import AdminComponent from "@/components/dashboard/AdminComponent";
import EntranceExitPreviewSearch from "@/views/search/EntranceExitPreviewSearch";
import EntranceExitPreviewCreate from "@/views/create/EntranceExitPreviewCreate";

export default {

    path: '/entrance-exit-preview',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'entrance-exit-preview-search', component: EntranceExitPreviewSearch },
        { path: 'create', name: 'entrance-exit-preview-create', component: EntranceExitPreviewCreate },
    ],

}
