<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoder"/>

        <form class="mb-4 mt-2" v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formDate.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.balance }">
                    <label>Saldo:</label>
                    <input-component :type-input="'text'" v-model="formDate.balance" v-money="money"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.bank }">
                    <label>Banco:</label>
                    <multiselect v-model="formDate.bank" :searchable="true" :options="selectBank"
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Tipo de conta:</label>
                    <multiselect v-model="formDate.account_type" :searchable="true" :options="selectAccountType"
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Agência:</label>
                    <input-component :type-input="'text'" v-model="formDate.agency"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Conta/Dígito:</label>
                    <input-component :type-input="'text'" v-model="formDate.digit_account"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>PIX Tipo:</label>
                    <multiselect v-model="formDate.pix_type" :searchable="true" :options="selectPIX"
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                    <label>Chave:</label>
                    <input-component :type-input="'text'" v-model="formDate.key_pix"
                                     v-if="parseInt(this.formDate.pix_type) === 1"
                                     v-mask="['###.###.###-##', '##.###.###/####-##']"/>

                    <input-component :type-input="'text'" v-model="formDate.key_pix"
                                     v-else-if="parseInt(this.formDate.pix_type) === 2"
                                     v-mask="['(##) ####-####', '(##) #####-####']"/>

                    <input-component :type-input="'email'" v-model="formDate.key_pix"
                                     v-else-if="parseInt(this.formDate.pix_type) === 3"/>

                    <input-component :type-input="'text'" v-model="formDate.key_pix"
                                     v-else-if="parseInt(this.formDate.pix_type) === 4"/>

                    <input-component :type-input="'text'" v-model="formDate.key_pix" v-else/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>
                    <input-text-area :modelValue="formDate.obs" v-model="formDate.obs" :rows="4"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                    <label>Conta Principal ?:</label>

                    <input type="radio" id="main_account_n" v-model="formDate.main_account" value="1" checked>
                    <label for="main_account_n">Não</label>

                    <input type="radio" id="main_account_s" v-model="formDate.main_account" value="2">
                    <label for="main_account_s">Sim</label>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.allow_negative_balance }">
                    <label>Permitir Saldo Negativo ?:</label>

                    <input type="radio" id="allow_negative_balance_n" v-model="formDate.allow_negative_balance"
                           value="1" checked>
                    <label for="allow_negative_balance_n">Não</label>

                    <input type="radio" id="allow_negative_balance_s" v-model="formDate.allow_negative_balance"
                           value="2">
                    <label for="allow_negative_balance_s">Sim</label>
                </row-col-component>
            </row-component>

            <row-component class="mt-3">
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one style="width: 100%" @submit="submit" :icon="'plus circle'" :name-button="'Adicionar'"
                                :class="{ 'preloderSend': sendForm }"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-float style="width: 100%" :itens="itensTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import {MONEY} from "@/configs/constants"
import {ACTION_MESSAGES} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "AccountBankCreate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        LoderSendComponent,
        ErrorComponent,
        InputTextArea
    },

    directives: {
        money: VMoney,
        mask
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Conta Bancária')
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/account-bank/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                bank: '',
                balance: '',
                account_type: '',
                agency: '',
                digit_account: '',
                pix_type: '',
                key_pix: '',
                allow_negative_balance: 1,
                main_account: 1,
                status: '',
                obs: ''
            },

            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoder: true,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeAccountBank', this.formDate)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.create, {timeout: 1200});
                        this.$router.push({name: 'account-bank-edit', params: {id: r.data}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop();
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 14)
                .then((r) => {
                    if (r.data.indexOf('account-bank-create') !== -1) {
                        this.showLoder = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        selectBank() {
            return this.$store.state.var.selectBank;
        },

        selectAccountType() {
            return this.$store.state.var.selectAccountType;
        },

        selectPIX() {
            return this.$store.state.var.selectPIX;
        }
    },
}
</script>