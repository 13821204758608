<template>
    <painel-component :itens="itensOne">
        <div class="filter-position" v-if="btnFilter">
            <button-one icon="filter" class="btn-filter" @click="filterWindow"/>
        </div>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Código:</label>
                        <input-component :type-input="'text'" v-model="formDate.code"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDate.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDate.status" :searchable="true" :options="selectStatusBudget"
                                     :noResultsText="this.$store.state.var.selectNotSearch"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <button-one class="mx-1" @submit="submit" :icon="'search'"/>
                        <button-float class="mx-1" :itens="itensTwo" v-if="buttonCheck.create"/>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end'">
                    <div class="toggle_preferences">
                        <button :class="{ 'bg-active': parseInt(cardTable) === 1 }" @click="toggleCard" type="button"
                                tooltip="Card">
                            <i class="window maximize outline icon"></i>
                        </button>
                        <button :class="{ 'bg-active': parseInt(cardTable) === 2 }" @click="toggleTable" type="button"
                                tooltip="Tabela">
                            <i class="align justify icon"></i>
                        </button>
                    </div>
                </row-col-component>
            </row-component>

            <row-component v-if="parseInt(cardTable) === 1">
                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4'"
                                   v-for="(data, index) in objectReturn.itens.data " :key="index">
                    <div class="card-container">
                        <div class="flag-status">
                            {{ statusHistoric(data.status) }}
                        </div>
                        <p>Código: {{ data.code }}</p>
                        <p>{{ data.name }}</p>

                        <div class="responsible-task">
                            <h6> Criador:</h6>
                            <img v-if="data.photo_creator" :src="data.photo_creator" class="avatar__image_2" alt="">
                            <img v-else src="@/assets/img/avatar.png" class="avatar__image_2" alt="">
                            {{ data.creator_user_name }}
                        </div>

                        <div class="buttons">
                            <button tooltip="Editar" class="primary" v-if="buttonCheck.edit"
                                    @click="routeEdit(data.id)">
                                <i class="edit icon"></i>
                            </button>

                            <button tooltip="Ver" class="primary" v-if="buttonCheck.show" @click="routeShow(data.id)">
                                <i class="eye icon"></i>
                            </button>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <table class="table table-striped" v-if="parseInt(cardTable) === 2">
                <thead>
                <tr>
                    <th scope="col" style="width: 5%">Código</th>
                    <th scope="col" style="width: 15%">Criador</th>
                    <th scope="col" style="width: 15%">Nome</th>
                    <th scope="col" style="width: 20%">Status</th>
                    <th scope="col" style="width: 15%" v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.close">
                        Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in objectReturn.itens.data" :key="index">
                    <td data-label="Código">{{ data.code }}</td>
                    <td data-label="Criador">
                        <img v-if="data.photo_create" :src="data.photo_create" class="avatar__image_2" alt="">
                        <img v-else src="@/assets/img/avatar.png" class="avatar__image_2" alt="">
                        {{ data.creator_user_name }}
                    </td>
                    <td data-label="Nome">{{ data.name }}</td>
                    <td data-label="Status">{{ statusHistoric(data.status) }}</td>
                    <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.edit">
                        <button tooltip="Ver" class="btn btn-secondary mx-1 button-show" v-if="buttonCheck.show"
                                @click="routeShow(data.id)">
                            <i class="eye icon"></i>
                        </button>

                        <button tooltip="Editar" class="btn btn-success mx-1 button-show" v-if="buttonCheck.edit"
                                @click="routeEdit(data.id)">
                            <i class="edit icon"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <button-pagination :pagination="objectReturn.itens" :offset="3" @paginate="loadBudgets"/>
        </div>
    </painel-component>
</template>

<script>
import ButtonPagination from "@/components/buttons/ButtonPagination";
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import InputComponent from "@/components/form/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";

import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "BudgetSearch",
    components: {
        ButtonPagination,
        PainelComponent,
        ButtonOne,
        ButtonFloat,
        InputComponent,
        RowComponent,
        RowColComponent,
        LoderSendComponent,
        Multiselect,
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Orçamento')
        this.loadBudgets(1)
        this.resizeWindow()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            itensTwo: {
                route: "/budget/create",
                icon: "plus",
                name: ''
            },

            buttonCheck: {
                edit: false,
                create: false,
                show: false
            },

            formDate: {
                code: '',
                name: '',
                status: '',
            },

            cardTable: '',
            preferences: {
                type: 21,
                preference: ''
            },

            filter: true,
            btnFilter: false,
            showLoader: true,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadBudgets(1)
            this.searchSubmit = true;
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },

        routeEdit(id) {
            this.$router.push({name: 'budget-edit', params: {id: id}})
        },

        routeShow(id) {
            this.$router.push({name: 'budget-show', params: {id: id}})
        },

        toggleTable() {
            this.userPreferences(2)
            this.cardTable = 2
        },

        toggleCard() {
            this.userPreferences(1)
            this.cardTable = 1
        },

        userPreferences(preference) {
            this.preferences.preference = preference
            this.$store.dispatch('userPreferences', this.preferences)
        },

        loadBudgets(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadBudgets', {...this.params, page})
                    .then((r) => {
                        this.showLoader = false
                        this.cardTable = r.tableCard
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        statusHistoric(status) {
            if (parseInt(status) === 1)
                return "Cadastro"
            if (parseInt(status) === 2)
                return "Enviado para cliente"
            if (parseInt(status) === 3)
                return "Aprovado pelo cliente"
            if (parseInt(status) === 4)
                return "Aprovado pela D7M"
            if (parseInt(status) === 5)
                return "Cancelado"
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 25)
                .then((r) => {
                    if (r.data.indexOf('budget-search') !== -1) {
                        this.showLoader = false

                        if (r.data.indexOf('budget-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('budget-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('budget-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        selectStatusBudget() {
            return this.$store.state.var.selectStatusBudget
        },

        objectReturn() {
            return this.$store.state.budget.items
        },

        params() {
            return {
                page: this.objectReturn.current_page,
                code: this.formDate.code,
                name: this.formDate.name,
                status: this.formDate.status,
            }
        },
    },
}
</script>
<style scoped src="@/assets/css/table-resp.css"></style>
<style scoped>
.toggle_preferences > button {
    border: 0;
    color: #fff;
    width: 45px;
    height: 45px;
    margin: 0 5px;
    border-radius: 50%;
    padding-right: 4px;
    background-color: #323232;
}

.button-show {
    width: 35px;
    height: 35px;
    font-size: 15px;
    border-radius: 50px;
    padding-left: 0.5rem;
}

.bg-active {
    background-color: #d67c1c !important;
}

.buttons {
    display: flex;
}

button.primary {
    width: 100%;
}
</style>