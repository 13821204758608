import state from "@/store/modules/entrance-exit/state";
import actions from "@/store/modules/entrance-exit/actions";
import mutations from "@/store/modules/entrance-exit/mutations";
import getters from "@/store/modules/entrance-exit/getters";

export default {
  state,
  actions,
  mutations,
  getters
}