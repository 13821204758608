<template>
    <painel-component :itens="itensOne">
        <button-add :itens="itensThree" v-if="buttonCheck.create"></button-add>

        <error-component :errors="errors" v-if="showError"></error-component>

        <loder-send-component :text-title="'Carregando'" v-if="showLoder"></loder-send-component>

        <form autocomplete="off" v-else>

            <div class="aux mb-4 mt-3">
                <div class="avatar-form">
                    <img v-if="formDate.product_photo" :src="formDate.product_photo" class="avatar__image">
                    <img v-else src="@/assets/img/avatar.png" alt="" class="avatar__image">
                </div>
                <p>{{ formDate.name }}</p>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" :model-value="formDate.name" disabled>
                    </input-component>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Negócio:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectBusiness" disabled
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectInput" disabled
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.price }">
                    <label>Preço:</label>
                    <input-component :type-input="'text'" v-model="formDate.price" :model-value="formDate.price"
                                     disabled v-money="money">
                    </input-component>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.initial_inventory }">
                    <label>Estoque Inicial:</label>
                    <input-number-component :type-input="'number'" v-model="formDate.initial_inventory" disabled
                                            :model-value="formDate.initial_inventory">
                    </input-number-component>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.minimum_stock }">
                    <label>Estoque Mínimo:</label>
                    <input-number-component :type-input="'number'" v-model="formDate.minimum_stock" disabled
                                            :model-value="formDate.minimum_stock">
                    </input-number-component>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.classification }">
                    <label>Classificação:</label>
                    <multiselect v-model="formDate.classification" :searchable="true" :options="selectClassification"
                                 disabled :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações</label>
                    <input-text-area :rows="4" v-model="formDate.obs" :model-value="formDate.obs" disabled>
                    </input-text-area>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-float style="width: 100%" :itens="itensTwo"/>
                </row-col-component>
            </row-component>

        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonAdd from "@/components/buttons/ButtonAdd"
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import InputNumberComponent from "@/components/form/InputNumberComponent.vue"

import {MONEY} from "@/configs/constants"
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "ProductShow",

    created() {
        this.$store.dispatch('updateNamePage', 'Produtos')
        this.loadPageItens()
        this.checkACL()
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/product/search",
                icon: "reply all",
                name: 'Voltar'
            },

            itensThree: {
                route: "/product/create",
                icon: "plus",
                name: 'Adicionar'
            },

            buttonCheck: {
                create: false,
            },

            formDate: {
                name: '',
                status: '',
                price: '',
                initial_inventory: '',
                minimum_stock: '',
                classification: '',
                obs: '',
                product_photo: ''
            },

            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoder: true,
        }
    },

    methods: {
        loadPageItens() {
            this.$store.dispatch('loadProduct', this.id).then((r) => {
                this.formDate = r;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 21)
                .then((r) => {
                    if (r.data.indexOf('product-search') !== -1) {
                        this.showLoder = false
                        this.buttonCheck.create = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonAdd,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea,
        InputNumberComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    computed: {
        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        selectClassification() {
            return this.$store.state.var.selectClassification
        },
        selectBusiness() {
            return this.$store.state.var.selectBusiness
        }
    }

}
</script>
  
  
  
    