<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off" v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.brand_model }">
                    <label>Marca/Modelo:</label>
                    <input-component :type-input="'text'" v-model="formDate.brand_model"
                                     :model-value="formDate.brand_model"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Renavam:</label>
                    <input-component :type-input="'text'" v-model="formDate.reindeer" :model-value="formDate.reindeer"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Chassi:</label>
                    <input-component :type-input="'text'" v-model="formDate.chassis" :model-value="formDate.chassis"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Nº Documento:</label>
                    <input-component :type-input="'text'" v-model="formDate.document" :model-value="formDate.document"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Cor:</label>
                    <input-component :type-input="'text'" v-model="formDate.color" :model-value="formDate.color"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Placa:</label>
                    <input-component :type-input="'text'" v-model="formDate.board" :model-value="formDate.board"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Ano:</label>
                    <input-component :type-input="'text'" v-model="formDate.year" :model-value="formDate.year"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Tipo de combustível:</label>
                    <multiselect v-model="formDate.fuel" :searchable="true" :options="selectTypeFuel"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.km }">
                    <label>KM:</label>
                    <input-number-component :type-input="'number'" min="1" v-model="formDate.km"
                                            :model-value="formDate.km"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>
            </row-component>
        </form>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-one class="w-100" @submit="submit" :icon="'plus circle'" :name-button="'Adicionar'"
                            :class="{ 'preloderSend': sendForm }"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputNumberComponent from "@/components/form/InputNumberComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import {ACTION_MESSAGES} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "FleetCreate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputNumberComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Frota')
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/fleet/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                brand_model: '',
                year: '',
                reindeer: '',
                color: '',
                chassis: '',
                document: '',
                board: '',
                fuel: '',
                km: '',
                status: ''
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeFleet', this.formDate)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                        this.$router.push({name: 'fleet-edit', params: {id: r.data}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 30)
                .then((r) => {
                    if (r.data.indexOf('fleet-create') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        selectTypeFuel() {
            return this.$store.state.var.selectTypeFuel
        },
    }

}
</script>

  
  
  
    