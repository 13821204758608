import AdminComponent from "@/components/dashboard/AdminComponent";
import ContractModelSearch from "@/views/search/ContractModelSearch";
import ContractModelCreate from "@/views/create/ContractModelCreate";
import ContractModelUpdate from "@/views/update/ContractModelUpdate";
import ContractModelShow from "@/views/show/ContractModelShow";

export default {
    path: '/contract-model',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'create', name: 'contract-model-create', component: ContractModelCreate },
        { path: 'search', name: 'contract-model-search', component: ContractModelSearch },
        { path: 'show/:id', name: 'contract-model-show', component: ContractModelShow, props: true },
        { path: 'edit/:id', name: 'contract-model-edit', component: ContractModelUpdate, props: true },
    ],
}
