import AdminComponent from "@/components/dashboard/AdminComponent";
import GuestScheduleSearch from "@/views/search/GuestScheduleSearch";
import GuestScheduleCreate from "@/views/create/GuestScheduleCreate";
import GuestScheduleUpdate from "@/views/update/GuestScheduleUpdate";
import GuestScheduleUpdatePhoto from "@/views/update/GuestScheduleUpdatePhoto";
import GuestScheduleShow from "@/views/show/GuestScheduleShow";

export default {
    path: '/guest-schedule',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'create', name: 'guest-schedule-create', component: GuestScheduleCreate },
        { path: 'search', name: 'guest-schedule-search', component: GuestScheduleSearch },
        { path: 'edit/:id', name: 'guest-schedule-edit', component: GuestScheduleUpdate, props: true },
        { path: 'show/:id', name: 'guest-schedule-show', component: GuestScheduleShow, props: true },
        { path: 'photo/:id', name: 'guest-schedule-update-photo', component: GuestScheduleUpdatePhoto, props: true }
    ],
}
