<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off" v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Frota:</label>
                    <Multiselect v-model="formDate.id_frota" label="name" :options="selectBrandModel" :searchable="true"
                                 disabled :noResultsText="noResultsText" :noOptionsText="noResultsText">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-9 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <label>Motivo Saída:</label>
                    <input-component :type-input="'text'" v-model="formDate.motive" disabled/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Data Inicial:</label>
                    <input-component v-mask="'##/##/####'" :type-input="'text'" v-model="formDate.date_start" disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Hora Inicial:</label>
                    <input-component :type-input="'text'" v-model="formDate.hour_start" disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>KM Inicial:</label>
                    <input-number-component :type-input="'number'" min="1" v-model="formDate.km_start" disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Data Final:</label>
                    <input-component v-mask="'##/##/####'" :type-input="'text'" v-model="formDate.date_end" disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Hora Final:</label>
                    <input-component :type-input="'text'" v-model="formDate.hour_end" disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>KM Final:</label>
                    <input-number-component :type-input="'number'" min="1" v-model="formDate.km_end" disabled/>
                </row-col-component>
            </row-component>
        </form>

        <div class="line-division mt-5 mb-5">
            <h1 class="h1-text">
                <i class="comment icon"></i>
                Observações Internas
            </h1>
        </div>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Observações:</label>

                <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                     v-if="formDate.obsPrivate.length === 0">
                    <p>Não há nada cadastrado</p>
                </div>

                <div class="merito" v-else>
                    <section class="container-desc">
                        <ul class="task-desc">
                            <li class="item" v-for="(obs, index) in formDate.obsPrivate" :key="index">
                                <div class="aux-row">
                                    <div class="user">
                                        <img :src="obs.user_photo" alt="Image 001" class="owner-img"/>
                                        {{ obs.user_name }}
                                    </div>
                                    <div class="desc-date">
                                        <div class="text"><i class="calendar icon"></i>{{ obs.date_create }}</div>
                                        <div class="text"><i class="clock icon"></i>{{ obs.hour_create }}</div>
                                    </div>
                                </div>
                                <p>{{ obs.obs }}</p>
                            </li>
                        </ul>
                    </section>
                </div>
            </row-col-component>
        </row-component>

        <form autocomplete="off">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>
                    <input-text-area :rows="4" v-model="formDateObs.obs"/>
                </row-col-component>
            </row-component>
        </form>

        <button-one @submit="submitObs" :icon="'plus circle'" :name-button="'Adicionar Observação'"/>

        <div class="line-division mt-5 mb-5">
            <h1 class="h1-text">
                <i class="history icon"></i>
                Histórico de interações
            </h1>
        </div>

        <section class="container-desc-two">
            <ul class="task-desc">
                <li class="item" v-for="(historic, index) in formDate.historic" :key="index">
                    <div class="aux-row">
                        <div class="user">
                            <img :src="historic.user_photo" alt="Image 001" class="owner-img"/>
                            {{ historic.name_user }}
                        </div>
                        <div class="desc-date">
                            <div class="text"><i class="calendar icon"></i>{{ historic.date_create }}</div>
                            <div class="text"><i class="clock icon"></i>{{ historic.hour_create }}</div>
                        </div>
                    </div>
                    <p>
                        {{ statusHistoric(historic.status) }},
                        Plataforma: {{ historic.platform }},
                        Navegador: {{ historic.browser }},
                        Endereço IP: {{ historic.ip }}
                    </p>
                </li>
            </ul>
        </section>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3'">
                <button-float style="width: 100%" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputNumberComponent from "@/components/form/InputNumberComponent";
import InputComponent from "@/components/form/InputComponent";
import InputTextArea from "@/components/form/InputTextArea";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "FleetDeparturesShow",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputNumberComponent,
        InputComponent,
        InputTextArea,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
    },

    directives: {
        mask,
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Saída Frota')
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "eye"
            },

            itensTwo: {
                route: "/fleet-departures/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                id_frota: '',
                name_frota: '',
                motive: '',
                date_start: '',
                hour_start: '',
                km_start: '',
                date_end: '',
                hour_end: '',
                km_end: '',
                user_photo: '',
                fleet_photo: '',
                obsPrivate: [],
                historic: []
            },

            formDateObs: {
                id: this.id,
                obs: '',
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        submitObs() {
            this.$store.dispatch('storeFleetDepartureObs', this.formDateObs)
                .then(() => {
                    this.showError = false
                    this.errors = []
                    this.loadPageItens()
                    this.formDateObs.obs = ''
                    toast.success('Observação adicionada com sucesso!', {timeout: 2000});
                }).catch((r) => {
                this.showError = true;
                this.errors = r.response.data.errors;
                this.scrollTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        loadPageItens() {
            this.$store.dispatch('loadFleetDeparture', this.id).then((r) => {
                this.formDate = r;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 31)
                .then((r) => {
                    if (r.data.indexOf('fleet-departures-search') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        statusHistoric(status) {
            if (parseInt(status) === 1)
                return "Cadastro"
            if (parseInt(status) === 2)
                return "Editado"
            if (parseInt(status) === 3)
                return "Finalizado"
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        selectBrandModel() {
            return this.$store.state.var.selectBrandModel
        },
    }
}
</script>