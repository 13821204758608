<template>
    <div v-if="modal" class="window-modal">
        <div class="btn-close" @click="modal = !modal"></div>
        <img v-if="extension(this.content) === 'png'
        || extension(this.content) === 'jpg' || extension(this.content) === 'jpg'" :src="content" alt=""/>

        <video v-else :src="content" autoplay loop controls></video>
    </div>

    <painel-component :itens="itensOne">
        <button-add :itens="itensThree" v-if="buttonCheck.create"/>
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoder"/>

        <div class="tabs effect-3 mt-3" v-else>
            <input type="radio" id="tab-1" name="tab-effect-3" checked="checked">
            <span>
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="paperclip icon"></i>
                <p>Anexo</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3">
            <span>
                <i class="comment outline icon"></i>
                <p>Comentários</p>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3">
            <span>
                <i class="clock outline icon"></i>
                <p>Histórico de interação</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form autocomplete="off">
                        <row-component>
                            <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.name }">
                                <label>Nome:</label>
                                <input-component :maxlength=100 :type-input="'text'" v-model="formDate.name"/>
                            </row-col-component>

                            <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.sector }">
                                <label>Setor:</label>
                                <Multiselect v-model="formDate.sector" :searchable="true" :options="selectSector"
                                             :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                    :class="{ 'formError': errors.responsibles }">
                                <label>Responsáveis:</label>
                                <Multiselect v-model="formDate.responsibles" mode="tags" track-by="name" label="name"
                                             :options="selectUserList" :searchable="true"
                                             :noResultsText="noResultsText" :noOptionsText="noResultsText"
                                             :close-on-select="false"
                                             :search="true">
                                    <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                        <div class="multiselect-tag is-user" :class="{ 'is-disabled': disabled }">
                                            <img :src="option.icon" alt="">
                                            {{ option.name }}
                                            <span v-if="!disabled" class="multiselect-tag-remove"
                                                  @mousedown.prevent="handleTagRemove(option, $event)">
                                            <span class="multiselect-tag-remove-icon"></span>
                                        </span>
                                        </div>
                                    </template>

                                    <template v-slot:option="{ option }">
                                        <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                                    </template>
                                </Multiselect>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                    :class="{ 'formError': errors.desc }">
                                <label>Descrição:</label>
                                <QuillEditor theme="snow" :toolbar="toolbarOptions" :contentType="'html'" ref="myEditor"
                                             v-model:content="formDate.desc"
                                             style="min-height: 150px; margin-bottom: 2%"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formDate.files.length === 0">
                        <p>Não há anexos</p>
                    </div>

                    <row-component>
                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <label class="uploadButton float-end" for="upload" tooltip="Adicionar Arquivos">
                                <input :disabled="sendForm" type="file" ref="upload-files" @change="selectFiles"
                                       id="upload" multiple>
                                <i class="icon plus"></i>
                            </label>
                        </row-col-component>
                    </row-component>

                    <row-component v-if="files.length > 0">
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <div class="container">
                                <div class="files">
                                    <ul>
                                        <li v-for="(data, index) in files" :key="index"
                                            class="d-flex align-items-center justify-content-between">
                                            <div>
                                                {{ data.file.name }}
                                                <span class="file-size">
                                                    {{ convertBytes(data.file.size) }}
                                                </span>
                                            </div>

                                            <div class="me-5 align-items-center justify-content-around"
                                                 style="width: 30%; display: flex" :id="'progress-bar' + index">
                                                <progress :value="data.progress" max="100"></progress>
                                                {{ data.progress }}%
                                            </div>

                                            <div class="file-success" :id="'file-success' + index">
                                                Sucesso ao enviar
                                                <i class="check icon"></i>
                                            </div>

                                            <div class="file-erro" :id="'file-erro' + index">
                                                Erro ao enviar
                                                <i class="close icon"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                           v-for="(file, index) in formDate.files" :key="index">
                            <div class="card">
                                <div class="card-image">
                                    <a :href="file.name_encoded" target="_blank"
                                       v-if="extension(file.name_encoded) === 'png' || extension(file.name_encoded) === 'jpg'">
                                        <img :src="file.name_encoded" alt="">
                                    </a>
                                    <i v-else-if="extension(file.name_encoded) === 'pdf'" class="file pdf icon"></i>
                                    <i v-else-if="extension(file.name_encoded) === 'docx' || extension(file.name_encoded) === 'doc'"
                                       class="file word icon"></i>
                                    <i v-else-if="extension(file.name_encoded) === 'txt' || extension(file.name_encoded) === 'csv'"
                                       class="file alternate icon"></i>
                                    <i v-else-if="extension(file.name_encoded) === 'mp4' || extension(file.name_encoded) === 'mov' || extension(file.name_encoded) === 'avi'"
                                       class="file video icon"></i>
                                    <i v-else-if="extension(file.name_encoded) === 'xlsx' || extension(file.name_encoded) === 'xlsm' || extension(file.name_encoded) === 'xlsb'
                                       || extension(file.name_encoded) === 'xltx'|| extension(file.name_encoded) === 'xltm'|| extension(file.name_encoded) === 'xls'
                                       || extension(file.name_encoded) === 'xlt' || extension(file.name_encoded) === 'xml' || extension(file.name_encoded) === 'xlam'
                                       || extension(file.name_encoded) === 'xla' || extension(file.name_encoded) === 'xlw' || extension(file.name_encoded) === 'xlr'"
                                       class="file excel icon"></i>
                                    <i v-else class="file audio icon"></i>
                                </div>

                                <p>{{ file.name_original }}</p>
                                <div class="aux-row-3">
                                    <div class="text"><i class="calendar icon"></i>{{ file.date_create }}</div>
                                    <div class="text"><i class="clock icon"></i>{{ file.hour_create }}</div>
                                </div>
                                <div class="ow-img">
                                    <img :src="file.user_photo" alt=""/>
                                    Enviado por: {{ file.user_name }}
                                </div>
                                <div class="dates-card">
                                    <button-one
                                            v-if="extension(file.name_encoded) === 'png' || extension(file.name_encoded) === 'jpg'
                                            || extension(file.name_encoded) === 'mp4' || extension(file.name_encoded) === 'mov'
                                            || extension(file.name_encoded) === 'avi'"
                                            class="w-100 rounded-0 border-0 bg-secondary" :icon="'eye'"
                                            @click="previewContent(file.name_encoded)"/>

                                    <button-one @click="previewOther(file.name_encoded)" v-else
                                                class="w-100 rounded-0 border-0 bg-secondary" :icon="'eye'"/>
                                </div>
                            </div>
                        </row-col-component>
                    </row-component>
                </section>

                <section id="tab-item-3">
                    <row-component>
                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <label>Comentários:</label>
                            <input-text-area :rows="4" v-model="formDateObs.obs"></input-text-area>
                        </row-col-component>

                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <button-one class="float-end mt-0" @submit="storeNoteObs" :icon="'plus'"
                                        :name-button="'Adicionar'"/>
                        </row-col-component>
                    </row-component>

                    <div v-if="formDate.comentes.length >= 1" class="me-rito">
                        <section class="container-desc">
                            <ul class="task-desc">
                                <li class="item" v-for="(obs, index) in formDate.comentes" :key="index">
                                    <div class="aux-row">
                                        <div class="user">
                                            <img :src="obs.user_photo" alt="Image 001" class="owner-img"/>
                                            {{ obs.user_name }}
                                        </div>
                                        <div class="desc-date">
                                            <div class="text"><i class="calendar icon"></i>{{ obs.date_create }}
                                            </div>
                                            <div class="text"><i class="clock icon"></i>{{ obs.hour_create }}</div>
                                        </div>
                                    </div>
                                    <p>{{ obs.obs }}</p>
                                </li>
                            </ul>
                        </section>
                    </div>
                </section>

                <section id="tab-item-4">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formDate.historic.length === 0">
                        <p>Não há histórico</p>
                    </div>

                    <div class="container-desc-two" v-else>
                        <ul class="task-desc">
                            <li class="item" v-for="(historic, index) in formDate.historic" :key="index">
                                <div class="aux-row">
                                    <div class="user">
                                        <img :src="historic.user_photo" alt="Image 001" class="owner-img"/>
                                        {{ historic.name_user }}
                                    </div>
                                    <div class="desc-date">
                                        <div class="text"><i class="calendar icon"></i>{{ historic.date_create }}</div>
                                        <div class="text"><i class="clock icon"></i>{{ historic.hour_create }}</div>
                                    </div>
                                </div>
                                <p>{{ statusHistoric(historic.status) }}, Plataforma: {{ historic.platform }},
                                    Navegador: {{ historic.browser }}, Endereço IP: {{ historic.ip }}</p>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        </div>

        <row-component>
            <row-col-component
                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-one style="width: 100%" @submit="submit" :icon="'save'" :name-button="'Salvar'"
                            :class="{'preloderSend': sendForm}"/>
            </row-col-component>

            <row-col-component
                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 position-relative'">
                <button-float style="width: 100%; position: absolute; bottom: 0" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonAdd from "@/components/buttons/ButtonAdd"
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {ACTION_MESSAGES} from "@/configs/constants";
import {useToast} from "vue-toastification";
import InputTextArea from "@/components/form/InputTextArea";
import axios from "axios";

const toast = useToast();

export default {
    name: "NoteUpdate",
    components: {
        InputTextArea,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        ButtonAdd,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        QuillEditor
    },

    created() {
        this.loadPageItens()
        this.checkACL()
        this.$store.dispatch('loadUserListSelect')
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/note/search",
                icon: "reply all",
                name: 'Voltar'
            },

            itensThree: {
                route: "/note/create",
                icon: "plus",
                name: 'Adicionar'
            },

            buttonCheck: {
                create: false,
            },

            formDate: {
                name: '',
                desc: '',
                sector: '',
                status: '',
                files: [],
                historic: [],
                comentes: [],
                responsibles: [],
            },

            formDateObs: {
                id: this.id,
                obs: ''
            },

            formDateFile: [],
            formDateUploadFile: {
                id: this.id,
                file: ""
            },

            files: [],

            contUpload: 0,

            modal: false,
            content: '',

            errors: [],
            showError: false,
            sendForm: false,
            showLoder: true,

            toolbarOptions: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],
                [{'header': 1}, {'header': 2}],               // custom button values
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
                [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
                [{'direction': 'rtl'}],                         // text direction
                [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                [{'font': []}],
                [{'align': []}],
                ['clean']                                         // remove formatting button
            ],

        }
    },

    methods: {
        previewContent(url) {
            this.modal = !this.modal;
            this.content = url;
        },

        previewOther(url) {
            window.open(url, '_blank')
        },

        extension(name) {
            return name.split('.').pop().toString()
        },

        selectFiles(event) {
            this.files = Array.from(event.target.files).map((file) => ({
                file,
                id: this.id,
            }));
            this.uploadFiles()
        },

        uploadFiles() {
            this.sendForm = true
            let removeFileClass = document.getElementsByClassName("remove-file");
            removeFileClass.forEach((element) => {
                element.style.display = 'none'
            });

            this.files.forEach((fileObject, index) => {
                const params = {
                    file: fileObject.file,
                    id: this.id
                }

                axios.post("api/v1/admin/note-file", params, {
                    headers: {'Content-Type': 'multipart/form-data'},
                    onUploadProgress: (progressEvent) => {
                        fileObject.progress = Math.round(
                            (progressEvent.loaded / progressEvent.total) * 100
                        );
                    },
                }).then(() => {
                    document.getElementById('file-success' + index).style.display = 'block'
                    document.getElementById('progress-bar' + index).style.display = 'none'
                    fileObject.progress = 0;
                    this.contUpload += 1
                    if (this.contUpload === this.files.length) {
                        this.sendForm = false
                        this.contUploadApproved = 0
                        this.files = []
                        this.loadPageItens()
                    }
                }).catch(() => {
                    document.getElementById('file-erro' + index).style.display = 'block'
                    document.getElementById('progress-bar' + index).style.display = 'none'
                    fileObject.progress = 0;
                    this.contUpload += 1
                    if (this.contUpload === this.files.length) {
                        this.sendForm = false
                        this.contUploadApproved = 0
                        this.files = []
                        this.loadPageItens()
                    }
                });
            });
        },

        statusHistoric(status) {
            if (parseInt(status) === 1) {
                return "Criando"
            }
            if (parseInt(status) === 2) {
                return "Enviado para Responsável"
            }
            if (parseInt(status) === 3) {
                return "Enviado para aprovação"
            }
            if (parseInt(status) === 4) {
                return "Reprovado"
            }
            if (parseInt(status) === 5) {
                return "Enviado para Responsável"
            }
            if (parseInt(status) === 6) {
                return "Finalizado"
            }
        },

        convertBytes(size) {
            if (size >= 1024 && size < 1024000) {
                size = Math.round((size / 1024) * 100) / 100;
                return `${size} KB`
            } else if (size >= 1024000) {
                size = Math.round((size / 1024000) * 100) / 100;
                return `${size} MB`
            }
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateNote', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        storeNoteObs() {
            this.$store.dispatch('storeNoteObs', this.formDateObs)
                .then((r) => {
                    this.showError = false
                    this.errors = []
                    this.formDate.comentes = r.data
                    this.formDateObs.obs = ''
                    toast.success("Observação adicionada com sucesso!", {timeout: 2000});
                }).catch((r) => {
                this.showError = true;
                this.errors = r.response.data.errors;
                this.scrollTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        loadPageItens() {
            this.$store.dispatch('updateNamePage', 'Anotações')
            this.$store.dispatch('loadNote', this.id).then((r) => {
                this.formDate = r;
                this.$refs.myEditor.setHTML(r.desc)
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 12)
                .then((r) => {
                    if (r.data.indexOf('note-edit') !== -1) {
                        this.showLoder = false
                    }
                    if (r.data.indexOf('note-create') !== -1) {
                        this.buttonCheck.create = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectSector() {
            return this.$store.state.var.selectSector
        },

        selectUserList() {
            return this.$store.state.var.selectUserList;
        },
    }
}
</script>

<style scoped>
#quillEditor {
    min-height: 700px;
}

.tabs > input,
.tabs > span,
.effect-3 .line {
    width: calc(100% / 4);
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 25%;
}

#tab-3,
#tab-3 + span,
#tab-3:checked ~ .line {
    left: 50%;
}

#tab-4,
#tab-4 + span,
#tab-4:checked ~ .line {
    left: 75%;
}

.me-rito > .container-desc {
    display: block;
}

.me-rito {
    height: 200px;
    width: 100%;
    padding: 0 20px;
    background: #ebebeb;
    overflow-x: scroll;
}

.uploadButton {
    border: 0;
    color: #fff;
    width: 45px;
    height: 45px;
    font-size: 18px;
    text-align: center;
    padding-top: 0.6rem;
    border-radius: 5px;
    background: #d67c1c;
    padding-left: 0.8rem;
    transition: background-color 0.5s ease-out;
}

div.files {
    margin: 1rem 0;
    padding: 1rem 0;
}

div.files ul {
    padding: 0;
    overflow: auto;
    list-style: none;
    max-height: 160px;
}

div.files ul li {
    width: 99%;
    padding: 0.5rem;
    background: #eee;
    margin: 0.5rem 0;
    position: relative;
}

div.files ul li button {
    right: 0;
    border: 0;
    outline: none;
    position: absolute;
    margin-right: 0.5rem;
}

div.container {
    padding: 0;
    max-width: 100%;
}

span.file-size {
    color: #999;
    padding-left: 0.5rem;
}

.file-success > i.icon {
    color: #16ab39;
}

.file-erro > i.icon {
    color: #db2828;
}

.file-success,
.file-erro {
    top: 0;
    right: 0;
    display: none;
    margin: 0.5rem;
    width: fit-content;
    position: absolute;

}

.multiselect-tag.is-user {
    padding: 5px 8px;
    border-radius: 22px;
    background: #d67c1c;
    margin: 3px 3px 8px;
}

.multiselect-tag.is-user img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    border-radius: 50%;
    border: 2px solid #ffffffbf;
}

.multiselect-tag.is-user i:before {
    color: #ffffff;
    border-radius: 50%;;
}

@keyframes Rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    display: none;
    margin: 0.5rem;
    position: absolute;
    border: 4px solid lightgray;
    border-top-color: gray;
    animation: Rotate 1.2s linear 0s infinite forwards;
}

.card-image {
    display: block;
    object-fit: contain;
    text-align: center;
}

.card-image a > img {
    display: block;
    width: 100%;
}

.card-image i.icon {
    display: initial;
    font-size: 8rem;
}

.card {
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 90%;
    min-height: auto;
    max-width: 20rem;
    margin: 1rem;
    font-size: 1rem;
    text-decoration: none;
    box-shadow: 0 0 3rem -1rem rgba(0, 0, 0, 0.5);
    transition: transform 0.1s ease-in-out, box-shadow 0.1s;
}

.card:hover {
    transform: translateY(-0.5rem) scale(1.0125);
    box-shadow: 0 0.5em 3rem -1rem rgba(0, 0, 0, 0.5);
}

.card p {
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
}

.ow-img {
    padding: 10px 10px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    width: 100%;
}

.ow-img > img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin-right: 10px;
}

.aux-row-3 {
    display: flex;
    justify-content: space-evenly
}

.window-modal {
    top: 0;
    left: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
    background-color: rgb(0, 0, 0, 0.7);
}

.window-modal .btn-close {
    top: 3%;
    right: 3%;
    z-index: 1;
    position: absolute;
    background: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFFF%27><path d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/></svg>");
}

.window-preview > img,
.window-preview > video {
    display: block;
    max-width: 80vw;
    max-height: 80vh;
    position: relative;
    background-color: rgba(0, 0, 0, 0.7);
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    transition: -webkit-transform 0.3s ease-in-out;
    -webkit-box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

progress {
    border-radius: 8px;
    border: 1px solid #fbfbfb;
}

progress::-webkit-progress-bar {
    background-color: #fbfbfb;
}

progress::-webkit-progress-value {
    background-color: #d67c1c;
}
</style>