import AdminComponent from "@/components/dashboard/AdminComponent";
import ServiceSearch from "@/views/search/ServiceSearch";
import ServiceCreate from "@/views/create/ServiceCreate";
import ServiceUpdate from "@/views/update/ServiceUpdate";
import ServiceShow from "@/views/show/ServiceShow";

export default {
    path: '/service',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'create', name: 'service-create', component: ServiceCreate },
        { path: 'search', name: 'service-search', component: ServiceSearch },
        { path: 'edit/:id', name: 'service-edit', component: ServiceUpdate, props: true },
        { path: 'show/:id', name: 'service-show', component: ServiceShow, props: true },
    ],
}