<template>
    <input :name="nameInput" class="form-control" :placeholder="placeholder" :required="requiredInput"
        :maxlength="maxlength" :minlength="minlength" :value="modelValue" type="number"
        @input="$emit('update:modelValue', $event.target.value)">

</template>
  
<script>
export default {
    name: "InputNumberComponent",

    props: {

        modelValue: {
            type: [String, Number],
            default: '',
            required: false
        },

        nameInput: {
            "required": false,
            "type": String,
            "default": ''
        },

        placeholder: {
            "required": false,
            "type": String,
            "default": ''
        },

        requiredInput: {
            "required": false,
            "type": Boolean,
            "default": false
        },

        maxlength: {
            "required": false,
            "type": Number,
        },

        minlength: {
            "required": false,
            "type": Number,
        }

    },
}
</script>
  
<style scoped>
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;

}

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;

}
</style>