export default {

    UPDATE_NAME(state, name) {
        state.namePage = name
    },

    UPDATE_SELECT_SEARCH(state, item) {
        state.selectSearch = item
    },

    UPDATE_SELECT_TASK_TYPE_LIST(state, item) {
        state.selectTypeTaskList = item
    },

    UPDATE_SELECT_USER_LIST(state, item) {
        state.selectUserList = item
    },

    UPDATE_SELECT_CLIENT_LIST(state, item) {
        state.selectClientList = item
    },

    UPDATE_SELECT_ACCOUNT_TYPE_LIST(state, item) {
        state.selectSearchAccountType = item
    },

    UPDATE_SELECT_ACCOUNT_BANK_LIST(state, item) {
        state.selectBankAccountList = item
    },

    UPDATE_SELECT_PRODUCT_LIST(state, item) {
        state.selectProductList = item
    },

    UPDATE_SELECT_PROVIDER_LIST(state, item) {
        state.selectProvider = item
    },

    UPDATE_SELECT_BUDGET_TEMPLATE(state, item) {
        state.selectBudgetTemplate = item
    },

    UPDATE_SELECT_PROPOSAL_TEMPLATE(state, item) {
        state.selectProposalTemplate = item
    },

    UPDATE_SELECT_EMAIL_CLIENT(state, item) {
        state.selectClientEmailList = item
    },

    UPDATE_SELECT_PHONE_CLIENT(state, item) {
        state.selectClientPhoneList = item
    },

    UPDATE_SELECT_CONTRACT_MODEL(state, item) {
        state.selectContractModel = item
    },

    UPDATE_SELECT_SERVICE(state, item) {
        state.selectService = item
    },

    UPDATE_SELECT_ACCOUNT_TYPE_SUB_LIST(state, item) {
        state.selectAccountTypeSubList = item
    },

    UPDATE_SELECT_GUEST_SCHEDULE_SUB_LIST(state, item) {
        state.selectGuestSchedule = item
    },

    UPDATE_SELECT_BRAND_MODEL_LIST(state, item) {
        state.selectBrandModel = item
    },

    UPDATE_SELECT_SPOT_SEGMENT_LIST(state, item) {
        state.selectSpotSegment = item
    },

    UPDATE_SELECT_EMPLOYEE_LIST(state, item) {
        state.selectEmployee = item
    },

    UPDATE_SELECT_EMPLOYEE_BANK_LIST(state, item) {
        state.selectEmployeeBank = item
    },

    UPDATE_SELECT_EMAIL_NOTIFICATION_LIST(state, item) {
        state.selectEmailNotificationList = item
    }

}