<template>
    <div class="modal-budget" v-if="modal_client ||modal_provider || modal_email || modal_phone">
        <div v-if="modal_client">
            <div class="btn-close" @click="openClient"></div>

            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Cliente:</label>
                <Multiselect v-model="client" label="name" :options="selectClientList"
                             :searchable="true"
                             :noResultsText="noResultsText" noOptionsText="noResultsText">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>

            <button-one class="p-2" @click="closeClient(client)" name-button="Confirmar"/>
        </div>

        <div v-if="modal_provider">
            <div class="btn-close" @click="openProvider()"></div>

            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Fornecedor:</label>
                <Multiselect v-model="provider" label="name" :options="selectProvider"
                             :searchable="true"
                             :noResultsText="noResultsText" noOptionsText="noResultsText">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>

            <button-one class="p-2" @click="closeProvider(id)" name-button="Confirmar"/>
        </div>

        <div v-if="modal_email">
            <div class="btn-close" @click="openEmail()"></div>

            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Email:</label>
                <Multiselect v-model="formDate.email" label="name" :options="selectClientEmailList"
                             :noResultsText="noResultsText" noOptionsText="noResultsText">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>

            <button-one class="p-3" @click="openEmail" name-button="Confirmar"/>
        </div>

        <div v-if="modal_phone">
            <div class="close-provider" @click="openPhone()">X</div>

            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Telefone:</label>
                <Multiselect v-model="formDate.phone" label="name" :options="selectClientPhoneList"
                             :searchable="true" :noResultsText="noResultsText"
                             noOptionsText="noResultsText">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>

            <button-one class="p-3" @click="openPhone" name-button="Confirmar"/>
        </div>
    </div>

    <painel-component :itens="itensOne">
        <button-add :itens="itensThree" v-if="buttonCheck.create"/>
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div class="tabs effect-3">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="money bill alternate outline icon"></i>
                <p>Orçamento</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form autocomplete="off">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button-one :icon="'send icon'" tooltip="Enviar para cliente"
                                                v-if="formDate.status <= 1"
                                                @submit="updateBudgetSendClient"/>

                                    <button-one :icon="'check circle'" tooltip="Aprovar" @submit="updateBudgetApproved"
                                                v-if="parseInt(formDate.status) !== 3 || parseInt(formDate.status) !== 4"/>

                                    <button-one :icon="'window close'" tooltip="Cancelar" @submit="updateBudgetCancel"
                                                v-if="parseInt(formDate.status) < 2 || parseInt(formDate.status) === 5"/>
                                </div>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 '">
                                    

                                <div class="cont-status">
                                    <button-one icon="copy" @click="cloneBudget" class="fs-4 color bg-transparent border-0"/>
   
                                    <a tooltip="Imprimir PDF" :href="formDate.link_budget_pdf" target="_blank">
                                        <i class="print icon"></i>
                                    </a>
                                </div>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                <label>Url:</label>
                                <div class="input-group" role="button" @click="copyUrl(formDate.link_budget)">
                                    <input role="button" class="form-control" type="text" v-model="formDate.link_budget"
                                           @focus="$event.target.select()" ref="select" readonly>
                                    <span @click="copyUrl(formDate.link_budget)" class="input-group-text my_btn"
                                          id="basic-addon1">
                                        <i :class="[copy ? 'copy icon' : 'check circle icon']"></i>
                                    </span>
                                </div>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Status:</label>
                                <input-component :type-input="'text'" v-model="statusInput" disabled/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'"
                                    :class="{ 'formError': errors.budget_template_id }">
                                <label>Modelo de Orçamento:</label>
                                <multiselect v-model="formDate.budget_template_id" :searchable="true"
                                             :disabled="formDate.status > 1" :options="selectBudgetTemplate"
                                             :noResultsText="noResultsText" noOptionsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.status }">
                                <label>Dias Válido:</label>
                                <input-number-component :type-input="'number'" min="15" v-model="formDate.valid"
                                                        :disabled="formDate.status > 1"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                    :class="{ 'formError': errors.name }">
                                <label>Nome:</label>
                                <input-component :type-input="'text'" v-model="formDate.name"
                                                 :disabled="formDate.status > 1"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                    :class="{ 'formError': errors.client_name }">
                                <label>Cliente:</label>
                                <div class="input-group">
                                    <input class="form-control" type="text" v-model="formDate.client_name"
                                           :disabled="formDate.status > 1">
                                    <span @click="openClient" class="input-group-text my_btn" id="basic-addon1">
                                <i class="search icon"></i>
                            </span>
                                </div>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                <label>Fornecedor:</label>
                                <div class="input-group">
                                    <input class="form-control" type="text" v-model="formDate.provider_name"
                                           :disabled="formDate.status > 1">
                                    <span @click="openProvider()" class="input-group-text my_btn" id="basic-addon1">
                                        <i class="search icon"></i>
                                    </span>
                                </div>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 pt-2 mt-4'"
                                    :class="{ 'formError': errors.show_provider }">
                                <label>Exibir Fornecedor?</label>

                                <input type="radio" id="show-provide" v-model="formDate.show_provider" value="1"
                                       :disabled="formDate.status > 1">
                                <label for="show-provide">Não</label>

                                <input type="radio" id="not-show-provide" v-model="formDate.show_provider" value="2"
                                       :disabled="formDate.status > 1">
                                <label for="not-show-provide">Sim</label>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.responsible }">
                                <label>Email:</label>
                                <div class="input-group">
                                    <input class="form-control" type="text" v-model="formDate.email"
                                           :disabled="formDate.status > 1">
                                    <span @click="openEmail()" class="input-group-text my_btn" id="basic-addon1">
                                        <i class="search icon"></i>
                                    </span>
                                </div>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Telefone:</label>
                                <div class="input-group">
                                    <input class="form-control" type="text" v-model="formDate.phone"
                                           :disabled="formDate.status > 1">
                                    <span @click="openPhone()" class="input-group-text my_btn" id="basic-addon1">
                                <i class="search icon"></i>
                            </span>
                                </div>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                    :class="{ 'formError': errors.responsible }">
                                <label>Responsável</label>
                                <input-component :type-input="'text'" v-model="formDate.responsible"
                                                 :disabled="formDate.status > 1"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Observações:</label>
                                <input-text-area :rows="4" v-model="formDate.obs" :disabled="formDate.status > 1"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component v-if="formDate.status <= 1"
                                               :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <button-one class="w-100" @submit="submit" :icon="'save'" :name-button="'Salvar'"
                                            :class="{ 'preloderSend': sendForm }"/>
                            </row-col-component>

                            <row-col-component :class="{'col-xxl-12 col-xl-12 col-lg-12' : formDate.status >= 2}"
                                               :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <button-float class="w-100" :itens="itensTwo"/>
                            </row-col-component>
                        </row-component>
                    </form>

                    <div class="line-division mt-5 mb-5">
                        <h1 class="h1-text"><i class="comment icon"></i> Observações Internas</h1>
                    </div>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <label>Observações:</label>
                            <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                                 v-if="formDate.obsPrivate.length === 0">
                                <p>Não há nada cadastrado</p>
                            </div>

                            <div class="merito" v-else>
                                <section class="container-desc">
                                    <ul class="task-desc">
                                        <li class="item" v-for="(obs, index) in formDate.obsPrivate" :key="index">
                                            <div class="aux-row">
                                                <div class="user">
                                                    <img :src="obs.user_photo" alt="Image 001" class="owner-img"/>
                                                    {{ obs.user_name }}
                                                </div>
                                                <div class="desc-date">
                                                    <div class="text"><i class="calendar icon"></i>{{ obs.date_create }}
                                                    </div>
                                                    <div class="text"><i class="clock icon"></i>{{ obs.hour_create }}
                                                    </div>
                                                </div>
                                            </div>
                                            <p>{{ obs.obs }}</p>
                                        </li>
                                    </ul>
                                </section>
                            </div>
                        </row-col-component>
                    </row-component>

                    <form autocomplete="off">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Novas Observações:</label>
                                <input-text-area :rows="4" v-model="formDateObs.obs"/>
                            </row-col-component>
                        </row-component>
                    </form>

                    <button-one @submit="submitObs" :icon="'plus circle'" :name-button="'Adicionar Observação'"
                                :class="{ 'preloderSend': sendForm }"/>

                    <div class="line-division mt-5 mb-5">
                        <h1 class="h1-text"><i class="history icon"></i> Histórico de interações</h1>
                    </div>

                    <section class="container-desc-two">
                        <ul class="task-desc">
                            <li class="item" v-for="(historic, index) in formDate.historic" :key="index">
                                <div class="aux-row">
                                    <div class="user">
                                        <img :src="historic.user_photo" alt="Image 001" class="owner-img"/>
                                        {{ historic.name_user }}
                                    </div>
                                    <div class="desc-date">
                                        <div class="text"><i class="calendar icon"></i>{{ historic.date_create }}</div>
                                        <div class="text"><i class="clock icon"></i>{{ historic.hour_create }}</div>
                                    </div>
                                </div>
                                <p>
                                    {{ statusHistoric(historic.status) }},
                                    Plataforma: {{ historic.platform }},
                                    Navegador: {{ historic.browser }},
                                    Endereço IP: {{ historic.ip }}
                                </p>
                            </li>
                        </ul>
                    </section>
                </section>

                <section id="tab-item-2">
                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <div class="clearfix" v-if="formDate.status <= 1">
                                <button-one icon="plus" class="rounded-0 float-end" @click="addBudget"/>
                            </div>

                            <draggable
                                    v-model="formDate.itens"
                                    group="people"
                                    @start="drag=true"
                                    @end="drag=false"
                                    ghost-class="ghost"
                                    item-key="id">
                                <template #item="{element, index}">
                                    <div class="border-content">
                                        <div class="indexCount">{{ index + 1 }}</div>
                                        <form autocomplete="off">
                                            <div v-if="formDate.status <= 1">
                                                <button-remove-round
                                                        class="rounded-0 position-absolute end-0 bottom-0 m-2"
                                                        v-if="formDate.itens.length > 1" @click="budgetRemove(index)"/>
                                            </div>

                                            <row-component>
                                                <row-col-component
                                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                                    <label>Produto/Serviço:</label>
                                                    <input-component :type-input="'text'" v-model="element.name"
                                                                     :disabled="formDate.status > 1"/>
                                                </row-col-component>

                                                <row-col-component
                                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                                    <label>Quantidade:</label>
                                                    <input-number-component :type-input="'number'" min="1"
                                                                            v-model="element.amount"
                                                                            :disabled="formDate.status > 1"
                                                                            placeholder="1"
                                                                            @keyup="calcBudget(index)"/>
                                                </row-col-component>

                                                <row-col-component
                                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                                    <label>Valor Unitário:</label>
                                                    <input-component :type-input="'text'" v-model="element.unit_price"
                                                                     :disabled="formDate.status > 1"
                                                                     @keyup="calcBudget(index)"
                                                                     v-money="money" :maxlength="12"/>
                                                </row-col-component>

                                                <row-col-component
                                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                                    <label>Valor Total:</label>
                                                    <input-component :type-input="'text'" v-model="element.total_price"
                                                                     v-money="money" disabled/>
                                                </row-col-component>
                                            </row-component>
                                        </form>
                                    </div>
                                </template>
                            </draggable>

                            <h4>Total: R$ {{ totalResult }}</h4>
                        </row-col-component>
                    </row-component>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputNumberComponent from "@/components/form/InputNumberComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonAdd from "@/components/buttons/ButtonAdd"
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {ACTION_MESSAGES} from "@/configs/constants";
import {MONEY} from "@/configs/constants"
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";
import ButtonRemoveRound from "@/components/buttons/ButtonRemoveRound";
import draggable from "vuedraggable";

const toast = useToast();

export default {
    name: "BudgetUpdate",
    components: {
        draggable,
        ButtonRemoveRound,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        ButtonAdd,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea,
        InputNumberComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Orçamento')
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/budget/search",
                icon: "reply all",
                name: 'Voltar'
            },

            itensThree: {
                route: "/budget/create",
                icon: "plus",
                name: 'Adicionar'
            },

            buttonCheck: {
                create: false,
            },

            formDate: {
                budget_template_id: '',
                name: '',
                status: '',
                provider_id: '',
                provider_name: '',
                client_id: '',
                client_name: '',
                responsible: '',
                email: '',
                phone: '',
                show_provider: '',
                valid: '',
                obs: '',
                link_budget: '',
                link_budget_pdf: '',
                obsPrivate: [],
                itens: [],
                historic: []
            },

            formDateObs: {
                id: this.id,
                obs: ''
            },

            client: '',
            provider: '',
            copy: true,

            statusInput: '',
            totalResult: 0,

            drag: false,

            modal_client: false,
            modal_provider: false,
            modal_phone: false,
            modal_email: false,
            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateBudget', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        submitObs() {
            this.$store.dispatch('storeBudgetObservation', this.formDateObs)
                .then(() => {
                    this.showError = false
                    this.sendForm = false
                    this.errors = []
                    this.loadPageItens()
                    this.formDateObs.obs = ''
                    toast.success('Observção adicionada com sucesso!', {timeout: 2000});
                }).catch((r) => {
                this.showError = true;
                this.sendForm = false
                this.errors = r.response.data.errors;
                this.scrollTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        updateBudgetSendClient() {
            this.$store.dispatch('updateBudgetSendClient', this.id).then(() => {
                this.loadPageItens()
                toast.success("Enviado para o cliente com sucesso", {timeout: 2000});
            }).catch(() => {
                toast.error("Não foi possível alterar o status!", {timeout: 2000});
            })
        },

        updateBudgetCancel() {
            this.$store.dispatch('updateBudgetCancel', this.id).then(() => {
                this.loadPageItens()
                toast.success("Orçamento cancelado com sucesso", {timeout: 2000});
            }).catch(() => {
                toast.error("Não foi possível alterar o status!", {timeout: 2000});
            })
        },

        updateBudgetApproved() {
            this.$store.dispatch('updateBudgetApproved', this.id).then(() => {
                this.loadPageItens()
                toast.success("Orçamento aprovado com sucesso", {timeout: 2000});
            }).catch(() => {
                toast.error("Não foi possível alterar o status!", {timeout: 2000});
            })
        },

        loadPageItens() {
            this.$store.dispatch('loadBudget', this.id).then((r) => {
                this.formDate = r;
                this.calcTotal()
                this.$store.dispatch('loadProviderSelect')
                this.$store.dispatch('loadBudgetTemplateSelect')
                this.$store.dispatch('loadClientListSelect')
                this.nullInputArray()
                this.statusString()
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        statusString() {
            if (parseInt(this.formDate.status) === 1) {
                this.statusInput = "Cadastro"
            }
            if (parseInt(this.formDate.status) === 2) {
                this.statusInput = "Enviado para cliente"
            }
            if (parseInt(this.formDate.status) === 3) {
                this.statusInput = "Aprovado pelo cliente"
            }
            if (parseInt(this.formDate.status) === 4) {
                this.statusInput = "Aprovado pela D7M"
            }
            if (parseInt(this.formDate.status) === 5) {
                this.statusInput = "Cancelado"
            }
        },

        statusHistoric(status) {
            if (parseInt(status) === 1) {
                return "Enviado para cliente"
            }
            if (parseInt(status) === 2) {
                return "Aprovado pelo cliente"
            }
            if (parseInt(status) === 3) {
                return "Aprovado pela D7M"
            }
            if (parseInt(status) === 4) {
                return "Cancelado"
            }
        },

        openClient() {
            this.modal_client = !this.modal_client
            if (this.formDate.status > 1)
                this.modal_client = false
        },

        closeClient(id) {
            this.modal_client = !this.modal_client
            this.getClient(id)

        },

        openProvider() {
            this.modal_provider = !this.modal_provider
            if (this.formDate.status > 1)
                this.modal_provider = false
        },

        closeProvider(id) {
            this.modal_provider = !this.modal_provider
            this.getProvider(id)
        },

        openEmail() {
            this.modal_email = !this.modal_email
            if (this.formDate.status > 1)
                this.modal_email = false
        },

        openPhone() {
            this.modal_phone = !this.modal_phone
            if (this.formDate.status > 1)
                this.modal_phone = false
        },

        copyUrl(url) {
            navigator.clipboard.writeText(url)
            this.$refs.select.focus()
            this.copy = false
            setTimeout(() => {
                this.copy = true
            }, 2000)
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        nullInputArray() {
            if (this.formDate.itens.length === 0)
                this.addBudget()
        },

        addBudget() {
            this.formDate.itens.push({
                name: '',
                amount: '',
                unit_price: '',
                total_price: ''
            })
        },

        budgetRemove(index) {
            this.formDate.itens.splice(index, 1)
            this.calcTotal()
        },

        getClient(id) {
            this.$store.dispatch('loadClient', id).then((r) => {
                this.formDate.client_id = r.id
                this.formDate.client_name = r.surname
                this.$store.dispatch('loadEmailClient', r.id)
                this.$store.dispatch('loadPhoneClient', r.id)
            })
        },

        getProvider(id) {
            this.$store.dispatch('loadProvider', id).then((r) => {
                this.formDate.provider_id = r.id
                this.formDate.provider_name = r.surname
            })
        },

        calcBudget(index) {
            let budget = this.formDate.itens[index];
            budget.unit_price.toLocaleString('pt-br', {minimumFractionDigits: 2});
            let value = budget.unit_price.replace(/[.,]/g, '')
            let amount = budget.amount
            let result = (value * amount / 100)
            budget.total_price = result.toLocaleString('pt-br', {minimumFractionDigits: 2})
            this.calcTotal()
        },

        calcTotal() {
            let sum = 0;
            if (this.totalResult > 0)
                sum = this.replaceStringOne(this.totalResult)
            this.formDate.itens.forEach(item => {
                sum += this.replaceStringOne(item.total_price.replace(/[.,]/g, '')) / 100;
            });
            this.totalResult = sum.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        },

        replaceStringOne(label) {
            if (label.length < 8) {
                return parseFloat(label.replace(',', '.'))
            } else {
                return parseFloat(label.replace(',', ''))
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 25)
                .then((r) => {
                    if (r.data.indexOf('budget-edit') !== -1) {
                        this.showLoader = false
                    }
                    if (r.data.indexOf('budget-create') !== -1) {
                        this.buttonCheck.create = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        cloneBudget() {
            // Clonar profundamente os itens para evitar referências compartilhadas
            let clonedItens = this.formDate.itens.map(item => ({
                name: item.name,
                unit_price: item.unit_price,
                amount: item.amount,
                total_price: item.total_price
            }));

            // Adiciona os dados clonados ao storeState
            this.storeState.push({
                valid: this.formDate.valid,
                status: this.formDate.status,
                budget_template_id: this.formDate.budget_template_id,
                name: this.formDate.name,
                client_name: this.formDate.client_name,
                provider_name: this.formDate.provider_name,
                show_provider: this.formDate.show_provider,
                email: this.formDate.email,
                phone: this.formDate.phone,
                obs: this.formDate.obs,
                responsible: this.formDate.responsible,
                itens: clonedItens  
            });

            // Redireciona para a página 'budget-create'
            this.$router.push({ name: 'budget-create' });
        },
    },


    mounted() {
        this.formDate.itens.push({
            name: '',
            amount: '',
            unit_price: '',
            total_price: ''
        })
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        selectProvider() {
            return this.$store.state.var.selectProvider
        },

        selectBudgetTemplate() {
            return this.$store.state.var.selectBudgetTemplate
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectClientEmailList() {
            return this.$store.state.var.selectClientEmailList
        },

        selectClientPhoneList() {
            return this.$store.state.var.selectClientPhoneList
        },
        
        storeState() {
            return this.$store.state.var.storeState
        },
    },

}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.ghost {
    opacity: 0.5;
    background: #d67c1c !important;
}

.modal-budget {
    top: 50%;
    left: 50%;
    width: 40%;
    z-index: 9999;
    position: fixed;
    min-height: auto;
    background: white;
    padding: 40px 20px;
    margin-right: -50%;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.btn-close {
    top: 0;
    right: 0;
    cursor: pointer;
    margin: 10px 20px;
    position: absolute;
}

.my_btn {
    color: white;
    cursor: pointer;
    position: relative;
    background: #d67c1c;
    padding-bottom: 15px;
    border: 1px solid #d67c1c;
}

.border-content {
    margin-top: 20px;
    position: relative;
    background: #ebebeb;
    padding: 30px 20px 40px;
}

.line-division {
    position: relative;
    width: 100%;
    border-top: 1px solid #c4c4c4;
}

.h1-text {
    margin-top: 1px;
    width: fit-content;
    padding: 10px;
    background-color: #d67c1c;
    color: white;
    font-size: 16px;
}

h4 {
    color: #000;
    text-align: end;
    font-size: 16px;
    padding: 10px 15px;
    background-color: #c9c9c9;
}

.merito {
    width: 100%;
    height: 200px;
    padding: 0 20px;
    overflow-x: scroll;
    background: #ebebeb;
}

ul.task-desc li.item {
    display: flex;
    margin: 20px 0;
    padding: 0 20px;
    box-shadow: none;
    position: relative;
    align-items: flex-start;
    background: transparent;
    flex-direction: column;
    justify-content: space-between;
}

.cont-status {
    width: 100%;
    display: flex;
    margin-top: 20px;
    font-size: 1.5rem;
    justify-content: flex-end;
    align-items: baseline;
}

.cont-status a {
    color: #d67c1c;
    text-decoration: none;
}

.desc-date {
    width: 280px;
    display: flex;
    margin: 10px 0;
    align-items: center;
    justify-content: space-between;
}

.aux-row {
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container-desc-two > ul.task-desc li.item {
    display: flex;
    padding: 20px;
    background: #fff;
    position: relative;
    border-radius: 5px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
}

.indexCount {
    top: 0;
    left: 0;
    width: 20px;
    height: 25px;
    display: flex;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    border-radius: 50%;
    background: #d67c1c;
    align-items: center;
    justify-content: center;
}

.color {
    color: #d67c1c !important;
}

@media (max-width: 991px) {
    .modal-budget{
        width: 95%;
    }
}
</style>
