<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form>
            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-5'">
                <input disabled type="radio" id="manual" v-model="formDate.type" value="1">
                <label for="manual">Manual</label>

                <input disabled type="radio" id="sistema" v-model="formDate.type" value="2">
                <label for="sistema">Sistema</label>

                <input disabled type="radio" id="pl" v-model="formDate.type" value="3">
                <label for="pl">PI</label>
            </row-col-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.client_id }">
                    <label>Cliente:</label>
                    <Multiselect disabled v-model="formDate.client_id" label="name" :options="selectClientList"
                                 :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.number }">
                    <label>Nº:</label>
                    <input-number-component disabled :type-input="'number'" v-model="formDate.number"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.services }">
                    <label>Serviços:</label>
                    <Multiselect disabled v-model="formDate.services" mode="tags" :close-on-select="false"
                                 :searchable="true" :create-option="true" :options="selectService"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.signature_date }">
                    <label>Data Assinatura:</label>
                    <input-component disabled :type-input="'text'" v-model="formDate.signature_date"
                                     v-mask="'##/##/####'"/>
                </row-col-component>
            </row-component>
        </form>

        <form>
            <div class="line-division mt-5 mb-5">
                <h1 class="h1-text"><i class="comment icon"></i> Observações Internas</h1>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>

                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formDate.obsPrivate.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <div class="merito" v-else>
                        <section class="container-desc">
                            <ul class="task-desc">
                                <li class="item" v-for="(obs, index) in formDate.obsPrivate" :key="index">
                                    <div class="aux-row">
                                        <div class="user">
                                            <img :src="obs.user_photo" alt="Image 001" class="owner-img"/>
                                            {{ obs.user_name }}
                                        </div>
                                        <div class="desc-date">
                                            <div class="text">
                                                <i class="calendar icon"></i>{{ obs.date_create }}
                                            </div>
                                            <div class="text">
                                                <i class="clock icon"></i>{{ obs.hour_create }}
                                            </div>
                                        </div>
                                    </div>
                                    <p>{{ obs.obs }}</p>
                                </li>
                            </ul>
                        </section>
                    </div>
                </row-col-component>
            </row-component>
        </form>

        <form>
            <div class="line-division mt-5 mb-5">
                <h1 class="h1-text"> Vencimento</h1>
            </div>

            <section class="container-desc-two">
                <ul class="task-desc">
                    <li class="item" v-for="(data, index) in formDate.datesExpiration" :key="index">
                        <div class="aux-row">
                            <div class="user">
                                <img :src="data.user_photo" alt="Image 001" class="owner-img"/>
                                {{ data.creator_user_name }}
                            </div>
                            <div class="tag-status">{{ stringStatus(data.status) }}</div>
                        </div>
                        <br>
                        <div class="desc-date-two">
                            <div class="aux-one">
                                <div class="text">Quantidade de Meses: {{ data.quantity_months }}</div>
                                <div class="text">
                                    <i class="calendar icon"></i>Data de Início: {{ data.date_start }}
                                </div>
                                <div class="text">
                                    <i class="calendar icon"></i>Data Final: {{ data.date_end }}
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </section>
        </form>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import InputNumberComponent from "@/components/form/InputNumberComponent";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "ContractExpirationShow",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonFloat,
        ErrorComponent,
        LoderSendComponent,
        InputNumberComponent
    },

    directives: {
        mask,
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.checkACL()
        this.loadContractExpiration()
        this.$store.dispatch('updateNamePage', 'Vencimento')
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "eye"
            },

            itensTwo: {
                route: "/contract-expiration/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                type: '',
                client_id: '',
                number: '',
                signature_date: '',
                services: [],
                obsPrivate: [],
                datesExpiration: []
            },

            errors: [],
            showError: false,
            showLoader: true,
        }
    },

    methods: {
        stringStatus(value) {
            if (parseInt(value) === 1)
                return "Cadastrado"
            if (parseInt(value) === 2)
                return "Em andamento"
            if (parseInt(value) === 3)
                return "Pausado"
            if (parseInt(value) === 4)
                return "Cancelado"
            if (parseInt(value) === 5)
                return "Finalizado"
        },

        loadContractExpiration() {
            this.$store.dispatch('loadContractExpiration', this.id)
                .then((r) => {
                    this.formDate = r
                    this.$store.dispatch('loadServiceList')
                    this.$store.dispatch('loadClientListSelect')
                })
                .catch(() => {
                    toast.error("Erro desconhecido !", {timeout: 2000});
                })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 36)
                .then((r) => {
                    if (r.data.indexOf('contract-expiration-search') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        selectService() {
            return this.$store.state.var.selectService
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        }
    },
}
</script>

<style scoped>
.merito {
    width: 100%;
    height: 200px;
    padding: 0 20px;
    overflow-x: scroll;
    background: #ebebeb;
}

section.container-desc-two,
.merito > .container-desc {
    display: block;
}

ul.task-desc li.item {
    display: flex;
    margin: 20px 0;
    padding: 0 20px;
    box-shadow: none;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    background: transparent;
    justify-content: space-between;
}

ul.task-desc li.item p {
    word-break: break-all;
}

.container-desc-two > ul.task-desc li.item {
    display: flex;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
}

.desc-date-two {
    width: 98%;
    display: flex;
    flex-wrap: wrap;
}

.aux-one {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.tag-status {
    color: #fff;
    padding: 0 0.5rem;
    font-weight: bold;
    width: fit-content;
    border-radius: 20px;
    background-color: #d67c1c;
}

.line-division {
    position: relative;
}

@media (max-width: 991px) {
    .desc-date-two {
        width: 100%;
        flex-direction: column;
    }

    .aux-one {
        width: 100%;
        margin: 0.5rem 0;
    }

    .text {
        margin: 0.5rem 0;
    }
}
</style>