<template>
    <div class="modal-w position-fixed start-0 top-0 d-grid" v-if="clauses.show">
        <div class="modal-p position-relative p-4 pt-5" v-if="clauses.show">
            <div role="button" @click="clausesClose" class="btn-close"></div>

            <form>
                <row-component>
                    <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <label>Importante:</label>
                        <checkbox-component v-if="!isEdit" class="my-2" @checkboxSwitcher="importantChange"/>
                        <checkbox-component v-else class="my-2"
                                            :input-checked="parseInt(clauses.formData.important) === 2"
                                            @checkboxSwitcher="importantChange"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <QuillEditor style="min-height: 200px" :contentType="'html'" :toolbar="toolbar"
                                     ref="quill-editor" theme="snow" v-model:content="clauses.formData.item"/>
                    </row-col-component>
                </row-component>

                <row-component class="mt-5">
                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-one v-if="!isEdit" @click="submitClause" icon="plus" name-button="Adicionar"/>
                        <button-one v-else @click="updateClause" icon="save" name-button="Salvar"/>
                    </row-col-component>
                </row-component>
            </form>
        </div>
    </div>

    <div class="modal-sections-contract" v-if="modal_email || modal_phone">
        <div v-if="modal_email">
            <div class="btn-close" @click="openEmail"></div>
            <row-col-component
                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Email:</label>
                <Multiselect v-model="formDate.email" label="name" :options="selectClientEmailList"
                             :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>
            <button-one @click="openEmail" name-button="Confirmar"/>
        </div>

        <div v-if="modal_phone">
            <div class="btn-close" @click="openPhone"></div>
            <row-col-component
                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Telefone:</label>
                <Multiselect v-model="formDate.phone" label="name" :options="selectClientPhoneList"
                             :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>
            <button-one @click="openPhone" name-button="Confirmar"/>
        </div>
    </div>

    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div class="tabs effect-3" v-else>
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="file icon"></i>
                <p>Contrato</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">

                <section id="tab-item-1">
                    <div class="clearfix mb-3">
                        <div class="btn-group float-end" role="group">
                            <button @click="printContract" class="btn btn-main-two">
                                <i class="print icon"></i>
                            </button>

                            <button @click="routeAdd" class="btn btn-main-two" v-if="buttonCheck.create">
                                <i class="plus icon"></i>
                            </button>
                        </div>
                    </div>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.contract_models_id }">
                            <label>Modelo de Contrato:</label>
                            <Multiselect v-model="formDate.contract_models_id" :searchable="true"
                                         :disabled="formDate.status >= 3" :options="selectContractModel"
                                         @select="getContractModel(formDate.contract_models_id)"
                                         :noResultsText="this.$store.state.var.selectNotSearch"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                            <label>Status:</label>
                            <input-component disabled :type-input="'text'" v-model="this.status"
                                             :model-value="this.status"/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.name }">
                            <label>Nome do Contrato:</label>
                            <input-component :type-input="'text'" v-model="formDate.name" :model-value="formDate.name"/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.client_id }">
                            <label>Cliente:</label>
                            <Multiselect v-model="formDate.client_id" label="name" :options="selectClientList"
                                         :disabled="formDate.status >= 3" @select="getClient(client)" :searchable="true"
                                         :noResultsText="this.$store.state.var.selectNotSearch">
                                <template v-slot:singlelabel="{ value }">
                                    <div class="multiselect-single-label">
                                        <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                    </div>
                                </template>
                                <template v-slot:option="{ option }">
                                    <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                                </template>
                            </Multiselect>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.email }">
                            <label>Email:</label>
                            <div class="input-group">
                                <input class="form-control" type="email" v-model="formDate.email"
                                       :disabled="formDate.status >= 3">
                                <span @click="openEmail" class="input-group-text my_btn" id="basic-addon1">
                                    <i class="search icon"></i>
                                </span>
                            </div>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                            <label>Telefone:</label>
                            <div class="input-group">
                                <input class="form-control" type="text" v-model="formDate.phone"
                                       :disabled="formDate.status >= 3" v-mask="['(##) ####-####', '(##) #####-####']">
                                <span @click="openPhone" class="input-group-text my_btn" id="basic-addon1">
                                    <i class="search icon"></i>
                                </span>
                            </div>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.business }">
                            <label>Serviços:</label>
                            <Multiselect v-model="formDate.services" mode="tags" :close-on-select="false"
                                         :disabled="formDate.status >= 3" :searchable="true"
                                         :noResultsText="this.$store.state.var.selectNotSearch"
                                         :options="selectService"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.business }">
                            <label>Negócios:</label>
                            <multiselect v-model="formDate.business" :searchable="true" :options="selectBusiness"
                                         :disabled="formDate.status >= 3"
                                         :noResultsText="this.$store.state.var.selectNotSearch"/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <label>Observações:</label>
                            <input-text-area rows="4" v-model="formDate.obs" disabled/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                           v-if="formDate.status < 3">
                            <button-one class="w-100" :name-button="'Salvar'" :class="{ 'preloderSend': sendForm }"
                                        @submit="submit"
                                        :icon="'save'"/>
                        </row-col-component>

                        <row-col-component :class="{'col-xxl-6 col-xl-6 col-lg-6' : formDate.status > 3}"
                                           :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <button-float class="w-100" :itens="itensTwo"/>
                        </row-col-component>
                    </row-component>

                    <div class="line-division mt-5 mb-5">
                        <h1 class="h1-text"><i class="comment icon"></i> Observações Internas</h1>
                    </div>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <label>Observações:</label>
                            <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                                 v-if="formDate.obsPrivate.length === 0">
                                <p>Não há nada cadastrado</p>
                            </div>

                            <div class="merito" v-else>
                                <section class="container-desc">
                                    <ul class="task-desc">
                                        <li class="item" v-for="(obs, index) in formDate.obsPrivate" :key="index">
                                            <div class="aux-row">
                                                <div class="user">
                                                    <img :src="obs.user_photo" alt="Image 001" class="owner-img"/>
                                                    {{ obs.user_name }}
                                                </div>
                                                <div class="desc-date">
                                                    <div class="text">
                                                        <i class="calendar icon"></i>{{ obs.date_create }}
                                                    </div>
                                                    <div class="text">
                                                        <i class="clock icon"></i>{{ obs.hour_create }}
                                                    </div>
                                                </div>
                                            </div>
                                            <p>{{ obs.obs }}</p>
                                        </li>
                                    </ul>
                                </section>
                            </div>
                        </row-col-component>
                    </row-component>

                    <form autocomplete="off">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Novas Observações:</label>
                                <input-text-area rows="4" v-model="formDateObs.obs"/>
                            </row-col-component>
                        </row-component>
                    </form>

                    <button-one @submit="submitObs" :icon="'plus circle'" :name-button="'Adicionar Observação'"
                                :class="{ 'preloderSend': sendForm }"/>
                </section>

                <section id="tab-item-2">
                    <button-one @click="this.clauses.show = true" icon="plus"/>

                    <div class="alert alert-secondary alert-dismissible fade show mt-3" role="alert"
                         v-if="formDate.itens.length === 0">
                        <p>Não há cláusulas cadastradas</p>
                    </div>

                    <draggable v-else v-model="formDate.itens" @start="drag=true" @end="onEnd" item-key="id">
                        <template #item="{element, index}">
                            <div class="accordion mt-3" :id="'accordionExample' + index">
                                <div class="accordion-item position-relative">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                :data-bs-target="'#collapseOne' + index" aria-expanded="true"
                                                aria-controls="collapseOne">
                                            Cláusula {{ index + 1 }}
                                        </button>

                                        <div class="btn-group two" role="group">
                                            <button class="btn btn-main" @click="clausesEdit(element)">
                                                <i class="edit icon"></i>
                                            </button>

                                            <button class="btn btn-main" @click="deleteClause(element)">
                                                <i class="trash icon"></i>
                                            </button>
                                        </div>
                                    </h2>

                                    <div :id="'collapseOne' + index" class="accordion-collapse collapse show"
                                         :data-bs-parent="'#accordionExample' + index">
                                        <div class="accordion-body">
                                            <row-component>
                                                <row-col-component
                                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                                    <label>Importante:</label>
                                                    <checkbox-component class="my-2" disabled
                                                                        :input-checked="parseInt(element.important) === 2"/>
                                                </row-col-component>
                                            </row-component>

                                            <QuillEditor :contentType="'html'" theme="bubble" read-only
                                                         v-model:content="element.item"
                                                         style="min-height: 200px; border: 1px solid #d1d5db"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </draggable>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import InputTextArea from "@/components/form/InputTextArea";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import CheckboxComponent from "@/components/form/CheckboxComponent";
import draggable from "vuedraggable";
import {QuillEditor, Quill} from "@vueup/vue-quill";
import swal from "sweetalert";
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import {MONEY} from "@/configs/constants"
import {ACTION_MESSAGES} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast();

let Font = Quill.import('formats/font');
Font.whitelist = ['serif', 'monospace', 'Calibri'];

Quill.register(Font, true);

export default {
    name: "ContractUpdate",
    components: {
        QuillEditor,
        CheckboxComponent,
        draggable,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        InputTextArea,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
    },

    directives: {
        mask,
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Contrato')
        this.$store.dispatch('loadContractModelList')
        this.$store.dispatch('loadClientListSelect')
        this.$store.dispatch('loadServiceList')
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/contract/search",
                icon: "reply all",
                name: 'Voltar'
            },

            itensThree: {
                route: "/contract/create",
                icon: "plus",
                name: 'Adicionar'
            },

            buttonCheck: {
                create: false,
                send_client: false,
                approved: false,
                contract_new: false,
                cancel: false
            },

            formDate: {
                code: "",
                proposal_id: "",
                contract_models_id: "",
                name: "",
                client_id: "",
                cliente_name: "",
                email: "",
                phone: "",
                business: "",
                status: "",
                version: "",
                obs: "",
                printContract: "",
                services: [],
                itens: [],
                obsPrivate: []
            },

            formDateObs: {
                id: this.id,
                obs: ''
            },

            status: '',
            money: MONEY,

            modal_phone: false,
            modal_email: false,

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,

            drag: false,

            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{'header': 1}, {'header': 2}],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{'direction': 'rtl'}],
                [{'size': ['small', false, 'large', 'huge']}],
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'color': []}, {'background': []}],
                [{'align': []}],
                ['clean']
            ],

            important: 1,
            isEdit: false,

            clauses: {
                show: false,
                formData: {
                    contract_id: this.id,
                    item: '',
                    important: '',
                    order: ''
                }
            }
        }
    },

    methods: {
        loadPageItens() {
            this.$store.dispatch('loadContract', this.id).then((r) => {
                this.formDate = r;
                this.statusString()
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateContract', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 28)
                .then((r) => {
                    if (r.data.indexOf('contract-edit') !== -1) {
                        this.showLoader = false
                    }
                    if (r.data.indexOf('contract-approved') !== -1) {
                        this.showLoader = false
                        this.buttonCheck.approved = true
                        this.buttonCheck.send_client = true
                    }

                    if (r.data.indexOf('contract-cancel') !== -1) {
                        this.showLoader = false
                        this.buttonCheck.cancel = true
                    }

                    if (r.data.indexOf('contract-version') !== -1) {
                        this.showLoader = false
                        this.buttonCheck.contract_new = true
                    }
                    if (r.data.indexOf('contract-create') !== -1) {
                        this.buttonCheck.create = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        submitObs() {
            this.$store.dispatch('storeContractObservation', this.formDateObs)
                .then(() => {
                    this.showError = false
                    this.sendForm = false
                    this.errors = []
                    this.loadPageItens()
                    this.formDateObs.obs = ''
                    toast.success('Observação adicionada com sucesso!', {timeout: 2000});
                }).catch((r) => {
                this.showError = true;
                this.sendForm = false
                this.errors = r.response.data.errors;
                this.scrollTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        routeAdd() {
            this.$router.push({name: 'contract-create'})
        },

        printContract() {
            window.open(this.formDate.printContract, '_blank')
        },

        // Status strings functions
        statusHistoric(status) {
            if (parseInt(status) === 1) {
                return "Enviado para cliente"
            }
            if (parseInt(status) === 2) {
                return "Aprovado pelo cliente"
            }
            if (parseInt(status) === 3) {
                return "Aprovado pela D7M"
            }
            if (parseInt(status) === 4) {
                return "Cancelado"
            }
            if (parseInt(status) === 4) {
                return "Nova Versão"
            }
        },

        statusString() {
            if (parseInt(this.formDate.status) === 1) {
                return this.status = "A fazer"
            }
            if (parseInt(this.formDate.status) === 2) {
                return this.status = "Cadastro"
            }
            if (parseInt(this.formDate.status) === 3) {
                return this.status = "Enviado para cliente"
            }
            if (parseInt(this.formDate.status) === 4) {
                return this.status = "Aprovado pelo cliente"
            }
            if (parseInt(this.formDate.status) === 5) {
                return this.status = "Aprovado pela D7M"
            }
            if (parseInt(this.formDate.status) === 6) {
                return this.status = "Cancelado"
            }
            if (parseInt(this.formDate.status) === 7) {
                return this.status = "Nova Versão"
            }
        },

        // Modals Functions
        openEmail() {
            this.modal_email = !this.modal_email
            if (this.formDate.status >= 1)
                this.modal_email = false
        },

        openPhone() {
            this.modal_phone = !this.modal_phone
            if (this.formDate.status >= 3)
                this.modal_phone = false
        },

        getContractModel(id) {
            this.$store.dispatch('loadContractModelGet', id)
                .then((r) => {
                    this.formDate.contract_value = r.price
                    this.formDate.obs = r.obs
                    this.formDate.itens = r.itens
                })
        },

        getClient(id) {
            this.$store.dispatch('loadClient', id)
                .then((r) => {
                    this.formDate.client_id = r.id
                    this.formDate.cliente_name = r.name
                    this.$store.dispatch('loadEmailClient', r.id)
                    this.$store.dispatch('loadPhoneClient', r.id)
                })
        },

        importantChange() {
            if (parseInt(this.important) === 1) {
                this.important = 2
            } else {
                this.important = 1
            }
        },

        clausesClose() {
            this.isEdit = false
            this.clauses.show = false
            this.clauses.formData.item = ""
            this.clauses.formData.important = 1
            this.important = 1
        },

        clausesEdit(data) {
            this.isEdit = true
            this.clauses.show = true
            this.important = data.important
            this.clauses.formData = {
                id: data.id,
                contract_id: this.id,
                item: data.item,
                important: data.important,
            }
        },

        onEnd() {
            this.drag = false
            let clauses = this.formDate.itens
            let formData = {id: this.id, itens: []}

            clauses.forEach((element, index) => {
                formData.itens.push({
                    id: element.id,
                    order: index
                })
            })
            this.$store.dispatch("updateOrderContractItem", formData)
        },

        submitClause() {
            this.clauses.formData.order = this.formDate.itens.length + 1
            this.clauses.formData.important = this.important
            this.$store.dispatch('storeContractItem', this.clauses.formData)
                .then(() => {
                    toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                    this.clausesClose()
                    this.loadPageItens()
                }).catch(() => {
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        updateClause() {
            this.clauses.formData.important = this.important
            this.$store.dispatch('updateContractItem', this.clauses.formData)
                .then(() => {
                    toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    this.clausesClose()
                    this.loadPageItens()
                }).catch(() => {
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        deleteClause(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar esta cláusula ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyContractItem', item.id).then(() => {
                        this.loadPageItens(1)
                        toast.success(ACTION_MESSAGES.delete, {timeout: 2000});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar`, {timeout: 2000});
                    })
                }
            });
        },
    },

    computed: {
        selectContractModel() {
            return this.$store.state.var.selectContractModel
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectClientEmailList() {
            return this.$store.state.var.selectClientEmailList
        },

        selectClientPhoneList() {
            return this.$store.state.var.selectClientPhoneList
        },

        selectService() {
            return this.$store.state.var.selectService
        },

        selectBusiness() {
            return this.$store.state.var.selectBusiness
        },

        storeState() {
            return this.$store.state.var.storeState
        },
    },
}
</script>

<style scoped>
@font-face {
    font-family: "Calibri";
    src: url("@/assets/fonts/calibri-regular.ttf");
}

.ql-font-Calibri {
    font-family: Calibri;
}

.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.color {
    color: #d67c1c !important;
}

.ghost {
    opacity: 0.5;
    background: #d67c1c !important;
}

.modal-sections-contract {
    top: 50%;
    left: 50%;
    width: 60%;
    height: auto;
    z-index: 9999;
    position: fixed;
    background: white;
    max-height: 700px;
    overflow-x: scroll;
    padding: 40px 20px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.btn-close {
    top: 0;
    right: 0;
    margin: 1%;
    cursor: pointer;
    position: absolute;
}

.my_btn {
    cursor: pointer;
    position: relative;
    background: #d67c1c;
    color: white;
    border: 1px solid #d67c1c;
    padding-bottom: 15px;
}

.merito {
    width: 100%;
    height: 200px;
    padding: 0 20px;
    overflow-x: scroll;
    background: #ebebeb;
}

.merito > .container-desc {
    display: block;
}

ul.task-desc li.item {
    display: flex;
    margin: 20px 0;
    padding: 0 20px;
    box-shadow: none;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    background: transparent;
    justify-content: space-between;
}

.btn-group.two {
    top: 0;
    right: 40px;
    margin: 6px;
    z-index: 20;
    position: absolute;
}

.btn-main {
    color: #d67c1c;
    --bs-btn-border-width: 0;
}

.btn-main-two {
    color: #FFFFFF;
    border-radius: 50px;
    border: 1px solid #e0e0e0;
    background-color: #d67c1c;
}

.btn-main-two:active,
.btn-main-two:focus,
.btn-main-two:hover,
.btn-main-two:focus-visible {
    color: #FFFFFF;
    border-color: #d67c1c;
    background-color: #d67c1c;
}

.btn-main:active,
.btn-main:focus,
.btn-main:hover,
.btn-main:focus-visible {
    border: 0;
    color: #d67c1c;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: #000000;
    background-color: #f0f0f0;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:focus {
    box-shadow: none;
}

@media (max-width: 991px ) {
    .modal-sections-contract {
        width: 95%;
    }
}

.modal-w {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-p {
    width: 65%;
    overflow-y: auto;
    max-height: 800px;
    background-color: #FFFFFF;
}

.modal-p .btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

@media (max-width: 768px) {
    .modal-p {
        width: 98%;
    }
}
</style>

<style>
.ql-container {
    font-size: 16px;
    font-family: 'Calibri';
}
</style>