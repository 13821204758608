<template>
    <painel-component :itens="itensOne">
        <div class="filter-position" v-if="btnFilter">
            <button-one class="btn-filter" icon="filter" @click="filterWindow"/>
        </div>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Código:</label>
                        <input-component :type-input="'text'" v-model="formDate.code"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDate.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'">
                        <button-one class="mx-1" @submit="submit" :icon="'search'"/>
                        <button-float class="mx-1" :itens="itensTwo" v-if="buttonCheck.create"/>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end'">
                    <div class="toggle_preferences">
                        <button :class="{ 'bg-active': parseInt(cardTable) === 1 }" @click="toggleCard" type="button"
                                tooltip="Card">
                            <i class="window maximize outline icon"></i>
                        </button>
                        <button :class="{ 'bg-active': parseInt(cardTable) === 2 }" @click="toggleTable" type="button"
                                tooltip="Tabela">
                            <i class="align justify icon"></i>
                        </button>
                    </div>
                </row-col-component>
            </row-component>

            <row-component v-if="parseInt(cardTable) === 1">
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-4'"
                                   v-for="(data, index) in objectReturn.itens.data" :key="index">
                    <div class="card-container">
                        <div class="flag-status"></div>

                        <img v-if="data.photo" :src="data.photo" alt="">
                        <img v-else src="@/assets/img/avatar.png" alt="">

                        <h3 class="title-container">Produto: {{ data.name }}</h3>
                        <p>Código: {{ data.code }}</p>
                        <p> {{ businessString(data.business) }} </p>

                        <div class="aux-row-3">
                            <div class="text"><i class="calendar icon"></i>{{ data.date_create }}</div>
                            <div class="text"><i class="clock icon"></i>{{ data.hour_create }}</div>
                        </div>

                        <div class="responsible-task">
                            <h6> Criador:</h6>
                            <img v-if="data.photo_create" :src="data.photo_create" class="avatar__image_2" alt="">
                            <img v-else src="@/assets/img/avatar.png" alt="" class="avatar__image_2">
                            {{ data.creator_user_name }}
                        </div>

                        <div class="buttons">
                            <button tooltip="Editar" class="primary" v-if="buttonCheck.edit"
                                    @click="routeEdit(data.id)">
                                <i class="edit icon"></i>
                            </button>

                            <button tooltip="Ver" class="primary" v-if="buttonCheck.show" @click="routeShow(data.id)">
                                <i class="eye icon"></i>
                            </button>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <table class="table table-striped" v-if="parseInt(cardTable) === 2">
                <thead>
                <tr>
                    <th scope="col" style="width: 5%">Código</th>
                    <th scope="col" style="width: 15%">Criador</th>
                    <th scope="col" style="width: 25%">Produto</th>
                    <th scope="col" style="width: 15%">Negócio</th>
                    <th scope="col" style="width: 15%">Data / Hora de criação</th>
                    <th scope="col" style="width: 10%" v-if="buttonCheck.show || buttonCheck.edit">
                        Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in objectReturn.itens.data" :key="index">
                    <td data-label="Código">{{ data.code }}</td>
                    <td data-label="Criador">
                        <img v-if="data.photo_create" :src="data.photo_create" class="avatar__image_2" alt="">
                        <img v-else src="@/assets/img/avatar.png" alt="" class="avatar__image_2">
                        {{ data.creator_user_name }}
                    </td>
                    <td data-label="Produto">
                        <img v-if="data.photo" :src="data.photo" class="avatar__image_2" alt="">
                        <img v-else src="@/assets/img/avatar.png" alt="" class="avatar__image_2">
                        {{ data.name }}
                    </td>
                    <td data-label="Negócio">{{ businessString(data.business) }}</td>
                    <td data-label="Data / Hora de criação">{{ data.date_create }} / {{ data.hour_create }}</td>
                    <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.low">
                        <button tooltip="Ver" class="btn btn-secondary mx-1 button-show" v-if="buttonCheck.show"
                                @click="routeShow(data.id)">
                            <i class="eye icon"></i>
                        </button>

                        <button tooltip="Editar" class="btn btn-success mx-1 button-show" v-if="buttonCheck.edit"
                                @click="routeEdit(data.id)">
                            <i class="edit icon"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <button-pagination :pagination="objectReturn.itens" :offset="3" @paginate="loadProducts"/>
        </div>
    </painel-component>
</template>

<script>
import ButtonPagination from "@/components/buttons/ButtonPagination";
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import InputComponent from "@/components/form/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";

import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "ProductSearch",
    components: {
        ButtonPagination,
        PainelComponent,
        ButtonOne,
        ButtonFloat,
        InputComponent,
        RowComponent,
        RowColComponent,
        LoderSendComponent,
        Multiselect,
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Produtos')
        this.loadProducts(1)
        this.resizeWindow()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            itensTwo: {
                route: "/product/create",
                icon: "plus",
                name: ''
            },

            buttonCheck: {
                edit: false,
                create: false,
                show: false
            },

            formDate: {
                code: '',
                name: '',
                status: 1,
            },

            cardTable: '',
            preferences: {
                type: 5,
                preference: ''
            },

            filter: true,
            btnFilter: false,

            showLoader: true,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadProducts(1)
            this.searchSubmit = true;
        },

        routeEdit(id) {
            this.$router.push({name: 'product-edit', params: {id: id}})
        },

        routeShow(id) {
            this.$router.push({name: 'product-show', params: {id: id}})
        },

        toggleTable() {
            this.userPreferences(2)
            this.cardTable = 2
        },

        toggleCard() {
            this.userPreferences(1)
            this.cardTable = 1
        },

        userPreferences(preference) {
            this.preferences.preference = preference
            this.$store.dispatch('userPreferences', this.preferences)
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },

        loadProducts(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadProducts', {...this.params, page})
                    .then((r) => {
                        this.cardTable = r.tableCard
                        this.showLoader = false
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        businessString(value) {
            if (parseInt(value) === 1) {
                return 'Cidade Vertical'
            }
            if (parseInt(value) === 2) {
                return 'D7M Marketing'
            }
            if (parseInt(value) === 3) {
                return 'Methoduz'
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 21)
                .then((r) => {
                    if (r.data.indexOf('product-search') !== -1) {
                        this.showLoader = false

                        if (r.data.indexOf('product-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('product-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('product-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        objectReturn() {
            return this.$store.state.product.items
        },

        params() {
            return {
                page: this.objectReturn.current_page,
                code: this.formDate.code,
                name: this.formDate.name,
                status: this.formDate.status,
            }
        },
    },
}
</script>

<style scoped src="@/assets/css/table-resp.css"></style>
<style scoped>
.toggle_preferences > button {
    border: 0;
    width: 45px;
    color: #fff;
    height: 45px;
    margin: 0 5px;
    padding-right: 4px;
    border-radius: 50%;
    background-color: #323232;
}

.button-show {
    width: 35px;
    height: 35px;
    font-size: 15px;
    border-radius: 50px;
    padding-left: 0.5rem;
}

.bg-active {
    background-color: #d67c1c !important;
}

.responsible-task .avatar__image_2 {
    height: 30px;
    width: 30px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #d67c1c;
}

.card-container {
    padding: 0;
    width: 350px;
    color: #2f3032;
    margin: 20px 0;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    text-align: center;
    background-color: #ffffff;
    border: solid 1px #ededed;
    box-shadow: 10px 10px 15px rgb(0 0 0 / 35%);
}

.title-container {
    height: 60px;
    color: #424242;
    font-size: 14px;
    padding: 3px 15px;
    text-transform: uppercase;
}

.card-container > img {
    width: 90px;
    padding: 7px;
    height: 90px;
    border-radius: 50%;
    margin: 25px 0 10px;
    border: 1px solid #d67c1c;
}

.buttons {
    display: flex;
    min-height: 60px;
    border-top: 1px solid #ededed;
}

button.primary {
    width: 100%;
    height: 60px;
    color: #7e7e7e;
    padding: 5px 25px;
    background-color: #f5f5f5;
    border: 1px solid #fdfdfd;
}

.responsible-task {
    padding: 15px;
    min-height: 80px;
    text-align: left;
    border-top: 1px solid #ededed;
}

.responsible-task h6 {
    font-size: 14px;
}

.flag-status {
    top: 0;
    width: 100%;
    position: absolute;
    padding: 10px 20px;
    background-color: #d67c1c;
}

.aux-row-3 {
    width: 100%;
    display: flex;
    margin-top: 10px;
    margin-bottom: 15px;
    align-items: center;
    justify-content: space-evenly;
}

.aux-row-3 .text > i {
    color: #7e7e7e;
}
</style>