<template>
    <div class="modalProvider" v-if="modal_client">
        <div class="close-provider" @click="openClient">X</div>

        <row-col-component
                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
            <label>Cliente:</label>
            <Multiselect v-model="client" label="name" :options="selectClientList"
                         :searchable="true"
                         :noResultsText="this.$store.state.var.selectNotSearch">
                <template v-slot:singlelabel="{ value }">
                    <div class="multiselect-single-label">
                        <img class="character-label-icon" :src="value.icon" alt="">
                        {{ value.name }}
                    </div>
                </template>

                <template v-slot:option="{ option }">
                    <img class="character-option-icon" :src="option.icon" alt="">
                    {{ option.name }}
                </template>
            </Multiselect>
        </row-col-component>

        <button-one @click="closeClient(client)" name-button="Confirmar"/>
    </div>

    <div class="modalProvider" v-if="modal_email">
        <div class="close-provider" @click="openEmail">X</div>

        <row-col-component
                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
            <label>Email:</label>
            <Multiselect v-model="formDate.email" label="name" :options="selectClientEmailList" :searchable="true"
                         :noResultsText="this.$store.state.var.selectNotSearch">
                <template v-slot:singlelabel="{ value }">
                    <div class="multiselect-single-label">
                        <img class="character-label-icon" :src="value.icon" alt="">
                        {{ value.name }}
                    </div>
                </template>

                <template v-slot:option="{ option }">
                    <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                </template>
            </Multiselect>
        </row-col-component>

        <button-one @click="openEmail" name-button="Confirmar"/>
    </div>

    <div class="modalProvider" v-if="modal_phone">
        <div class="close-provider" @click="openPhone">X</div>

        <row-col-component
                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
            <label>Telefone:</label>
            <Multiselect v-model="formDate.phone" label="name" :options="selectClientPhoneList" :searchable="true"
                         :noResultsText="this.$store.state.var.selectNotSearch">
                <template v-slot:singlelabel="{ value }">
                    <div class="multiselect-single-label">
                        <img class="character-label-icon" :src="value.icon" alt="">
                        {{ value.name }}
                    </div>
                </template>

                <template v-slot:option="{ option }">
                    <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                </template>
            </Multiselect>
        </row-col-component>

        <button-one @click="openPhone" name-button="Confirmar"/>
    </div>

    <div class="modalProvider" v-if="modal_template">
        <div class="close-provider" @click="modal_template = !modal_template">X</div>

        <row-col-component
                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
            <label>Modelo de proposta:</label>
            <multiselect @select="getProposalTemplate(client)" v-model="client" :searchable="true"
                         :options="selectProposalTemplate"
                         :noResultsText="this.$store.state.var.selectNotSearch"/>
        </row-col-component>

        <button-one name-button="Confirmar" @click="modal_template = !modal_template"/>
    </div>

    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoder"/>

        <div class="tabs effect-3" v-else>
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="handshake alternate icon"></i>
                <p>Proposta</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <div class="clearfix">
                        <button-one @click="modal_template = !modal_template" class="float-end" icon="search plus"
                                    tooltip="Buscar modelo de prosposta"/>
                    </div>

                    <form action="">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
                                    :class="{ 'formError': errors.name }">
                                <label>Nome da Proposta:</label>
                                <input-component :type-input="'text'" v-model="formDate.name"
                                                 :model-value="formDate.name"/>
                            </row-col-component>

                            <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.valid }">
                                <label>Valído (Dias Quantidade):</label>
                                <input-number-component :type-input="'number'" min="15" v-model="formDate.valid"
                                                        :model-value="formDate.valid"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
                                    :class="{ 'formError': errors.client_name }">
                                <label>Cliente:</label>
                                <div class="input-group">
                                    <input class="form-control" type="text" v-model="formDate.client_name">
                                    <span @click="openClient()" class="input-group-text my_btn" id="basic-addon1">
                                        <i class="search icon"></i>
                                    </span>
                                </div>
                            </row-col-component>

                            <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.proposed_value }">
                                <label>Valor da Proposta:</label>
                                <input-component :type-input="'text'" v-model="formDate.proposed_value"
                                                 :model-value="formDate.proposed_value" v-money="money"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.email }">
                                <label>Email:</label>
                                <div class="input-group">
                                    <input class="form-control" type="email" v-model="formDate.email">
                                    <span @click="openEmail()" class="input-group-text my_btn" id="basic-addon1">
                                        <i class="search icon"></i>
                                    </span>
                                </div>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Telefone:</label>
                                <div class="input-group">
                                    <input class="form-control" type="text" v-model="formDate.phone"
                                           v-mask="['(##) ####-####', '(##) #####-####']">
                                    <span @click="openPhone()" class="input-group-text my_btn" id="basic-addon1">
                                        <i class="search icon"></i>
                                    </span>
                                </div>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                    :class="{ 'formError': errors.responsible }">
                                <label>Responsável:</label>
                                <input-component :type-input="'text'" v-model="formDate.responsible"
                                                 :model-value="formDate.responsible"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Observações:</label>
                                <input-text-area :rows="4" v-model="formDate.obs"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="clearfix mb-3">
                        <button-one @click="sectionAdd" class="float-start" icon="plus" tooltip="Adicionar Sessão"/>
                    </div>

                    <form v-for="(section, index) in addSection" :key="index" class="section-style">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-11 col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12'">
                                <label>Nome da sessão:</label>
                                <input-component :type-input="'text'" v-model="section.section"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-lg-4'">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button-one @click="itensAdd(index)" icon="plus" tooltip="Adicionar Item"/>
                                    <button-one v-if="formDate.sections.length > 1" @click="deleteSection(index)"
                                                icon="trash" tooltip="Deletar Sessão"/>
                                </div>
                            </row-col-component>
                        </row-component>

                        <row-component v-for="(itens, indexTwo) in section.itens" :key="indexTwo">
                            <row-col-component
                                    :class-row-col="'col-xxl-11 col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12'"
                                    class="item-style">
                                <label>Item:</label>
                                <input-component :type-input="'text'" v-model="itens.item"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-lg-4'">
                                <button-one v-if="section.itens.length > 1" @click="deleteItem(index, indexTwo)"
                                            icon="trash"
                                            tooltip="Deletar Item"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>
            </div>
        </div>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-one style="width: 100%;" @submit="submit" :icon="'plus circle'" :name-button="'Adicionar'"
                            :class="{ 'preloderSend': sendForm }"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-float style="width: 100%" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputNumberComponent from "@/components/form/InputNumberComponent";
import InputTextArea from "@/components/form/InputTextArea";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";

import {ACTION_MESSAGES} from "@/configs/constants";
import {useToast} from "vue-toastification";
import {mask} from 'vue-the-mask'
import {MONEY} from "@/configs/constants"
import {VMoney} from 'v-money'

const toast = useToast();

export default {
    name: "ProposalCreate",

    created() {
        this.$store.dispatch('updateNamePage', 'Proposta')
        this.$store.dispatch('loadProposalTemplateSelect')
        this.$store.dispatch('loadClientListSelect')
        this.checkACL()
        this.loadCloneItens()
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/proposal/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                proposal_template_id: '',
                valid: '',
                client_id: '',
                client_name: '',
                proposed_value: '',
                email: '',
                phone: '',
                responsible: '',
                obs: '',
                payments: [],
                sections: [],
            },

            money: MONEY,

            client: '',
            modal_client: false,
            modal_phone: false,
            modal_email: false,
            modal_template: false,

            errors: [],
            showError: false,
            sendForm: false,
            showLoder: true,
        }
    },

    methods: {
        scrollTop() {
            window.scrollTo(0, 0)
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeProposal', this.formDate)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                        this.$router.push({name: 'proposal-edit', params: {id: r.data}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 27)
                .then((r) => {
                    if (r.data.indexOf('proposal-create') !== -1) {
                        this.showLoder = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        loadCloneItens() {
            if (this.storeState.length > 0) {
                this.storeState.forEach((element) => {
                    let section = element.sections
                    section.forEach((el) => {
                        delete Object.assign(el, {['section']: el['item']})['item'];
                    })
                })
                if (this.storeState.length > 1)
                    this.storeState.splice(0, 1)
                this.formDate = this.storeState[0]
            } else {
                this.sectionAdd()
            }
        },

        openClient() {
            this.modal_client = !this.modal_client
        },

        closeClient(id) {
            this.modal_client = !this.modal_client
            this.getClient(id)
        },

        openEmail() {
            this.modal_email = !this.modal_email
        },

        openPhone() {
            this.modal_phone = !this.modal_phone
        },

        getProposalTemplate(id) {
            this.$store.dispatch('loadProposalTemplateInfos', id)
                .then((r) => {
                    this.formDate.obs = r.obs
                    this.formDate.proposed_value = r.price
                    this.formDate.sections = r.sections
                }).catch(() => {
            })
        },

        getClient(id) {
            this.$store.dispatch('loadClient', id).then((r) => {
                this.formDate.client_id = r.id
                this.formDate.client_name = r.surname
                this.$store.dispatch('loadEmailClient', r.id)
                this.$store.dispatch('loadPhoneClient', r.id)
            })
        },

        sectionAdd() {
            this.formDate.sections.unshift({
                item: '',
                itens: [{
                    item: ''
                }]
            })
        },

        itensAdd(index) {
            this.addSection[index].itens.push({
                item: ''
            })
        },

        deleteSection(index) {
            this.formDate.sections.splice(index, 1)
        },

        deleteItem(index, indexTwo) {
            this.addSection[index].itens.splice(indexTwo, 1)
        }

    },

    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        InputTextArea,
        InputNumberComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
    },

    directives: {
        mask,
        money: VMoney
    },

    beforeMount() {
        this.storeState.splice(0, 1)
    },

    computed: {
        selectProposalTemplate() {
            return this.$store.state.var.selectProposalTemplate
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectClientEmailList() {
            return this.$store.state.var.selectClientEmailList
        },

        selectClientPhoneList() {
            return this.$store.state.var.selectClientPhoneList
        },

        storeState() {
            return this.$store.state.var.storeState
        },

        addSection() {
            return this.formDate.sections
        },

    }
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

h4 {
    color: #000;
    text-align: end;
    font-size: 16px;
    padding: 10px 15px;
    background-color: #c9c9c9;
}

.modalProvider {
    width: 40%;
    top: 50%;
    left: 50%;
    z-index: 9999;
    position: fixed;
    min-height: auto;
    background: white;
    padding: 40px 20px;
    margin-right: -50%;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.close-provider {
    top: 0;
    right: 0;
    cursor: pointer;
    margin: 10px 20px;
    font-size: 1.5rem;
    position: absolute;
}

.my_btn {
    color: white;
    cursor: pointer;
    position: relative;
    background: #d67c1c;
    padding-bottom: 15px;
    border: 1px solid #d67c1c;
}

.section-style {
    max-height: 450px;
    position: relative;
    overflow-y: scroll;
    padding: 15px 25px;
    margin-bottom: 50px;
    background-color: #f0f0f0;
}

.item-style {
    padding-left: 25px;
}

@media (min-width: 1200px) {
    .item-style > .row {
        margin: 1rem !important;
        padding-top: 0 !important;
    }
}

@media (max-width: 991px) {
    .modalProvider {
        width: 95%;
    }
}
</style>