import state from "@/store/modules/home/state";
import actions from "@/store/modules/home/actions";
import mutations from "@/store/modules/home/mutations";
import getters from "@/store/modules/home/getters";

export default {
  state,
  actions,
  mutations,
  getters
}