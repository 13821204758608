import AdminComponent from "@/components/dashboard/AdminComponent";
import FleetDeparturesSearch from "@/views/search/FleetDeparturesSearch";
import FleetDeparturesCreate from "@/views/create/FleetDeparturesCreate";
import FleetDeparturesUpdate from "@/views/update/FleetDeparturesUpdate";
import FleetDeparturesShow from "@/views/show/FleetDeparturesShow";

export default {
    path: '/fleet-departures',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'create', name: 'fleet-departures-create', component: FleetDeparturesCreate },
        { path: 'search', name: 'fleet-departures-search', component: FleetDeparturesSearch },
        { path: 'edit/:id', name: 'fleet-departures-edit', component: FleetDeparturesUpdate, props: true },
        { path: 'show/:id', name: 'fleet-departures-show', component: FleetDeparturesShow, props: true }
    ],

}

