import axios from "axios";
import {
    URL_BASE
} from "@/configs/constants";

const RESOURCE = 'schedule';
export default {

    loadSchedules(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {
                params
            })
                .then(response => {
                    context.commit('LOAD_SCHEDULES', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    storeSchedule(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {
                })
        })
    },

    loadSchedule(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
        })
    },

    updateSchedule(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroySchedule(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeScheduleEvent(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}schedule-add-event`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {
                })
        })
    },

    loadScheduleEvent(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}schedule-show-event/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyScheduleEvent(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}schedule-delete-event/${id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },


    loadScheduleEventMonth(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}schedule-show-events-month/${formData.id}/${formData.month}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
                .finally(() => context.commit('PRELOADER', false))
        })
    },


}