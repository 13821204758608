<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div class="tabs effect-3" v-else>
            <input type="radio" id="tab-1" name="tab-effect-3" checked class="mobileTab">
            <span class="mobileTab">
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3" class="mobileTab">
            <span class="mobileTab">
                <i class="envelope icon"></i>
                <p>Email</p>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3" class="mobileTab">
            <span class="mobileTab">
                <i class="phone square icon"></i>
                <p>Telefone</p>
            </span>

            <input type="radio" id="tab-5" name="tab-effect-3" class="mobileTab">
            <span class="mobileTab">
                <i class="money icon"></i>
                <p>Conta Bancária</p>
            </span>

            <div class="line line-2 ease"></div>
            <div class="tab-content">

                <section id="tab-item-1">
                    <form>
                        <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                            <input type="radio" id="funcionario" v-model="formDate.type" value="1" checked>
                            <label for="funcionario">Funcionário</label>

                            <input type="radio" id="prestador" v-model="formDate.type" value="2">
                            <label for="prestador">Prestador de serviço</label>
                        </row-col-component>

                        <row-component>
                            <row-col-component :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.name }">
                                <label>Nome:</label>
                                <input-component :type-input="'text'" :modelValue="formDate.name"
                                                 v-model="formDate.name"/>
                            </row-col-component>

                            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.sexo }">
                                <label>Sexo:</label>
                                <Multiselect v-model="formDate.sexo" :searchable="true" :options="selectSexo"
                                             :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.cpf }">
                                <label>CPF:</label>
                                <input-component :type-input="'text'" :modelValue="formDate.cpf" v-model="formDate.cpf"
                                                 v-mask="'###.###.###-##'"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>RG:</label>
                                <input-component :type-input="'text'" :modelValue="formDate.rg" v-model="formDate.rg"
                                                 v-mask="['##.###.###-X', '#.###.###']"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Data Nasc:</label>
                                <input-component :type-input="'text'" :modelValue="formDate.birth_date"
                                                 v-model="formDate.birth_date" v-mask="'##/##/####'"/>
                            </row-col-component>

                            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.name }">
                                <label>Status:</label>
                                <Multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                             :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>CEP:</label>
                                <input-component :type-input="'text'" :modelValue="formDate.cep" v-mask="'#####-###'"
                                                 v-model="formDate.cep" @change="searchApiCEP"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Cidade:</label>
                                <input-component :type-input="'text'" :modelValue="formDate.city"
                                                 v-model="formDate.city"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Estado:</label>
                                <input-component :type-input="'text'" :modelValue="formDate.state"
                                                 v-model="formDate.state"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Bairro:</label>
                                <input-component :type-input="'text'" :modelValue="formDate.district"
                                                 v-model="formDate.district"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Endereço:</label>
                                <input-component :type-input="'text'" :modelValue="formDate.address"
                                                 v-model="formDate.address"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-6  col-xl-6  col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>País:</label>
                                <input-component :type-input="'text'" :modelValue="formDate.country"
                                                 v-model="formDate.country"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Refêrencia:</label>
                                <input-text-area :modelValue="formDate.complement" v-model="formDate.complement"
                                                 :rows="4"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-3">
                    <div class="clearfix">
                        <button-one class="rounded-0 my-3 float-end" icon="plus" @click="addNewEmail"/>
                    </div>

                    <div class="border-content" v-for="(email, index) in emailsAdd" :key="index">
                        <form>
                            <button-remove-round v-if="emailsAdd.length > 1" @click="emailsRemove(index)"
                                                 class="rounded-0 position-absolute end-0 bottom-0 m-2"/>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-11 col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12 mt-2 mb-2 '">
                                    <label>Receber notificação</label>
                                    <input type="radio" :id="'nao-email' + index" name="teste"
                                           v-model="email.notification"
                                           value="1" checked>
                                    <label :for="'nao-email' + index">Não</label>

                                    <input type="radio" :id="'sim-email' + index" name="teste"
                                           v-model="email.notification"
                                           value="2">
                                    <label :for="'sim-email' + index">Sim</label>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Nome:</label>
                                    <input-component :type-input="'text'" v-model="email.name"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                    <label>Email:</label>
                                    <input-component :type-input="'email'" v-model="email.email"/>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>
                </section>

                <section id="tab-item-4">
                    <div class="clearfix">
                        <button-one class="rounded-0 my-3 float-end" @click="addNewPhones" icon="plus"/>
                    </div>

                    <div class="border-content" v-for="(phone, index) in phonesAdd" :key="index">
                        <form>
                            <button-remove-round v-if="phonesAdd.length > 1" @click="phonesRemove(index)"
                                                 class="rounded-0 position-absolute end-0 bottom-0 m-2"/>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-2 mb-2'">
                                <label>Receber notificação</label>
                                <input type="radio" id="nao-phone" name="teste" v-model="phone.notification" value="1"
                                       checked>
                                <label for="nao-phone">Não</label>

                                <input type="radio" id="sim-phone" name="teste" v-model="phone.notification" value="2">
                                <label for="sim-phone">Sim</label>
                            </row-col-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Nome:</label>
                                    <input-component :type-input="'text'" v-model="phone.name"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                    <label>Telefone:</label>
                                    <input-component :type-input="'text'" v-model="phone.phone"
                                                     v-mask="['(##) ####-####', '(##) #####-####']"/>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>
                </section>

                <section id="tab-item-5">
                    <div class="clearfix">
                        <button-one class="rounded-0 my-3 float-end" @click="addNewBankAccounts" icon="plus"/>
                    </div>

                    <div class="border-content" v-for="(account, index) in bankAccountsAdd" :key="index">
                        <form>
                            <button-remove-round v-if="bankAccountsAdd.length > 1" @click="bankAccountsRemove(index)"
                                                 class="rounded-0 position-absolute end-0 bottom-0 m-2"/>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Banco:</label>
                                    <Multiselect v-model="account.bank" :searchable="true" :options="selectBank"
                                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Tipo de Conta:</label>
                                    <Multiselect v-model="account.account_type" :searchable="true"
                                                 :options="selectAccountType"
                                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Agência:</label>
                                    <input-component :type-input="'text'" v-model="account.agency"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Conta/Dígito</label>
                                    <input-component :type-input="'text'" v-model="account.digit_account"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Tipo PIX:</label>
                                    <Multiselect v-model="account.pix_type" :searchable="true" :options="selectPIX"
                                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12'">
                                    <label>Chave:</label>
                                    <input-component :type-input="'text'" v-model="account.key_pix"
                                                     v-mask="['###.###.###-##', '##.###.###/####-##']"
                                                     v-if="parseInt(this.formDate.bank_accounts[index].pix_type)  === 1"/>

                                    <input-component :type-input="'text'" v-model="account.key_pix"
                                                     v-mask="['(##) ####-####', '(##) #####-####']"
                                                     v-if="parseInt(this.formDate.bank_accounts[index].pix_type) === 2"/>

                                    <input-component :type-input="'email'" v-model="account.key_pix"
                                                     v-if="parseInt(this.formDate.bank_accounts[index].pix_type) === 3"/>

                                    <input-component :type-input="'text'" v-model="account.key_pix"
                                                     v-if="parseInt(this.formDate.bank_accounts[index].pix_type) === 4"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 text-center pt-4'">
                                    <label>Conta Principal ?</label>
                                    <input type="radio" :id="'nao-main' + index" name="teste"
                                           v-model="account.main_account" value="1" checked>
                                    <label :for="'nao-main' + index">Não</label>

                                    <input type="radio" :id="'sim-main' + index" name="teste"
                                           v-model="account.main_account" value="2">
                                    <label :for="'sim-main' + index">Sim</label>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>
                </section>

                <row-component>
                    <row-col-component
                            :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <button-one class="w-100" @submit="submit" :icon="'plus circle'"
                                    :name-button="'Adicionar'" :class="{ 'preloderSend': sendForm }"/>
                    </row-col-component>
                    <row-col-component
                            :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <button-float class="w-100" :itens="itensTwo"/>
                    </row-col-component>
                </row-component>
            </div>
        </div>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";

import {ACTION_MESSAGES} from "@/configs/constants";
import {ESTADOS} from "@/configs/constants"
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";
import ButtonRemoveRound from "@/components/buttons/ButtonRemoveRound.vue";

const toast = useToast();

export default {
    name: "EmployeeCreate",
    components: {
        ButtonRemoveRound,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea
    },

    directives: {mask},

    created() {
        this.$store.dispatch('updateNamePage', 'Funcionário')
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/employee/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                cpf: '',
                name: '',
                birth_date: '',
                situation: '',
                rg: '',
                type: '1',
                status: '',
                emails: [],
                phones: [],
                bank_accounts: [],
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: '',
                sexo: '',
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
            typeInputPix: 1,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeEmployee', this.formDate)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                        this.$router.push({name: 'employee-edit', params: {id: r.data}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        addNewBankAccounts() {
            this.formDate.bank_accounts.push({
                bank: "",
                account_type: "",
                agency: "",
                digit_account: "",
                pix_type: "4",
                key_pix: "",
                main_account: ""
            })
        },

        addNewEmail() {
            this.formDate.emails.push({
                name: '',
                email: '',
                notification: ''
            })
        },

        addNewPhones() {
            this.formDate.phones.push({
                name: '',
                phone: '',
                notification: ''
            })
        },

        bankAccountsRemove(index) {
            this.formDate.bank_accounts.splice(index, 1)
        },

        emailsRemove(index) {
            this.formDate.emails.splice(index, 1)
        },

        phonesRemove(index) {
            this.formDate.phones.splice(index, 1)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 5)
                .then((r) => {
                    if (r.data.indexOf('employee-create') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        searchApiCEP() {
            this.resetFormAddress()
            let cep = this.formDate.cep.replace(/\D/g, '');
            const dateCEP = {'cep': cep}
            this.$store.dispatch('searchApiCEP', dateCEP)
                .then((r) => {
                    this.formDate.city = r.data.localidade
                    this.formDate.state = r.data.uf
                    this.formDate.stateView = ESTADOS[r.data.uf]
                    this.formDate.district = r.data.bairro
                    this.formDate.address = r.data.logradouro
                    this.formDate.complement = r.data.complemento
                })
        },

        resetFormAddress() {
            this.formDate.city = ''
            this.formDate.state = ''
            this.formDate.stateView = ''
            this.formDate.district = ''
            this.formDate.address = ''
            this.formDate.complement = ''
            this.formDate.country = 'Brasil'
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        selectSexo() {
            return this.$store.state.var.selectSexo;
        },

        selectBank() {
            return this.$store.state.var.selectBank;
        },

        selectPIX() {
            return this.$store.state.var.selectPIX;
        },

        selectAccountType() {
            return this.$store.state.var.selectAccountType;
        },

        emailsAdd() {
            return this.formDate.emails;
        },

        phonesAdd() {
            return this.formDate.phones;
        },

        bankAccountsAdd() {
            return this.formDate.bank_accounts
        }
    },

    mounted() {
        this.formDate.emails.push({name: '', email: '', notification: '1'})
        this.formDate.phones.push({name: '', phone: '', notification: '1'})
        this.formDate.bank_accounts.push({
            bank: "",
            account_type: "",
            agency: "",
            digit_account: "",
            pix_type: "4",
            key_pix: "",
            main_account: "1"
        })
    }
}
</script>

<style scoped>
.border-content {
    padding: 0 20px 40px 20px;
    margin-bottom: 10px;
    position: relative;
    background: #fbfbfb;
}
</style>
