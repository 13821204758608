import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = 'proposal';
export default {

    loadProposals(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then(response => {
                    context.commit('LOAD_PROPOSAL', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    storeProposal(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {
                })
        })
    },

    loadProposal(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
        })
    },

    updateProposal(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProposalSection(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}proposal-section-edit/${formData.idproposal}`, formData)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeProposalSection(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}proposal-section-add`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {
                })
        })
    },

    destroyTemplateSection(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}proposal-section-delete/${id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProposalItem(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}proposal-add-item-edit/${formData.id}`, formData)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeProposalItem(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}proposal-add-item`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {
                })
        })
    },

    destroyTemplateItem(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}proposal-add-item-delete/${id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeProposalObservation(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}proposal-add-obs`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {
                })
        })
    },

    updateProposalSendClient(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}proposal-send-client/${id}`)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProposalApproved(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}proposal-approved/${id}`)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProposalNew(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}proposal-new/${id}`)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProposalUpdateSection(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}proposal-update-section/${formData.id}`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

}