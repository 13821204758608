import AdminComponent from "@/components/dashboard/AdminComponent";
import ScheduledNoteSearch from "@/views/search/ScheduledNoteSearch";
import ScheduledNoteCreate from "@/views/create/ScheduledNoteCreate";
import ScheduledNoteShow from "@/views/show/ScheduledNoteShow";
import ScheduledNoteUpdate from "@/views/update/ScheduledNoteUpdate";

export default {
    path: '/scheduled-note',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'scheduled-note-search', component: ScheduledNoteSearch },
        { path: 'create', name: 'scheduled-note-create', component: ScheduledNoteCreate },
        { path: 'show/:id', name: 'scheduled-note-show', component: ScheduledNoteShow, props: true },
        { path: 'edit/:id', name: 'scheduled-note-edit', component: ScheduledNoteUpdate, props: true },
    ],
}