import state from "@/store/modules/task-type/state";
import actions from "@/store/modules/task-type/actions";
import mutations from "@/store/modules/task-type/mutations";
import getters from "@/store/modules/task-type/getters";

export default {
  state,
  actions,
  mutations,
  getters
}