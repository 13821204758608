<template>
    <div class="add-expired" v-if="add_expired">
        <div>
            <div class="btn-close" @click="add_expired = !add_expired"></div>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Data Inicial:</label>
                    <input-component :type-input="'text'" v-model="addDateExpired.date_start" v-mask="'##/##/####'"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Quantidade(Meses):</label>
                    <input-number-component :type-input="'number'" v-model="addDateExpired.quantity_months"/>
                </row-col-component>
            </row-component>

            <button-one @submit="submitDateExpired" :icon="'plus circle'" :name-button="'Adicionar'"
                        :class="{ 'preloderSend': sendExpired }"/>
        </div>
    </div>

    <painel-component :itens="itensOne">
        <button-add :itens="itensThree" v-if="buttonCheck.create"/>
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form>
            <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mt-5'">
                <input type="radio" id="manual" v-model="formDate.type" value="1">
                <label for="manual">Manual</label>

                <input type="radio" id="sistema" v-model="formDate.type" value="2">
                <label for="sistema">Sistema</label>

                <input type="radio" id="pl" v-model="formDate.type" value="3">
                <label for="pl">PI</label>
            </row-col-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.client_id }">
                    <label>Cliente:</label>
                    <Multiselect v-model="formDate.client_id" label="name" :options="selectClientList"
                                 :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.number }">
                    <label>Nº / Desc:</label>
                    <input-component :maxlength=50 :type-input="'text'" v-model="formDate.number"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.services }">
                    <label>Serviços:</label>
                    <Multiselect v-model="formDate.services" mode="tags" :close-on-select="false" :searchable="true"
                                 :create-option="true" :options="selectService" :noResultsText="noResultsText"
                                 :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.signature_date }">
                    <label>Data Assinatura:</label>
                    <input-component :type-input="'text'" v-model="formDate.signature_date" v-mask="'##/##/####'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one class="w-100" @submit="submit" :icon="'save'" :name-button="'Salvar'"
                                :class="{ 'preloderSend': sendForm }"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-float class="w-100" :itens="itensTwo"/>
                </row-col-component>
            </row-component>
        </form>

        <form>
            <div class="line-division mt-5 mb-5">
                <h1 class="h1-text"><i class="comment icon"></i> Observações Internas</h1>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>

                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formDate.obsPrivate.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <div class="merito" v-else>
                        <section class="container-desc">
                            <ul class="task-desc">
                                <li class="item" v-for="(obs, index) in formDate.obsPrivate" :key="index">
                                    <div class="aux-row">
                                        <div class="user">
                                            <img :src="obs.user_photo" alt="Image 001" class="owner-img"/>
                                            {{ obs.user_name }}
                                        </div>
                                        <div class="desc-date">
                                            <div class="text"><i class="calendar icon"></i>{{ obs.date_create }}
                                            </div>
                                            <div class="text"><i class="clock icon"></i>{{ obs.hour_create }}
                                            </div>
                                        </div>
                                    </div>
                                    <p>{{ obs.obs }}</p>
                                </li>
                            </ul>
                        </section>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>
                    <input-text-area :rows="4" v-model="addObs.obs"/>
                </row-col-component>
            </row-component>

            <button-one @submit="submitObs" :icon="'plus circle'" :name-button="'Adicionar Observação'"
                        :class="{ 'preloderSend': sendObs }"/>
        </form>

        <form>
            <div class="line-division mt-5 mb-5">
                <h1 class="h1-text"> Vencimento</h1>

                <div class="btn-add-expiration">
                    <button-one class="rounded-0" @click="addExpired" icon="plus"/>
                </div>
            </div>

            <section class="container-desc-two">
                <ul class="task-desc">
                    <li class="item" v-for="(data, index) in formDate.datesExpiration" :key="index">
                        <div class="aux-row">
                            <div class="user">
                                <img :src="data.user_photo" alt="Image 001" class="owner-img"/>
                                {{ data.creator_user_name }}
                            </div>
                            <div class="tag-status">{{ stringStatus(data.status) }}</div>
                        </div>
                        <br>
                        <div class="desc-date-two">
                            <div class="aux-one">
                                <div class="text">Quantidade de Meses: {{ data.quantity_months }}</div>
                                <div class="text">
                                    <i class="calendar icon"></i>Data de Início: {{ data.date_start }}
                                </div>
                                <div class="text">
                                    <i class="calendar icon"></i>Data Final: {{ data.date_end }}
                                </div>
                            </div>

                            <div class="aux-two">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button-one :icon="'check'" tooltip="Finalizar" v-if="data.status <= 2"
                                                @click="finalizeContractExpiration(data.id)"/>

                                    <button-one :icon="'pause'" tooltip="Pausar" v-if="data.status <= 2"
                                                @click="pauseContractExpiration(data.id)"/>

                                    <button-one :icon="'window close'" tooltip="Cancelar" v-if="data.status <= 2"
                                                @click="cancelContractExpiration(data.id)"/>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </section>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputNumberComponent from "@/components/form/InputNumberComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonAdd from "@/components/buttons/ButtonAdd";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import {ACTION_MESSAGES} from "@/configs/constants";
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "ContractExpirationUpdate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        InputNumberComponent,
        ButtonOne,
        ButtonAdd,
        ButtonFloat,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea,
    },

    directives: {
        mask,
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.checkACL()
        this.loadContractExpiration()
        this.$store.dispatch('updateNamePage', 'Vencimento')
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/contract-expiration/search",
                icon: "reply all",
                name: 'Voltar'
            },

            itensThree: {
                route: "/contract-expiration/create",
                icon: "plus",
                name: 'Adicionar'
            },

            buttonCheck: {
                create: false,
            },

            formDate: {
                type: '',
                client_id: '',
                number: '',
                signature_date: '',
                services: [],
                obsPrivate: [],
                datesExpiration: []
            },

            addObs: {
                id: this.id,
                obs: ''
            },

            addDateExpired: {
                id: this.id,
                date_start: this.dateCurrent(),
                quantity_months: ''
            },

            actionsFormDate: {
                id: this.id,
                id_date: ''
            },

            add_expired: false,

            errors: [],
            sendObs: false,
            sendForm: false,
            showError: false,
            showLoader: true,
            sendExpired: false,
        }
    },

    methods: {
        addExpired() {
            this.add_expired = true
        },

        dateCurrent() {
            let currentTime = new Date();
            let day = currentTime.getDate();
            let month = currentTime.getMonth() + 1;
            let year = currentTime.getFullYear();

            day = (day < 10 ? '0' : '') + day;
            month = (month < 10 ? '0' : '') + month;

            return `${day}/${month}/${year}`
        },

        stringStatus(value) {
            if (parseInt(value) === 1)
                return "Cadastrado"
            if (parseInt(value) === 2)
                return "Em andamento"
            if (parseInt(value) === 3)
                return "Pausado"
            if (parseInt(value) === 4)
                return "Cancelado"
            if (parseInt(value) === 5)
                return "Finalizado"
        },

        loadContractExpiration() {
            this.$store.dispatch('loadContractExpiration', this.id)
                .then((r) => {
                    this.formDate = r
                    this.$store.dispatch('loadServiceList')
                    this.$store.dispatch('loadClientListSelect')
                })
                .catch(() => {
                    toast.error("Erro desconhecido !", {timeout: 2000});
                })
        },

        pauseContractExpiration(id) {
            this.actionsFormDate.id_date = id
            this.$store.dispatch('pauseContractExpiration', this.actionsFormDate)
                .then(() => {
                    toast.success('Pausado', {timeout: 2000})
                    this.loadContractExpiration()
                })
                .catch(() => {
                    toast.error('Erro ao pausar', {timeout: 2000})
                })
        },

        finalizeContractExpiration(id) {
            this.actionsFormDate.id_date = id
            this.$store.dispatch('finalizeContractExpiration', this.actionsFormDate)
                .then(() => {
                    toast.success('Finalizado', {timeout: 2000})
                    this.loadContractExpiration()
                })
                .catch(() => {
                    toast.error('Erro ao finalizar', {timeout: 2000})
                })
        },

        cancelContractExpiration(id) {
            this.actionsFormDate.id_date = id
            this.$store.dispatch('cancelContractExpiration', this.actionsFormDate)
                .then(() => {
                    toast.success('Cancelado', {timeout: 2000})
                    this.loadContractExpiration()
                })
                .catch(() => {
                    toast.error('Erro ao cancelar', {timeout: 2000})
                })
        },

        submitDateExpired() {
            if (!this.sendExpired) {
                this.sendExpired = true;
                this.$store.dispatch('storeContractExpirationDate', this.addDateExpired)
                    .then(() => {
                        toast.success('Data adicionada com sucesso', {timeout: 2000})
                        this.loadContractExpiration()
                        this.sendExpired = false;
                        this.add_expired = false
                    })
                    .catch(() => {
                        toast.error('Erro ao adicionar data', {timeout: 2000})
                        this.sendExpired = false;
                    })
            }
        },

        submitObs() {
            if (!this.sendObs) {
                this.sendObs = true;
                this.$store.dispatch('storeContractExpirationObservation', this.addObs)
                    .then(() => {
                        toast.success('Observação adicionada com sucesso', {timeout: 2000})
                        this.loadContractExpiration()
                        this.addObs.obs = ''
                        this.sendObs = false;
                    })
                    .catch(() => {
                        toast.error('Erro ao adicionar observação', {timeout: 2000})
                        this.sendObs = false;
                    })
            }
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateContractExpiration', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 36)
                .then((r) => {
                    if (r.data.indexOf('contract-expiration-edit') !== -1) {
                        this.showLoader = false
                    }
                    if (r.data.indexOf('contract-expiration-create') !== -1) {
                        this.buttonCheck.create = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        selectService() {
            return this.$store.state.var.selectService
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        }
    },

}
</script>

<style scoped>
.merito {
    width: 100%;
    height: 200px;
    padding: 0 20px;
    overflow-x: scroll;
    background: #ebebeb;
}

section.container-desc-two,
.merito > .container-desc {
    display: block;
}

ul.task-desc li.item {
    display: flex;
    margin: 20px 0;
    padding: 0 20px;
    box-shadow: none;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    background: transparent;
    justify-content: space-between;
}

ul.task-desc li.item p {
    word-break: break-all;
}

.container-desc-two > ul.task-desc li.item {
    display: flex;
    padding: 20px;
    background: #fff;
    position: relative;
    border-radius: 5px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
}

.desc-date-two {
    width: 98%;
    display: flex;
    flex-wrap: wrap;
}

.aux-one {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.aux-two {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.tag-status {
    color: #fff;
    padding: 0 0.5rem;
    font-weight: bold;
    width: fit-content;
    border-radius: 20px;
    background-color: #d67c1c;
}

.line-division {
    position: relative;
}

.btn-add-expiration {
    top: 0;
    right: 0;
    position: absolute;
}

.btn-add-expiration > button {
    border: 0;
    color: #fff;
    width: 45px;
    height: 45px;
    margin: 1px 0;
    outline: none;
    padding: 0.5rem;
    background-color: #d67c1c;
}

.add-expired {
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
}

.add-expired > div {
    width: 30%;
    padding: 2rem;
    position: absolute;
    background-color: #fff;
    box-shadow: -3px 44px 38px -25px rgb(184 184 184);
}

.btn-close {
    top: 0;
    right: 0;
    margin: 1rem;
    cursor: pointer;
    position: absolute;
}

@media (max-width: 991px) {
    .add-expired > div {
        width: 98%;
    }

    .desc-date-two {
        width: 100%;
        flex-direction: column;
    }

    .aux-one,
    .aux-two {
        width: 100%;
        margin: 0.5rem 0;
    }

    .text {
        margin: 0.5rem 0;
    }
}
</style>