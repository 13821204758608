<template>
  <Bar  
    :chart-options="chartOptions" 
    :chart-id="chartId" 
    :dataset-id-key="datasetIdKey" 
    :plugins="plugins"
    :css-classes="cssClasses" 
    :styles="styles" 
    :width="width" 
    :height="height"
    :data="chartData"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      },
      chartData: {
        labels: ['Maio', 'Junho', 'Julho'],
        datasets: [
          {
            label: 'Entrada',
            data: [1.99, 25, 15],
            backgroundColor: 'rgba(22, 171, 57, 0.6)', 
          },
          {
            label: 'Saída',
            data: [1.5, 20, 5],
            backgroundColor: 'rgba(219, 40, 40, 0.6)', 
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.icon-card-entrance {
  color: rgba(22, 171, 57, 0.404);
}

.icon-card-exit {
  color: rgb(219, 40, 40);
}
</style>
