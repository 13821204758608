import AdminComponent from "@/components/dashboard/AdminComponent";
import ProposalTemplateSearch from "@/views/search/ProposalTemplateSearch";
import ProposalTemplateCreate from "@/views/create/ProposalTemplateCreate";
import ProposalTemplateUpdate from "@/views/update/ProposalTemplateUpdate";
import ProposalTemplateShow from "@/views/show/ProposalTemplateShow";

export default {
    path: '/proposal-template',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'create', name: 'proposal-template-create', component: ProposalTemplateCreate },
        { path: 'search', name: 'proposal-template-search', component: ProposalTemplateSearch },
        { path: 'show/:id', name: 'proposal-template-show', component: ProposalTemplateShow, props: true },
        { path: 'edit/:id', name: 'proposal-template-edit', component: ProposalTemplateUpdate, props: true },
    ],
}
