import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = 'home';

export default {

    loadHome(context, year) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}?year=${year}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
        })
    },

}