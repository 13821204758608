import axios from "axios";
import { URL_BASE } from "@/configs/constants";

const RESOURCE = 'employee';
export default {

    loadEmployees(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, { params })
                .then(response => {
                    context.commit('LOAD_EMPLOYEES', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    storeEmployee(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {
                })
        })
    },

    loadEmployee(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
        })
    },

    updateEmployee(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateEmployeePhoto(context, formData) {
        let config = { headers: { 'Content-Type': 'multipart/form-data' } }
        return new Promise((resolve, reject) => {

            axios.post(`${URL_BASE}employee-photo`, formData, config)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => { })
        })
    }

}