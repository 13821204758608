<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off" v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.responsible_id }">
                    <label>Responsável:</label>
                    <Multiselect v-model="formDate.responsible_id" label="name" :options="selectUserList"
                                 @select="loadUser(formDate.responsible_id)" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.date_scheduling }">
                    <label>Data de Agendamento:</label>
                    <input-component v-mask="'##/##/####'" :type-input="'text'" v-model="formDate.date_scheduling"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.time_scheduling }">
                    <label>Hora de Agendamento:</label>
                    <input-component v-mask="'##:##:##'" :type-input="'text'" v-model="formDate.time_scheduling"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <div class="clearfix my-3">
                        <button-one icon="plus" class="float-start rounded-0" @click="addActivities"/>
                    </div>

                    <div class="border-content" v-for="(activity, index) in activitiesAdd" :key="index">
                        <form autocomplete="off">
                            <button-remove-round class="position-absolute end-0 bottom-0 rounded-0"
                                                 v-if="activitiesAdd.length >1" @click="removeActivities(index)"/>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                    <label>Atividade:</label>
                                    <input-text-area :rows="4" v-model="activity.activity"/>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>
                </row-col-component>
            </row-component>
        </form>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-one class="w-100" @submit="submit" :icon="'plus circle'" :name-button="'Adicionar'"
                            :class="{ 'preloderSend': sendForm }"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";

import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";
import {ACTION_MESSAGES} from "@/configs/constants";
import ButtonRemoveRound from "@/components/buttons/ButtonRemoveRound.vue";

const toast = useToast();

export default {
    name: "OrderServiceCreate",
    components: {
        ButtonRemoveRound,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea
    },

    directives: {
        mask,
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Ordem de Serviço')
        this.$store.dispatch('loadUserListSelect')
        this.nullInputArray()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/order-of-service/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                responsible_id: '',
                responsible_name: '',
                date_scheduling: this.dateCurrent(),
                time_scheduling: this.timeCurrent(),
                activitys: []
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeOrderOfService', this.formDate)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                        this.$router.push({name: 'order-of-service-edit', params: {id: r.data}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        loadUser(id) {
            this.$store.dispatch('loadUser', id).then((r) => {
                this.formDate.responsible_id = r.id
                this.formDate.responsible_name = r.name
            })
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 32)
                .then((r) => {
                    if (r.data.indexOf('order-of-service-create') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        addActivities() {
            this.formDate.activitys.push({
                activity: ''
            })
        },

        removeActivities(index) {
            this.formDate.activitys.splice(index, 1)
        },

        nullInputArray() {
            if (this.formDate.activitys.length === 0) {
                this.addActivities()
            }
        },

        dateCurrent() {
            let currentTime = new Date();
            if (currentTime.getDay() === 5) {
                currentTime.setDate(currentTime.getDate() + 3)
            }

            if (currentTime.getDay() === 4) {
                currentTime.setDate(currentTime.getDate() + 4)
            } else {
                currentTime.setDate(currentTime.getDate() + 2)
            }

            let day = currentTime.getDate();
            let month = currentTime.getMonth() + 1;
            let year = currentTime.getFullYear();

            // pad numbers with zero if necessary
            day = (day < 10 ? '0' : '') + day;
            month = (month < 10 ? '0' : '') + month;

            return `${day}/${month}/${year}`
        },

        timeCurrent() {
            let currentTime = new Date();
            let currentHours = currentTime.getHours();
            let currentMinutes = currentTime.getMinutes();
            let currentSeconds = currentTime.getSeconds();

            // pad numbers with zero if necessary
            currentHours = (currentHours < 10 ? '0' : '') + currentHours;
            currentMinutes = (currentMinutes < 10 ? '0' : '') + currentMinutes;
            currentSeconds = (currentSeconds < 10 ? '0' : '') + currentSeconds;
            return `${currentHours}:${currentMinutes}:${currentSeconds}`
        },
    },

    computed: {
        noResultsText(){
          return this.$store.state.var.selectNotSearch
        },

        selectUserList() {
            return this.$store.state.var.selectUserList
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        activitiesAdd() {
            return this.formDate.activitys
        }
    }
}
</script>