<template>
<preloder-component></preloder-component>
<dashboard-view></dashboard-view>

</template>


<script>
import DashboardView from "@/views/DashboardView";
import PreloderComponent from "@/components/PreloderComponent";
export default {
  name: "App",

  computed: {
    currentRouteName() {
       return this.$route.name == 'login';
    }
  },

  components:{
    DashboardView,
    PreloderComponent
  }


}
</script>

<style lang="scss">





</style>
