<template>
    <painel-component :itens="itensOne">
        <div class="filter-position" v-if="btnFilter">
            <button-one class="btn-filter" icon="filter" @click="filterWindow"/>
        </div>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDate.product_name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <label>Tipo Entrada / Saída:</label>
                        <multiselect v-model="formDate.type" :searchable="true" :options="selectEntranceExit"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'">
                        <button-one class="mx-1" @submit="submit" :icon="'search'"/>
                        <button-float class="mx-1" :itens="itensTwo" v-if="buttonCheck.create"/>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end'">
                    <div class="toggle_preferences">
                        <button :class="{ 'bg-active': parseInt(cardTable) === 1 }" @click="toggleCard" type="button"
                                tooltip="Card">
                            <i class="window maximize outline icon"></i>
                        </button>
                        <button :class="{ 'bg-active': parseInt(cardTable) === 2 }" @click="toggleTable" type="button"
                                tooltip="Tabela">
                            <i class="align justify icon"></i>
                        </button>
                    </div>
                </row-col-component>
            </row-component>

            <row-component v-if="parseInt(cardTable) === 1">
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-4'"
                                   v-for="(data, index) in objectReturn.itens.data" :key="index">
                    <div class="card-container">
                        <div class="flag-status"
                             :class="[{'entrance-color': parseInt(data.type) === 1, 'exit-color': parseInt(data.type) === 2}]">
                            <i :class="[
                                {
                                    'arrow alternate circle up outline': parseInt(data.type) === 1,
                                    'arrow alternate circle down outline': parseInt(data.type) === 2,
                                }, 'icon']"></i>
                            {{ typeString(data.type) }}
                        </div>

                        <h3 class="title-container">Produto: {{ data.product_name }}</h3>
                        <h3>Quantidade: {{ data.amount }}</h3>
                        <p>Código: {{ data.code }}</p>
                        <p v-if="parseInt(data.type) === 1"> R$ {{ data.price }} </p>

                        <div class="aux-row-3">
                            <div class="text"><i class="calendar icon"></i>{{ data.date_create }}</div>
                            <div class="text"><i class="clock icon"></i>{{ data.hour_create }}</div>
                        </div>

                        <div class="responsible-task">
                            <h6> Criador:</h6>
                            <img v-if="data.photo_create" :src="data.photo_create" class="avatar__image_2" alt="">
                            <img v-else src="@/assets/img/avatar.png" alt="" class="avatar__image_2">
                            {{ data.creator_user_name }}
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <table class="table table-striped" v-if="parseInt(cardTable) === 2">
                <thead>
                <tr>
                    <th scope="col" style="width: 5%">Código</th>
                    <th scope="col" style="width: 20%">Criador</th>
                    <th scope="col" style="width: 25%">Produto</th>
                    <th scope="col" style="width: 10%">Valor</th>
                    <th scope="col" style="width: 10%">Quantidade</th>
                    <th scope="col" style="width: 20%">Data / Hora de Criação</th>
                    <th scope="col" style="width: 20%">Entrada / Saída</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in objectReturn.itens.data" :key="index">
                    <td data-label="Código">{{ data.code }}</td>
                    <td data-label="Criador">
                        <img v-if="data.photo_create" :src="data.photo_create" class="avatar__image_2" alt="">
                        <img v-else src="@/assets/img/avatar.png" alt="" class="avatar__image_2">
                        {{ data.creator_user_name }}
                    </td>
                    <td data-label="Produto">{{ data.product_name }}</td>
                    <td data-label="Valor">
                        <div v-if="parseInt(data.type) === 1">R$ {{ data.price }}</div>
                    </td>
                    <td data-label="Quantidade">{{ data.amount }}</td>
                    <td data-label="Data / Hora de Criação">{{ data.date_create }} / {{ data.hour_create }}</td>
                    <td data-label="Entrada / Saída">{{ typeString(data.type) }}</td>
                </tr>
                </tbody>
            </table>

            <button-pagination :pagination="objectReturn.itens" :offset="3" @paginate="loadInventories"/>
        </div>
    </painel-component>
</template>

<script>
import ButtonPagination from "@/components/buttons/ButtonPagination";
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import InputComponent from "@/components/form/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";

import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "InventorieSearch",
    components: {
        ButtonPagination,
        PainelComponent,
        ButtonOne,
        ButtonFloat,
        InputComponent,
        RowComponent,
        RowColComponent,
        LoderSendComponent,
        Multiselect,
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Entrada / Saída')
        this.loadInventories(1)
        this.resizeWindow()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            itensTwo: {
                route: "/inventorie/create",
                icon: "plus",
                name: ''
            },

            buttonCheck: {
                edit: false,
                create: false,
                show: false
            },

            formDate: {
                product_name: '',
                type: ''
            },

            cardTable: '',
            preferences: {
                type: 6,
                preference: ''
            },

            filter: true,
            btnFilter: false,

            showLoader: true,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadInventories(1)
            this.searchSubmit = true;
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },

        toggleTable() {
            this.userPreferences(2)
            this.cardTable = 2
        },

        toggleCard() {
            this.userPreferences(1)
            this.cardTable = 1
        },

        userPreferences(preference) {
            this.preferences.preference = preference
            this.$store.dispatch('userPreferences', this.preferences)
        },

        loadInventories(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadInventories', {...this.params, page})
                    .then((r) => {
                        this.cardTable = r.tableCard
                        this.showLoader = false
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 22)
                .then((r) => {
                    if (r.data.indexOf('inventorie-search') !== -1) {
                        this.showLoader = false

                        if (r.data.indexOf('inventorie-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('inventorie-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        typeString(type) {
            if (parseInt(type) === 1)
                return 'Entrada'
            return 'Saida'
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectEntranceExit() {
            return this.$store.state.var.selectEntranceExit
        },

        objectReturn() {
            return this.$store.state.inventorie.items
        },

        params() {
            return {
                page: this.objectReturn.current_page,
                name: this.formDate.product_name,
                type: this.formDate.type
            }
        },
    },
}
</script>

<style scoped src="@/assets/css/table-resp.css"></style>
<style scoped>
.bg-active {
    background-color: #d67c1c !important;
}

.entrance-color {
    background-color: #16ab39;
}

.exit-color {
    background-color: #db2828;
}

.toggle_preferences > button {
    border: 0;
    width: 45px;
    height: 45px;
    color: #fff;
    margin: 0 5px;
    padding-right: 4px;
    border-radius: 50%;
    background-color: #323232;
}

.responsible-task .avatar__image_2 {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #d67c1c;
}

.card-container {
    width: 350px;
    display: flex;
    color: #2f3032;
    margin: 20px 0;
    max-width: 100%;
    overflow: hidden;
    min-height: 350px;
    padding-top: 50px;
    position: relative;
    text-align: center;
    flex-direction: column;
    background-color: #ffffff;
    justify-content: space-evenly;
    border: solid 1px #ededed;
    box-shadow: 10px 10px 15px rgb(0 0 0 / 35%);
}

.card-container h3 {
    color: #424242;
    font-size: 14px;
    font-family: inherit;
    text-transform: uppercase;
}

.title-container {
    height: 60px;
    color: #424242;
    font-size: 14px;
    padding: 3px 15px;
    font-family: inherit;
    text-transform: uppercase;
}

.card-container > img {
    width: 90px;
    padding: 7px;
    height: 90px;
    border-radius: 50%;
    margin: 25px 0 10px;
    border: 1px solid #d67c1c;
}

.responsible-task {
    padding: 15px;
    min-height: 80px;
    text-align: left;
    border-top: 1px solid #ededed;
}

.responsible-task h6 {
    font-size: 14px;
}

.flag-status {
    top: 0;
    width: 100%;
    color: #ededed;
    font-size: 14px;
    text-align: left;
    padding: 10px 20px;
    position: absolute;
    font-family: 'Comfortaa', sans-serif;
}

.aux-row-3 {
    width: 100%;
    display: flex;
    margin-top: 10px;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-evenly;
}

.aux-row-3 .text > i {
    color: #7e7e7e;
}
</style>