<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <form autocomplete="off">
                <row-component>
                    <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <div class="cont-status">
                            <p>{{ statusString(formDate.status) }}</p>
                        </div>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <label>Url:</label>
                        <div class="input-group" role="button" @click="copyUrl(formDate.link_budget)">
                            <input class="form-control cursor-pointer" type="text" v-model="formDate.link_budget"
                                   @focus="$event.target.select()" ref="select" readonly>
                            <span @click="copyUrl(formDate.link_budget)" class="input-group-text my_btn"
                                  id="basic-addon1">
                                <i :class="[copy ? 'copy icon' : 'check circle icon']"></i>
                            </span>
                        </div>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.budget_template_id }">
                        <label>Modelo de Orçamento:</label>
                        <multiselect v-model="formDate.budget_template_id" :searchable="true" disabled
                                     :options="selectBudgetTemplate"
                                     :noResultsText="this.$store.state.var.selectNotSearch"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.status }">
                        <label>Dias Válido:</label>
                        <input-number-component :type-input="'number'" min="15" v-model="formDate.valid" disabled/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.name }">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDate.name" disabled/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.client_name }">
                        <label>Cliente:</label>
                        <input-component :type-input="'text'" v-model="formDate.client_name" disabled/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                        <label>Fornecedor:</label>
                        <input-component :type-input="'text'" v-model="formDate.provider_name" disabled/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 pd-t'"
                                       :class="{ 'formError': errors.show_provider }">
                        <label>Exibir Fornecedor?</label>

                        <input type="radio" id="show-provide" v-model="formDate.show_provider" value="1" disabled>
                        <label for="show-provide">Não</label>

                        <input type="radio" id="not-show-provide" v-model="formDate.show_provider" value="2" disabled>
                        <label for="not-show-provide">Sim</label>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.responsible }">
                        <label>Email:</label>
                        <input-component :type-input="'text'" v-model="formDate.email" disabled/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Telefone:</label>
                        <input-component :type-input="'text'" v-model="formDate.phone" disabled/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.responsible }">
                        <label>Responsável</label>
                        <input-component :type-input="'text'" v-model="formDate.responsible" disabled/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                             v-if="budgetAdd.length === 0">
                            <p>Não há nada cadastrado</p>
                        </div>

                        <div class="border-content" v-for="(budget, index) in budgetAdd" :key="index" v-else>
                            <form autocomplete="off">
                                <row-component>
                                    <row-col-component
                                            :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                        <label>Produto/Serviço:</label>
                                        <input-component :type-input="'text'" v-model="budget.name" disabled/>
                                    </row-col-component>

                                    <row-col-component
                                            :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                        <label>Quantidade:</label>
                                        <input-number-component :type-input="'number'" min="1" v-model="budget.amount"
                                                                disabled placeholder="1"/>
                                    </row-col-component>

                                    <row-col-component
                                            :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                        <label>Valor Unitário:</label>
                                        <input-component :type-input="'text'" v-model="budget.unit_price" disabled
                                                         v-money="money" :maxlength="12"/>
                                    </row-col-component>

                                    <row-col-component
                                            :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                        <label>Valor Total:</label>
                                        <input-component :type-input="'text'" v-model="budget.total_price"
                                                         v-money="money" disabled/>
                                    </row-col-component>
                                </row-component>
                            </form>
                        </div>

                        <h4>Total: R$ {{ totalResult }}</h4>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <label>Observações:</label>
                        <input-text-area :rows="4" v-model="formDate.obs" disabled/>
                    </row-col-component>
                </row-component>
            </form>

            <div class="line-division mt-5 mb-5">
                <h1 class="h1-text">
                    <i class="comment icon"></i>
                    Observações Internas
                </h1>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">

                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formDate.obsPrivate.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <label>Observações:</label>
                    <div class="merito">
                        <section class="container-desc">
                            <ul class="task-desc">
                                <li class="item" v-for="(obs, index) in formDate.obsPrivate" :key="index">
                                    <div class="aux-row">
                                        <div class="user">
                                            <img :src="obs.user_photo" alt="Image 001" class="owner-img"/>
                                            {{ obs.user_name }}
                                        </div>
                                        <div class="desc-date">
                                            <div class="text"><i class="calendar icon"></i>{{ obs.date_create }}</div>
                                            <div class="text"><i class="clock icon"></i>{{ obs.hour_create }}</div>
                                        </div>
                                    </div>
                                    <p>{{ obs.obs }}</p>
                                </li>
                            </ul>
                        </section>
                    </div>
                </row-col-component>
            </row-component>

            <div class="line-division mt-5 mb-5">
                <h1 class="h1-text"><i class="history icon"></i>
                    Histórico de interações
                </h1>
            </div>

            <section class="container-desc-two">
                <ul class="task-desc">
                    <li class="item" v-for="(historic, index) in formDate.historic" :key="index">
                        <div class="aux-row">
                            <div class="user">
                                <img :src="historic.user_photo" alt="Image 001" class="owner-img"/>
                                {{ historic.name_user }}
                            </div>
                            <div class="desc-date">
                                <div class="text"><i class="calendar icon"></i>{{ historic.date_create }}</div>
                                <div class="text"><i class="clock icon"></i>{{ historic.hour_create }}</div>
                            </div>
                        </div>
                        <p>
                            {{ statusHistoric(historic.status) }},
                            Plataforma: {{ historic.platform }},
                            Navegador: {{ historic.browser }},
                            Endereço IP: {{ historic.ip }}
                        </p>
                    </li>
                </ul>
            </section>
        </div>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputNumberComponent from "@/components/form/InputNumberComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import {MONEY} from "@/configs/constants"
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "BudgetShow",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea,
        InputNumberComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Orçamento')
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/budget/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                budget_template_id: '',
                name: '',
                status: '',
                provider_id: '',
                provider_name: '',
                client_id: '',
                client_name: '',
                responsible: '',
                email: '',
                phone: '',
                show_provider: '',
                valid: '',
                obs: '',
                link_budget: '',
                obsPrivate: [],
                itens: [],
                historic: []
            },

            copy: true,
            totalResult: 0,

            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        loadPageItens() {
            this.$store.dispatch('loadBudget', this.id).then((r) => {
                this.formDate = r;
                this.calcTotal()
                this.$store.dispatch('loadProviderSelect')
                this.$store.dispatch('loadBudgetTemplateSelect')
                this.$store.dispatch('loadClientListSelect')
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        statusString(status) {
            if (parseInt(status) === 1)
                return "Cadastro"
            if (parseInt(status) === 2)
                return "Enviado para cliente"
            if (parseInt(status) === 3)
                return "Aprovado pelo cliente"
            if (parseInt(status) === 4)
                return "Aprovado pela D7M"
            if (parseInt(status) === 5)
                return "Cancelado"
        },

        statusHistoric(status) {
            if (parseInt(status) === 1)
                return "Enviado para cliente"
            if (parseInt(status) === 2)
                return "Aprovado pelo cliente"
            if (parseInt(status) === 3)
                return "Aprovado pela D7M"
            if (parseInt(status) === 4)
                return "Cancelado"
        },

        copyUrl(url) {
            navigator.clipboard.writeText(url)
            this.$refs.select.focus()
            this.copy = false
            setTimeout(() => {
                this.copy = true
            }, 2000)
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        calcTotal() {
            let sum = 0;
            if (this.totalResult > 0)
                sum = this.replaceStringOne(this.totalResult)
            this.formDate.itens.forEach(item => {
                sum += this.replaceStringOne(item.total_price.replace(/[.,]/g, '')) / 100;
            });
            this.totalResult = sum.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        },

        replaceStringOne(label) {
            if (label.length < 8) {
                return parseFloat(label.replace(',', '.'))
            } else {
                return parseFloat(label.replace(',', ''))
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 25)
                .then((r) => {
                    if (r.data.indexOf('budget-search') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        selectProvider() {
            return this.$store.state.var.selectProvider
        },

        selectBudgetTemplate() {
            return this.$store.state.var.selectBudgetTemplate
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectClientEmailList() {
            return this.$store.state.var.selectClientEmailList
        },

        selectClientPhoneList() {
            return this.$store.state.var.selectClientPhoneList
        },

        budgetAdd() {
            return this.formDate.itens;
        },
    },
}
</script>

<style scoped>
.my_btn {
    color: white;
    cursor: pointer;
    position: relative;
    background: #d67c1c;
    padding-bottom: 15px;
    border: 1px solid #d67c1c;
}

.border-content {
    margin-top: 20px;
    position: relative;
    background: #ebebeb;
    padding: 10px 20px 40px 20px;
}

h4 {
    color: #000;
    text-align: end;
    font-size: 16px;
    padding: 10px 15px;
    background-color: #c9c9c9;
}

.pd-t {
    padding-top: 32px;
}

.merito {
    width: 100%;
    height: 200px;
    padding: 0 20px;
    overflow-x: scroll;
    background: #ebebeb;
}

ul.task-desc li.item {
    display: flex;
    margin: 20px 0;
    padding: 0 20px;
    box-shadow: none;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    background: transparent;
    justify-content: space-between;
}

.cont-status {
    color: white;
    margin-top: 20px;
    font-weight: bold;
    width: fit-content;
    border-radius: 20px;
    padding: 0 10px 0 10px;
    background-color: #d67c1c;
}

.cursor-pointer {
    cursor: pointer;
}

.line-division {
    width: 100%;
    position: relative;
    border-top: 1px solid #c4c4c4;
}

.h1-text {
    color: white;
    padding: 10px;
    margin-top: 1px;
    font-size: 16px;
    width: fit-content;
    background-color: #d67c1c;
}

.desc-date {
    width: 280px;
    display: flex;
    margin: 10px 0;
    align-items: center;
    justify-content: space-between;
}

.aux-row {
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container-desc-two > ul.task-desc li.item {
    padding: 20px;
    position: relative;
    display: flex;
    background: #fff;
    border-radius: 5px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
}
</style>