import state from "@/store/modules/note/state";
import actions from "@/store/modules/note/actions";
import mutations from "@/store/modules/note/mutations";
import getters from "@/store/modules/note/getters";

export default {
  state,
  actions,
  mutations,
  getters
}