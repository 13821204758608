export default {

    icon: 'file alternate',
    name: 'Contrato',
    subLinks: [{
        name: 'Modelo de Contrato',
        route: '/contract-model/search',
        pagePermissions: [
            'contract-model-search',
            'contract-model-create',
            'contract-model-edit'
        ],
    },
    {
        name: 'Contrato',
        route: '/contract/search',
        pagePermissions: [
            'contract-search',
            'contract-create',
            'contract-edit',
            'contract-delete',
            'contract-approved',
            'contract-cancel',
            'contract-version'
        ],
    },
    {
        name: 'Vencimento',
        route: '/contract-expiration/search',
        pagePermissions: [
            'contract-expiration-search',
            'contract-expiration-create',
            'contract-expiration-edit',
            'contract-expiration-delete'
        ],
    },
    ],

    pageLinksNavCheck: [
        'contract-model-search',
        'contract-model-create',
        'contract-model-edit',
        'contract-search',
        'contract-create',
        'contract-edit',
        'contract-delete',
        'contract-approved',
        'contract-cancel',
        'contract-version',
        'contract-expiration-search',
        'contract-expiration-create',
        'contract-expiration-edit',
        'contract-expiration-delete'
    ],
}