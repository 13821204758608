import state from "@/store/modules/proposal-template/state";
import actions from "@/store/modules/proposal-template/actions";
import mutations from "@/store/modules/proposal-template/mutations";
import getters from "@/store/modules/proposal-template/getters";

export default {
  state,
  actions,
  mutations,
  getters
}