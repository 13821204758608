<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form class="mb-4 mt-2" v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.type }">
                    <label>Entrada / Saída:</label>
                    <multiselect v-model="formDate.type" :searchable="true" :options="selectEntranceExit"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.business }">
                    <label>Antecipado:</label>
                    <multiselect v-model="formDate.attended" :searchable="true" :options="selectAttended"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.bank_accounts_id }">
                    <label>Conta Bancária:</label>
                    <Multiselect v-model="formDate.bank_accounts_id" label="name" :options="selectBankAccountList"
                                 :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" alt="" :src="value.icon"> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" alt="" :src="option.icon"> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.business }">
                    <label>Negócio:</label>
                    <multiselect v-model="formDate.business" :searchable="true" :options="selectBusiness"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.account_types_id }">
                    <label>Tipo de Conta:</label>
                    <multiselect v-model="formDate.account_types_id" :searchable="true"
                                 @select="selectSubTypeAccount(formDate.account_types_id)"
                                 :options="selectSearchAccountType"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.account_types_id }">
                    <label>Tipo de Sub Conta:</label>
                    <multiselect v-model="formDate.account_type_sub_id" :searchable="true"
                                 :options="selectAccountTypeSubList"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.price }">
                    <label>Valor:</label>
                    <input-component :type-input="'text'" v-model="formDate.price" v-money="money"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>
                    <input-text-area v-model="formDate.obs" :rows="4"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one class="w-100" @submit="submit" :icon="'plus circle'" :name-button="'Adicionar'"
                                :class="{ 'preloderSend': sendForm }"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-float class="w-100" :itens="itensTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import {VMoney} from 'v-money'
import {MONEY} from "@/configs/constants"
import {ACTION_MESSAGES} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "EntranceExitCreate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        LoderSendComponent,
        ErrorComponent,
        InputTextArea
    },

    directives: {
        money: VMoney,
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Entrada / Saída')
        this.$store.dispatch('loadAccountTypeListSelect')
        this.$store.dispatch('loadAccountBankSelect')
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/entrance-exit/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                type: '',
                bank_accounts_id: '',
                account_types_id: '',
                price: '',
                business: '',
                obs: '',
                account_type_sub_id: '',
                attended: 1
            },

            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeEntranceExit', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                        this.$router.push({name: 'entrance-exit-search'})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop();
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 17)
                .then((r) => {
                    if (r.data.indexOf('entrance-exit-create') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        selectSubTypeAccount(id) {
            this.$store.dispatch('loadAccountTypeSubListSelect', id)
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectBankAccountList() {
            return this.$store.state.var.selectBankAccountList
        },

        selectEntranceExit() {
            return this.$store.state.var.selectEntranceExit;
        },

        selectSearchAccountType() {
            return this.$store.state.var.selectSearchAccountType;
        },
        selectBusiness() {
            return this.$store.state.var.selectBusiness;
        },

        selectAccountTypeSubList() {
            return this.$store.state.var.selectAccountTypeSubList
        },

        selectAttended() {
            return this.$store.state.var.selectAttended
        }
    },
}
</script>