<template>
    <div class="janela" v-if="windowSection">
        <div class="close-event" @click="windowSectionClose">X</div>

        <div class="border-content">
            <form autocomplete="off" class="mt-2">
                <row-component>
                    <row-col-component :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDateAddSub.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDateAddSub.status" :searchable="true" :options="selectInput"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>
                </row-component>
            </form>
        </div>

        <button-one @submit="storeAccountTypeSub" :icon="'plus'" :name-button="'Adicionar'"/>
    </div>

    <div class="janela" v-if="windowSectionEdit">
        <div class="close-event" @click="windowSectionEditClose">X</div>

        <div class="border-content">
            <form autocomplete="off" class="mt-2">
                <row-component>
                    <row-col-component :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDateAddSubEdit.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDateAddSubEdit.status" :searchable="true" :options="selectInput"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>
                </row-component>
            </form>
        </div>

        <button-one @submit="updateAccountTypeSub" :icon="'save'" :name-button="'Salvar'"/>
    </div>

    <painel-component :itens="itensOne">
        <button-add :itens="itensThree" v-if="buttonCheck.create"/>
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div class="tabs effect-3 mt-4" v-else>
            <input type="radio" id="tab-1" name="tab-effect-3" checked="checked">
            <span>
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="money bill alternate icon"></i>
                <p>Sub Tipo de Conta</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form class="mb-4 mt-2">
                        <row-component>
                            <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.name }">
                                <label>Nome:</label>
                                <input-component :type-input="'text'" v-model="formDate.name"/>
                            </row-col-component>

                            <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.status }">
                                <label>Status:</label>
                                <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                             :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Observações:</label>
                                <input-text-area :rows="4" :type-input="'text'" v-model="formDate.obs"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <div class="clearfix my-3" v-if="formDate.status <= 1">
                                <button-one icon="plus" class="rounded-0 float-start" @click="windowSectionModal"/>
                            </div>

                            <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                                 v-if="this.formDate.account_type_subs.length === 0">
                                <p>Não há nada cadastrado</p>
                            </div>

                            <div class="border-content" v-for="(account, index) in formDate.account_type_subs"
                                 :key="index">
                                <form autocomplete="off">
                                    <row-component>
                                        <row-col-component
                                                :class-row-col="'col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12'">
                                            <label>Nome:</label>
                                            <input-component disabled :type-input="'text'" v-model="account.name"/>
                                        </row-col-component>

                                        <row-col-component
                                                :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                            <label>Status:</label>
                                            <multiselect disabled v-model="account.status" :searchable="true"
                                                         :options="selectInput"
                                                         :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                                        </row-col-component>

                                        <row-col-component
                                                :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 pt-4'">
                                            <div class="btn-group" role="group" aria-label="Basic example">
                                                <button-one :icon="'edit icon'"
                                                            @click="loadAccountTypeSub(account.id)"/>

                                                <button-one @click="deleteAccountTypeSub(account)" tooltip="Deletar"
                                                            :icon="'trash icon'"/>
                                            </div>
                                        </row-col-component>
                                    </row-component>
                                </form>
                            </div>
                        </row-col-component>
                    </row-component>
                </section>
            </div>

            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one class="w-100" @submit="submit" :icon="'save'" :name-button="'Salvar'"
                                :class="{ 'preloderSend': sendForm }"/>
                </row-col-component>

                <row-col-component
                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-float class="w-100" :itens="itensTwo"/>
                </row-col-component>
            </row-component>
        </div>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputTextArea from "@/components/form/InputTextArea";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonAdd from "@/components/buttons/ButtonAdd"
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import swal from 'sweetalert';
import {ACTION_MESSAGES} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "AccountTypeUpdate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        InputTextArea,
        ButtonFloat,
        ButtonOne,
        ButtonAdd,
        Multiselect,
        LoderSendComponent,
        ErrorComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Tipo de conta')
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/account-type/search",
                icon: "reply all",
                name: 'Voltar'
            },

            itensThree: {
                route: "/account-type/create",
                icon: "plus",
                name: 'Adicionar'
            },

            buttonCheck: {
                create: false,
            },

            formDate: {
                name: '',
                status: '',
                obs: '',
                account_type_subs: []
            },

            formDateAddSub: {
                account_type_id: this.id,
                name: '',
                status: 1
            },

            formDateAddSubEdit: {
                account_type_id: '',
                name: '',
                status: ''
            },

            windowSectionEdit: false,
            windowSection: false,

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: true,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateAccountsType', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop();
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        loadPageItens() {
            this.$store.dispatch('loadAccountsType', this.id).then((r) => {
                this.formDate = r;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 13)
                .then((r) => {
                    if (r.data.indexOf('account-type-edit') !== -1) {
                        this.showLoader = false
                    }
                    if (r.data.indexOf('account-type-create') !== -1) {
                        this.buttonCheck.create = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        windowSectionClose() {
            this.windowSection = false
            this.formDateAddSub = {
                account_type_id: this.id,
                name: '',
                status: 1
            }
        },

        windowSectionModal() {
            this.windowSection = true
        },

        windowSectionEditClose() {
            this.formDateAddSubEdit = {
                account_type_id: '',
                name: '',
                status: ''
            }
            this.windowSectionEdit = false
        },

        loadAccountTypeSub(id) {
            this.$store.dispatch('loadAccountTypeSub', id).then((r) => {
                this.formDateAddSubEdit = r;
                this.windowSectionEdit = true
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        storeAccountTypeSub() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeAccountTypeSub', this.formDateAddSub)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        this.windowSectionClose()
                        this.loadPageItens()
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop();
                    toast.error("Erro ao criar sub conta!", {timeout: 2000});
                })
            }
        },

        updateAccountTypeSub() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateAccountTypeSub', this.formDateAddSubEdit)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        this.windowSectionEditClose()
                        this.loadPageItens()
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop();
                    toast.error("Erro ao editar sub conta!", {timeout: 2000});
                })
            }
        },

        deleteAccountTypeSub(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name}, ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyAccountTypeSub', item.id).then(() => {
                        this.loadPageItens()
                        toast.success(ACTION_MESSAGES.delete, {timeout: 10000});
                    }).catch(() => {
                        toast.error(`Não foi possivel deletar  ${item.name} `, {timeout: 10000});
                    })
                }
            });
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },
    },
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: calc(100% / 2);
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.janela {
    top: 50%;
    left: 50%;
    z-index: 99;
    width: 850px;
    height: auto;
    position: fixed;
    background: white;
    max-height: 550px;
    margin-right: -50%;
    overflow-x: scroll;
    padding: 40px 20px;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.close-event {
    top: 0;
    right: 0;
    cursor: pointer;
    margin: 10px 20px;
    font-size: 1.5rem;
    position: absolute;
}

@media (max-width: 991px) {
    .janela {
        width: 95%;
    }
}
</style>