<template>
  <div class="row" :class="classRow">

    <slot></slot>


  </div>
</template>

<script>
export default {
  name: "RowComponent",

  props: {

    classRow: {
      'type': String,
      "required": false,
    }

  }


}
</script>

<style scoped>

</style>