<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off" v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectInput" disabled
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Fornecedor:</label>
                    <input-component :type-input="'text'" v-model="formDate.provider_name" disabled/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="budgetAdd.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <div class="border-content" v-for="(budget, index) in budgetAdd" :key="index">
                        <form autocomplete="off">
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <label>Produto/Serviço:</label>
                                    <input-component :type-input="'text'" v-model="budget.name" disabled/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Quantidade:</label>
                                    <input-number-component :type-input="'number'" min="1" v-model="budget.amount"
                                                            disabled placeholder="1"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Valor Unitário:</label>
                                    <input-component :type-input="'text'" v-model="budget.unit_price" disabled
                                                     v-money="money"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Valor Total:</label>
                                    <input-component :type-input="'text'" v-model="budget.total_price" v-money="money"
                                                     disabled/>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>

                    <h4>Total: R$ {{ totalResult }}</h4>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>
                    <input-text-area :rows="4" v-model="formDate.obs" disabled/>
                </row-col-component>
            </row-component>
        </form>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputNumberComponent from "@/components/form/InputNumberComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import {MONEY} from "@/configs/constants"
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "BudgetTemplatUpdate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea,
        InputNumberComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Modelo de Orçamento')
        this.$store.dispatch('loadProviderSelect')
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "eye"
            },

            itensTwo: {
                route: "/budget-template/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                status: '',
                provider_id: '',
                provider_name: '',
                obs: '',
                itens: []
            },

            totalResult: 0,
            provider: [],

            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        loadPageItens() {
            this.$store.dispatch('loadBudgetTemplate', this.id).then((r) => {
                this.formDate = r;
                this.calcTotal()
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        calcTotal() {
            let sum = 0;
            if (this.totalResult > 0)
                sum = this.replaceStringOne(this.totalResult)
            this.formDate.itens.forEach(item => {
                sum += this.replaceStringOne(item.total_price.replace(/[.,]/g, '')) / 100;
            });
            this.totalResult = sum.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        },

        replaceStringOne(label) {
            if (label.length < 8) {
                return parseFloat(label.replace(',', '.'))
            } else {
                return parseFloat(label.replace(',', ''))
            }
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 24)
                .then((r) => {
                    if (r.data.indexOf('budget-template-search') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        selectProvider() {
            return this.$store.state.var.selectProvider
        },

        budgetAdd() {
            return this.formDate.itens;
        },
    },
}
</script>

<style scoped>
.border-content {
    margin-top: 20px;
    position: relative;
    background: #ebebeb;
    padding: 10px 20px 40px 20px;
}

h4 {
    color: #000;
    text-align: end;
    font-size: 16px;
    padding: 10px 15px;
    background-color: #c9c9c9;
}
</style>
    