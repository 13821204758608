<template>
    <painel-component :itens="itensOne">
        <div class="filter-position" v-if="btnFilter">
            <button-one icon="filter" class="btn-filter" @click="filterWindow"/>
        </div>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                        <label>Código:</label>
                        <input-component :type-input="'text'" v-model="formDate.code"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                        <label>Responsável pela Tarefa:</label>
                        <Multiselect v-model="formDate.responsible_task_user" label="name" :options="selectUserList"
                                     :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                            </template>
                        </Multiselect>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                        <label>Cliente:</label>
                        <Multiselect v-model="formDate.client_id" label="name" :options="selectClientList"
                                     :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                            </template>
                        </Multiselect>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDate.status" :searchable="true" :options="selectStatusTask"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Tipo da Tarefa:</label>
                        <Multiselect v-model="formDate.type_task_id" :searchable="true" :options="selectTypeTaskList"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Setor:</label>
                        <Multiselect v-model="formDate.sector" :searchable="true" :options="selectSector"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'">
                        <button-one class="mx-1" @submit="submit" :icon="'search'"/>
                        <button-float @click="taskCreate" class="mx-1" :itens="itensTwo" v-if="buttonCheck.create"/>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end'">
                    <div class="toggle_preferences">
                        <button :class="{ 'bg-active': parseInt(cardTable) === 1 }" @click="toggleCard" type="button"
                                tooltip="Card">
                            <i class="window maximize outline icon"></i>
                        </button>
                        <button :class="{ 'bg-active': parseInt(cardTable) === 2 }" @click="toggleTable" type="button"
                                tooltip="Tabela">
                            <i class="align justify icon"></i>
                        </button>
                    </div>
                </row-col-component>
            </row-component>

            <row-component v-if="parseInt(cardTable) === 1">
                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4'"
                                   v-for="(task, index) in tasks.itens.data" :key="index">
                    <div class="card-container">
                        <div class="flag-status" :class="[{
                            'create-color': parseInt(task.status) === 1,
                            'send-color': parseInt(task.status) === 2,
                            'approval-color': parseInt(task.status) === 3,
                            'disapproved-color': parseInt(task.status) === 4,
                            'canceled-color': parseInt(task.status) === 5,
                            'finished-color': parseInt(task.status) === 6
                        }]">
                            <i :class="[{
                                'pen square': parseInt(task.status) === 1,
                                'send': parseInt(task.status) === 2,
                                'coffee': parseInt(task.status) === 3,
                                'window close': parseInt(task.status) === 4,
                                'times circle': parseInt(task.status) === 5,
                                'check circle': parseInt(task.status) === 6
                            }, 'icon']"></i>
                            {{ loadStatusTask(task.status) }} {{ tasks.priority }}
                        </div>

                        <img v-if="task.photo_client" :src="task.photo_client" alt="">
                        <img v-else src="@/assets/img/avatar.png" alt="">

                        <h3>CLIENTE: {{ task.name_client }}</h3>
                        <p>Código: {{ task.code }}</p>
                        <p>{{ sectorString(task.sector) }}</p>

                        <div class="skills">
                            <h6><span>Tarefa:</span> {{ task.name }}</h6>

                            <div class="pointer" @click="copyUrl(task.file_location, index)" v-if="task.file_location">
                                <i :id="'icon-folder' + index" class="folder outline icon"></i>
                                <i style="display: none;" :id="'icon-check' + index" class="check circle icon "></i>
                                <p>{{ task.file_location }}</p>
                            </div>
                        </div>

                        <div class="responsible-task">
                            <h6> Responsável pela tarefa:</h6>
                            <img v-if="task.photo_user" :src="task.photo_user" class="avatar__image_2" alt="">
                            <img v-else src="@/assets/img/avatar.png" class="avatar__image_2" alt="">
                            {{ task.name_user }}
                            <br>
                            <span class="tag-priority" :class="[{
                                'send-color': parseInt(task.priority) === 1,
                                'bg-warning': parseInt(task.priority) === 2,
                                'disapproved-color': parseInt(task.priority) === 3
                            }]">
                                Prioridade: {{ priorityString(task.priority) }}
                            </span>
                        </div>

                        <div class="buttons">
                            <button class="primary" tooltip="Finalizar" v-if="parseInt(task.status) === 3"
                                    @click="submitUpdateStatus(6, task)">
                                <i class="check circle outline icon"></i>
                            </button>

                            <button class="primary" tooltip="Reprovar" v-if="parseInt(task.status) === 3"
                                    @click="submitUpdateStatus(4, task)">
                                <i class="times circle icon"></i>
                            </button>
                            <!-- ################# -->
                            <button class="primary" tooltip="Editar" v-if="buttonCheck.edit && task.status <= 4"
                                    @click=routeEditTask(task)>
                                <i class="edit icon"></i>
                            </button>

                            <button class="primary" tooltip="Excluir" v-if="buttonCheck.delete && task.status <= 1"
                                    @click=deleteSubmit(task)>
                                <i class="trash icon"></i>
                            </button>

                            <button class="primary" tooltip="Cancelar" v-if="task.status > 1 && task.status <= 4"
                                    @click="submitUpdateStatus(5, task)">
                                <i class="times icon"></i>
                            </button>

                            <button class="primary " tooltip="Ver" v-if="buttonCheck.show && task.status >= 5"
                                    @click="routeShowTaks(task)">
                                <i class="eye icon"></i>
                            </button>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <table class="table table-striped" v-if="parseInt(cardTable) === 2">
                <thead>
                <tr>
                    <th scope="col" style="width: 10%">Código <br> Prioridade</th>
                    <th scope="col" style="width: 15%">Responsável pela tarefa</th>
                    <th scope="col" style="width: 15%">Cliente</th>
                    <th scope="col" style="width: 5%">Setor</th>
                    <th scope="col" style="width: 10%">Tarefa</th>
                    <th scope="col" style="width: 10%">Local</th>
                    <th scope="col" style="width: 10%">Status</th>
                    <th scope="col" style="width: 10%" v-if="buttonCheck.edit || buttonCheck.delete">
                        Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(task, index) in tasks.itens.data" :key="index">
                    <td data-label="Código">{{ task.code }}
                        <br>
                        <span class="tag-priority" :class="[{
                                'send-color': parseInt(task.priority) === 1,
                                'bg-warning': parseInt(task.priority) === 2,
                                'disapproved-color': parseInt(task.priority) === 3
                            }]">
                                {{ priorityString(task.priority) }}
                            </span>
                    </td>
                    <td data-label="Responsável pela tarefa">
                        <img v-if="task.photo_user" :src="task.photo_user" class="avatar__image_2" alt="">
                        <img v-else src="@/assets/img/avatar.png" class="avatar__image_2" alt="">
                        {{ task.name_user }}
                    </td>
                    <td data-label="Cliente">
                        {{ task.name_client }}
                    </td>
                    <td data-label="Setor">{{ sectorString(task.sector) }}</td>
                    <td data-label="Tarefa">{{ task.name }}</td>
                    <td data-label="Local" @click="copyUrl(task.file_location, index)">
                        <div v-if="task.file_location">
                            {{ task.file_location }}
                        </div>
                    </td>
                    <td data-label="Status">{{ loadStatusTask(task.status) }}</td>
                    <td data-label="Ações" v-if="buttonCheck.delete || buttonCheck.edit">
                        <button class="btn btn-primary mx-1 button-show" tooltip="Finalizar"
                                v-if="parseInt(task.status) === 3"
                                @click="submitUpdateStatus(6, task)">
                            <i class="check circle outline icon"></i>
                        </button>

                        <button class="btn btn-danger mx-1 button-show" tooltip="Reprovar"
                                v-if="parseInt(task.status) === 3"
                                @click="submitUpdateStatus(4, task)">
                            <i class="times circle icon"></i>
                        </button>
                        <!-- ################# -->
                        <button class="btn btn-success mx-1 button-show" tooltip="Editar"
                                v-if="buttonCheck.edit && task.status <= 4" @click=routeEditTask(task)>
                            <i class="edit icon"></i>
                        </button>

                        <button class="btn btn-danger mx-1 button-show" tooltip="Excluir"
                                v-if="buttonCheck.delete && task.status <= 1" @click=deleteSubmit(task)>
                            <i class="trash icon"></i>
                        </button>

                        <button class="btn btn-warning mx-1 button-show" tooltip="Cancelar"
                                v-if="task.status > 1 && task.status <= 4" @click="submitUpdateStatus(5, task)">
                            <i class="times icon"></i>
                        </button>

                        <button class="btn btn-secondary mx-1 button-show" tooltip="Ver"
                                v-if="buttonCheck.show && task.status >= 5" @click="routeShowTaks(task)">
                            <i class="eye icon"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <button-pagination :pagination="tasks.itens" :offset="3" @paginate="loadTasks"/>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import InputComponent from "@/components/form/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";
import {ACTION_MESSAGES} from "@/configs/constants";
import swal from 'sweetalert';
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "TaskSearch",
    components: {
        PainelComponent,
        ButtonOne,
        ButtonFloat,
        ButtonPagination,
        InputComponent,
        RowComponent,
        RowColComponent,
        LoderSendComponent,
        Multiselect,
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Tarefa')
        this.$store.dispatch('loadTypeTasksSelect')
        this.$store.dispatch('loadUserListSelect')
        this.$store.dispatch('loadClientListSelect')
        this.loadTasks(1)
        this.resizeWindow()
        this.checkACL();
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            itensTwo: {
                route: "/",
                icon: "plus",
                name: ''
            },

            buttonCheck: {
                edit: false,
                delete: false,
                create: false,
                show: false
            },

            formDate: {
                code: '',
                type_task_id: '',
                responsible_task_user: '',
                client_id: '',
                name: '',
                name_user: '',
                photo_user: '',
                name_client: '',
                photo_client: '',
                status: '',
                sector: '',
            },

            formDateStatus: {
                id: '',
                status: ''
            },

            copy: true,

            cardTable: '',
            preferences: {
                type: 17,
                preference: ''
            },

            filter: true,
            showLoader: true,
            btnFilter: false,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadTasks(1)
            this.searchSubmit = true;
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },

        taskCreate() {
            this.$store.dispatch('storeTaskCreate')
                .then((r) => {
                    this.$router.push({name: 'task-create', params: {id: r.data}})
                })
        },

        copyUrl(url, index) {
            navigator.clipboard.writeText(url)
            document.getElementById("icon-check" + index).style.display = 'block'
            document.getElementById("icon-folder" + index).style.display = 'none'

            setTimeout(() => {
                document.getElementById("icon-check" + index).style.display = 'none'
                document.getElementById("icon-folder" + index).style.display = 'block'
            }, 2000)
        },

        routeEditTask(task) {
            this.$router.push({name: 'task-edit', params: {id: task.id}})
        },

        routeShowTaks(task) {
            this.$router.push({name: 'task-show', params: {id: task.id}})
        },

        toggleTable() {
            this.userPreferences(2)
            this.cardTable = 2
        },

        toggleCard() {
            this.userPreferences(1)
            this.cardTable = 1
        },

        userPreferences(preference) {
            this.preferences.preference = preference
            this.$store.dispatch('userPreferences', this.preferences)
        },

        submitUpdateStatus(status, task) {
            this.formDateStatus.id = task.id
            this.formDateStatus.status = status

            if (parseInt(this.formDateStatus.status) === 5) {
                swal({
                    title: 'Atenção!',
                    text: `Deseja realmente cancelar a tarefa ?`,
                    closeOnClickOutside: false,
                    buttons: {
                        cancel: {
                            text: 'Não',
                            visible: true,
                            closeModal: true,
                        },
                        confirm: "Sim"
                    }
                }).then((result) => {
                    if (result) {
                        this.$store.dispatch('storeTaskStatus', this.formDateStatus).then(() => {
                            if (parseInt(this.formDateStatus.status) === 5) {
                                this.loadTasks()
                                toast.success("Tarefa cancelada com sucesso", {timeout: 2000});
                            }
                        }).catch(() => {
                            toast.error("Não foi possível alterar o status!", {timeout: 2000});
                        })
                    }
                });
            } else {
                this.$store.dispatch('storeTaskStatus', this.formDateStatus)
                    .then(() => {
                        if (parseInt(this.formDateStatus.status) === 4) {
                            toast.success("Tarefa foi reprovada com sucesso", {timeout: 2000});
                            this.loadTasks()
                        }

                        if (parseInt(this.formDateStatus.status) === 6) {
                            toast.success("Tarefa finalizada com sucesso", {timeout: 2000});
                            this.loadTasks()
                        }
                    }).catch(() => {
                    toast.error("Não foi possível alterar o status!", {timeout: 2000});
                })
            }
        },

        loadTasks(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadTasks', {...this.params, page})
                    .then((r) => {
                        this.showLoader = false
                        this.cardTable = r.tableCard
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        deleteSubmit(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyTask', item.id).then(() => {
                        this.loadTasks(1)
                        toast.success(ACTION_MESSAGES.delete, {timeout: 10000});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 10000});
                    })
                }
            });
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 8)
                .then((r) => {
                    if (r.data.indexOf('task-search') !== -1) {
                        this.showLoader = false
                        if (r.data.indexOf('task-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('task-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                        if (r.data.indexOf('task-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('task-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        loadStatusTask(item) {
            if (parseInt(item) === 1)
                return "Criando"
            if (parseInt(item) === 2)
                return "Enviado para responsável"
            if (parseInt(item) === 3)
                return "Enviado para aprovação"
            if (parseInt(item) === 4)
                return "Reprovado"
            if (parseInt(item) === 5)
                return "Cancelada"
            if (parseInt(item) === 6)
                return "Finalizado"
        },

        sectorString(value) {
            if (parseInt(value) === 1)
                return 'Administrativo'
            if (parseInt(value) === 2)
                return 'Criação'
            if (parseInt(value) === 3)
                return 'Comercial'
            if (parseInt(value) === 4)
                return 'Financeiro'
            if (parseInt(value) === 5)
                return 'RH'
            if (parseInt(value) === 6)
                return 'Operacional'
            if (parseInt(value) === 7)
                return 'T.I'
        },

        priorityString(value) {
            if (parseInt(value) === 1)
                return "Normal"
            if (parseInt(value) === 2)
                return "Média"
            if (parseInt(value) === 3)
                return "Alta"
        }
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectStatusTask() {
            return this.$store.state.var.selectStatusTask
        },

        selectTypeTaskList() {
            return this.$store.state.var.selectTypeTaskList;
        },

        selectUserList() {
            return this.$store.state.var.selectUserList;
        },

        selectClientList() {
            return this.$store.state.var.selectClientList;
        },

        selectSector() {
            return this.$store.state.var.selectSector;
        },

        tasks() {
            return this.$store.state.task.items
        },

        params() {
            return {
                page: this.tasks.current_page,
                code: this.formDate.code,
                type_task_id: this.formDate.type_task_id,
                responsible_task_user: this.formDate.responsible_task_user,
                client_id: this.formDate.client_id,
                status: this.formDate.status,
                sector: this.formDate.sector
            }
        },
    },
}
</script>

<style scoped src="@/assets/css/table-resp.css"></style>
<style scoped>
.toggle_preferences > button {
    border: 0;
    width: 45px;
    color: #fff;
    height: 45px;
    margin: 0 5px;
    padding-right: 4px;
    border-radius: 50%;
    background-color: #323232;
}

.button-show {
    width: 35px;
    height: 35px;
    font-size: 15px;
    border-radius: 50px;
    padding-left: 0.5rem;
}

.bg-active {
    background-color: #d67c1c !important;
}

.tag-priority {
    color: #fff;
    padding: 0 15px;
    font-size: 13px;
    text-align: right;
    font-weight: bold;
    border-radius: 10px;
}

.responsible-task .avatar__image_2 {
    height: 30px;
    width: 30px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
    border: 1px solid #d67c1c;
}

.card-container {
    width: 350px;
    color: #2f3032;
    max-width: 100%;
    overflow: hidden;
    /*margin: 20px auto;*/
    min-height: 525px;
    padding: 30px 0 0;
    position: relative;
    text-align: center;
    background-color: #ffffff;
    border: solid 1px #ededed;
    box-shadow: 10px 10px 15px rgb(0 0 0 / 35%);
}

.card-container h3 {
    color: #424242;
    font-size: 14px;
    font-family: inherit;
    text-transform: uppercase;
}

.card-container > img {
    width: 90px;
    padding: 7px;
    height: 90px;
    border-radius: 50%;
    margin: 25px 0 10px;
    border: 1px solid #d67c1c;
}

.buttons {
    width: 100%;
    display: flex;
    min-height: 60px;
    border-top: 1px solid #ededed;
}

button.primary {
    width: 100%;
    height: 60px;
    color: #7e7e7e;
    padding: 5px 25px;
    background-color: #f5f5f5;
    border: 1px solid #fdfdfd;
}

button.primary a {
    padding: 0;
    color: #7e7e7e;
    text-decoration: none;
}

.skills {
    height: 82px;
    display: flex;
    padding: 0.6rem;
    margin-top: 10px;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    background-color: #f5f5f5;
    border-top: 1px solid #ededed;
}

.responsible-task {
    padding: 15px;
    min-height: 80px;
    text-align: left;
    border-top: 1px solid #ededed;
}

.responsible-task h6 {
    font-size: 14px;
}

.skills h6 {
    font-size: 1rem;
    font-family: 'Comfortaa', sans-serif;
}

.skills h6 > span {
    font-weight: bold;
}

.skills > .pointer {
    width: 100%;
    height: auto;
    display: flex;
    cursor: pointer;
    text-align: left;
}

.skills > .pointer p {
    font-size: 12px;
}

.flag-status {
    top: 0;
    width: 100%;
    color: #ededed;
    font-size: 14px;
    text-align: left;
    padding: 10px 20px;
    position: absolute;
    font-family: 'Comfortaa', sans-serif;
}

.create-color {
    background-color: #5a5a5a;
}

.send-color {
    background-color: #16ab39;
}

.approval-color {
    background-color: #2185d0;
}

.disapproved-color {
    background-color: #db2828;
}

.canceled-color {
    background-color: #db2828;
}

.finished-color {
    background-color: #b5cc18;
}
</style>