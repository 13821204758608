<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off" v-else disabled="true">
            <div class="aux mb-4 mt-3">
                <div class="avatar-form">
                    <img v-if="formDate.photo" :src="formDate.photo" class="avatar__image" alt="">
                    <img v-else src="@/assets/img/avatar.png" class="avatar__image" alt="">
                </div>
                <p>{{ formDate.name }}</p>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Nome:</label>
                    <input-component :name-input="'name'" :type-input="'text'" v-model="formDate.name" disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Email (Login):</label>
                    <input-component :name-input="'email'" :type-input="'email'" v-model="formDate.email" disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Status:</label>
                    <Multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText" :name="'status'"
                                 disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Grupo:</label>
                    <Multiselect v-model="formDate.group_id" :searchable="true" :options="selectPage"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText" :name="'group_id'"
                                 disabled/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-float class="w-100" :itens="itensTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "UserShow",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Usuário')
        this.loadItensPage()
        this.checkACL();

    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "eye"
            },

            itensTwo: {
                route: "/user/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                status: '',
                email: '',
                group_id: '',

            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        async checkACL() {
            this.$store.dispatch('ACLitens', 2)
                .then((r) => {

                    if (r.data.indexOf('user-search') !== -1) {
                        this.showLoader = false;
                    } else {
                        this.$router.push({name: 'home'})
                    }


                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        loadItensPage() {
            this.$store.dispatch('updateNamePage', 'Usuário')
            this.$store.dispatch('loadSelectSearch', 'group-list')
            this.$store.dispatch('loadUser', this.id).then((r) => {
                this.formDate = r;
            }).catch(() => {
                this.showLoader = true;
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        }
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        selectPage() {
            return this.$store.state.var.selectSearch;
        }
    }
}
</script>