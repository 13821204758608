import state from "@/store/modules/task/state";
import actions from "@/store/modules/task/actions";
import mutations from "@/store/modules/task/mutations";
import getters from "@/store/modules/task/getters";

export default {
  state,
  actions,
  mutations,
  getters
}