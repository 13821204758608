<template>
    <div class="card-container" v-if="windowSchedule">
        <div class="close-event" @click="closeModalShowEvent">X</div>

        <div class="header-card"
             :class="[{'blue-bg': parseInt(formDateEventView.color) === 1,
                 'green-bg': parseInt(formDateEventView.color) === 2,
                 'red-bg': parseInt(formDateEventView.color) === 3,
                 'salmon-bg': parseInt(formDateEventView.color) === 4,
                 'silver-bg': parseInt(formDateEventView.color) === 5,
            }]">

            <h3>Evento: {{ formDateEventView.name }}</h3>
            <article v-if="formDateEventView.guests.length > 0">
                <h4>Convidados:</h4>
                <div class="c-profile__list">
                    <a v-for="(image, index) in formDateEventView.guests" :key="index" class="c-profile"
                       :data-user="image.guest_name"
                       :style="{ backgroundImage: 'url(' + image.guest_photo + ')' }">
                    </a>
                </div>
            </article>
        </div>

        <div class="footer-card">
            <div class="skills">
                <div class="date-start">
                    <i class="calendar icon"></i>
                    <span>Data Início: {{ formDateEventView.date_start }} </span>
                    <span>Data Final: {{ formDateEventView.date_end }} </span>
                </div>

                <div class="date-start">
                    <i class="clock icon"></i>
                    <span>Hora Início: {{ formDateEventView.time_start }} </span>
                    <span>Hora Final: {{ formDateEventView.time_end }} </span>
                </div>
            </div>

            <div class="skills">
                <div class="places">
                    <i class="map marker alternate icon"></i>
                    <span>Local: {{ formDateEventView.place }} </span>
                </div>
            </div>

            <div class="skills">
                <textarea rows="4" v-model="formDateEventView.desc" disabled></textarea>
            </div>
        </div>
    </div>

    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off" v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <div class="aux-row-2">
                        <p>Criador da agenda:</p>
                        <img class="character-label-icon-2" :src="formDate.creator_user_photo" alt="">
                        {{ formDate.creator_user_name }}
                    </div>

                    <div class="aux-row-2">
                        <p>Código:</p>
                        {{ formDate.code }}
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <div class="aux-row-3">
                        <div class="text"><i class="calendar icon"></i>{{ formDate.date_create }}</div>
                        <div class="text"><i class="clock icon"></i>{{ formDate.hour_create }}</div>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError':errors.name}">
                    <label>Nome:</label>
                    <input-component :name-input="'name'" :type-input="'text'" v-model="formDate.name" disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError':errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formDate.status" :searchable="true" :options="selectInput" disabled
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText" :name="'status'"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError':errors.participants}">
                    <label>Participantes:</label>
                    <Multiselect v-model="formDate.participants" mode="tags" track-by="name" label="name" disabled
                                 :options="selectUserList" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText" :close-on-select="false"
                                 :search="true">
                        <template v-slot:tag="{ option, handleTagRemove, disabled }">
                            <div class="multiselect-tag is-user" :class="{'is-disabled': disabled}">
                                <img :src="option.icon" alt="">
                                {{ option.name }}
                                <span v-if="!disabled" class="multiselect-tag-remove"
                                      @mousedown.prevent="handleTagRemove(option, $event)">
                                    <span class="multiselect-tag-remove-icon"></span>
                                </span>
                            </div>
                        </template>
                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>
            </row-component>

            <div class="line-division mt-5 mb-5">
                <h1 class="h1-text">
                    <i class="calendar alternate icon"></i>
                    Eventos da Agenda
                </h1>
            </div>

            <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
        </form>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import ptLocale from '@fullcalendar/core/locales/pt'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import "@fullcalendar/core/vdom"

const toast = useToast();

export default {
    name: "ScheduleUpdate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        FullCalendar,
    },

    directives: {
        mask,
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('loadUserListSelect')
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            calendarOptions: {
                plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                eventClick: this.eventClick,
                locale: ptLocale,
                themeSystem: 'standard',
                selectable: true,
                eventDisplay: 'block',
                events: [],
                height: 'auto',
                headerToolbar: {
                    left: 'title',
                    right: 'dayGridMonth,dayGridWeek,listDay, prev,next'
                },
                weekends: true,
                customButtons: {
                    prev: {
                        click: () => {
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.prev();
                            this.formDateEventMonth.month = calendarApi.getDate().getMonth() + 1;
                            this.getEventApiMonth()
                        }
                    },
                    next: {
                        click: () => {
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.next();
                            this.formDateEventMonth.month = calendarApi.getDate().getMonth() + 1;
                            this.getEventApiMonth()
                        }
                    }
                },
            },

            itensOne: {
                title: "Ver",
                icon: "eye"
            },

            itensTwo: {
                route: "/schedule/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                status: '',
                creator_user_name: '',
                date_create: '',
                hour_create: '',
                creator_user_photo: '',
                participants: [],
            },

            formDateEvent: {
                schedule_id: this.id,
                name: '',
                date_start: '',
                time_start: '',
                date_end: '',
                time_end: '',
                place: '',
                desc: '',
                color: 1,
            },

            formDateEventView: {
                creator_user_id: '',
                creator_user_name: '',
                name: '',
                date_start: '',
                time_start: '',
                date_end: '',
                time_end: '',
                place: '',
                desc: '',
                color: '',
                creator_user_photo: '',
                date_create: '',
                hour_create: '',
                guests: []
            },

            formDateEventMonth: {
                id: this.id,
                month: '',
            },

            windowSchedule: false,

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: true,
        }
    },

    methods: {
        getEventApiMonth() {
            this.$store.dispatch('loadScheduleEventMonth', this.formDateEventMonth)
                .then((r) => {
                    this.calendarOptions.events = r
                });
        },

        eventClick: function (dateObject) {
            let publicId = dateObject.event._def.publicId
            this.ShowEvent(publicId)
        },

        dateEventStart(arg) {
            let date = arg.split("-")
            return `${date[2]}/${date[1]}/${date[0]}`
        },

        timeCurrent() {
            let currentTime = new Date();
            let currentHours = currentTime.getHours();
            let currentMinutes = currentTime.getMinutes();
            let currentSeconds = currentTime.getSeconds();

            if (currentHours <= 9)
                currentHours = "0" + currentHours;

            if (currentMinutes <= 9)
                currentMinutes = "0" + currentMinutes

            if (currentSeconds <= 9)
                currentSeconds = "0" + currentSeconds

            return `${currentHours}:${currentMinutes}:${currentSeconds}`
        },

        timeCurrentAdd() {
            let currentTime = new Date();
            let currentHours = currentTime.getHours() + 2;
            let currentMinutes = currentTime.getMinutes();
            let currentSeconds = currentTime.getSeconds();

            if (currentHours <= 9)
                currentHours = "0" + currentHours;

            if (currentMinutes <= 9)
                currentMinutes = "0" + currentMinutes

            if (currentSeconds <= 9)
                currentSeconds = "0" + currentSeconds

            return `${currentHours}:${currentMinutes}:${currentSeconds}`
        },

        ShowEvent(params) {
            this.$store.dispatch('loadScheduleEvent', params).then((r) => {
                this.formDateEventView = r;
                this.windowSchedule = true;
            }).catch(() => {
                this.showLoader = false;
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        closeModalShowEvent() {
            this.clearFormDateEventView()
            this.windowSchedule = false
        },

        clearFormDateEventView() {
            this.formDateEventView = {
                creator_user_id: '',
                creator_user_name: '',
                name: '',
                date_start: '',
                time_start: '',
                date_end: '',
                time_end: '',
                place: '',
                desc: '',
                color: '',
                creator_user_photo: '',
                date_create: '',
                hour_create: ''
            }
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        loadPageItens() {
            this.$store.dispatch('updateNamePage', 'Agenda')
            this.$store.dispatch('loadSchedule', this.id).then((r) => {
                this.formDate = r;
                this.calendarOptions.events = r.events
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 10)
                .then((r) => {
                    if (r.data.indexOf('schedule-edit') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            });
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        selectUserList() {
            return this.$store.state.var.selectUserList;
        },
    }
}
</script>

<style scoped>
.aux-row-2 p {
    margin: 0 10px 0 0;
}

.line-division {
    width: 100%;
    border-top: 1px solid #c4c4c4;
}

.h1-text {
    color: white;
    padding: 10px;
    font-size: 16px;
    margin-top: 1px;
    width: fit-content;
    background-color: #d67c1c;
}

.character-label-icon-2 {
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
}

.aux-row-2 {
    width: 98%;
    display: flex;
    align-items: center;
}

.text {
    margin-right: 10px;
}

.aux-row-3 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.close-event {
    top: 0;
    right: 0;
    cursor: pointer;
    font-size: 1.5rem;
    margin: 10px 20px;
    position: absolute;
}

section {
    display: flex;
    justify-content: space-around;
}

input[type="radio"] + label {
    cursor: pointer;
    line-height: 20px;
    padding-left: 20px;
}

.fc {
    margin-bottom: 100px;
}

.fc .fc-col-header-cell-cushion {
    color: #2C3E50;
    padding: 2px 4px;
    text-decoration: none;
    display: inline-block;
    text-transform: capitalize;
}

.fc .fc-toolbar-title {
    margin: 0;
    font-size: 1.75em;
    text-transform: capitalize;
}

.fc .fc-daygrid-day-number {
    z-index: 4;
    padding: 4px;
    color: #2C3E50;
    position: relative;
    text-decoration: none;
}

.fc .fc-toolbar-title {
    margin: 0;
    font-size: 1.75em;
    text-transform: capitalize;
    font-family: 'Comfortaa', sans-serif;
}

.fc-theme-standard .fc-list-day-cushion {
    z-index: 4;
    padding: 4px;
    color: #2C3E50;
    position: relative;
    text-decoration: none;
}

.fc .fc-today-button,
.fc .fc-button-primary:disabled {
    display: none;
}

.fc a {
    cursor: pointer;
}

.fc-direction-ltr .fc-list-day-text {
    float: left;
    color: #2C3E50;
    text-decoration: none;
    text-transform: capitalize;
}

.float {
    z-index: 9999;
}

.card-container {
    top: 50%;
    left: 50%;
    z-index: 5;
    width: 450px;
    position: fixed;
    background: #fff;
    margin: 0 -50% 0 0;
    padding-bottom: 20px;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.header-card {
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.footer-card {
    height: 50%;
    width: 100%;
    display: flex;
    padding-left: 10px;
    flex-direction: column;
}

.card-container img {
    width: 50px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 50%;
}

.card-container h3 {
    font-size: 16px;
}

.card-container h4 {
    font-size: 16px;
    font-weight: 400;
}

.skills {
    width: 100%;
    display: flex;
    margin-top: 20px;
    align-items: center;
    background-color: #fff;
}

.skills textarea {
    width: 98%;
    resize: none;
}

.places {
    width: 50%;
    display: flex;
    padding-left: 35px;
    position: relative;
    flex-direction: column;
}

.date-start {
    width: 50%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.places i.icon {
    top: 0;
    left: 0;
    font-size: 1.2rem;
    position: absolute;
}

.date-start i.icon {
    left: 0;
    top: 20%;
    font-size: 1.2rem;
    position: absolute;
}

.blue-bg {
    color: white;
    background-color: #2185d0;
}

.green-bg {
    color: white;
    background-color: #16ab39;
}

.red-bg {
    color: white;
    background-color: #db2828;
}

.salmon-bg {
    background-color: #b5cc18;
}

.silver-bg {
    color: white;
    background-color: #5a5a5a;
}

.c-profile {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
    display: inline-block;
    border: 4px solid white;
    background: #FFFFFF center center;
    background-size: cover;
    transition: all 200ms ease;
    box-shadow: 0 3px 8px rgba(44, 44, 84, 0.2);
}

.c-profile:nth-of-type(n+2) {
    margin-left: -42px;
}

.c-profile:hover {
    transform: scale(1.2);
    box-shadow: 0 8px 20px rgba(44, 44, 84, 0.2);
}

.c-profile:hover:after {
    top: 50px;
    opacity: 0;
    padding: 5px;
    width: 100px;
    color: #2C3E50;
    font-size: 10px;
    position: absolute;
    border-radius: 0.15rem;
    content: attr(data-user);
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 5px 12px rgb(0 0 0 / 12%);
    animation: fade 100ms ease 750ms forwards;
    -webkit-animation: fade 100ms ease 750ms forwards;
}

.c-profile__list {
    width: auto;
    position: relative;
    display: inline-flex;
}

.c-profile__list:hover .c-profile:nth-of-type(n+2) {
    margin-left: 7px;
}

article {
    width: 100%;
    max-width: 600px;
}

.multiselect-tag.is-user {
    padding: 5px 8px;
    border-radius: 22px;
    background: #d67c1c;
    margin: 3px 3px 8px;
}

.multiselect-tag.is-user img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    border-radius: 50%;
    border: 2px solid #ffffffbf;
}

.multiselect-tag.is-user i:before {
    color: #ffffff;
    border-radius: 50%;;
}

@-webkit-keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width: 991px) {
    .card-container {
        width: 90%;
        height: 480px;
    }

    .skills {
        flex-direction: column;
        align-items: flex-start;
    }

    .date-start {
        width: 100%;
        align-items: flex-start;
        padding: 5px 5px 5px 50px;
    }

    .places {
        padding-left: 50px;
    }

    .close-event {
        margin: 0 10px 0 0;
    }
}

@media (max-width: 661px) {
    .fc .fc-toolbar {
        display: block;
    }
}
</style>