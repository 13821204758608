<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <input type="radio" id="cliente" disabled v-model="formDate.client_employee_provider_type"
                           value="1">
                    <label for="cliente">Cliente</label>

                    <input type="radio" id="fornecedor" disabled v-model="formDate.client_employee_provider_type"
                           value="3">
                    <label for="fornecedor">Fornecedor</label>

                    <input type="radio" id="funcionario" disabled v-model="formDate.client_employee_provider_type"
                           value="2">
                    <label for="funcionario">Funcionário</label>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <div class="cont-status">
                        <a tooltip="Imprimir PDF" :href="formDate.link_receipt_pdf" target="_blank">
                            <i class="print icon"></i>
                        </a>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Tipo:</label>
                    <multiselect disabled v-model="formDate.type" :searchable="true" :options="selectTypeReceipt"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component v-if="parseInt(formDate.client_employee_provider_type) === 1"
                                   :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <label>Cliente:</label>
                    <Multiselect v-model="formDate.client_employee_provider_id" label="name" disabled
                                 :options="selectClientList"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>

                <row-col-component v-if="parseInt(formDate.client_employee_provider_type) === 2"
                                   :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <label>Funcionário:</label>
                    <Multiselect disabled v-model="formDate.client_employee_provider_id" label="name"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"
                                 :options="selectEmployee">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>

                <row-col-component v-if="parseInt(formDate.client_employee_provider_type) === 3"
                                   :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <label>Fornecedor:</label>
                    <Multiselect disabled v-model="formDate.client_employee_provider_id" label="name"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"
                                 :options="selectProvider">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <label>Título:</label>
                    <input-component disabled v-model="formDate.title" :type-input="'text'"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Data:</label>
                    <input-component disabled v-model="formDate.date" :type-input="'text'" v-mask="'##/##/####'"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Valor:</label>
                    <input-component disabled v-money="money" v-model="formDate.price" :type-input="'text'"/>
                </row-col-component>
            </row-component>

            <row-component v-if="parseInt(formDate.client_employee_provider_type) === 2">
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Pago:</label>
                    <div class="input-group">
                        <input disabled class="form-control" type="email" v-model="formDate.paid">
                        <span class="input-group-text my_btn" id="basic-addon1"><i class="search icon"></i></span>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Referente:</label>
                    <input-text-area disabled :rows="2" v-model="formDate.referent" :type-input="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-float class="w-100" :itens="itensTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputTextArea from "@/components/form/InputTextArea";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import {useToast} from "vue-toastification";
import {mask} from 'vue-the-mask'
import {VMoney} from 'v-money'
import {MONEY} from "@/configs/constants"

const toast = useToast();

export default {
    name: "ReceiptShow",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        InputTextArea,
        ButtonFloat,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
    },

    directives: {
        mask,
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Recibo')
        this.$store.dispatch('loadProviderSelect')
        this.$store.dispatch('loadClientListSelect')
        this.$store.dispatch('loadEmployeeList')
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "eye"
            },

            itensTwo: {
                route: "/receipt/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                type: '',
                title: '',
                client_employee_provider_type: '',
                client_employee_provider_id: '',
                link_receipt_pdf: '',
                date: '',
                price: '',
                paid: '',
                referent: ''
            },

            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        loadPageItens() {
            this.$store.dispatch('loadReceipt', this.id)
                .then((r) => {
                    this.formDate = r;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 33)
                .then((r) => {
                    if (r.data.indexOf('receipt-search') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        }
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectTypeReceipt() {
            return this.$store.state.var.selectTypeReceipt
        },

        selectProvider() {
            return this.$store.state.var.selectProvider
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectEmployee() {
            return this.$store.state.var.selectEmployee
        },

        selectEmployeeBank() {
            return this.$store.state.var.selectEmployeeBank
        },
    },
}
</script>

<style scoped>
.my_btn {
    color: white;
    cursor: pointer;
    position: relative;
    background: #d67c1c;
    padding-bottom: 15px;
    border: 1px solid #d67c1c;
}

.cont-status {
    width: 100%;
    display: flex;
    color: #d67c1c;
    margin-top: 20px;
    font-size: 1.5rem;
    justify-content: flex-end;
}

.cont-status a {
    color: #d67c1c;
    text-decoration: none;
}
</style>