<template>
    <painel-component :itens="itensOne">
        <div class="filter-position" v-if="btnFilter">
            <button-one class="btn-filter" @click="filterWindow" icon="filter"/>
        </div>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <label>Marca/Modelo:</label>
                        <Multiselect v-model="formDate.brand_model" label="name" :options="selectBrandModel"
                                     :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                            </template>
                        </Multiselect>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'">
                        <button-one class="mx-1" @submit="submit" :icon="'search'"/>
                        <button-float class="mx-1" :itens="itensTwo" v-if="buttonCheck.create"/>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end'">
                    <div class="toggle_preferences">
                        <button :class="{ 'bg-active': parseInt(cardTable) === 1 }" @click="toggleCard" type="button"
                                tooltip="Card">
                            <i class="window maximize outline icon"></i>
                        </button>
                        <button :class="{ 'bg-active': parseInt(cardTable) === 2 }" @click="toggleTable" type="button"
                                tooltip="Tabela">
                            <i class="align justify icon"></i>
                        </button>
                    </div>
                </row-col-component>
            </row-component>

            <row-component v-if="parseInt(cardTable) === 1">
                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4'"
                                   v-for="(data, index) in objectReturn.itens.data" :key="index">
                    <div class="card-container">
                        <div class="flag-status"></div>

                        <img v-if="data.photo_fleet" :src="data.photo_fleet" alt="">
                        <img v-else src="@/assets/img/avatar.png" alt="">

                        <p>Ano: {{ data.year }}</p>
                        <p><strong>Combustível: {{ stringFuel(data.fuel) }} </strong></p>

                        <div class="aux-row-3">
                            <div class="text"><i class="car icon"></i>{{ data.brand_model }}</div>
                            <div class="text"><i class="tachometer alternate icon"></i>{{ data.km }}</div>
                        </div>

                        <div class="responsible-task">
                            <h6> Criador:</h6>
                            <img v-if="data.photo_create" :src="data.photo_create" class="avatar__image_2" alt="">
                            <img v-else src="@/assets/img/avatar.png" class="avatar__image_2" alt="">
                            {{ data.creator_user_name }}
                        </div>

                        <div class="buttons">
                            <button tooltip="Editar" class="primary" v-if="buttonCheck.edit"
                                    @click="routeEdit(data.id)">
                                <i class="edit icon"></i>
                            </button>

                            <button tooltip="Ver" class="primary" v-if="buttonCheck.show" @click="routeShow(data.id)">
                                <i class="eye icon"></i>
                            </button>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <table class="table table-striped" v-if="parseInt(cardTable) === 2">
                <thead>
                <tr>
                    <th scope="col" style="width: 15%">Criador</th>
                    <th scope="col" style="width: 15%">Modelo / Marca</th>
                    <th scope="col" style="width: 5%">Ano</th>
                    <th scope="col" style="width: 5%">Quilometragem</th>
                    <th scope="col" style="width: 5%">Combustível</th>
                    <th scope="col" style="width: 10%" v-if="buttonCheck.show || buttonCheck.edit">
                        Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in objectReturn.itens.data" :key="index">
                    <td data-label="Criador">
                        <img v-if="data.photo_create" :src="data.photo_create" class="avatar__image_2" alt="">
                        <img v-else src="@/assets/img/avatar.png" class="avatar__image_2" alt="">
                        {{ data.creator_user_name }}
                    </td>
                    <td data-label="Modelo / Marca">
                        <img v-if="data.photo_fleet" :src="data.photo_fleet" class="avatar__image_2" alt="">
                        <img v-else src="@/assets/img/avatar.png" class="avatar__image_2" alt="">
                        {{ data.brand_model }}
                    </td>
                    <td data-label="Ano">{{ data.year }}</td>
                    <td data-label="Ano">{{ data.km }}</td>
                    <td data-label="Ano">{{ stringFuel(data.fuel) }}</td>
                    <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.low">
                        <button-show-round v-if="buttonCheck.show" :id-param="data.id" route="fleet-show"/>
                        <button-edit-round v-if="buttonCheck.edit" :id-param="data.id" route="fleet-edit"/>
                    </td>
                </tr>
                </tbody>
            </table>

            <button-pagination :pagination="objectReturn.itens" :offset="3" @paginate="loadItems"/>
        </div>
    </painel-component>
</template>

<script>
import ButtonPagination from "@/components/buttons/ButtonPagination";
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";

import {useToast} from "vue-toastification";
import ButtonEditRound from "@/components/buttons/ButtonEditRound.vue";
import ButtonShowRound from "@/components/buttons/ButtonShowRound.vue";

const toast = useToast();

export default {
    name: "FleetSearch",
    components: {
        ButtonShowRound,
        ButtonEditRound,
        ButtonPagination,
        PainelComponent,
        ButtonOne,
        ButtonFloat,
        RowComponent,
        RowColComponent,
        LoderSendComponent,
        Multiselect,
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Frota')
        this.$store.dispatch('loadBrandModelList')
        this.loadItems(1)
        this.resizeWindow()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            itensTwo: {
                route: "/fleet/create",
                icon: "plus",
            },

            buttonCheck: {
                edit: false,
                create: false,
                show: false
            },

            formDate: {
                brand_model: '',
                status: '',
            },

            cardTable: '',
            preferences: {
                type: 1,
                preference: ''
            },

            filter: true,
            btnFilter: false,

            showLoader: true,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadFleets', {...this.params, page})
                    .then((r) => {
                        this.cardTable = r.tableCard
                        this.showLoader = false
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 30)
                .then((r) => {

                    if (r.data.indexOf('fleet-search') !== -1) {
                        this.showLoader = false

                        if (r.data.indexOf('fleet-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('fleet-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('fleet-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        routeEdit(id) {
            this.$router.push({name: 'fleet-edit', params: {id: id}})
        },

        routeShow(id) {
            this.$router.push({name: 'fleet-show', params: {id: id}})
        },

        toggleTable() {
            this.userPreferences(2)
            this.cardTable = 2
        },

        toggleCard() {
            this.userPreferences(1)
            this.cardTable = 1
        },

        userPreferences(preference) {
            this.preferences.preference = preference
            this.$store.dispatch('userPreferences', this.preferences)
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return 'Ativo'
            return 'Inativo'
        },

        stringFuel(value) {
            if (parseInt(value) === 1)
                return 'Diesel'
            if (parseInt(value) === 2)
                return 'Elétrico'
            if (parseInt(value) === 3)
                return 'Etanol'
            if (parseInt(value) === 4)
                return 'Etanol/Gasolina'
            if (parseInt(value) === 5)
                return 'Gasolina'
            if (parseInt(value) === 6)
                return 'GNV(Gás Natural Veicular)'
        }

    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch;
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        selectBrandModel() {
            return this.$store.state.var.selectBrandModel
        },

        objectReturn() {
            return this.$store.state.fleet.items
        },

        params() {
            return {
                page: this.objectReturn.current_page,
                brand_model: this.formDate.brand_model,
                status: this.formDate.status
            }
        },
    },
}
</script>

<style scoped src="@/assets/css/table-resp.css"></style>
<style scoped>
.toggle_preferences > button {
    border: 0;
    width: 45px;
    height: 45px;
    color: #fff;
    margin: 0 5px;
    padding-right: 4px;
    border-radius: 50%;
    background-color: #323232;
}

.bg-active {
    background-color: #d67c1c !important;
}

.card-container > img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
}

.card-container {
    padding: 20px 0 0;
}

.buttons {
    display: flex;
}

button.primary {
    width: 100%;
}
</style>