<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoder"/>

        <form class="mb-4 mt-2" v-else>
            <div class="aux mb-5 mt-3">
                <div class="avatar-form">
                    <img v-if="formDate.photo" :src="formDate.photo" class="avatar__image" alt="">
                    <img v-else src="@/assets/img/avatar.png" class="avatar__image" alt="">
                </div>
                <p>{{ formDate.name }}</p>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError':errors.name}">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Saldo:</label>
                    <input-component :type-input="'text'" v-model="formDate.balance" v-money="money" disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError':errors.status}">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectInput" disabled
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError':errors.status}">
                    <label>Banco:</label>
                    <multiselect v-model="formDate.bank" :searchable="true" :options="selectBank" disabled
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError':errors.status}">
                    <label>Tipo de conta:</label>
                    <multiselect v-model="formDate.account_type" :searchable="true" :options="selectAccountType"
                                 disabled :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError':errors.name}">
                    <label>Agência:</label>
                    <input-component :type-input="'text'" v-model="formDate.agency" disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Conta/Dígito:</label>
                    <input-component :type-input="'text'" v-model="formDate.digit_account" disabled/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError':errors.status}">
                    <label>PIX Tipo:</label>
                    <multiselect v-model="formDate.pix_type" :searchable="true" :options="selectPIX" disabled
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                    <label>Chave:</label>
                    <input-component :type-input="'text'" v-model="formDate.key_pix" disabled
                                     v-mask="['###.###.###-##', '##.###.###/####-##']"
                                     v-if="parseInt(this.formDate.pix_type) === 1"/>

                    <input-component :type-input="'text'" v-model="formDate.key_pix" disabled
                                     v-mask="['(##) ####-####', '(##) #####-####']"
                                     v-else-if="parseInt(this.formDate.pix_type) === 2"/>

                    <input-component :type-input="'email'" v-model="formDate.key_pix"
                                     v-else-if="parseInt(this.formDate.pix_type) === 3" disabled/>

                    <input-component :type-input="'text'" v-model="formDate.key_pix"
                                     v-else-if="parseInt(this.formDate.pix_type) === 4" disabled/>

                    <input-component :type-input="'text'" v-model="formDate.key_pix" disabled v-else/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>
                    <input-text-area :modelValue="formDate.obs" v-model="formDate.obs" :rows="4" disabled/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                    <label>Conta Principal ?:</label>

                    <input type="radio" id="main_account_n" v-model="formDate.main_account" value="1" disabled>
                    <label for="main_account_n">Não</label>

                    <input type="radio" id="main_account_s" v-model="formDate.main_account" value="2" disabled>
                    <label for="main_account_s">Sim</label>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                    <label>Permitir Saldo Negativo ?:</label>

                    <input type="radio" id="allow_negative_balance_n" v-model="formDate.allow_negative_balance"
                           value="1" disabled>
                    <label for="allow_negative_balance_n">Não</label>

                    <input type="radio" id="allow_negative_balance_s" v-model="formDate.allow_negative_balance"
                           value="2" disabled>
                    <label for="allow_negative_balance_s">Sim</label>
                </row-col-component>
            </row-component>

            <row-component class="mt-3">
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-float style="width: 100%" :itens="itensTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";

import {MONEY} from "@/configs/constants";
import {VMoney} from "v-money";
import {mask} from "vue-the-mask";

import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "AccountBankUpdate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        Multiselect,
        LoderSendComponent,
        ErrorComponent,
        InputTextArea
    },

    directives: {
        money: VMoney,
        mask
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Conta Bancária')
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/account-bank/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                bank: '',
                balance: '',
                account_type: '',
                agency: '',
                digit_account: '',
                pix_type: '',
                key_pix: '',
                allow_negative_balance: '',
                main_account: '',
                status: '',
                obs: '',
                photo: '',
            },

            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoder: true,

        }
    },

    methods: {
        loadPageItens() {
            this.$store.dispatch('loadAccountBank', this.id).then((r) => {
                this.formDate = r;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 14)
                .then((r) => {
                    if (r.data.indexOf('account-bank-search') !== -1) {
                        this.showLoder = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        selectBank() {
            return this.$store.state.var.selectBank;
        },

        selectAccountType() {
            return this.$store.state.var.selectAccountType;
        },

        selectPIX() {
            return this.$store.state.var.selectPIX;
        }
    },
}
</script>