<template>
    <div class="modal-files-preview" v-if="preview_files">
        <span @click="preview_files = false">
            <div class="btn-close"></div>
        </span>
        <video v-if="extension(url_file) === 'mp4' || extension(url_file) === 'mov' || extension(url_file) === 'avi'"
               :src="url_file" controls autoplay></video>
        <audio v-else :src="url_file" controls autoplay></audio>
    </div>
    <div class="modal-files-preview" v-if="historic_view">
        <div class="row">
            <div class="container overflow-scroll p-5 position-relative historic-container">
                <div @click="historic_view = !historic_view"
                     class="btn-close position-absolute top-0 end-0 m-3 fs-5"></div>
                <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                     v-if="formDate.historic.length === 0">
                    <p>Não há histórico</p>
                </div>

                <div class="container-desc-two" v-else>
                    <ul class="task-desc">
                        <li class="item" v-for="(historic, index) in historicFileApproved" :key="index">
                            <div class="aux-row">
                                <div class="user">
                                    <img :src="historic.user_photo" alt="Image 001" class="owner-img"/>
                                    {{ historic.name_user }}
                                </div>
                                <div class="desc-date">
                                    <div class="text"><i class="calendar icon"></i>{{ historic.date_create }}</div>
                                    <div class="text"><i class="clock icon"></i>{{ historic.hour_create }}</div>
                                </div>
                            </div>
                            <p>{{ statusApprovedHistoric(historic.status) }}, Plataforma: {{ historic.platform }},
                                Navegador: {{ historic.browser }}, Endereço IP: {{ historic.ip }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <painel-component :itens="itensOne">
        <vue-easy-lightbox :visible="visibleRef" :imgs="photo" :index="indexRef" @hide="onHide"/>
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div class="tabs effect-3" v-else>
            <input checked type="radio" id="tab-1" name="tab-effect-3">
            <span>
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span id="tab-count" :data-count="formDate.files.length">
                <i class="paperclip icon"></i>
                <p>Anexos</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3">
            <span id="tab-count" :data-count="formDate.descs.length">
                <i class="comment outline icon"></i>
                <p>Comentários</p>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3">
            <span id="tab-count" :data-count="formDate.historic.length">
                <i class="clock outline icon"></i>
                <p>Histórico de interações</p>
            </span>

            <input type="radio" id="tab-5" name="tab-effect-3">
            <span id="tab-count" :data-count="formDate.filesApproved.length">
                <i class="clipboard check icon"></i>
                <p>Arquivos aprovação</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12'">
                            <div class="aux-row-2">
                                <p>Criador da tarefa:</p>
                                <img class="character-label-icon-2" :src="formDate.creator_task_photo" alt="">
                                {{ formDate.creator_task_user }}
                            </div>

                            <div class="aux-row-2">
                                <p>Código:</p>
                                {{ formDate.code }}
                            </div>

                            <div class="aux-row-2">
                                <p>Setor:</p>
                                <p>{{ sectorString(formDate.sector) }}</p>
                            </div>

                            <div class="cont-status" :class="[{
                                'send-color': formDate.status === '2',
                                'approval-color': formDate.status === '3',
                                'disapproved-color': formDate.status === '4',
                                'canceled-color': formDate.status === '5',
                                'finished-color': formDate.status === '6',}]">

                                <p>
                                    <i :class="[{
                                    'pen square': formDate.status === '2',
                                    'coffee': formDate.status === '3',
                                    'window close': formDate.status === '4',
                                    'times circle': formDate.status === '5',
                                    'check': formDate.status === '6',}, 'icon']"></i>
                                    {{ loadStatusTask(formDate.status) }}
                                </p>
                            </div>
                        </row-col-component>

                        <row-col-component
                                :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 text-align-end'">
                            <button-float style="border-radius: 50%; margin: 0 15px" :itens="itensTwo"/>

                            <button-one @click="profileClient(formDate.client_id)" class="me-2"
                                        tooltip="Perdil do Cliente"
                                        icon="user outline"/>

                            <button-one @click="submitUpdateStatus(3, this.formDate.file_location)"
                                        :icon="'send'" tooltip="Enviar para aprovação"
                                        v-if="formDate.approve === '2' && formDate.status !== '3' && formDate.status < '5'"/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.type_task_id }">
                            <label>Tipo de Tarefa:</label>
                            <Multiselect v-model="formDate.type_task_id" :searchable="true"
                                         :options="selectTypeTaskList"
                                         disabled
                                         :noResultsText="this.$store.state.var.selectNotSearch"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.client_id }">
                            <label>Cliente:</label>
                            <Multiselect v-model="formDate.client_id" label="name" :options="selectClientList" disabled
                                         :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch">
                                <template v-slot:singlelabel="{ value }">
                                    <div class="multiselect-single-label">
                                        <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                                    </div>
                                </template>

                                <template v-slot:option="{ option }">
                                    <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                                </template>
                            </Multiselect>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.priority }">
                            <label>Prioridade:</label>
                            <Multiselect v-model="formDate.priority" :searchable="true" :options="selectPriority"
                                         disabled
                                         :noResultsText="this.$store.state.var.selectNotSearch"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                            <label>Data de Entrega:</label>
                            <input-component :type-input="'text'" v-model="formDate.date_delivery" disabled
                                             v-mask="'##/##/####'"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                            <label>Hora entrega:</label>
                            <input-component :type-input="'text'" v-model="formDate.time_delivery" disabled
                                             v-mask="'##:##:##'"/>
                        </row-col-component>
                    </row-component>

                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.desc }">
                        <label>Descrição:</label>
                        <QuillEditor :modules="modules" toolbar="full" :contentType="'html'" ref="myEditor"
                                     v-model:content="formDate.desc"/>
                    </row-col-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                           v-if="formDate.inform_file_location === '2'"
                                           :class="{ 'formError': this.showError }">
                            <label>Local do arquivo:</label>
                            <input-component :type-input="'text'" v-model="this.formDate.file_location"
                                             :model-value="this.formDate.file_location"/>
                        </row-col-component>
                    </row-component>

                    <div>
                        <div class="line-division mt-5 mb-5">
                            <h1 class="h1-text">
                                <i class="object group outline icon"></i>
                                Resolução
                            </h1>
                        </div>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <table class="table table-striped">
                                    <tbody>
                                    <tr v-for="(format, index) in formDate.format_resolutions" :key="index">
                                        <td data-label="Resolução">{{ format.resolution }}</td>
                                        <td data-label="Formato">{{ returnFormat(format.format) }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </row-col-component>
                        </row-component>
                    </div>
                </section>

                <section id="tab-item-2">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formDate.files.length === 0">
                        <p>Não há anexos</p>
                    </div>

                    <div class="clearfix" v-if="formDate.status !== '3' && formDate.status < '5'">
                        <label class="uploadButton float-end" for="upload" tooltip="Adicionar Arquivos">
                            <input :disabled="sendForm" type="file" ref="upload-files" @change="selectFiles" id="upload"
                                   multiple accept="image/*">
                            <i class="icon plus"></i>
                        </label>
                    </div>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <div class="container">
                                <div class="files">
                                    <ul>
                                        <li v-for="(data, index) in files" :key="index"
                                            class="d-flex align-items-center justify-content-between">
                                            <div style="width: 15%" class="d-flex justify-content-between">
                                                <div class="text-truncate w-50">
                                                    {{ data.file.name }}
                                                </div>
                                                <div class="file-size w-50">
                                                    {{ convertBytes(data.file.size) }}
                                                </div>
                                            </div>

                                            <div class="me-5 align-items-center justify-content-around"
                                                 style="width: 20%; display: flex" :id="'progress-bar' + index">
                                                <progress :value="data.progress" max="100"></progress>
                                                {{ data.progress }}%
                                            </div>

                                            <div class="file-success" :id="'file-success' + index">
                                                Sucesso ao enviar
                                                <i class="check icon"></i>
                                            </div>

                                            <div class="file-erro" :id="'file-erro' + index">
                                                Erro ao enviar
                                                <i class="close icon"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                           v-for="(file, index) in formDate.files" :key="index">
                            <div class="card">
                                <div class="card-image">
                                    <a :href="file.name_encoded" target="_blank"
                                       v-if="extension(file.name_encoded) === 'png' || extension(file.name_encoded) === 'jpeg' || extension(file.name_encoded) === 'jpg'">
                                        <img :src="file.name_encoded" alt="">
                                    </a>

                                    <i v-else-if="extension(file.name_encoded) === 'pdf'" class="file pdf icon"></i>

                                    <i v-else-if="extension(file.name_encoded) === 'docx' || extension(file.name_encoded) === 'doc'"
                                       class="file word icon"></i>

                                    <i v-else-if="extension(file.name_encoded) === 'mp4' || extension(file.name_encoded) === 'mov' || extension(file.name_encoded) === 'avi'"
                                       class="file video icon"></i>

                                    <i v-else-if="extension(file.name_encoded) === 'xlsx' || extension(file.name_encoded) === 'xlsm' || extension(file.name_encoded) === 'xlsb'
                                        || extension(file.name_encoded) === 'xltx'|| extension(file.name_encoded) === 'xltm'|| extension(file.name_encoded) === 'xls'
                                        || extension(file.name_encoded) === 'xlt' || extension(file.name_encoded) === 'xml' || extension(file.name_encoded) === 'xlam'
                                        || extension(file.name_encoded) === 'xla' || extension(file.name_encoded) === 'xlw' || extension(file.name_encoded) === 'xlr'"
                                       class="file excel icon"></i>

                                    <i v-else-if="extension(file.name_encoded) === 'txt' || extension(file.name_encoded) === 'csv'"
                                       class="file alternate icon"></i>

                                    <i v-else class="file audio icon"></i>
                                </div>
                                <p>{{ file.name_original }}</p>
                                <div class="aux-row-3">
                                    <div class="text"><i class="calendar icon"></i>{{ file.date_create }}</div>
                                    <div class="text"><i class="clock icon"></i>{{ file.hour_create }}</div>
                                </div>
                                <div class="ow-img">
                                    <img :src="file.user_photo" alt=""/>
                                    Enviado por: {{ file.user_name }}
                                </div>
                                <div class="dates-card">
                                    <button
                                            v-if="extension(file.name_encoded) === 'png' && extension(file.name_encoded) === 'jpeg' && extension(file.name_encoded) === 'jpg'"
                                            class="btn btn-secondary buttonRound" type="button" @click="show(index)">
                                        <i class="eye icon"></i>
                                    </button>

                                    <button v-else class="btn btn-secondary buttonRound" type="button"
                                            @click="previewFiles(file.name_encoded)">
                                        <i class="eye icon"></i>
                                    </button>
                                </div>
                            </div>
                        </row-col-component>
                    </row-component>
                </section>

                <section id="tab-item-3">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formDate.descs.length === 0">
                        <p>Não há comentários</p>
                    </div>

                    <div>
                        <row-component v-if="formDate.status !== '3' && formDate.status < '5'">
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Comentários:</label>
                                <input-text-area :rows="4" v-model="formDateObs.obs"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-align-end'">
                                <button-one @submit="submitObs" :icon="'plus'" :name-button="'Adicionar'"
                                            :class="{ 'preloderSend': sendForm }"/>
                            </row-col-component>
                        </row-component>

                        <div class="merito" v-if="formDate.descs.length > 0">
                            <section class="container-desc">
                                <ul class="task-desc">
                                    <li class="item" v-for="(obs, index) in formDate.descs" :key="index">
                                        <div class="aux-row">
                                            <div class="user">
                                                <img :src="obs.user_photo" alt="Image 001" class="owner-img"/>
                                                {{ obs.user_name }}
                                            </div>
                                            <div class="desc-date">
                                                <div class="text"><i class="calendar icon"></i>{{ obs.date_create }}
                                                </div>
                                                <div class="text"><i class="clock icon"></i>{{ obs.hour_create }}</div>
                                            </div>
                                        </div>
                                        <p>{{ obs.obs }}</p>
                                    </li>
                                </ul>
                            </section>
                        </div>
                    </div>
                </section>

                <section id="tab-item-4">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formDate.historic.length === 0">
                        <p>Não há histórico</p>
                    </div>

                    <div class="container-desc-two" v-else>
                        <ul class="task-desc">
                            <li class="item" v-for="(historic, index) in formDate.historic" :key="index">
                                <div class="aux-row">
                                    <div class="user">
                                        <img :src="historic.user_photo" alt="Image 001" class="owner-img"/>
                                        {{ historic.name_user }}
                                    </div>
                                    <div class="desc-date">
                                        <div class="text"><i class="calendar icon"></i>{{ historic.date_create }}</div>
                                        <div class="text"><i class="clock icon"></i>{{ historic.hour_create }}</div>
                                    </div>
                                </div>
                                <p>{{ statusHistoric(historic.status) }}, Plataforma: {{ historic.platform }},
                                    Navegador: {{ historic.browser }}, Endereço IP: {{ historic.ip }}</p>
                            </li>
                        </ul>
                    </div>
                </section>

                <section id="tab-item-5">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formDate.filesApproved.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <div class="clearfix">
                        <label v-if="formDate.status !== '3' && formDate.status < '5'" class="uploadButton float-end"
                               for="uploadApproved"
                               tooltip="Adicionar Arquivos">
                            <input :disabled="sendForm" id="uploadApproved" type="file" @change="selectFilesApproved"
                                   multiple>
                            <i class="icon plus"></i>
                        </label>

                        <div v-if="formDate.filesApproved.length > 0" class="btn-group" role="group">
                            <button-one icon="send" @click="filterApproved(1)" tooltip="Enviados"/>
                            <button-one icon="close" @click="filterApproved(2)" tooltip="Reprovados"/>
                            <button-one icon="check" @click="filterApproved(3)" tooltip="Aprovados"/>
                            <button-one icon="reply all" @click="loadPageItens" tooltip="Listar Todos"/>
                        </div>
                    </div>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <div class="container">
                                <div class="files">
                                    <ul>
                                        <li v-for="(data, index) in filesApproved" :key="index"
                                            class="d-flex align-items-center justify-content-between">

                                            <div style="width: 15%" class="d-flex justify-content-between">
                                                <div class="text-truncate w-50">
                                                    {{ data.file.name }}
                                                </div>
                                                <div class="file-size w-50">
                                                    {{ convertBytes(data.file.size) }}
                                                </div>
                                            </div>

                                            <div class="me-5 align-items-center justify-content-around"
                                                 style="width: 15%; display: flex"
                                                 :id="'progress-bar-approved' + index">
                                                <progress :value="data.progress" max="100"></progress>
                                                {{ data.progress }}%
                                            </div>

                                            <div class="file-success" :id="'file-success-approved' + index">
                                                Sucesso ao enviar
                                                <i class="check icon"></i>
                                            </div>

                                            <div class="file-erro" :id="'file-erro-approved' + index">
                                                Erro ao enviar
                                                <i class="close icon"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'"
                                           v-for="(file, index) in formDate.filesApproved" :key="index">
                            <div class="card">
                                <div class="card-image">
                                    <a :href="file.name_encoded" target="_blank"
                                       v-if="extension(file.name_encoded) === 'png' || extension(file.name_encoded) === 'jpeg' || extension(file.name_encoded) === 'jpg'">
                                        <img :src="file.name_encoded" alt="">
                                    </a>
                                    <i v-else-if="extension(file.name_encoded) === 'pdf'" class="file pdf icon"></i>
                                    <i v-else-if="extension(file.name_encoded) === 'docx' || extension(file.name_encoded) === 'doc'"
                                       class="file word icon"></i>
                                    <i v-else-if="extension(file.name_encoded) === 'mp4' || extension(file.name_encoded) === 'mov' || extension(file.name_encoded) === 'avi'"
                                       class="file video icon"></i>
                                    <i v-else-if="extension(file.name_encoded) === 'xlsx' || extension(file.name_encoded) === 'xlsm' || extension(file.name_encoded) === 'xlsb'
                                        || extension(file.name_encoded) === 'xltx' || extension(file.name_encoded) === 'xltm' || extension(file.name_encoded) === 'xls'
                                        || extension(file.name_encoded) === 'xlt' || extension(file.name_encoded) === 'xml' || extension(file.name_encoded) === 'xlam'
                                        || extension(file.name_encoded) === 'xla' || extension(file.name_encoded) === 'xlw' || extension(file.name_encoded) === 'xlr' || extension(file.name_encoded) === 'csv'"
                                       class="file excel icon"></i>

                                    <i v-else-if="extension(file.name_encoded) === 'txt' || extension(file.name_encoded) === 'csv'"
                                       class="file alternate icon"></i>

                                    <i v-else class="file audio icon"></i>
                                </div>
                                <p>{{ file.name_original }}</p>

                                <div class="aux-row-3">
                                    <div class="text"><i class="calendar icon"></i>{{ file.date_create }}</div>
                                    <div class="text"><i class="clock icon"></i>{{ file.hour_create }}</div>
                                </div>

                                <div class="container ps-3">
                                    <div class="tag-priority"
                                         :class="[{'create-color': file.to_approve === '1' || file.status === '1',
                                         'disapproved-color': file.to_approve === '2' || file.status === '2',
                                         'send-color': file.to_approve === '3' || file.status === '3',
                                         'finished-color': file.to_approve === '4'|| file.status === '4' }]">
                                        {{ statusApprovedHistoric(file.to_approve || file.status) }}
                                    </div>
                                </div>

                                <div class="ow-img">
                                    <img :src="file.user_photo" alt=""/>
                                    Enviado por: {{ file.user_name }}
                                </div>

                                <div class="card-container-buttons">
                                    <div>
                                        <button class="btn"
                                                :class="[{'altt': formDate.status === '3', 'altt': formDate.status >= '5'}]"
                                                v-if="extension(file.name_encoded) === 'png' || extension(file.name_encoded) === 'jpeg' || extension(file.name_encoded) === 'jpg'"
                                                type="button" @click="show(index)">
                                            <i class="eye icon"></i>
                                        </button>

                                        <button class="btn"
                                                :class="[{'altt': formDate.status === '3', 'altt': formDate.status >= '5'}]"
                                                v-else type="button"
                                                @click="previewFiles(file.name_encoded)">
                                            <i class="eye icon"></i>
                                        </button>

                                        <label :for="'editFileApproved' + index" class="btn"
                                               v-if="formDate.status !== '3' && formDate.status < '5'">
                                            <input type="file" @change="onFileChangeEditApproved(file.id, index)"
                                                   :id="'editFileApproved' + index">
                                            <i class="edit icon"></i>
                                        </label>

                                        <button :data-count="file.historic_total"
                                                :class="[{'altt': formDate.status === '3', 'altt': formDate.status >= '5'}]"
                                                @click="historicViewApproved(file.id)"
                                                class="btn file-historic_total" type="button">
                                            <i class="history icon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </row-col-component>
                    </row-component>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import InputTextArea from "@/components/form/InputTextArea";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import VueEasyLightbox, {useEasyLightbox} from 'vue-easy-lightbox'
import {ACTION_MESSAGES} from "@/configs/constants";
import {FORMATOS} from "@/configs/constants"
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";
import PainelComponent from "@/components/dashboard/PainelComponent.vue";
import ButtonFloat from "@/components/buttons/ButtonFloat.vue";
import axios from "axios";
import ImageUploader from 'quill-image-uploader';

const toast = useToast();

export default {
    name: "MyTaskUpdate",
    components: {
        ButtonFloat,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        QuillEditor,
        InputTextArea,
        VueEasyLightbox
    },

    directives: {mask},

    props: {
        id: {
            required: true
        },
    },

    created() {
        this.loadPageItens()
        this.checkACL()
    },

    setup(props) {
        const {
            show, onHide, changeIndex,
            visibleRef, indexRef, imgsRef
        } = useEasyLightbox({initIndex: 0})

        const modules = {
            name: "imageUploader",
            module: ImageUploader,
            options: {
                upload: file => {
                    return new Promise((resolve, reject) => {
                        const formData = new FormData()
                        formData.append("file", file)
                        formData.append("id", props.id)
                        axios.post(`/api/v1/admin/task-file`, formData, {
                            headers: {'Content-Type': 'multipart/form-data'},
                            responseType: 'json'
                        }).then(res => {
                            resolve(res.data[0].name_encoded);
                        }).catch(() => {
                            reject("Upload failed");
                        })
                    })
                },
            }
        }

        return {
            visibleRef,
            indexRef,
            imgsRef,
            show,
            onHide,
            changeIndex,
            modules
        }
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/my-task/search",
                icon: "reply all",
                name: ''
            },

            formDate: {
                creator_task_photo: "",
                creator_task_user: "",
                type_task_id: "",
                platform: "",
                responsible_task_user: "",
                client_id: "",
                priority: "",
                quantity: "",
                inform_file_location: "",
                approve: "",
                date_delivery: "",
                time_delivery: "",
                desc: "",
                status: "",
                file_location: "",
                files: [],
                descs: [],
                historic: [],
                sector: '',
                profile_client: '',
                filesApproved: [],
                format_resolutions: []
            },

            formDateObs: {
                id: this.id,
                obs: ""
            },

            url_file: '',
            preview_files: false,

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,

            toolbarOptions: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],
                [{'header': 1}, {'header': 2}],               // custom button values
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
                [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
                [{'direction': 'rtl'}],                         // text direction
                [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                [{'font': []}],
                [{'align': []}],
                ['clean']                                         // remove formatting button
            ],

            images: '',
            photo: [],

            formDateStatusModal: {
                id: this.id,
                status: '',
                file_location: ''
            },

            formDateFile: [],
            formDateUpdaloadFile: {
                id: this.id,
                file: ""
            },

            formDateFileApproved: [],
            formDateUpdaloadFileApproved: {
                id: this.id,
                file: ""
            },

            formDataApprovedUpdate: {
                id: this.id,
                file_id: '',
                file: ''
            },

            files: [],
            filesApproved: [],

            historic_view: false,

            formDateHistoric: {
                id: this.id,
                file_id: ''
            },

            historicFileApproved: [],

            suffix: '',
            size: '',
            contUpload: 0,
            contUploadApproved: 0,
        }
    },

    methods: {

        returnFormat(format) {
            return FORMATOS[format]
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        getImages() {
            for (let p of this.images) {
                this.photo.push(p.name_encoded)
            }
            return this.photo
        },

        extension(name) {
            return name.split('.').pop().toString()
        },

        filterApproved(params) {
            let formData = {
                id: this.id,
                status: params
            }
            this.$store.dispatch('loadFileApprovedHistoricFilter', formData)
                .then((r) => {
                    if (r.length === 0) {
                        if (params === 1)
                            toast.info("Nenhum arquivo enviado foi encontrado!", {timeout: 2000});
                        if (params === 2)
                            toast.info("Nenhum arquivo reprovado foi encontrado!", {timeout: 2000});
                        if (params === 3)
                            toast.info("Nenhum arquivo aprovado foi encontrado!", {timeout: 2000});
                    } else {
                        this.formDate.filesApproved = r
                    }
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        previewFiles(file) {
            let ext = file.split('.').pop()
            let types = ['wav', 'aiff', 'pcm', 'flac', 'alac', 'wma', 'mp3', 'ogg', 'aac', 'mp4', 'mov', 'avi']

            if (types.find(element => element === ext)) {
                this.url_file = file
                this.preview_files = true
            } else {
                window.open(file, '_blank')
            }
        },

        loadPageItens() {
            this.$store.dispatch('updateNamePage', 'Minhas Tarefas')
            this.$store.dispatch('loadTypeTasksSelect')
            this.$store.dispatch('loadClientListSelect')
            this.$store.dispatch('loadMyTask', this.id).then((r) => {
                this.formDate = r;
                this.formDateStatusModal.file_location = this.formDate.file_location
                this.images = r.filesApproved
                this.getImages()
                this.$refs.myEditor?.setHTML(r.desc)
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 9)
                .then((r) => {
                    if (r.data.indexOf('my-task-edit') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        loadStatusTask(item) {
            if (parseInt(item) === 2)
                return "A fazer"
            if (parseInt(item) === 3)
                return "Enviado para aprovação"
            if (parseInt(item) === 4)
                return "Reprovado (Alteração)"
            if (parseInt(item) === 5)
                return "Cancelada"
            if (parseInt(item) === 6)
                return "Finalizada"
        },

        sectorString(value) {
            if (parseInt(value) === 1)
                return 'Administrativo'
            if (parseInt(value) === 2)
                return 'Criação'
            if (parseInt(value) === 3)
                return 'Comercial'
            if (parseInt(value) === 4)
                return 'Financeiro'
            if (parseInt(value) === 5)
                return 'RH'
            if (parseInt(value) === 6)
                return 'Operacional'
            if (parseInt(value) === 7)
                return 'T.I'
        },

        statusHistoric(status) {
            if (parseInt(status) === 1) {
                return "Criando"
            }
            if (parseInt(status) === 2) {
                return "Enviado para Responsável"
            }
            if (parseInt(status) === 3) {
                return "Enviado para aprovação"
            }
            if (parseInt(status) === 4) {
                return "Reprovado"
            }
            if (parseInt(status) === 5) {
                return "Cancelado"
            }
            if (parseInt(status) === 6) {
                return "Finalizado"
            }
        },

        statusApprovedHistoric(status) {
            if (parseInt(status) === 1) {
                return "Enviado"
            }
            if (parseInt(status) === 2) {
                return "Reprovado"
            }
            if (parseInt(status) === 3) {
                return "Aprovado"
            }
            if (parseInt(status) === 4) {
                return "Atualizado"
            }
        },

        // ################################

        submitObs() {
            this.$store.dispatch('storeMyTaskObs', this.formDateObs)
                .then((r) => {
                    this.showError = false
                    this.errors = []
                    this.formDate.descs = r.data
                    this.formDateObs.obs = ''
                    toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                }).catch((r) => {
                this.showError = true;
                this.errors = r.response.data.errors;
                this.scrollTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        // ################################

        submitUpdateStatus(status, file) {
            this.formDateStatusModal.file_location = file
            this.formDateStatusModal.status = status
            this.$store.dispatch('storeMyTaskStatus', this.formDateStatusModal)
                .then(() => {
                    if (parseInt(status) === 3) {
                        toast.success("Tarefa enviada para a aprovação", {timeout: 2000});
                        this.$router.push({name: 'my-task-search'})
                        this.showError = false;
                        this.errors = [];
                    } else {
                        toast.success("Tarefa finalizada com sucesso", {timeout: 2000});
                        this.showError = false;
                        this.errors = [];
                    }
                    setTimeout(() => this.$router.push({name: 'my-task-search'}), 2100);
                }).catch((r) => {
                this.showError = true;
                this.errors = r.response.data.errors;
                toast.error("Não foi possível alterar o status!", {timeout: 2000});
            })
        },

        convertBytes(size) {
            if (size >= 1024 && size < 1024000) {
                size = Math.round((size / 1024) * 100) / 100;
                return size
            } else if (size >= 1024000) {
                size = Math.round((size / 1024000) * 100) / 100;
                return size
            }
        },

        selectFiles(event) {
            this.files = Array.from(event.target.files).map((file) => ({
                file,
                id: this.id,
            }));
            this.uploadFiles()
        },

        uploadFiles() {
            if (this.formDate.status >= 5) {
                this.files = []
                toast.error("Não é possível fazer alterações!", {timeout: 2000});
            } else {
                this.sendForm = true
                let removeFileClass = document.getElementsByClassName("remove-file");
                removeFileClass.forEach((element) => {
                    element.style.display = 'none'
                });

                this.files.forEach((fileObject, index) => {
                    const params = {
                        file: fileObject.file,
                        id: this.id
                    }

                    axios.post("api/v1/admin/task-file", params, {
                        headers: {'Content-Type': 'multipart/form-data'},
                        onUploadProgress: (progressEvent) => {
                            fileObject.progress = Math.round(
                                (progressEvent.loaded / progressEvent.total) * 100
                            );
                        },
                    }).then(() => {
                        document.getElementById('file-success' + index).style.display = 'block'
                        document.getElementById('progress-bar' + index).style.display = 'none'
                        fileObject.progress = 0;
                        this.contUpload += 1
                        if (this.contUpload === this.files.length) {
                            this.loadPageItens()
                            this.sendForm = false
                            this.files = []
                        }
                    }).catch(() => {
                        document.getElementById('file-erro' + index).style.display = 'block'
                        document.getElementById('progress-bar' + index).style.display = 'none'
                        fileObject.progress = 0;
                        this.contUpload += 1
                        if (this.contUpload === this.files.length) {
                            this.loadPageItens()
                            this.sendForm = false
                            this.files = []
                        }
                    });
                });
            }
        },

        // ##################################

        historicViewApproved(id) {
            this.formDateHistoric.file_id = id

            this.$store.dispatch('loadFileApprovedHistoric', this.formDateHistoric)
                .then((r) => {
                    this.historicFileApproved = r;
                    this.historic_view = true
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        selectFilesApproved(event) {
            this.filesApproved = Array.from(event.target.files).map((file) => ({
                file,
                id: this.id,
            }));

            this.uploadFilesApproved()
        },

        uploadFilesApproved() {
            if (this.formDate.status >= 5) {
                this.filesApproved = []
                toast.error("Não é possível fazer alterações!", {timeout: 2000});
            } else {
                this.sendForm = true
                let removeFileClass = document.getElementsByClassName("remove-file");
                removeFileClass.forEach((element) => {
                    element.style.display = 'none'
                });

                this.filesApproved.forEach((fileObject, index) => {
                    const params = {
                        file: fileObject.file,
                        id: this.id
                    }

                    axios.post("api/v1/admin/my-task-file-approved", params, {
                        headers: {'Content-Type': 'multipart/form-data'},
                        onUploadProgress: (progressEvent) => {
                            fileObject.progress = Math.round(
                                (progressEvent.loaded / progressEvent.total) * 100
                            );
                        },
                    }).then(() => {
                        document.getElementById('file-success-approved' + index).style.display = 'block'
                        document.getElementById('progress-bar-approved' + index).style.display = 'none'
                        fileObject.progress = 0;
                        this.contUpload += 1
                        if (this.contUpload === this.filesApproved.length) {
                            this.sendForm = false
                            this.contUploadApproved = 0
                            this.filesApproved = []
                            this.loadPageItens()
                        }
                    }).catch(() => {
                        document.getElementById('file-erro-approved' + index).style.display = 'block'
                        document.getElementById('progress-bar-approved' + index).style.display = 'none'
                        fileObject.progress = 0;
                        this.contUpload += 1
                        if (this.contUpload === this.filesApproved.length) {
                            this.sendForm = false
                            this.contUploadApproved = 0
                            this.filesApproved = []
                            this.loadPageItens()
                        }
                    });
                });
            }
        },

        // #####################################################

        onFileChangeEditApproved(id, index) {
            this.formDataApprovedUpdate.file_id = id
            let files = document.getElementById('editFileApproved' + index).files || document.getElementById('editFileApproved' + index).dataTransfer.files
            if (!files.length)
                return
            this.formDataApprovedUpdate.file = files[0]
            setTimeout(() => {
                this.uploadEditFileApproved()
            })
        },

        uploadEditFileApproved() {
            this.$store.dispatch('updateMyTaskFileApproved', this.formDataApprovedUpdate)
                .then(() => {
                    toast.success(ACTION_MESSAGES.update_photo, {timeout: 2000});
                    this.loadPageItens()
                }).catch(() => {
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        profileClient(id) {
            if (!this.formDate.profile_client) {
                toast.error("Ops!, perfil do cliente não foi cadastrado.", {timeout: 2000});
            } else {
                this.$router.push({name: 'profile-client-show', params: {id: id}})
            }
        },
    },

    computed: {
        selectPlatform() {
            return this.$store.state.var.selectPlatform;
        },

        selectPriority() {
            return this.$store.state.var.selectPriority;
        },

        selectTypeTaskList() {
            return this.$store.state.var.selectTypeTaskList;
        },

        selectClientList() {
            return this.$store.state.var.selectClientList;
        },

        selectSector() {
            return this.$store.state.var.selectSector;
        }
    }
}
</script>

<style scoped>
.tag-priority {
    color: #fff;
    padding: 0 15px;
    font-size: 13px;
    text-align: right;
    font-weight: bold;
    width: fit-content;
    border-radius: 10px;
}

.card-container-buttons {
    width: 100%;
    height: 60px;
    position: relative;
}

.card-container-buttons > div {
    bottom: 0;
    width: 100%;
    height: 50px;
    position: absolute;
}

.card-container-buttons > div label {
    padding-top: 12px;
}

.altt {
    width: calc(100% / 2) !important;
}

.card-container-buttons > div button,
.card-container-buttons > div label {
    width: calc(100% / 3);
    height: 50px;
    background: #f5f5f5;
    border: 1px solid #fdfdfd;
    border-radius: 0;
    color: #7e7e7e;
}

.create-color {
    background-color: #5a5a5a;
}

.send-color {
    background-color: #16ab39;
}

.approval-color {
    background-color: #2185d0;
}

.disapproved-color {
    background-color: #db2828;
}

.canceled-color {
    background-color: #db2828;
}

.finished-color {
    background-color: #b5cc18;
}

.file-historic_total:after,
#tab-count:after {
    top: 5%;
    width: 20px;
    right: 10px;
    height: 20px;
    display: flex;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: bold;
    border-radius: 50%;
    position: absolute;
    background: #ff851b;
    align-items: center;
    justify-content: center;
    content: attr(data-count);
}

@media (max-width: 991px) {
    .file-historic_total:after,
    #tab-count:after{
        top: 5px;
        right: 5%;
        width: 15px;
        height: 15px;
    }
}

.historic-container {
    width: 95%;
    height: 700px;
    overflow: scroll;
    background: #FFFFFF;
}

@media (max-width: 991px) {
    #tab-count:after {
        top: 0;
        right: 0;
    }

    .historic-container {
        width: 95%;
        height: 700px;
        overflow: scroll;
    }
}

.character-label-icon,
.character-option-icon {
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.character-label-icon-2 {
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.text-align-end {
    text-align: end;
}

.cont-status {
    margin-top: 20px;
    width: fit-content;
    padding: 0 10px 0 10px;
    border-radius: 20px;
    color: white;
    font-weight: bold;
}

.line-division {
    position: relative;
    width: 100%;
    border-top: 1px solid #c4c4c4;
}

.h1-text {
    margin-top: 1px;
    width: fit-content;
    padding: 10px;
    background-color: #d67c1c;
    color: white;
    font-size: 16px;
}

.aux-row {
    display: flex;
    width: 98%;
    align-items: center;
    justify-content: space-between;
}

.aux-row-2 {
    display: flex;
    width: 98%;
    align-items: center;
}

.aux-row-2 p {
    margin: 0 10px 0 0;
}

.buttonRound {
    border-radius: 50px;
    font-size: 15px;
    width: 35px;
    height: 35px;
    margin-right: 7px;
    padding: 6px;
}

.card-image {
    display: block;
    object-fit: contain;
    text-align: center;
}

.card-image a > img {
    display: block;
    width: 100%;
}

.card-image i.icon {
    display: initial;
    font-size: 8rem;
}

.card {
    position: relative;
    display: inline-block;
    width: 90%;
    min-height: auto;
    max-width: 20rem;
    margin: 1rem;
    font-size: 1rem;
    text-decoration: none;
    box-shadow: 0 0 3rem -1rem rgba(0, 0, 0, 0.5);
    transition: transform 0.1s ease-in-out, box-shadow 0.1s;
}

.card:hover {
    transform: translateY(-0.5rem) scale(1.0125);
    box-shadow: 0 0.5em 3rem -1rem rgba(0, 0, 0, 0.5);
}

.card p {
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
}

.ow-img {
    padding: 10px 10px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    width: 100%;
}

.ow-img > img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin-right: 10px;
}

.aux-row-3 {
    display: flex;
    justify-content: space-evenly
}

.merito > .container-desc {
    display: block;
}

.merito {
    height: 200px;
    width: 100%;
    padding: 0 20px;
    background: #ebebeb;
    overflow-x: scroll;
}

.dates-card {
    display: flex;
    place-items: center;
    place-content: center;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.5s;
}

.dates-card:hover {
    opacity: 1;
    transition: opacity 0.5s;
}

input[type=file] {
    position: absolute;
    right: -9999px;
    visibility: hidden;
    opacity: 0;
}

input[type=button] {
    position: relative;
    padding: 1rem 3rem;
    background: #FF7700;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    border-radius: 5px;
    border: 0;
    color: #fff;
}

div.files {
    padding: 1rem 0;
    margin: 1rem 0;
}

div.files ul {
    list-style: none;
    padding: 0;
    max-height: 160px;
    overflow: auto;
}

div.files ul li {
    width: 99%;
    background: #eee;
    margin: 0.5rem 0;
    padding: 0.5rem;
    position: relative;
}

div.files ul li button {
    border: 0;
    outline: none;
    position: absolute;
    right: 0;
    margin-right: 0.5rem;
}

div.container {
    max-width: 100%;
    padding: 0;
}

span.file-size {
    color: #999;
    padding-left: 0.5rem;
}

.uploadButton {
    line-height: 20px;
    padding: 0.7rem;
    height: 42px;
    background: #d67c1c;
    border-radius: 5px;
    border: 0;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.5s ease-out;
    top: 0;
    right: 0;
}

.file-success > i.icon {
    color: #16ab39;
}

.file-erro > i.icon {
    color: #db2828;
}

.file-success,
.file-erro {
    display: none;
    width: fit-content;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;

}

.modal-files-preview {
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 100;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
    place-content: center;
    background: rgb(0, 0, 0, 0.6);
}

.modal-files-preview .btn-close {
    top: 0;
    right: 0;
    margin: 2%;
    cursor: pointer;
    position: absolute;
}

.modal-files-preview span {
    top: 0;
    right: 0;
    margin: 2%;
    width: 50px;
    height: 50px;
    display: block;
    background: white;
    position: absolute;
    border-radius: 50%;
    padding: 0.4rem 0.5rem;
}

.modal-files-preview .btn-close {
    font-size: 1.5rem;
    position: relative;
}

.modal-files-preview > video {
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    display: block;
    max-height: 80vh;
    max-width: 80vw;
    position: relative;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
}

progress {
    border-radius: 8px;
    border: 1px solid #fbfbfb;
}

progress::-webkit-progress-bar {
    background-color: #fbfbfb;
}

progress::-webkit-progress-value {
    background-color: #d67c1c;
}
</style>