import AdminComponent from "@/components/dashboard/AdminComponent";
import LeadSearch from "@/views/search/LeadSearch";
import LeadCreate from "@/views/create/LeadCreate";
import LeadUpdate from "@/views/update/LeadUpdate";
import LeadShow from "@/views/show/LeadShow"

export default {
    path: '/lead',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'create', name: 'lead-create', component: LeadCreate },
        { path: 'search', name: 'lead-search', component: LeadSearch },
        { path: 'show/:id', name: 'lead-show', component: LeadShow, props: true },
        { path: 'edit/:id', name: 'lead-edit', component: LeadUpdate, props: true },
    ],
}
