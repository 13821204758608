import AdminComponent from "@/components/dashboard/AdminComponent";
import ProductSearch from "@/views/search/ProductSearch";
import ProductShow from "@/views/show/ProductShow";
import ProductCreate from "@/views/create/ProductCreate";
import ProductUpdate from "@/views/update/ProductUpdate";
import ProductUpdatePhoto from "@/views/update/ProductUpdatePhoto"

export default {
    path: '/product',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'create', name: 'product-create', component: ProductCreate },
        { path: 'search', name: 'product-search', component: ProductSearch },
        { path: 'edit/:id', name: 'product-edit', component: ProductUpdate, props: true },
        { path: 'show/:id', name: 'product-show', component: ProductShow, props: true },
        { path: 'photo/:id', name: 'product-update-photo', component: ProductUpdatePhoto, props: true },
    ],
}
