import AdminComponent from "@/components/dashboard/AdminComponent";
import TaskCreate from "@/views/create/TaskCreate";
import TaskSearch from "@/views/search/TaskSearch";
import TaskUpdate from "@/views/update/TaskUpdate";

export default {
    path: '/task',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'create/:id', name: 'task-create', component: TaskCreate, props: true},
        {path: 'search', name: 'task-search', component: TaskSearch},
        {path: 'edit/:id', name: 'task-edit', component: TaskUpdate, props: true},
        {path: 'show/:id', name: 'task-show', component: TaskUpdate, props: true},
    ],
}