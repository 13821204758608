<template>
    <painel-component :itens="itensOne">

        <error-component :errors="errors" v-if="showError"></error-component>

        <loder-send-component :text-title="'Carregando'" v-if="showLoder"></loder-send-component>

        <form autocomplete="off" v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.status }">
                    <label>Cliente:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                        :noResultsText="this.$store.state.var.selectNotSearch" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.status }">
                    <label>Modelo de Contrato:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                        :noResultsText="this.$store.state.var.selectNotSearch" />
                </row-col-component>
            </row-component>
        </form>

        <button-one @submit="submit" :icon="'plus circle'" :name-button="'Adicionar'"
            :class="{ 'preloderSend': sendForm }">
        </button-one>

    </painel-component>

    <button-add :itens="itensThree" v-if="buttonCheck.create"></button-add>
    <button-float :itens="itensTwo"></button-float>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";

import { useToast } from "vue-toastification";
const toast = useToast();

export default {
    name: "ContractCreate",

    created() {
        this.loadPageItens(1)
        this.checkACL()
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/contract/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                price: '',
                obs: '',
                status: '',
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoder: true,
        }
    },

    methods: {
        loadPageItens() {
            this.$store.dispatch('updateNamePage', 'Contrato')
            this.$store.dispatch('loadEmployee', this.id).then((r) => {
                this.formDate = r;
            }).catch(() => {
                toast.error("Erro desconhecido !", { timeout: 2000 });
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 28)
                .then((r) => {
                    if (r.data.indexOf('contract-search') !== -1) {
                        this.showLoder = false
                    } else {
                        this.$router.push({ name: 'home' })
                    }
                }).catch(() => {
                    this.$router.push({ name: 'home' })
                })
        },
    },

    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        ButtonFloat,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
    },

    computed: {
        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },
    },
}
</script>

