<template>
    <painel-component :itens="itensOne">
        <div class="filter-position" v-if="btnFilter">
            <button-one class="btn-filter" @click="filterWindow" icon="filter"/>
        </div>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-4 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDate.surname" placeholder="Nome"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-4 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>CNPJ:</label>
                        <input-component :type-input="'text'" v-model="formDate.cnpj"
                                         :placeholder="'00.000.000/0000-00'" v-mask="'##.###.###/####-##'"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>CPF:</label>
                        <input-component :type-input="'text'" v-model="formDate.cpf" :placeholder="'000.000.000-00'"
                                         v-mask="'###.###.###-##'"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                        <button-one class="me-2" @submit="submit" :icon="'search'"/>
                        <button-float :itens="itensTwo" v-if="buttonCheck.create"/>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <table class="table table-striped">
                <thead>
                <tr>
                    <th scope="col" style="width: 45%">Nome</th>
                    <th scope="col" style="width: 20%">CPF / CNPJ</th>
                    <th scope="col" style="width: 10%">Status</th>
                    <th scope="col" style="width: 10%" v-if="buttonCheck.show || buttonCheck.edit">Ações</th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(provider, index) in providers.data" :key="index">
                    <td data-label="Nome">
                        <img v-if="provider.photo" :src="provider.photo" class="avatar__image_2" alt="">
                        <img v-else src="@/assets/img/avatar.png" class="avatar__image_2" alt="">
                        {{ provider.name }}
                    </td>
                    <td data-label="CPF / CNPJ">{{ provider.cpf }} {{ provider.cnpj }}</td>
                    <td data-label="Status">{{ statusString(provider.status) }}</td>
                    <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.edit">
                        <button-show-round :route="'provider-show'" :id-param="provider.id" v-if="buttonCheck.show"/>
                        <button-edit-round :route="'provider-edit'" :id-param="provider.id" v-if="buttonCheck.edit"/>
                    </td>
                </tr>
                </tbody>
            </table>

            <button-pagination :pagination="providers" :offset="3" @paginate="loadItems"/>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import InputComponent from "@/components/form/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonShowRound from "@/components/buttons/ButtonShowRound";
import ButtonEditRound from "@/components/buttons/ButtonEditRound";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";

import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "ProviderSearch",
    components: {
        PainelComponent,
        ButtonOne,
        ButtonFloat,
        ButtonPagination,
        InputComponent,
        RowComponent,
        RowColComponent,
        ButtonShowRound,
        ButtonEditRound,
        LoderSendComponent,
        Multiselect,
    },

    directives: {
        mask
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Fornecedor')
        this.loadItems(1)
        this.resizeWindow()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            itensTwo: {
                route: "/provider/create",
                icon: "plus",
            },

            buttonCheck: {
                edit: false,
                create: false,
                show: false
            },

            formDate: {
                name: '',
                cnpj: '',
                cpf: '',
                status: '',
                photo: ''
            },

            filter: true,
            btnFilter: false,

            showLoader: true,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadProviders', {...this.params, page})
                    .then((r) => {
                        this.showLoader = false
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 15)
                .then((r) => {
                    if (r.data.indexOf('provider-search') !== -1) {
                        this.showLoader = false

                        if (r.data.indexOf('provider-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('provider-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('provider-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return 'Ativo'
            return 'Inativo'
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch;
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactiveSearch;
        },

        providers() {
            return this.$store.state.provider.items
        },

        params() {
            return {
                page: this.providers.current_page,
                name: this.formDate.name,
                status: this.formDate.status,
                cpf: this.formDate.cpf,
                cnpj: this.formDate.cnpj
            }
        },
    },
}
</script>

<style scoped src="@/assets/css/table-resp.css"></style>
<style scoped>
.avatar__image_2 {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
    border: 1px solid #d67c1c;
}
</style>