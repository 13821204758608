import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Toast from "vue-toastification";


/* Bootstrap import*/
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./assets/css/main.css"
import "./assets/css/icon.min.css"
import "vue-toastification/dist/index.css";

import {NAME_TOKEN} from '@/configs/constants'


const tokenAcess = localStorage.getItem(NAME_TOKEN)
if (tokenAcess)
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenAcess}`;

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = 'https://system.d7m.com.br';
} else {
    axios.defaults.baseURL = 'https://apidev.d7mmarketing.com.br';
}
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';


createApp(App)
    .use(Toast)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    .mount('#app')

store.dispatch('loginCheck')
    .catch(() => {
        store.dispatch('logout')
    })
