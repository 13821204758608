import AdminComponent from "@/components/dashboard/AdminComponent";
import MyTaskSearch from "@/views/search/MyTaskSearch";
import MyTaskUpdate from "@/views/update/MyTaskUpdate";

export default {
    path: '/my-task',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'my-task-search', component: MyTaskSearch },
        { path: 'edit/:id', name: 'my-task-edit', component: MyTaskUpdate, props: true },
    ],
}