export default {

    icon: 'calculator',
    name: 'Financeiro',
    subLinks: [

        {
            name: 'Conta Bancária',
            route: '/account-bank/search',
            pagePermissions: [
                'account-bank-edit',
                'account-bank-search',
                'account-bank-create'
            ],
        },

        {
            name: 'Entrada/Saída',
            route: '/entrance-exit/search',
            pagePermissions: [
                'entrance-exit-search',
                'entrance-exit-create'
            ],
        },

        {
            name: 'Entrada/Saída (Previsão)',
            route: '/entrance-exit-preview/search',
            pagePermissions: [
                'entrance-exit-preview-low',
                'entrance-exit-preview-search',
                'entrance-exit-preview-create',
                'entrance-exit-preview-delete',
                'entrance-and-exit-preview-low'
            ],
        },

        {
            name: 'Recibo',
            route: '/receipt/search',
            pagePermissions: [
                'receipt-create',
                'receipt-search',
                'receipt-edit',
                'receipt-delete'
            ],
        },

        {
            name: 'Tipo de Conta',
            route: '/account-type/search',
            pagePermissions: [
                'account-type-edit',
                'account-type-search',
                'account-type-create',
            ],
        },


    ],

    pageLinksNavCheck: [
        'account-bank-edit',
        'account-bank-search',
        'account-bank-create',
        'entrance-exit-search',
        'entrance-exit-create',
        'entrance-exit-preview-low',
        'entrance-exit-preview-search',
        'entrance-exit-preview-create',
        'entrance-exit-preview-delete',
        'entrance-and-exit-preview-low',
        'account-type-edit',
        'account-type-search',
        'account-type-create',
        'receipt-create',
        'receipt-search',
        'receipt-edit',
        'receipt-delete'
    ],

}