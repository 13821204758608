<template>
    <painel-component :itens="itensOne">
        <button-add :itens="itensThree" v-if="buttonCheck.create"/>
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form autocomplete="off" v-else class="mt-4">
            <!--<row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 clearfix'">
                    <button-one icon="send" class="float-start" @submit="submit" tooltip="Enviar para proposta"/>
                </row-col-component>
            </row-component>-->

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome Contato:</label>
                    <input-component :type-input="'text'" v-model="formDate.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.company }">
                    <label>Empresa:</label>
                    <input-component :type-input="'text'" v-model="formDate.company"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.email }">
                    <label>Email:</label>
                    <input-component :type-input="'email'" v-model="formDate.email"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.phone }">
                    <label>Telefone / Celular:</label>
                    <input-component :type-input="'email'" v-model="formDate.phone"
                                     v-mask="['(##) ####-####', '(##) #####-####']"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.segment }">
                    <label>Segmento:</label>
                    <input-component :type-input="'email'" v-model="formDate.segment"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.met }">
                    <label>Como nos conheceu?:</label>
                    <multiselect v-model="formDate.met" :searchable="true" :options="selectMet"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.closure }">
                    <label>Possibilidade de fechamento?:</label>
                    <multiselect v-model="formDate.closure" :searchable="true" :options="selectClosure"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.presentation }">
                    <label>Apresentação:</label>
                    <multiselect v-model="formDate.presentation" :searchable="true" :options="selectPresentation"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Novas Observações:</label>
                    <input-text-area :rows="4" v-model="formDate.obs"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>
                    <div class="merito">
                        <section class="container-desc">
                            <ul class="task-desc">
                                <li class="item" v-for="(obs, index) in formDate.obslist" :key="index">
                                    <div class="aux-row">
                                        <div class="user">
                                            <img :src="obs.user_photo" alt="Image 001" class="owner-img"/>
                                            {{ obs.user_name }}
                                        </div>
                                        <div class="desc-date">
                                            <div class="text"><i class="calendar icon"></i>{{ obs.date_create }}</div>
                                            <div class="text"><i class="clock icon"></i>{{ obs.hour_create }}</div>
                                        </div>
                                    </div>
                                    <p>{{ obs.obs }}</p>
                                </li>
                            </ul>
                        </section>
                    </div>
                </row-col-component>
            </row-component>
        </form>

        <!--<div class="line-division mt-5 mb-5">
            <h1 class="h1-text">
                <i class="user alternate icon"></i>
                Histórico de envio
            </h1>
        </div>-->

        <row-component>
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-one class="w-100" @submit="submit" :icon="'save'" :name-button="'Salvar'"
                            :class="{ 'preloderSend': sendForm }"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonAdd from "@/components/buttons/ButtonAdd"
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {ACTION_MESSAGES} from "@/configs/constants";
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "LeadUpdate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        ButtonAdd,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea
    },

    directives: {
        mask
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Leads')
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/lead/search",
                icon: "reply all",
                name: 'Voltar'
            },

            itensThree: {
                route: "/lead/create",
                icon: "plus",
                name: 'Adicionar'
            },

            buttonCheck: {
                create: false,
            },

            formDate: {
                name: '',
                company: '',
                email: '',
                phone: '',
                segment: '',
                met: '',
                presentation: '',
                closure: '',
                obs: '',
                obslist: []
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateLead', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        this.loadPageItens()
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        loadPageItens() {
            this.$store.dispatch('loadLead', this.id).then((r) => {
                this.formDate = r;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 19)
                .then((r) => {
                    if (r.data.indexOf('lead-edit') !== -1) {
                        this.showLoader = false
                    }
                    if (r.data.indexOf('lead-create') !== -1) {
                        this.buttonCheck.create = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },
        selectMet() {
            return this.$store.state.var.selectMet
        },
        selectPresentation() {
            return this.$store.state.var.selectPresentation
        },
        selectClosure() {
            return this.$store.state.var.selectClosure
        }
    }

}
</script>

<style scoped>
.line-division {
    width: 100%;
    border-top: 1px solid #c4c4c4;
}

.h1-text {
    color: white;
    padding: 10px;
    margin-top: 1px;
    font-size: 16px;
    width: fit-content;
    background-color: #d67c1c;
}

.merito {
    width: 100%;
    height: 200px;
    padding: 0 20px;
    overflow-x: scroll;
    background: #ebebeb;
}

ul.task-desc li.item {
    display: flex;
    margin: 20px 0;
    padding: 0 20px;
    box-shadow: none;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    background: transparent;
    justify-content: space-between;
}
</style>