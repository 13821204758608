export default {

    icon: 'calendar alternate',
    name: 'Planejamento',
    subLinks: [

        {
            name: 'Agenda',
            route: '/schedule/search',
            pagePermissions: [
                'schedule-create',
                'schedule-edit',
                'schedule-search',
                'schedule-delete'
            ],
        },

        {
            name: 'Anotações',
            route: '/note/search',
            pagePermissions: [
                'note-edit',
                'note-search',
                'note-create'
            ],
        },

        {
            name: 'Anotações Agendadas',
            route: '/scheduled-note/search',
            pagePermissions: [
                'note-schedule-search',
                'note-schedule-create',
                'note-schedule-edit',
                'note-schedule-delete'
            ],
        },

        {
            name: 'Convidado Agenda',
            route: '/guest-schedule/search',
            pagePermissions: [
                'guest-schedule-edit',
                'guest-schedule-search',
                'guest-schedule-create',
                'guest-schedule-delete'
            ],
        },

        {
            name: 'Perfil do Cliente',
            route: '/customer-profile/search',
            pagePermissions: [
                'profile-search',
                'profile-create',
                'profile-edit',
                'profile-password',
                'profile-password-show'
            ],
        },

        {
            name: 'Minha Agenda',
            route: '/my-schedule/search',
            pagePermissions: [
                'my-schedule-edit',
                'my-schedule-search',
            ],
        },

        {
            name: 'Minhas Tarefas',
            route: '/my-task/search',
            pagePermissions: [
                'my-task-create',
                'my-task-edit',
                'my-task-search',
                'my-task-delete'
            ],
        },

        {
            name: 'Tarefa',
            route: '/task/search',
            pagePermissions: [
                'task-create',
                'task-edit',
                'task-search',
                'task-delete'
            ],
        },

        {
            name: 'Tipo de Tarefa',
            route: '/task-type/search',
            pagePermissions: [
                'task-type-edit',
                'task-type-search',
                'task-type-create',
                'task-type-delete'
            ],
        },
    ],

    pageLinksNavCheck: [
        'schedule-create',
        'schedule-edit',
        'schedule-search',
        'schedule-delete',
        'note-edit',
        'note-search',
        'note-create',
        'guest-schedule-edit',
        'guest-schedule-search',
        'guest-schedule-create',
        'guest-schedule-delete',
        'profile-search',
        'profile-create',
        'profile-edit',
        'profile-password',
        'profile-password-show',
        'my-schedule-edit',
        'my-schedule-search',
        'my-task-create',
        'my-task-edit',
        'my-task-search',
        'my-task-delete',
        'task-create',
        'task-edit',
        'task-search',
        'task-delete',
        'task-type-edit',
        'task-type-search',
        'task-type-create',
        'task-type-delete',
        'note-schedule-search',
        'note-schedule-create',
        'note-schedule-edit',
        'note-schedule-delete'
    ],

}