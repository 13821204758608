import axios from "axios";
import { URL_BASE } from "@/configs/constants";

const RESOURCE = 'task';
export default {

    loadTasks(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, { params })
                .then(response => {
                    context.commit('LOAD_TASK', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
        })
    },

    storeTask(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => { })
        })
    },

    storeTaskCreate(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}task-create`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => { })
        })
    },

    loadTask(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateTask(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyTask(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeTaskFile(context, formData) {
        let config = { headers: { 'Content-Type': 'multipart/form-data' } }
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}task-file`, formData, config)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
        })
    },

    destroyTaskFile(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}task-file-delete/${id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeTaskObs(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}task-obs`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyTaskObs(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}task-obs-delete/${id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeTaskStatus(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}task-update-status`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadTaskFileApprovedStatus(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}task-file-approved-get/${formData.id}/${formData.status}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadTaskFileApprovedHistoric(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}task-file-approved-get-historic/${formData.id}/${formData.fileid}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    approvedSetFilesTask(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}task-approved-set`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    reproachSetFilesTask(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}task-reproach-set`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

}