import state from "@/store/modules/contract/state";
import actions from "@/store/modules/contract/actions";
import mutations from "@/store/modules/contract/mutations";
import getters from "@/store/modules/contract/getters";

export default {
  state,
  actions,
  mutations,
  getters
}