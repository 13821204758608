<template>
    <div class="modal-proposal"
         v-if="windowSection || windowItem || modal_client || modal_email || modal_phone || modal_section || modal_item || modal_search">

        <div v-if="windowSection">
            <div class="btn-close" @click="windowSectionClose"></div>
            <div class="clearfix">
                <button-one icon="plus" class="float-start rounded-0" @click="addSection"/>
            </div>

            <div class="border-content" v-for="(section, index) in sectionAdd" :key="index">
                <form autocomplete="off" class="mt-2">
                    <button-remove-round class="position-absolute end-0 bottom-0 rounded-0 my-2"
                                         @click="sectionRemove(index)" v-if="sectionAdd.length > 1"/>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <label>Adicionar Sessão:</label>
                            <input-component :type-input="'text'" v-model="section.section"/>
                        </row-col-component>
                    </row-component>
                </form>
            </div>
            <button-one @submit="submitSection" :icon="'plus'" :name-button="'adicionar'"/>
        </div>

        <div v-if="windowItem">
            <div class="btn-close" @click="windowItemClose"></div>
            <div class="clearfix">
                <button-one icon="plus" class="float-start rounded-0" @click="addItem"/>
            </div>

            <div class="border-content" v-for="(item, index) in itemAdd" :key="index">
                <form autocomplete="off" class="mt-2">
                    <button-remove-round class="position-absolute end-0 bottom-0 rounded-0 my-2"
                                         @click="itemRemove(index)" v-if="itemAdd.length > 1"/>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <label>Adicionar Items:</label>
                            <input-component :type-input="'text'" v-model="item.item"/>
                        </row-col-component>
                    </row-component>
                </form>
            </div>
            <button-one @submit="submitItem" :icon="'plus'" :name-button="'Adicionar'"/>
        </div>

        <div v-if="modal_client">
            <div class="btn-close" @click="openClient"></div>

            <row-col-component
                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Cliente:</label>
                <Multiselect v-model="client" label="name" :options="selectClientList"
                             :searchable="true"
                             :noResultsText="this.$store.state.var.selectNotSearch">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>

            <button-one name-button="Confirmar" @click="closeClient(client)"/>
        </div>

        <div v-if="modal_email">
            <div class="btn-close" @click="openEmail"></div>

            <row-col-component
                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Email:</label>
                <Multiselect v-model="formDate.email" label="name" :options="selectClientEmailList"
                             :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>

            <button-one name-button="Confirmar" @click="openEmail"/>
        </div>

        <div v-if="modal_phone">
            <div class="btn-close" @click="openPhone"></div>

            <row-col-component
                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Telefone:</label>
                <Multiselect v-model="formDate.phone" label="name" :options="selectClientPhoneList"
                             :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>

            <button-one name-button="Confirmar"/>
        </div>

        <div v-if="modal_section">
            <div class="btn-close" @click="openSection(1)"></div>
            <draggable
                    v-model="formDate.sections"
                    group="people"
                    @start="drag=true"
                    @end="drag=false"
                    ghost-class="ghost"
                    item-key="id">
                <template #item="{element, index}">
                    <row-component class="section-style" style="cursor: pointer">
                        <div class="indexCount">{{ index + 1 }}</div>
                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <label>Nome da sessão:</label>
                            <input-component :type-input="'text'" v-model="element.item"/>
                        </row-col-component>
                    </row-component>
                </template>
            </draggable>

            <button-one @submit="submitSectionEdit" icon="save" name-button="Salvar"/>
        </div>

        <div v-if="modal_item">
            <div class="btn-close" @click="openItem"></div>
            <draggable
                    v-model="formDateItem.itens"
                    group="people"
                    @start="drag=true"
                    @end="drag=false"
                    ghost-class="ghost"
                    item-key="id">
                <template #item="{element, index}">
                    <row-component class="section-style" style="cursor: pointer">
                        <div class="indexCount">{{ index + 1 }}</div>
                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <label>Item:</label>
                            <input-component :type-input="'text'" v-model="element.item"/>
                        </row-col-component>
                    </row-component>
                </template>
            </draggable>

            <button-one @submit="submitItemEdit" icon="save" name-button="Salvar"/>
        </div>

        <div v-if="modal_search">
            <div class="btn-close" @click="modal_search = false"></div>
            <div class="alert alert-warning" role="alert">
                Essa opção irá excluir as sessões e itens e adicionar as novas!
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.proposal_template_id }">
                    <label>Modelo de Proposta:</label>
                    <Multiselect v-model="formDate.proposal_template_id" :searchable="true"
                                 :options="selectProposalTemplate"
                                 @select="getProposalTemplate(formDate.proposal_template_id)"
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>
            </row-component>

            <button-one @submit="updateProposalUpdateSection" icon="save" name-button="Salvar"/>
        </div>
    </div>

    <painel-component :itens="itensOne">
        <button-add :itens="itensThree" v-if="buttonCheck.create"/>
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div class="tabs effect-3 mt-3" v-else>
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="handshake alternate icon"></i>
                <p>Proposta</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button-one :icon="'send icon'" tooltip="Enviar para cliente" v-if="formDate.status < 3"
                                            @submit="updateProposalSendClient"/>

                                <button-one :icon="'check circle'" tooltip="Aprovar" @submit="updateProposalApproved"
                                            v-if="formDate.status < 4 || formDate.status > 5"/>

                                <button-one :icon="'window close'" tooltip="Novas versão" @submit="updateProposalNew"
                                            v-if="formDate.status < 6"/>
                            </div>
                        </row-col-component>

                        <row-col-component
                                :class-row-col="'clearfix col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <div class="float-end">
                                <button-one icon="copy" @click="cloneProposal"
                                            class="fs-4 color bg-transparent border-0"/>

                                <button-float class="fs-4 color bg-transparent border-0" target="_blank"
                                              :itens="{icon: 'print', route: ''}" :href="formDate.link_proposal_pdf"/>
                            </div>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                            <label>Url:</label>
                            <div class="input-group" role="button" @click="copyUrl(formDate.link_proposal)">
                                <input class="form-control cursor-pointer" type="text" v-model="formDate.link_proposal"
                                       @focus="$event.target.select()" ref="select" readonly>
                                <span @click="copyUrl(formDate.link_proposal)" class="input-group-text my_btn"
                                      id="basic-addon1">
                                    <i :class="[copy ? 'copy icon' : 'check circle icon']"></i>
                                </span>
                            </div>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Status:</label>
                            <input-component disabled :type-input="'text'" v-model="status" :model-value="status"/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.name }">
                            <label>Nome da Proposta:</label>
                            <input-component :type-input="'text'" v-model="formDate.name" :model-value="formDate.name"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Valído (Dias Quantidade):</label>
                            <input-number-component :type-input="'number'" min="15" v-model="formDate.valid"/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.client_name }">
                            <label>Cliente:</label>
                            <div class="input-group">
                                <input class="form-control" type="text" v-model="formDate.client_name">
                                <span class="input-group-text my_btn" id="basic-addon1">
                                    <i @click="openClient()" class="search icon"></i>
                                </span>
                            </div>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.proposed_value }">
                            <label>Valor da Proposta:</label>
                            <input-component :type-input="'text'" v-model="formDate.proposed_value" v-money="money"/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.email }">
                            <label>Email:</label>
                            <div class="input-group">
                                <input class="form-control" type="email" v-model="formDate.email">
                                <span class="input-group-text my_btn" id="basic-addon1">
                                    <i @click="openEmail()" class="search icon"></i>
                                </span>
                            </div>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                            <label>Telefone:</label>
                            <div class="input-group">
                                <input class="form-control" type="text" v-model="formDate.phone"
                                       v-mask="['(##) ####-####', '(##) #####-####']">
                                <span class="input-group-text my_btn" id="basic-addon1">
                                    <i @click="openPhone()" class="search icon"></i>
                                </span>
                            </div>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.responsible }">
                            <label>Responsável:</label>
                            <input-component :type-input="'text'" v-model="formDate.responsible"/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <label>Observações:</label>
                            <input-text-area :rows="4" v-model="formDate.obs"/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <button-one class="w-100" @submit="submit" :icon="'save'"
                                        :name-button="'Salvar'" :class="{ 'preloderSend': sendForm }"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <button-float class="w-100" :itens="itensTwo"/>
                        </row-col-component>
                    </row-component>

                    <div class="line-division my-4">
                        <h1 class="h1-text">
                            <i class="comment icon"></i>
                            Observações Internas
                        </h1>
                    </div>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <label>Observações:</label>

                            <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                                 v-if="formDate.obsPrivate.length === 0">
                                <p>Não há nada cadastrado</p>
                            </div>

                            <div class="merito" v-else>
                                <section class="container-desc">
                                    <ul class="task-desc">
                                        <li class="item" v-for="(obs, index) in formDate.obsPrivate" :key="index">
                                            <div class="aux-row">
                                                <div class="user">
                                                    <img :src="obs.user_photo" alt="Image 001" class="owner-img"/>
                                                    {{ obs.user_name }}
                                                </div>
                                                <div class="desc-date">
                                                    <div class="text">
                                                        <i class="calendar icon"></i>
                                                        {{ obs.date_create }}
                                                    </div>
                                                    <div class="text">
                                                        <i class="clock icon"></i>
                                                        {{ obs.hour_create }}
                                                    </div>
                                                </div>
                                            </div>
                                            <p>{{ obs.obs }}</p>
                                        </li>
                                    </ul>
                                </section>
                            </div>
                        </row-col-component>
                    </row-component>

                    <form autocomplete="off">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Novas Observações:</label>
                                <input-text-area :rows="4" v-model="formDateObs.obs"/>
                            </row-col-component>
                        </row-component>
                    </form>

                    <button-one @submit="submitObs" :icon="'plus circle'" :name-button="'Adicionar Observação'"
                                :class="{ 'preloderSend': sendForm }"/>

                    <div class="line-division mt-5 mb-5">
                        <h1 class="h1-text">
                            <i class="history icon"></i>
                            Histórico de interações
                        </h1>
                    </div>

                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formDate.historic.length === 0">
                        <p>Não há nenhum registro</p>
                    </div>

                    <section class="container-desc-two">
                        <ul class="task-desc">
                            <li class="item" v-for="(historic, index) in formDate.historic" :key="index">
                                <div class="aux-row">
                                    <div class="user">
                                        {{ historic.name_user }}
                                    </div>
                                </div>
                                <p>
                                    {{ statusHistoric(historic.status) }},
                                    Plataforma: {{ historic.platform }},
                                    Navegador: {{ historic.browser }},
                                    Endereço IP: {{ historic.ip }}
                                </p>
                            </li>
                        </ul>
                    </section>
                </section>

                <section id="tab-item-2">
                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-11 col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12'">
                            <button-one class="me-3" @submit="windowSectionModal" :icon="'plus'"
                                        tooltip="Adicionar Sessão"/>

                            <button-one class="me-3" @submit="openSection" :icon="'edit'"
                                        tooltip="Editar Sessões"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12'">
                            <button-one class="me-3" @submit="modal_search = !modal_search"
                                        :icon="'search plus'" tooltip="Buscar Sessões"/>
                        </row-col-component>
                    </row-component>

                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formDate.sections.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <form v-for="(section, index) in formDate.sections" :key="index" class="section-style">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'">
                                <label>Nome da sessão:</label>
                                <input-component :type-input="'text'" v-model="section.item" disabled/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 pt-4'">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button-one @click="windowItemModal(section.id)" :icon="'plus icon'"/>
                                    <button-one v-if="section.itens.length > 0"
                                                @click="openItem(section.itens, section.id)" :icon="'edit icon'"/>
                                    <button-one @click="deleteSection(section)" :icon="'trash icon'"/>
                                </div>
                            </row-col-component>
                        </row-component>

                        <row-component v-for="(itens, index) in section.itens" :key="index" class="item-style">
                            <row-col-component
                                    :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                                <label>Item:</label>
                                <input-component :type-input="'text'" v-model="itens.item" disabled/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 pt-4'">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button-one @click="deleteItens(itens)" :icon="'trash icon'"/>
                                </div>
                            </row-col-component>
                        </row-component>
                    </form>

                    <row-component>
                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <button-float class="w-100" :itens="itensTwo"/>
                        </row-col-component>
                    </row-component>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputTextArea from "@/components/form/InputTextArea"
import InputNumberComponent from "@/components/form/InputNumberComponent"
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import ButtonAdd from "@/components/buttons/ButtonAdd"
import swal from "sweetalert";
import {useToast} from "vue-toastification";
import {mask} from 'vue-the-mask'
import {MONEY} from "@/configs/constants"
import {VMoney} from 'v-money'
import {ACTION_MESSAGES} from "@/configs/constants";
import draggable from 'vuedraggable'
import ButtonRemoveRound from "@/components/buttons/ButtonRemoveRound.vue";

const toast = useToast();

export default {
    name: "ProposalUpdate",
    components: {
        ButtonRemoveRound,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        InputNumberComponent,
        InputTextArea,
        ButtonFloat,
        ButtonAdd,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        ButtonOne,
        draggable,
    },

    directives: {
        mask,
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/proposal/search",
                icon: "reply all",
                name: 'Voltar'
            },

            itensThree: {
                route: "/proposal/create",
                icon: "plus",
                name: 'Adicionar'
            },

            buttonCheck: {
                create: false,
            },

            formDate: {
                name: '',
                creator_user_id: '',
                creator_user_name: '',
                code_url: '',
                code: '',
                lead_id: '',
                proposal_template_id: '',
                link_proposal: '',
                link_proposal_pdf: '',
                valid: '',
                client_id: '',
                client_name: '',
                proposed_value: '',
                email: '',
                phone: '',
                responsible: '',
                obs: '',
                status: '',
                version: '',
                payments: [],
                sections: [],
                historic: [],
                obsPrivate: []
            },

            formDateSection: {
                id: this.id,
                sections: [],
            },

            formDateItem: {
                id: '',
                itens: []
            },

            formDateObs: {
                id: this.id,
                obs: ''
            },

            drag: false,

            modal_client: false,
            modal_phone: false,
            modal_email: false,
            modal_section: false,
            modal_item: false,
            modal_search: false,

            windowSection: false,
            windowItem: false,

            client: '',
            copy: true,

            status: '',

            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateProposal', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                        this.errors = [];
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop();
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 27)
                .then((r) => {
                    if (r.data.indexOf('proposal-edit') !== -1) {
                        this.showLoader = false
                    }
                    if (r.data.indexOf('proposal-create') !== -1) {
                        this.buttonCheck.create = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            });
        },

        loadPageItens() {
            this.$store.dispatch('updateNamePage', 'Proposta',)
            this.$store.dispatch('loadProposal', this.id).then((r) => {
                this.formDate = r;
                this.$store.dispatch('loadProposalTemplateSelect')
                this.$store.dispatch('loadClientListSelect')
                this.nullInputArray()
                this.statusString()
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        copyUrl(url) {
            navigator.clipboard.writeText(url)
            this.$refs.select.focus()
            this.copy = false
            setTimeout(() => {
                this.copy = true
            }, 2000)
        },

        nullInputArray() {
            if (this.formDate.payments.length === 0)
                this.formDate.payments.push({type: '', amount: '', price: ''})
            if (this.formDateSection.sections === 0)
                this.formDateSection.sections.push({section: ''})
            if (this.formDateItem.itens.length === 0)
                this.formDateItem.itens.push({item: ''})
        },

        // Status strings functions
        statusString() {
            if (parseInt(this.formDate.status) === 1) {
                return this.status = "A fazer"
            }
            if (parseInt(this.formDate.status) === 2) {
                return this.status = "Cadastro"
            }
            if (parseInt(this.formDate.status) === 3) {
                return this.status = "Enviado para cliente"
            }
            if (parseInt(this.formDate.status) === 4) {
                return this.status = "Aprovado pelo cliente"
            }
            if (parseInt(this.formDate.status) === 5) {
                return this.status = "Aprovado pela D7M"
            }
            if (parseInt(this.formDate.status) === 6) {
                return this.status = "Cancelado"
            }
            if (parseInt(this.formDate.status) === 7) {
                return this.status = "Nova Versão"
            }
        },

        statusHistoric(status) {
            if (parseInt(status) === 1) {
                return "Enviado para cliente"
            }
            if (parseInt(status) === 2) {
                return "Aprovado pelo cliente"
            }
            if (parseInt(status) === 3) {
                return "Aprovado pela D7M"
            }
            if (parseInt(status) === 4) {
                return "Cancelado"
            }
        },

        // Submit functions
        submitSection() {
            this.$store.dispatch('storeProposalSection', this.formDateSection)
                .then(() => {
                    this.showError = false
                    this.sendForm = false
                    this.errors = []
                    this.loadPageItens()
                    this.windowSectionClose()
                    toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                }).catch((r) => {
                this.showError = true;
                this.sendForm = false
                this.errors = r.response.data.errors;
                this.scrollToTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        submitItem() {
            this.$store.dispatch('storeProposalItem', this.formDateItem)
                .then(() => {
                    this.showError = false
                    this.sendForm = false
                    this.errors = []
                    this.loadPageItens()
                    this.windowItemClose()
                    toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                }).catch((r) => {
                this.showError = true;
                this.sendForm = false
                this.errors = r.response.data.errors;
                this.scrollToTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        submitSectionEdit() {
            let params = {idproposal: this.id, sections: this.formDate.sections}
            params.sections.forEach((el) => {
                delete el.itens
                delete Object.assign(el, {['section']: el['item']})['item'];
            })
            this.$store.dispatch('updateProposalSection', params)
                .then(() => {
                    this.openSection()
                    this.loadPageItens()
                    toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                }).catch(() => {
                this.scrollToTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        submitItemEdit() {
            let params = {id: this.formDateItem.id, itens: []}
            this.formDateItem.itens.forEach((el) => {
                params.itens.push(el.item)
            })
            this.$store.dispatch('updateProposalItem', params)
                .then(() => {
                    this.openItem()
                    this.loadPageItens()
                    toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                }).catch(() => {
                this.scrollToTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        submitObs() {
            this.$store.dispatch('storeProposalObservation', this.formDateObs)
                .then(() => {
                    this.showError = false
                    this.sendForm = false
                    this.errors = []
                    this.loadPageItens()
                    this.formDateObs.obs = ''
                    toast.success('Observação adicionada com sucesso!', {timeout: 2000});
                }).catch((r) => {
                this.showError = true;
                this.sendForm = false
                this.errors = r.response.data.errors;
                this.scrollToTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        // Delete functions
        deleteSection(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.item}, ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyTemplateSection', item.id).then(() => {
                        this.loadPageItens()
                        toast.success(ACTION_MESSAGES.delete, {timeout: 10000});
                    })
                        .catch(() => {
                            toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 10000});
                        })
                }
            });
        },

        deleteItens(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.item}, ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyTemplateItem', item.id).then(() => {
                        this.loadPageItens()
                        toast.success(ACTION_MESSAGES.delete, {timeout: 10000});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 10000});
                    })
                }
            });
        },

        // Update functions
        updateProposalSendClient() {
            this.$store.dispatch('updateProposalSendClient', this.id).then(() => {
                this.loadPageItens()
                toast.success("Proposta aprovado com sucesso", {timeout: 2000});
            }).catch(() => {
                toast.error("Não foi possível alterar o status!", {timeout: 2000});
            })
        },

        updateProposalNew() {
            this.$store.dispatch('updateProposalNew', this.id).then(() => {
                this.loadPageItens()
                toast.success("Orçamento cancelado com sucesso", {timeout: 2000});
            }).catch(() => {
                toast.error("Não foi possível alterar o status!", {timeout: 2000});
            })
        },

        updateProposalApproved() {
            this.$store.dispatch('updateProposalApproved', this.id).then(() => {
                this.loadPageItens()
                toast.success("Orçamento aprovado com sucesso", {timeout: 2000});
            }).catch(() => {
                toast.error("Não foi possível alterar o status!", {timeout: 2000});
            })
        },

        updateProposalUpdateSection() {
            let params = {id: this.id, sections: this.formDate.sections}
            params.sections.forEach((el) => {
                delete Object.assign(el, {['section']: el['item']})['item'];
            })
            this.$store.dispatch('updateProposalUpdateSection', params).then(() => {
                this.loadPageItens()
                toast.success("Sessões e itens atualizados", {timeout: 2000});
            }).catch(() => {
                toast.error("Não foi possível atualizar!", {timeout: 2000});
            })
        },

        // Modal functions
        openClient() {
            this.modal_client = !this.modal_client
        },

        closeClient(id) {
            this.modal_client = !this.modal_client
            this.getClient(id)
        },

        openEmail() {
            this.modal_email = !this.modal_email
        },

        openPhone() {
            this.modal_phone = !this.modal_phone
        },

        openSection() {
            this.modal_section = !this.modal_section
        },

        openItem(itens, id) {
            this.modal_item = !this.modal_item
            this.formDateItem.id = id
            this.formDateItem.itens = itens

            if (this.modal_item === false)
                this.formDateItem = {
                    id: this.id,
                    itens: []
                }
            this.nullInputArray()
        },

        windowItemModal(id) {
            this.windowItem = true
            this.formDateItem.id = id
        },

        windowItemClose() {
            this.windowItem = false
            this.formDateItem = {
                id: this.id,
                itens: []
            }
            this.nullInputArray()
        },

        windowSectionModal() {
            this.windowSection = true
        },

        windowSectionClose() {
            this.windowSection = false
            this.formDateSection = {
                id: this.id,
                sections: []
            }
            this.nullInputArray()
        },

        addItem() {
            this.formDateItem.itens.push({
                item: '',
            })
        },

        itemRemove(index) {
            this.formDateItem.itens.splice(index, 1)
        },

        addSection() {
            this.formDateSection.sections.push({
                section: '',
            })
        },

        sectionRemove(index) {
            this.formDateSection.sections.splice(index, 1)
        },

        getProposalTemplate(id) {
            this.$store.dispatch('loadProposalTemplateInfos', id).then((r) => {
                let section = r.sections
                section.forEach((el) => {
                    delete Object.assign(el, {['item']: el['section']})['section']
                })
                this.formDate.sections = section
            })
        },

        getClient(id) {
            this.$store.dispatch('loadClient', id).then((r) => {
                this.formDate.client_id = r.id
                this.formDate.client_name = r.surname
                this.$store.dispatch('loadEmailClient', r.id)
                this.$store.dispatch('loadPhoneClient', r.id)
            })
        },

        // Clone functions
        cloneProposal() {
            this.storeState.push({
                valid: this.formDate.valid,
                name: this.formDate.name,
                proposed_value: this.formDate.proposed_value,
                proposal_template_id: this.formDate.proposal_template_id,
                sections: this.formDate.sections,
                obs: this.formDate.obs
            })
            this.$router.push({name: 'proposal-create'})
        }
    },

    computed: {
        selectProposalTemplate() {
            return this.$store.state.var.selectProposalTemplate
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectClientEmailList() {
            return this.$store.state.var.selectClientEmailList
        },

        selectClientPhoneList() {
            return this.$store.state.var.selectClientPhoneList
        },

        storeState() {
            return this.$store.state.var.storeState
        },

        sectionAdd() {
            return this.formDateSection.sections;
        },

        itemAdd() {
            return this.formDateItem.itens;
        },
    }

}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.indexCount {
    top: 0;
    left: 0;
    width: 20px;
    height: 25px;
    display: flex;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    border-radius: 50%;
    background: #d67c1c;
    align-items: center;
    justify-content: center;
}

.color {
    color: #d67c1c !important;
}

.ghost {
    opacity: 0.5;
    background: #d67c1c !important;
}

.modal-proposal {
    top: 50%;
    left: 50%;
    width: 60%;
    height: auto;
    z-index: 9999;
    position: fixed;
    background: white;
    max-height: 700px;
    overflow-x: scroll;
    padding: 40px 20px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.btn-close {
    top: 0;
    right: 0;
    margin: 1%;
    cursor: pointer;
    position: absolute;
}

.my_btn {
    color: white;
    cursor: pointer;
    position: relative;
    background: #d67c1c;
    padding-bottom: 15px;
    border: 1px solid #d67c1c;
}

.section-style {
    max-height: 450px;
    position: relative;
    overflow-y: scroll;
    padding: 15px 25px;
    margin-bottom: 50px;
    background-color: #f0f0f0;
}

.item-style {
    padding-left: 25px;
}

.merito {
    width: 100%;
    height: 200px;
    padding: 0 20px;
    overflow-x: scroll;
    background: #ebebeb;
}

section.container-desc-two,
.merito > .container-desc {
    display: block;
}

ul.task-desc li.item {
    display: flex;
    margin: 20px 0;
    padding: 0 20px;
    box-shadow: none;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    background: transparent;
    justify-content: space-between;
}

.container-desc-two > ul.task-desc li.item {
    display: flex;
    padding: 20px;
    background: #fff;
    position: relative;
    border-radius: 5px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
}

.line-division {
    position: relative;
    width: 100%;
    border-top: 1px solid #c4c4c4;
}

.h1-text {
    margin-top: 1px;
    width: fit-content;
    padding: 10px;
    background-color: #d67c1c;
    color: white;
    font-size: 16px;
}

.cont-status-two a {
    color: #d67c1c;
    text-decoration: none;
    margin-top: 7px;
    font-size: 1.5rem;
}

@media (min-width: 1200px) {
    .item-style > .row {
        padding-top: 0 !important;
        margin: 1rem !important;
    }
}

@media (max-width: 428px) {
    .modal-proposal {
        width: 95%;
    }
}
</style>