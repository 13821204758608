import state from "@/store/modules/fleet-departures/state";
import actions from "@/store/modules/fleet-departures/actions";
import mutations from "@/store/modules/fleet-departures/mutations";
import getters from "@/store/modules/fleet-departures/getters";

export default {
    state,
    actions,
    mutations,
    getters
}