import state from "@/store/modules/receipt/state";
import actions from "@/store/modules/receipt/actions";
import mutations from "@/store/modules/receipt/mutations";
import getters from "@/store/modules/receipt/getters";

export default {
  state,
  actions,
  mutations,
  getters
}