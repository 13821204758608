import state from "@/store/modules/fleet/state";
import actions from "@/store/modules/fleet/actions";
import mutations from "@/store/modules/fleet/mutations";
import getters from "@/store/modules/fleet/getters";


export default {
  state,
  actions,
  mutations,
  getters
}