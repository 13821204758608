<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoder"/>

        <form autocomplete="off" v-else>
            <button-add :itens="itensThree" v-if="buttonCheck.create"/>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Ponto nome:</label>
                    <input-component :type-input="'text'" v-model="formDate.name"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Título:</label>
                    <input-component :type-input="'text'" v-model="formDate.title"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.type }">
                    <label>Tipo de Mídia:</label>
                    <multiselect v-model="formDate.type" :searchable="true" :options="selectTypeMedia"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Segmento:</label>
                    <multiselect v-model="formDate.segment" :searchable="true" :options="selectSpotSegment"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Público (QT/Mês):</label>
                    <input-number-component :type-input="'number'" min="1" v-model="formDate.public" placeholder="1"
                                            :model-value="formDate.public"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.monitors_tvs }">
                    <label>Tvs/Monitores (QT):</label>
                    <input-number-component :type-input="'number'" min="1" v-model="formDate.monitors_tvs"
                                            placeholder="1"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>CEP:</label>
                    <input-component @change="searchApiCEP" v-mask="'#####-###'" :type-input="'text'"
                                     v-model="formDate.cep" :model-value="formDate.cep"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Cidade:</label>
                    <input-component :type-input="'text'" v-model="formDate.city"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Estado:</label>
                    <input-component :type-input="'text'" v-model="formDate.state"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Bairro:</label>
                    <input-component :type-input="'text'" v-model="formDate.district"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <label>Endereço:</label>
                    <input-component :type-input="'text'" v-model="formDate.address"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>País:</label>
                    <input-component :type-input="'text'" v-model="formDate.country"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Referência:</label>
                    <input-text-area :rows="4" v-model="formDate.complement"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one class="w-100" @submit="submit" :icon="'save'" :name-button="'Salvar'"
                                :class="{ 'preloderSend': sendForm }"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-float class="w-100" :itens="itensTwo"/>
                </row-col-component>
            </row-component>
        </form>

        <div class="line-division mt-5 mb-5">
            <h1 class="h1-text">
                <i class="comment icon"></i>
                Observações
            </h1>
        </div>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Observações:</label>

                <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                     v-if="formDate.obsPrivate.length === 0">
                    <p>Não há nada cadastrado</p>
                </div>

                <div class="merito" v-else>
                    <section class="container-desc">
                        <ul class="task-desc">
                            <li class="item" v-for="(obs, index) in formDate.obsPrivate" :key="index">

                                <div class="aux-row">
                                    <div class="user">
                                        <img :src="obs.user_photo" alt="Image 001" class="owner-img"/>
                                        {{ obs.user_name }}
                                    </div>
                                    <div class="desc-date">
                                        <div class="text"><i class="calendar icon"></i>{{ obs.date_create }}</div>
                                        <div class="text"><i class="clock icon"></i>{{ obs.hour_create }}</div>
                                    </div>
                                </div>
                                <p>{{ obs.obs }}</p>
                            </li>
                        </ul>
                    </section>
                </div>
            </row-col-component>
        </row-component>

        <form autocomplete="off">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Observações:</label>
                    <input-text-area :rows="4" v-model="formDateObs.obs"/>
                </row-col-component>
            </row-component>
        </form>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <button-one @submit="submitObs" :icon="'plus circle'" :name-button="'Adicionar Observação'"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputNumberComponent from "@/components/form/InputNumberComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonAdd from "@/components/buttons/ButtonAdd"
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";
import {ACTION_MESSAGES} from "@/configs/constants";
import {ESTADOS} from "@/configs/constants";

const toast = useToast();

export default {
    name: "SpotsUpdate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        InputNumberComponent,
        ButtonFloat,
        ButtonOne,
        ButtonAdd,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea
    },

    directives: {
        mask,
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Pontos')
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/spots/search",
                icon: "reply all",
                name: 'Voltar'
            },

            itensThree: {
                route: "/spots/create",
                icon: "plus",
                name: 'Adicionar'
            },

            buttonCheck: {
                create: false
            },

            formDate: {
                name: '',
                title: '',
                type: '',
                public: '',
                segment: '',
                monitors_tvs: '',
                status: '',
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: '',
                equipments: [],
                opening_hours: [],
                passwords: [],
                responsibles: [],
                obsPrivate: ''
            },

            formDateObs: {
                id: this.id,
                obs: ''
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoder: true,
        }
    },

    methods: {
        loadPageItens() {
            this.$store.dispatch('loadSpot', this.id).then((r) => {
                this.formDate = r;
                this.$store.dispatch('loadSpotSegmentList')
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateSpots', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = [];
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    }).catch((r) => {
                    this.scrollTop()
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        submitObs() {
            this.$store.dispatch('storeSpotObs', this.formDateObs)
                .then(() => {
                    this.showError = false
                    this.errors = []
                    this.loadPageItens()
                    this.formDateObs.obs = ''
                    toast.success('Observção adicionada com sucesso!', {timeout: 2000});
                }).catch((r) => {
                this.showError = true;
                this.errors = r.response.data.errors;
                this.scrollTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 29)
                .then((r) => {
                    if (r.data.indexOf('spot-edit') !== -1) {
                        this.showLoder = false
                    }
                    if (r.data.indexOf('spot-create') !== -1) {
                        this.buttonCheck.create = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        searchApiCEP() {
            let cep = this.formDate.cep.replace(/\D/g, '');
            const dateCEP = {'cep': cep}
            this.$store.dispatch('searchApiCEP', dateCEP)
                .then((r) => {
                    this.formDate.city = r.data.localidade
                    this.formDate.state = r.data.uf
                    this.formDate.state = ESTADOS[r.data.uf]
                    this.formDate.district = r.data.bairro
                    this.formDate.address = r.data.logradouro
                    this.formDate.complement = r.data.complemento
                })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        selectTypeMedia() {
            return this.$store.state.var.selectTypeMedia
        },

        selectDayWeek() {
            return this.$store.state.var.selectDayWeek
        },

        selectOccupation() {
            return this.$store.state.var.selectOccupation
        },

        selectSpotSegment() {
            return this.$store.state.var.selectSpotSegment
        },

        openingHours() {
            return this.formDate.opening_hours
        },
    }
}
</script>

<style scoped>
.merito > .container-desc {
    display: block;
}

.merito {
    width: 100%;
    height: 200px;
    padding: 0 20px;
    overflow-x: scroll;
    background: #ebebeb;
}

.line-division {
    width: 100%;
    position: relative;
    border-top: 1px solid #c4c4c4;
}

.h1-text {
    color: white;
    padding: 10px;
    margin-top: 1px;
    font-size: 16px;
    width: fit-content;
    background-color: #d67c1c;
}

ul.task-desc li.item {
    display: flex;
    margin: 20px 0;
    padding: 0 20px;
    box-shadow: none;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    background: transparent;
    justify-content: space-between;
}
</style>