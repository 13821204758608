<template>
    <painel-component :itens="itensOne">

        <error-component :errors="errors" v-if="showError"></error-component>

        <div class="aux mb-4">
            <div class="avatar-form">
                <img v-if="me.photo" :src="me.photo" class="avatar__image">
                <img v-else src="@/assets/img/avatar.png" alt="" class="avatar__image">
            </div>
            <p>{{ me.fullName }}</p>
            <router-link :to="{ name: 'user-update-photo' }" class="figure">
                <i class="camera icon"></i>
            </router-link>
        </div>

        <loder-send-component :text-title="'Carregando'" v-if="showLoder"></loder-send-component>

        <form autocomplete="off" v-else>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Senha:</label>
                    <input-component :name-input="'password'" :type-input="'password'" v-model="formDate.password"
                                     :model-value="formDate.password" autocomplete="new-password"></input-component>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Confirmação de Senha:</label>
                    <input-component :name-input="'passwordconfirm'" :type-input="'password'"
                                     v-model="formDate.passwordconfirm" :model-value="formDate.passwordconfirm"
                                     autocomplete="new-password"></input-component>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one @submit="submit" :icon="'save'" :name-button="'Salvar'"
                                :class="{ 'preloderSend': sendForm }"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-float :itens="itensTwo"></button-float>
                </row-col-component>
            </row-component>

        </form>

    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import {useToast} from "vue-toastification";
import {ACTION_MESSAGES} from "@/configs/constants";

const toast = useToast();

export default {
    name: "UserUpdatePassword",

    data() {
        return {
            itensOne: {
                title: "Alterar Senha",
                icon: "edit"
            },

            itensTwo: {
                route: "/",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                password: '',
                passwordconfirm: '',
            },

            errors: [],
            showError: false,
            loaddingPageGroup: false,
            sendForm: false,
            showLoder: false,
            updatePassword: false
        }
    },

    methods: {
        submit() {

            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateUserPassword', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                        setTimeout(() => this.$router.push({name: 'home'}), 2100);
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },
    },

    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        ErrorComponent,
        LoderSendComponent,
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },
    },
    created() {
        this.$store.dispatch('updateNamePage', 'Usuário')
    }
}
</script>

<style src="../../../src/assets/css/avatar.css">

</style>
  