<template>
    <div class="toast d-flex flex-column" :class="itens.type">
        <div @click="dismissToast" class="btn-close"></div>
        <div class="avatar-user d-flex align-items-center">
<!--            <img v-if="itens.photoCreator" src="@/assets/img/avatar.png" alt="">-->
            <img :src="itens.photoCreator" alt="">
            <p class="my-0 mx-2">{{ itens.nameCreator }}</p>
        </div>
        <div class="message mt-2">
            {{ itens.message }}
        </div>
    </div>
</template>

<script>

export default {
    name: 'NotifysComponent',

    props: {
        itens: {
            "required": true,
            "type": Object,
            "default": []
        }
    },

    created() {
        setTimeout(() => {
            this.dismissToast();
        }, 120000);
    },
    methods: {
        dismissToast() {
            this.$store.commit('clearToast', this.itens.nameCreator);
        }
    }
}

</script>

<style scoped>
/*.newTask {
    border-top: 3px solid #5a5a5a;
}*/

.newTask {
    border-top: 3px solid #16ab39;
}

.approvedTask {
    border-top: 3px solid #2185d0;
}

.disapprovedTask {
    border-top: 3px solid #db2828;
}

.canceledTask{
    border-top: 3px solid #db2828;
}

.submittedForApprovalTask {
    border-top: 3px solid #b5cc18;
}

.avatar-user img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.btn-close {
    top: 0;
    right: 0;
    margin: 2% 3%;
    cursor: pointer;
    position: absolute;
}

.toast {
    font-weight: bold;
    color: #000000;
    position: relative;
    max-height: 800px;
    min-height: 64px;
    max-width: 600px;
    min-width: 326px;
    direction: ltr;
    overflow: hidden;
    padding: 22px 24px;
    border-radius: 8px;
    margin-bottom: 1rem;
    background: #FFFFFF;
    font-family: "Lato", Helvetica, "Roboto", Arial, sans-serif;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
}

</style>