<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div class="tabs effect-3" v-else>
            <input type="radio" id="tab-1" name="tab-effect-3" checked="checked">
            <span>
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="tasks icon"></i>
                <p>Sessão</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component :class-row-col="'col-xxl-8 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.name }">
                                <label>Nome Contato:</label>
                                <input-component :type-input="'text'" v-model="formDate.name" disabled/>
                            </row-col-component>

                            <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.status }">
                                <label>Status:</label>
                                <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                             disabled :noResultsText="this.$store.state.var.selectNotSearch"/>
                            </row-col-component>

                            <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.price }">
                                <label>Valor Total:</label>
                                <input-component :type-input="'text'" v-model="formDate.price" disabled
                                                 v-money="money"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Observações:</label>
                                <input-text-area :rows="4" v-model="formDate.obs" disabled/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formDate.sections.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <form v-for="(section, index) in formDate.sections" :key="index" class="section-style">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Nome da sessão:</label>
                                <input-component :type-input="'text'" v-model="section.item" disabled/>
                            </row-col-component>
                        </row-component>

                        <row-component v-for="(itens, index) in section.itens" :key="index" class="item-style">
                            <row-col-component
                                    :class-row-col="'col-xxl-11 col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12'">
                                <label>Nome do item:</label>
                                <input-component :type-input="'text'" v-model="itens.item" disabled/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>
            </div>
        </div>

        <row-component>
            <row-col-component
                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {MONEY} from "@/configs/constants"
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "ProposalTemplateShow",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea
    },

    directives: {
        mask,
        money: VMoney
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Modelo de Proposta')
        this.loadPageItens()
        this.checkACL()
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "eye"
            },

            itensTwo: {
                route: "/proposal-template/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                price: '',
                obs: '',
                status: '',
                sections: ''
            },

            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        scrollTop() {
            window.scrollTo(0, 0)
        },

        loadPageItens() {
            this.$store.dispatch('loadProposalTemplate', this.id).then((r) => {
                this.formDate = r;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 20)
                .then((r) => {
                    if (r.data.indexOf('proposal-template-search') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },
    },
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.section-style {
    max-height: 450px;
    position: relative;
    overflow-y: scroll;
    padding: 15px 25px;
    margin-bottom: 50px;
    background-color: #f0f0f0;
}
</style>