<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <form v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formDate.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <Multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 '"
                                   :class="{ 'formError': errors.permissions }"
                                   v-for="(permission, index) in selectPage" :key="index">
                    <div class="form-check checkBoxHeader">
                        <input class="form-check-input " type="checkbox" @click="checkAll(index)" :value="index"
                               :id="'checkBoxMaster' + index" :name="index">
                        <label class="form-check-label">
                            {{ permission.label }}
                        </label>
                    </div>

                    <div class="form-check " v-for="(option, indexTwo) in permission.options" :key="indexTwo">
                        <input type="checkbox" :value="option.value" :id="indexTwo"
                               :class="'form-check-input checkBoxAll' + index + ' checkOne' + option.value"
                               @click="checkOne(index, permission)">
                        <label class="form-check-label">
                            {{ option.label }}
                        </label>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 '">
                    <button-one class="w-100" @submit="submit" :icon="'plus circle'" :name-button="'Adicionar'"
                                :class="{ 'preloderSend': sendForm }"/>
                </row-col-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 '">
                    <button-float class="w-100" :itens="itensTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import {ACTION_MESSAGES} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "GroupUserCreate",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Grupo de Usuário')
        this.$store.dispatch('loadSelectSearch', 'page')
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/group-user/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                status: '',
                permissions: [],
            },

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        checkOne(index, permission) {
            let listPermission = document.getElementsByClassName("checkBoxAll" + index);
            let cont = 0;
            for (let i = 0; i < listPermission.length; ++i) {
                if (listPermission[i].checked) {
                    listPermission[i].checked = true;
                    if (!this.formDate.permissions.includes(parseInt(listPermission[i].value))) {
                        this.formDate.permissions.push(parseInt(listPermission[i].value))
                    }
                    cont = cont + 1;
                } else {
                    listPermission[i].checked = false;
                    let indexArray = this.formDate.permissions.indexOf(parseInt(listPermission[i].value));
                    if (indexArray !== -1) {
                        this.formDate.permissions.splice(indexArray, 1);
                    }
                }
            }
            document.getElementById("checkBoxMaster" + index).checked = cont >= permission.options.length;
        },

        checkAll(index) {
            let listPermission = document.getElementsByClassName("checkBoxAll" + index);
            if (document.getElementById("checkBoxMaster" + index).checked) {
                for (let i = 0; i < listPermission.length; ++i) {
                    listPermission[i].checked = true;
                    if (!this.formDate.permissions.includes(parseInt(listPermission[i].value))) {
                        this.formDate.permissions.push(parseInt(listPermission[i].value))
                    }
                }
            } else {
                for (let cont = 0; cont < listPermission.length; ++cont) {
                    listPermission[cont].checked = false;
                    let indexArray = this.formDate.permissions.indexOf(parseInt(listPermission[cont].value));
                    if (indexArray !== -1) {
                        this.formDate.permissions.splice(indexArray, 1);
                    }
                }
            }
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeGroupUser', this.formDate)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                        this.$router.push({name: 'group-user-edit', params: {id: r.data.id}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        scrollTop() {
            window.scrollTo(0, 0);
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 1)
                .then((r) => {
                    if (r.data.indexOf('group-user-create') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        selectPage() {
            return this.$store.state.var.selectSearch;
        }
    }
}
</script>
