import AdminComponent from "@/components/dashboard/AdminComponent";
import CustomerProfileSearch from "@/views/search/CustomerProfileSearch";
import CustomerProfileCreate from "@/views/create/CustomerProfileCreate";
import CustomerProfileUpdate from "@/views/update/CustomerProfileUpdate";
import CustomerProfileShow from "@/views/show/CustomerProfileShow";

export default {
    path: '/customer-profile',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'create', name: 'customer-profile-create', component: CustomerProfileCreate},
        {path: 'search', name: 'customer-profile-search', component: CustomerProfileSearch},
        {path: 'edit/:id', name: 'customer-profile-edit', component: CustomerProfileUpdate, props: true},
        {path: 'show/:id', name: 'customer-profile-show', component: CustomerProfileShow, props: true},
    ],
}
