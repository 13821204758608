import AdminComponent from "@/components/dashboard/AdminComponent";
import BudgetSearch from "@/views/search/BudgetSearch";
import BudgetCreate from "@/views/create/BudgetCreate";
import BudgetUpdate from "@/views/update/BudgetUpdate";
import BudgetShow from "@/views/show/BudgetShow";

export default {
    path: '/budget',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'budget-search', component: BudgetSearch },
        { path: 'create', name: 'budget-create', component: BudgetCreate },
        { path: 'edit/:id', name: 'budget-edit', component: BudgetUpdate, props: true },
        { path: 'show/:id', name: 'budget-show', component: BudgetShow, props: true },
    ],
}
