<template>
    <painel-component :itens="itensOne">
        <div class="filter-position" v-if="btnFilter">
            <button-one class="btn-filter" @click="filterWindow"/>
        </div>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDate.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                     :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'">
                        <button-one class="mx-1" @submit="submit" :icon="'search'"/>
                        <button-float class="mx-1" :itens="itensTwo" v-if="buttonCheck.create"/>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div v-else>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4'"
                                   v-for="(banner, index) in objectReturn.data" :key="index">
                    <div class="card-container">
                        <div class="dates-card">
                            <button class="btn btn-secondary buttonRound" type="button" @click="show(index)">
                                <i class="eye icon"></i>
                            </button>

                            <input type="file" name="file" id="formFile" @change="onFileChange(banner.id)"
                                   accept="image/*">
                            <label for="formFile" class="labelFile">
                                <span class="btn btn-secondary buttonRound"><i class="image icon"></i></span>
                            </label>
                        </div>

                        <div class="banner">
                            <img :src="banner.banner_link" alt="">
                        </div>

                        <article>
                            <p>Criador:</p>
                            <div class="c-profile__list">
                                <a class="c-profile" :data-user="banner.creator_user_name"
                                   :style="{ backgroundImage: 'url(' + banner.photo_creator + ')' }">
                                </a>
                            </div>
                            <p>{{ banner.creator_user_name }}</p>
                        </article>

                        <div class="buttons">
                            <button tooltip="Editar" class="primary" v-if="buttonCheck.edit"
                                    @click="routeEdit(banner.id)">
                                <i class="edit icon"></i>
                            </button>

                            <button tooltip="Ver" class="primary" v-if="buttonCheck.show" @click="routeShow(banner.id)">
                                <i class="eye icon"></i>
                            </button>

                            <button tooltip="Deletar" class="primary" v-if="buttonCheck.delete"
                                    @click=deleteSubmit(banner)>
                                <i class="trash icon"></i>
                            </button>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <button-pagination :pagination="objectReturn" :offset="3" @paginate="loadBanners"/>
            <div>
                <vue-easy-lightbox :visible="visibleRef" :imgs="photo" :index="indexRef" @hide="onHide"/>
            </div>
        </div>
    </painel-component>
</template>

<script>
import ButtonPagination from "@/components/buttons/ButtonPagination";
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import InputComponent from "@/components/form/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";

import {useToast} from "vue-toastification";
import {ACTION_MESSAGES} from "@/configs/constants";
import VueEasyLightbox, {useEasyLightbox} from 'vue-easy-lightbox'

import swal from "sweetalert";

const toast = useToast();

export default {
    name: "BannerSearch",
    components: {
        ButtonPagination,
        PainelComponent,
        ButtonOne,
        ButtonFloat,
        InputComponent,
        RowComponent,
        RowColComponent,
        LoderSendComponent,
        Multiselect,
        VueEasyLightbox
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Banner')
        this.loadBanners(1)
        this.resizeWindow()
        this.checkACL()
    },

    setup() {
        const {
            show, onHide, changeIndex,
            visibleRef, indexRef, imgsRef
        } = useEasyLightbox({initIndex: 0})
        return {
            visibleRef,
            indexRef,
            imgsRef,
            show,
            onHide,
            changeIndex
        }
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "list alternate"
            },

            itensTwo: {
                route: "/banner/create",
                icon: "plus",
                name: ''
            },

            page: 1,
            pagination: [],

            formDate: {
                code: '',
                name: '',
                banner: '',
                status: 1,
            },

            formDateBanner: {
                id: '',
                banner: ''
            },

            showLoader: true,
            searchSubmit: false,

            images: '',
            photo: [],

            buttonCheck: {
                edit: false,
                delete: false,
                create: false,
                show: false
            },

            filter: true,
            btnFilter: false,
        }
    },

    methods: {
        submit() {
            this.loadBanners(1)
            this.searchSubmit = true;
        },

        filterWindow() {
            this.filter = !this.filter
        },

        resizeWindow() {
            if (window.innerWidth <= 991) {
                this.filter = false
                this.btnFilter = true
            } else {
                this.filter = true
                this.btnFilter = false
            }
        },

        routeEdit(id) {
            this.$router.push({name: 'banner-edit', params: {id: id}})
        },

        routeShow(id) {
            this.$router.push({name: 'banner-show', params: {id: id}})
        },

        getImages() {
            for (let p of this.images) {
                this.photo.push(p.banner_link)
            }
            return this.photo
        },

        onFileChange(id) {
            let files = event.target.files || event.dataTransfer.files
            if (!files.length)
                return
            this.formDateBanner.id = id
            this.formDateBanner.banner = files[0]
            if (files[0].type.indexOf('image') == 0)
                this.submitFiles()
        },

        submitFiles() {
            this.$store.dispatch('storeBannerUp', this.formDateBanner)
                .then(() => {
                    this.showError = false
                    this.errors = []
                    this.loadBanners(1)
                    toast.success("Arquivo enviado com sucesso!", {timeout: 2000});
                }).catch((r) => {
                this.showError = true;
                this.errors = r.response.data.errors;
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        loadBanners(page) {
            this.showLoader = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadBanners', {...this.params, page})
                    .then((r) => {
                        this.showLoader = false
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 10000});
                            }
                        }
                        this.images = r.data
                        this.getImages()
                    }).catch(() => {
                    this.showLoader = false
                    this.searchSubmit = false;
                });
            }
        },

        deleteSubmit(banner) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${banner.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyBanner', banner.id)
                        .then(() => {
                            this.loadBanners(1)
                            toast.success(ACTION_MESSAGES.delete, {timeout: 10000});
                        })
                        .catch(() => {
                            toast.error(`Não foi possível deletar ${banner.name} `, {timeout: 10000});
                        })
                }
            });
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 26)
                .then((r) => {
                    if (r.data.indexOf('banner-search') !== -1) {
                        this.showLoader = false

                        if (r.data.indexOf('banner-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('banner-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('banner-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('banner-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText(){
           return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        objectReturn() {
            return this.$store.state.banner.items
        },

        params() {
            return {
                page: this.objectReturn.current_page,
                name: this.formDate.name,
                status: this.formDate.status,
            }
        },
    },
}
</script>

<style scoped>
.card-container {
    padding: 0;
    color: #2f3032;
    width: 350px;
    max-width: 100%;
    overflow: hidden;
    min-height: 310px;
    margin: 20px auto;
    position: relative;
    text-align: center;
    background-color: #ffffff;
    border: solid 1px #ededed;
    box-shadow: 50px 10px 15px rgb(0 0 0 / 35%);
}

.banner {
    width: 100%;
    height: 200px;
    object-fit: cover;
    overflow: hidden;
}

.banner > img {
    width: 100%;
    height: 100%;
}

.dates-card {
    top: 0;
    opacity: 0;
    height: 45%;
    width: 100%;
    display: flex;
    place-items: center;
    position: absolute;
    place-content: center;
    transition: opacity 0.5s;
    background-color: rgba(0, 0, 0, 0.5);
}

.dates-card:hover {
    opacity: 1;
    transition: opacity 0.5s;
}

.card-container p {
    margin: 0;
}

.buttons {
    bottom: 0;
    width: 100%;
    display: flex;
    min-height: 60px;
    position: absolute;
    border-top: 1px solid #ededed;
}

button.primary {
    width: 100%;
    height: 60px;
    color: #7e7e7e;
    background-color: #f5f5f5;
    border: 1px solid #fdfdfd;
}

.buttonRound {
    width: 35px;
    height: 35px;
    padding: 6px;
    font-size: 15px;
    margin-right: 7px;
    border-radius: 50px;
}

.labelFile {
    border: 0;
    cursor: pointer;
    background-color: transparent;
}

.c-profile {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
    margin-bottom: 5px;
    display: inline-block;
    border: 4px solid white;
    background: #FFFFFF center center;
    background-size: cover;
    transition: all 200ms ease;
    box-shadow: 0 3px 8px rgba(44, 44, 84, 0.2);
}

.c-profile:nth-of-type(n+2) {
    margin-left: -42px;
}

.c-profile:hover {
    transform: scale(1.2);
    box-shadow: 0 8px 20px rgba(44, 44, 84, 0.2);
}

.c-profile__list {
    width: auto;
    position: relative;
    display: inline-flex;
}

.c-profile__list:hover .c-profile:nth-of-type(n+2) {
    margin-left: 7px;
}

article {
    width: 100%;
    margin-top: 10px;
    max-width: 600px;
    margin-bottom: 70px;
}

@-webkit-keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>