<template>
  
    <textarea
            :name="nameInput"
            class="form-control"
            :placeholder="placeholder"
            :required="requiredInput"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            :cols="cols"
            :rows="rows"/>
</template>

<script>
export default {
    name: "InputTextArea",


    props: {

        modelValue: {
            type: String,
            default: '',
            required: false
        },


        nameInput: {
            "required": false,
            "type": String,
            "default": ''
        },


        placeholder: {
            "required": false,
            "type": String,
            "default": ''
        },

        requiredInput: {
            "required": false,
            "type": Boolean,
            "default": false
        },

        cols: {
            "required": false,
            "type": Number,
        },

        rows: {
            "required": false,
        }
    },
}
</script>

<style scoped>
textarea {
    resize: none;
}
</style>