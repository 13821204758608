import AdminComponent from "@/components/dashboard/AdminComponent";
import TaskTypeCreate from "@/views/create/TaskTypeCreate";
import TaskTypeSearch from "@/views/search/TaskTypeSearch";
import TaskTypeUpdate from "@/views/update/TaskTypeUpdate";
import TaskTypeShow from "@/views/show/TaskTypeShow";

export default {
    path: '/task-type',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'create', name: 'task-type-create', component: TaskTypeCreate },
        { path: 'search', name: 'task-type-search', component: TaskTypeSearch },
        { path: 'edit/:id', name: 'task-type-edit', component: TaskTypeUpdate, props: true },
        { path: 'show/:id', name: 'task-type-show', component: TaskTypeShow, props: true },
    ],
}

