<template>
    <painel-component :itens="itensOne">

        <error-component :errors="errors" v-if="showError"></error-component>

        <loder-send-component :text-title="'Carregando'" v-if="showLoder"></loder-send-component>

        <div class="tabs effect-3" v-else>

            <input type="radio" id="tab-1" name="tab-effect-3" checked="checked"
                   :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3" v-if="picked == '2'">
            <span v-if="picked == '2'">
                <i class="user circle icon"></i>
                <p>Sócio</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="envelope icon"></i>
                <p>Email</p>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="phone square icon"></i>
                <p>Telefone</p>
            </span>

            <input type="radio" id="tab-5" name="tab-effect-3" v-if="picked == '2'">
            <span v-if="picked == '2'">
                <i class="tasks icon"></i>
                <p>Atividades econômicas secundárias</p>
            </span>

            <div class="line ease" :class="{ 'line-3': tabMobile }"></div>

            <div class="tab-content">

                <section id="tab-item-1">
                    <form>

                        <div class="aux mb-4 mt-3">
                            <div class="avatar-form">
                                <img v-if="formDate.photo" :src="formDate.photo" class="avatar__image">
                                <img v-else src="@/assets/img/avatar.png" alt="" class="avatar__image">
                            </div>
                            <p>{{ formDate.name }}</p>
                        </div>

                        <div v-if="picked == '2'">
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>CNPJ:</label>
                                    <input-component :type-input="'text'" :modelValue="formDate.cnpj" disabled>
                                    </input-component>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Porte:</label>
                                    <input-component :type-input="'text'" :modelValue="formDate.size" disabled>
                                    </input-component>
                                </row-col-component>

                                <row-col-component :class="{ 'formError': errors.service }"
                                                   :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Serviço:</label>
                                    <Multiselect disabled v-model="formDate.service" :searchable="true"
                                                 :options="selectServiceClient"
                                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Status:</label>
                                    <Multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                                 :noResultsText="this.$store.state.var.selectNotSearch" disabled/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                    <label>Nome:</label>
                                    <input-component :type-input="'text'" :modelValue="formDate.name" disabled>
                                    </input-component>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                    <label>Nome Fantasia:</label>
                                    <input-component :type-input="'text'" :modelValue="formDate.name_f" disabled>
                                    </input-component>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                    <label>Apelido:</label>
                                    <input-component :type-input="'text'" :modelValue="formDate.surname" disabled>
                                    </input-component>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <label>Natureza Jurídica:</label>
                                    <input-component :type-input="'text'" :modelValue="formDate.legal_nature" disabled>
                                    </input-component>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Status da Empresa:</label>
                                    <input-component :type-input="'text'" :modelValue="formDate.company_status" disabled
                                                     v-model="formDate.company_status">
                                    </input-component>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Capital Social:</label>
                                    <input-component :type-input="'text'" :modelValue="formDate.share_capital" disabled>
                                    </input-component>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                    <label>Atividade Econômica Principal:</label>
                                    <input-component :type-input="'text'" :modelValue="formDate.main_activity" disabled
                                                     v-model="formDate.main_activity">
                                    </input-component>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Data de Abertura:</label>
                                    <input-component :type-input="'text'" :modelValue="formDate.date_open" disabled>
                                    </input-component>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Matriz:</label>
                                    <input-component :type-input="'text'" :modelValue="formDate.headquarters" disabled>
                                    </input-component>
                                </row-col-component>
                            </row-component>
                        </div>

                        <div v-else>
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                    <label>Nome:</label>
                                    <input-component :type-input="'text'" :modelValue="formDate.name" disabled>
                                    </input-component>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.surname }">
                                    <label>Apelido:</label>
                                    <input-component :type-input="'text'" :modelValue="formDate.surname" disabled
                                                     v-model="formDate.surname">
                                    </input-component>
                                </row-col-component>

                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>CPF:</label>
                                    <input-component :type-input="'text'" :modelValue="formDate.cpf" disabled>
                                    </input-component>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>RG:</label>
                                    <input-component :type-input="'text'" :modelValue="formDate.rg" disabled>
                                    </input-component>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Data Nasc:</label>
                                    <input-component :type-input="'text'" :modelValue="formDate.birth_date" disabled>
                                    </input-component>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Status:</label>
                                    <Multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                                 :noResultsText="this.$store.state.var.selectNotSearch" disabled/>
                                </row-col-component>
                            </row-component>
                        </div>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>CEP:</label>
                                <input-component :type-input="'text'" :modelValue="formDate.cep" disabled>
                                </input-component>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Cidade:</label>
                                <input-component :type-input="'text'" :modelValue="formDate.city" disabled>
                                </input-component>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Estado:</label>
                                <input-component :type-input="'text'" :modelValue="formDate.state" disabled>
                                </input-component>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Bairro:</label>
                                <input-component :type-input="'text'" :modelValue="formDate.district" disabled>
                                </input-component>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                                <label>Endereço:</label>
                                <input-component :type-input="'text'" :modelValue="formDate.address" disabled>
                                </input-component>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3  col-xl-3  col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>País:</label>
                                <input-component :type-input="'text'" :modelValue="formDate.country" disabled>
                                </input-component>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Refêrencia:</label>
                                <input-text-area v-model="formDate.complement" :model-value="formDate.complement"
                                                 disabled
                                                 :rows="4">
                                </input-text-area>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">

                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="membersAdd.length == 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <div class="border-content" v-for="(member, index) in membersAdd" :key="index" v-else>
                        <form>
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                    <label>Nome:</label>
                                    <input-component :type-input="'text'" v-model="member.name" disabled>
                                    </input-component>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>CPF/CNPJ:</label>
                                    <input-component :type-input="'text'" v-model="member.cpf_cnpj" disabled>
                                    </input-component>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Idade:</label>
                                    <input-component :type-input="'text'" :v-model="member.age" disabled>
                                    </input-component>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Qualificação:</label>
                                    <input-component :type-input="'text'" v-model="member.qualification" disabled>
                                    </input-component>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>

                </section>

                <section id="tab-item-3">

                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="emailsAdd.length == 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <div class="border-content" v-for="(email, index) in emailsAdd" :key="index" v-else>
                        <form>
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Nome:</label>
                                    <input-component :type-input="'text'" v-model="email.name" disabled>
                                    </input-component>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                    <label>Email:</label>

                                    <input-component :type-input="'email'" v-model="email.email" disabled>
                                    </input-component>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>
                </section>

                <section id="tab-item-4">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="phonesAdd.length == 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <div class="border-content" v-for="(phone, index) in phonesAdd" :key="index" v-else>
                        <form>
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Nome:</label>
                                    <input-component :type-input="'text'" v-model="phone.name" disabled>
                                    </input-component>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                    <label>Telefone:</label>
                                    <input-component :type-input="'text'" v-model="phone.phone" disabled>
                                    </input-component>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>
                </section>

                <section id="tab-item-5">

                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="activityAdd.length == 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <div class="border-content" v-for="(secondary_activity, index) in activityAdd" :key="index" v-else>
                        <form>
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Código:</label>
                                    <input-component :type-input="'text'" v-model="secondary_activity.code" disabled>
                                    </input-component>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'">
                                    <label>Descrição:</label>
                                    <input-component :type-input="'text'" v-model="secondary_activity.desc" disabled>
                                    </input-component>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>

                </section>

                <row-component>
                    <row-col-component
                            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <button-float style="width: 100%" :itens="itensTwo"/>
                    </row-col-component>
                </row-component>

            </div>
        </div>

    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import LoderSendComponent from "@/components/LoderSendComponent";
import Multiselect from "@vueform/multiselect";
import InputTextArea from "@/components/form/InputTextArea";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "ClientShow",

    created() {
        this.$store.dispatch('updateNamePage', 'Cliente')
        this.loadPageItens()
        this.showLoder = true
        this.checkACL();
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "eye"
            },

            itensTwo: {
                route: "/client/search",
                icon: "reply all",
                name: 'Voltar'
            },

            picked: '2',

            formDate: {
                cpf: '',
                cnpj: '',
                size: '',
                name: '',
                name_f: '',
                legal_nature: '',
                share_capital: '',
                main_activity: '',
                date_open: '',
                birth_date: '',
                headquarters: '',
                rg: '',
                type: '2',
                company_status: '',
                emails: [],
                phones: [],
                members: [],
                secondary_activitys: [],
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: '',
                photo: '',
                service: '',
                status: ''
            },

            errors: [],
            showError: false,
            showLoder: false,
        }
    },

    methods: {

        loadPageItens() {
            this.$store.dispatch('loadClient', this.id).then((r) => {
                this.formDate = r;
                this.picked = r.type
            }).catch(() => {
                this.showLoder = false;
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 4)
                .then((r) => {
                    if (r.data.indexOf('client-search') !== -1) {
                        this.showLoder = false;
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            });
        },
    },

    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ErrorComponent,
        LoderSendComponent,
        Multiselect,
        InputTextArea
    },

    computed: {
        selectInput() {
            return this.$store.state.var.selectActiveInactive;
        },

        selectServiceClient() {
            return this.$store.state.var.selectServiceClient;
        },

        tabMobile() {
            if (this.picked == '2')
                return false
            return true
        },

        emailsAdd() {
            return this.formDate.emails;
        },

        membersAdd() {
            return this.formDate.members;
        },

        phonesAdd() {
            return this.formDate.phones;
        },

        activityAdd() {
            return this.formDate.secondary_activitys;
        },
    }
}
</script>

<style src="../../../src/assets/css/formTab.css"></style>

<style scoped>
.alert p {
    margin: 0;
}
</style>




