export default {

    AUTH_USER_OK(state, user) {
        state.authenticate = true,
            state.me = user
    },

    AUTH_USER_LOGOF(state) {
        state.me = {},
            state.authenticate = false


    },

    CHANGE_URL_BACK(state, url) {
        state.urlBack = url
    },

    UPDATE_LINK_PHOTO(state, photo) {
        state.me.photo = photo
    }

}