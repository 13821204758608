<template>
    <notify-component/>

    <nav-menu v-if="!currentRouteNameLogin" :openMenu="active" @menu-resp="toggleMenu"></nav-menu>

    <div class="wrapperComponent" v-if="!currentRouteNameLogin" :class="{ toggle: active }">
        <div class="menuTopBar">
            <h1> {{ currentRouteName }}</h1>
            <div class="menu-toggle" @click="toggleMenu()">
                <div class="hamburger-menu-button">
                    <div class="hamburger-menu" :class="{ open: active }"></div>
                </div>
            </div>
        </div>

        <router-view v-slot="{ Component, route }">
            <transition name="fade" mode="out-in">
                <div :key="route.name">
                    <component :is="Component"/>
                </div>
            </transition>
        </router-view>

    </div>
    <div v-else>
        <router-view></router-view>
    </div>
</template>

<script>

import NavMenu from "@/components/nav/NavMenu";
import {useToast} from "vue-toastification";
import NotifyComponent from "@/components/alerts/NotifyComponent";

const Pusher = require('pusher-js');
const sound = require('@/assets/sound_one.mp3')

const toast = useToast();

export default {
    name: "WrapperComponent",

    data() {
        return {
            active: false,
            not: []
        }
    },

    mounted() {
        window.addEventListener("offline", () => {
            toast.error("Sem conexão com a internet", {timeout: false})
            this.$store.commit('PRELOADER', true)
        }, false)

        window.addEventListener("online", () => {
            toast.success("Conectado com internet", {timeout: 1500})
            setTimeout(() => {
                toast.clear()
            }, 1500)
            this.$store.commit('PRELOADER', false)
        }, false)

        const self = this;
        let pusherKey = '';

        if (process.env.NODE_ENV === 'production') {
            pusherKey = 'f4111961e7c9815a440a';
        } else {
            pusherKey = '8f5167f617499ff51046';
        }

        let pusher = new Pusher(pusherKey, {
            cluster: 'us2'
        });

        let channel = pusher.subscribe('my-channel');
        channel.bind('my-event', function (data) {
            let objectChannel = data.message
            let me = self.me
            self.not = objectChannel

            let audio = new Audio(sound)

            if (me) {
                if (me.id === objectChannel.idUser) {
                    self.$store.commit('addToast', {
                        nameCreator: self.not.nameCreator,
                        photoCreator: self.not.photoCreator,
                        type: self.not.type,
                        message: self.not.message
                    });
                    audio.play()
                }
            }
        });
    },

    computed: {
        currentRouteName() {
            return this.$store.state.var.namePage;
        },

        currentRouteNameLogin() {
            return this.$route.name == 'login';
        },

        me() {
            return this.$store.state.auth.me
        },
    },

    components: {
        NotifyComponent,
        NavMenu
    },

    methods: {
        toggleMenu() {
            this.active = !this.active
        },
    },
}

</script>

<style scoped>
.wrapperComponent {
    width: calc(100% - 260px);
    float: right;
    transition: all 0.5s linear;
}

.menuTopBar {
    position: relative;
    background-color: #ebebeb;
    height: 75px;
    -webkit-box-shadow: -3px 44px 38px -25px rgba(184, 184, 184, 1);
    -moz-box-shadow: -3px 44px 38px -25px rgba(184, 184, 184, 1);
    box-shadow: -3px 44px 38px -25px rgba(184, 184, 184, 1);
    padding: 20px 2%;

}

.menuTopBar h1 {
    font-family: 'Comfortaa', cursive;
    font-size: 30px;
    color: #1b1c1d;
    font-weight: 100;
    text-transform: capitalize;
    padding-left: 60px;
}

@media (max-width: 441px) {
    .menuTopBar h1 {
        font-size: 1.2rem;
        margin-top: 5px;
    }
}


.menuTopBar h1 i {
    color: #f38f0c;
    font-size: 25px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

@media (max-width: 991px) {
    .wrapperComponent {
        width: 100%;
    }

    .toggle {
        transform: translate3d(260px, 0, 0);
    }
}
</style>